import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {SubSink} from 'subsink';

@Component({
    selector: 'app-topbar',
    styleUrls: ['app.topbar.component.css'],
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
                    <div class="layout-topbar-logo-wrapper">
                        <a href="#" class="layout-topbar-logo">
                            <img src="assets/layout/images/logo/logo_transparent.png" alt="mirage-layout"/>
                        </a>
                    </div>

                    <a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
                        <i class="pi pi-bars"></i>
                    </a>
                </div>
            </div>
            <app-breadcrumb *ngIf="appMain.envDesktop" (toolbarActionButton)="onToolbarButtonClick($event)"></app-breadcrumb>
        </div>
    `
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    private subs = new SubSink();

    activeItem: number;

    user: any;

    @Output() toolbarActionButton = new EventEmitter();

    constructor(public appMain: AppMainComponent,
    ) {}

    mobileMegaMenuItemClick(index) {
        this.appMain.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onToolbarButtonClick(action: string) {
        this.toolbarActionButton.emit(action);
    }
}
