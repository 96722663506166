import * as ContactFormActions from './contact-form.actions';

export interface State {
    contactFormDialogShow: boolean;
}

const initialState = {
    contactFormDialogShow: false,
};

export function contactFormReducer(state: State = initialState, action: ContactFormActions.ContactFormActions) {
    switch (action.type) {
        case ContactFormActions.ContactFormActionTypes.ContactFormDialogShow:
            state = {
                ...state,
                contactFormDialogShow: true
            };
            break;
        case ContactFormActions.ContactFormActionTypes.ContactFormDialogHide:
            state = {
                ...state,
                contactFormDialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
