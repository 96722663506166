import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {GenericText, GenericTextInput} from '../../../../generated/graphql';

export enum GenericTextsActionTypes {
    GenericTextsFetchLazyMeta = '[@GenericTexts] FetchLazyMeta',
    GenericTextsSet = '[@GenericTexts] Set',
    GenericTextSet = '[@GenericText] Set',
    GenericTextsFetch = '[@GenericTexts] Fetch',
    GenericTextFetch = '[@GenericText] Fetch',
    GenericTextCreate = '[@GenericText] Create',
    GenericTextCreateSuccess = '[@GenericText] CreateSuccess',
    GenericTextUpdate = '[@GenericText] Update',
    GenericTextUpdateSuccess = '[@GenericText] UpdateSuccess',
    GenericTextDelete = '[@GenericText] Delete',
    GenericTextDeleteSuccess = '[@GenericText] DeleteSuccess',
    GenericTextsExport = '[@GenericTexts] Export',
    GenericTextsExportSuccess = '[@GenericTexts] ExportSuccess',
    GenericTextsError = '[@GenericTexts] Error',
}

export class GenericTextsSet implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {genericTexts: GenericText[], totalRows: number }) {}
}

export class GenericTextsFetch implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {lazyLoad: LazyLoadMeta, type: number}) {}
}

export class GenericTextSet implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {genericText: GenericText}) {}
}

export class GenericTextFetch implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {id: number}) {}
}

export class GenericTextsExport implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextsExport;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextsExport, spinner: 'sp_content'}],
        type: 'global'
    };
}

export class GenericTextsExportSuccess implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextsExportSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextsExport, spinner: 'sp_content'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('generic_texts.exported')],
        type: 'global'
    };

    constructor(public payload: {type: string, fileContentBase64: string }) {}
}

export class GenericTextCreate implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {genericText: GenericTextInput}) {}
}

export class GenericTextCreateSuccess implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('generic_texts.created')],
        type: 'global'
    };
}

export class GenericTextUpdate implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {genericText: GenericTextInput}) {}
}

export class GenericTextUpdateSuccess implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('generic_texts.updated')],
        type: 'global'
    };
}

export class GenericTextDelete implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {id: number, type: number}) {}
}

export class GenericTextDeleteSuccess implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_content'}],
        // ids: [{name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('generic_texts.deleted')],
        type: 'global'
    };
}

export class GenericTextsFetchLazyMeta implements Action {
    readonly type = GenericTextsActionTypes.GenericTextsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export class GenericTextsError implements Action, LoadingAction {
    readonly type = GenericTextsActionTypes.GenericTextsError;
    fxLoading = {
        action: 'remove',
        ids: [
            // {name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_lll'},
            // {name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_llcl'},
            // {name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_llb'},
            // {name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_lll'},
            // {name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_lll'},
            {name: GenericTextsActionTypes.GenericTextsFetch, spinner: 'sp_content'},
            {name: GenericTextsActionTypes.GenericTextFetch, spinner: 'sp_content'},
            {name: GenericTextsActionTypes.GenericTextUpdate, spinner: 'sp_content'},
            {name: GenericTextsActionTypes.GenericTextCreate, spinner: 'sp_content'},
            {name: GenericTextsActionTypes.GenericTextDelete, spinner: 'sp_content'},
            {name: GenericTextsActionTypes.GenericTextsExport, spinner: 'sp_content'},
        ],
        type: 'all'
    };

    constructor() {}
}

export type GenericTextsActions =
    GenericTextsFetch |
    GenericTextsSet |
    GenericTextFetch |
    GenericTextSet |
    GenericTextCreate |
    GenericTextCreateSuccess |
    GenericTextUpdate |
    GenericTextUpdateSuccess |
    GenericTextDelete |
    GenericTextDeleteSuccess |
    GenericTextsFetchLazyMeta |
    GenericTextsError;
