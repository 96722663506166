import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
    TagGoodPracticeInput,
} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {TagsService} from '../../../components/tags/tags.service';
import {UploadManager} from '../../../shared/classes/upload-manager';
import {currentStateGetCurrentProject} from '../../../store/current-state/current-state.selector';
import {take} from 'rxjs/operators';
import {TagTypes} from '../../../shared/enums/entity-enums';
import {AppState} from '../../../store/app.reducer';
import {TagGoodPracticeCreate} from '../../../components/tags/tag-good-practice/store/tag-good-practice.actions';
import {generalGetGoodPracticeCreateForm} from '../../../store/general/general.selector';
import {GeneralSaveFormAction} from '../../../store/general/general.actions';
import {AppActions} from '../../../store/app-actions.service';
import {GlobalService} from '../../../shared/services/global.service';
import {TagDialogBaseComponentComponent} from '../../../components/tags/tag-dialog-base-component/tag-dialog-base-component.component';
import {DialogHide} from './store/good-practice-create-form.actions';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {Gallery} from '@ngx-gallery/core';

@Component({
  selector: 'app-good-practice-create',
  templateUrl: './good-practice-create.component.html',
  styleUrls: ['./good-practice-create.component.css']
})
export class GoodPracticeCreateComponent extends TagDialogBaseComponentComponent implements OnInit, OnDestroy {

    tagType: number = TagTypes.TAG_GOOD_PRACTICE;

    @ViewChild('goodPracticeImageUpload') goodPracticeImageUpload;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected tagsService: TagsService,
        private appActions: AppActions,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
        protected gallery: Gallery,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {

        this.uploadManager = new UploadManager(this.store, this.tagsService);

        this.subs.sink = this.uploadManager.uploadingState.subscribe((state) => {
            this.uploadingState = state;
        });

        this.subs.sink = this.store.select('tagGoodPracticeCreate').pipe().subscribe((data) => {

            if (this.display !== data.dialogShow) {
                this.display = data.dialogShow;

                if (this.display) {
                    this.store.select(generalGetGoodPracticeCreateForm).pipe(take(1)).subscribe((formData) => {
                        if (formData?.data == null) {
                            this.store.pipe(take(1), select(currentStateGetCurrentProject)).subscribe(project => {
                                this.selectedProject = project;
                                this.tagForm.form.patchValue({
                                    project: this.selectedProject
                                });
                            });
                        } else {
                            const fData = JSON.parse(formData.data);
                            this.tagForm.form.patchValue({
                                ...fData,
                                occurredAt: fData.occurredAt ? new Date(fData.occurredAt) : null,
                            });
                        }

                        this.subs.sink = this.tagForm.valueChanges.subscribe(() => {
                            this.appActions.dispatch(new GeneralSaveFormAction({
                                id: 'goodPracticeCreateForm',
                                data: JSON.stringify(this.tagForm.value)
                            })).toPromise().then();
                        });
                    });
                }
            }
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentProject)).subscribe(
            (project) => {
                this.selectedProject = project;
            }
        );
    }

    ngOnDestroy() {
        this.clearUploadImages();
        this.subs.unsubscribe();
    }

    clearUploadImages() {
        if (this.goodPracticeImageUpload) {
            if (this.imagesStored) {
                this.uploadManager.clearUploadedFilesList();
            }
            this.goodPracticeImageUpload.clear();
        }
    }

    onHide(event) {
        this.clearUploadImages();
        this.store.dispatch(new DialogHide());
    }

    onCancel() {
        this.store.dispatch(new DialogHide());
    }

    onSubmit(form: NgForm) {
        const goodPractice: TagGoodPracticeInput = {
            project: form.value.project.id,
            location: form.value.location,
            assignedToSubjects: form.value.assignedToSubjects.map(s => s.id) ?? [],
            assignedToSubjectNote: form.value?.assignedToSubjectNote ?? null,
            assignedToUser: form.value.assignedToUser?.id ?? null,
            areaOf: form.value.areaOf?.id ?? null,
            description: form.value.description,
            images: this.uploadManager.getFilesNameArray(),
            note: form.value.note,
            occurredAt: form.value.occurredAt,
        };

        this.imagesStored = true;
        this.store.dispatch(new TagGoodPracticeCreate({goodPractice}));
    }
}
