import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, NgForm} from '@angular/forms';
import {UserPartComponent} from '../../../forms/parts/user-part.component';
import {AddressInput, User, UserInput} from '../../../../generated/graphql';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Params} from '@angular/router';
import {delay, map} from 'rxjs/operators';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {AddMessage} from '../../../loading/loading.action';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {MessageService} from 'primeng/api';
import {AppState} from '../../../store/app.reducer';
import {UserCreate, UserFetch, UserSet, UserUpdate} from '../store/users.actions';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {UserFormDialogHide} from '../../../forms/user-form/store/user-form.actions';

@Component({
    selector: 'app-users-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('f') userForm: NgForm;
    @ViewChild(UserPartComponent) userPart: UserPartComponent;

    display: boolean;
    newUser = true;
    public signatureFileBase64: string;
    public loadedUser: User;
    public signaturePreview: SafeResourceUrl;
    private editId: number;
    private editMode: boolean;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected route: ActivatedRoute,
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        private ps: CustomPermissionsService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.subs.sink = this.route.params.pipe(delay(0)).subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            if (this.editMode) {
                this.fetchUser(this.editId);

                this.subs.sink = this.store.select('users').pipe(
                    delay(0),
                    map(usersState => {
                        return {
                            loadedUser: usersState.loadedUser,
                        };
                    })).subscribe(
                    ({
                         loadedUser: loadedUser,
                     }) => {
                        if (loadedUser) {
                            this.editUser(loadedUser);
                            this.store.dispatch(new UserSet({user: null}));
                        }
                    });
            }
        });

        this.buttons = [
            {
                id: 'saveUserButton',
                name: 'users.save',
                action: 'onSaveUser',
                class: 'p-button-success',
                icon: 'pi pi-save p-mr-1',
                disabled: false,
            }
        ];

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.ps.hasPermission('createUsers').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'createUserButton',
                            name: 'users.create',
                            action: 'onCreateUser',
                            class: 'p-button-primary',
                            icon: 'pi pi-plus',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchUser(userId: number) {
        this.store.dispatch(new UserFetch({userId}));
    }

    editUser(user: User) {
        this.userForm.resetForm();
        this.userForm.form.patchValue({
                userData: {
                    ...user,
                    userId: user.id,
                },
                addressData: {
                    ...user.address,
                },
                signatureData: {
                    signatureDescription: user.signatureDescription
                }
            }
        );

        this.newUser = false;
        this.userPart.changePassword = true;
        this.userPart.onChangePasswordToggle();
        this.display = true;
        this.loadedUser = user;
    }

    onHide(event) {
        if (!this.newUser) {
            this.userForm.resetForm();
        }

        this.newUser = true;
        this.store.dispatch(new UserFormDialogHide());
    }

    onCancel() {
        this.userForm.resetForm();
        this.store.dispatch(new UserFormDialogHide());
    }

    onReset() {
        this.userForm.resetForm();
    }

    onSaveUser() {
        this.userForm.ngSubmit.emit();
    }

    onSubmit(form: NgForm) {
        if (form.valid) {
            // let address = null;
            // if (
            //     form.value.addressData.street &&
            //     form.value.addressData.zip &&
            //     form.value.addressData.city &&
            //     form.value.addressData.country
            // ) {
            //     address = {
            //         id: this.loadedUser?.address?.id ?? null,
            //         street: form.value.addressData.street,
            //         zip: form.value.addressData.zip,
            //         city: form.value.addressData.city,
            //         country: form.value.addressData.country,
            //     } as AddressInput;
            // }

            const userId = form.value.userData.userId ? form.value.userData.userId : null;

            const user: UserInput = {
                id: userId,
                firstName: form.value.userData.firstName,
                lastName: form.value.userData.lastName,
                email: form.value.userData.email,
                phoneNumber: form.value.userData.phoneNumber,
                address: null,
                password: form.value.userData.password,
                blocked: form.value.userData.blocked ? form.value.userData.blocked : false,
                emailLogin: form.value.userData.emailLogin ? form.value.userData.emailLogin : false,
                googleLogin: form.value.userData.googleLogin ? form.value.userData.googleLogin : false,
                facebookLogin: form.value.userData.facebookLogin ? form.value.userData.facebookLogin : false,
                superadmin: form.value.userData.superadmin ? form.value.userData.superadmin : false,
                signatureFileBase64: this.signatureFileBase64 ?? null,
                signatureDescription: form.value.signatureData.signatureDescription
            };

            if (userId) {
                this.store.dispatch(new UserUpdate({user}));
            } else {
                this.store.dispatch(new UserCreate({user}));
            }

            // this.signaturePreview = null;
        } else {
            Object.keys(form.controls).forEach(group => {
                const formGroup = form.controls[group] as FormGroup;
                Object.keys(formGroup.controls).forEach(key => {
                    formGroup.controls[key].markAsTouched();
                });
            });

            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('errors.invalid_form_fields')], 'global'));
        }
    }

    onSignatureUpload(signatureFileBase64: any) {
        this.signatureFileBase64 = signatureFileBase64;
        if (signatureFileBase64.length === 0) {
            this.signaturePreview = null;
        } else {
            this.signaturePreview = this.sanitizer.bypassSecurityTrustResourceUrl(signatureFileBase64);
        }
    }
}
