import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {BaseComponent} from '../../../../shared/base/base.component';
import {DefectCreateComponent} from '../../../../forms/tags/defect-create/defect-create.component';
import {map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../../shared/model/lazy-load-meta.model';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {ProxyService} from '../../../../shared/services/proxy.service';
import {GlobalService} from '../../../../shared/services/global.service';
import {CustomPermissionsService} from '../../../../shared/services/custom-permissions.service';
import {currentStateGetCurrentSubject} from '../../../../store/current-state/current-state.selector';
import {EntityTypes, TagTypes} from '../../../../shared/enums/entity-enums';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {MediaService} from '../../../../shared/services/media.service';
import {getDefects} from '../store/tag-defects.selector';
import {EnumsHelper} from '../../../../shared/helpers/enums-helper.service';
import {StatisticsService} from '../../../../shared/services/statistics.service';
import {Location} from '@angular/common';
import {TagDefect} from '../../../../../generated/graphql';
import {Table} from 'primeng/table';
import {AppState} from '../../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../../fixed-toolbar/fixed-toolbar.action';
import {DialogShow} from '../../../../forms/tags/defect-create/store/defect-create-form.actions';
import {TagDefectDelete, TagDefectsFetch} from '../store/tag-defects.actions';
import * as SendActions from '../../../../forms/send-tag-form/store/send-form.actions';

@Component({
    selector: 'app-tag-defects-list',
    templateUrl: './tag-defects-list.component.html',
    styleUrls: ['./tag-defects-list.component.css'],
})
export class TagDefectsListComponent extends BaseComponent implements OnInit, AfterViewInit {

    breadcrumbItems: MenuItem[];

    @ViewChild('dt') dataTable: Table;
    @ViewChild(DefectCreateComponent) defectCreateComponent: DefectCreateComponent;

    defects: TagDefect[];
    totalRecords: any;
    createdAt: any;
    updatedAt: any;
    completedAt: any;
    projects: any;
    private filter: any;
    filtersDef: any;

    public sortColumns: any[];
    public filters: any;
    public presetFilters: any[] = [];

    constructor(protected store: Store<AppState>,
                public  gs: GlobalService,
                private router: Router,
                private route: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private proxyService: ProxyService,
                private ps: CustomPermissionsService,
                private translate: TranslateService,
                public media: MediaService,
                private enumsHelper: EnumsHelper,
                private statisticsService: StatisticsService,
                private location: Location
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const presetStateFilter = this.statisticsService.getTagFilterState(TagTypes.TAG_DEFECT);
        if (presetStateFilter !== undefined) {
            this.presetFilters.push({field: 'state', value: presetStateFilter});
            this.statisticsService.clearTagFilterState(TagTypes.TAG_DEFECT);
        }

        this.subs.sink = forkJoin([
            this.translate.get('defects.title_m'),
        ]).subscribe(result => {
            this.breadcrumbItems = [];
            this.breadcrumbItems.push({label: result[0], routerLink: '/defects'});
            this.store.dispatch(new SetBreadcrumb(this.breadcrumbItems));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.ps.permissionsLoaded().subscribe((loaded) => {
                if (loaded) {
                    this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                        this.ps.hasPermission('createDefects').then((permission) => {
                            this.buttons = [...this.buttons];
                            if (permission) {
                                this.buttons.push(
                                    {
                                        id: 'createDefectButton',
                                        name: 'defects.create',
                                        action: 'onCreateDefect',
                                        class: 'p-button-primary',
                                        icon: 'pi pi-plus',
                                        disabled: false,
                                    }
                                );
                            }
                            this.store.dispatch(new UpdateToolbarButtons(this.buttons));
                        });

                        this.subs.sink = this.store.select(getDefects).pipe(
                            map(defectState => {
                                return {
                                    defects: defectState.defects,
                                    totalRows: defectState.totalRows,
                                };
                            }),
                            switchMap(({
                                           defects: defects,
                                           totalRows: totalRows,
                                       }) => {
                                if (defects.length > 0) {

                                    const promises = [];

                                    defects.forEach(record => {
                                        promises.push(this.ps.hasProjectPermission(record.project, 'updateDefects'));
                                        promises.push(this.ps.hasProjectPermission(record.project, 'resolveDefects'));
                                        promises.push(this.ps.hasProjectPermission(record.project, 'deleteDefects'));
                                    });

                                    return Promise.all(
                                        promises
                                    ).then(results => {
                                        defects = defects.map((defect, index) => {
                                            const idx1 = (index * 3);
                                            const idx2 = (index * 3) + 1;
                                            const idx3 = (index * 3) + 2;

                                            const updatePerm = results[idx1];
                                            const resolvePerm = results[idx2];
                                            const deletePerm = results[idx3];

                                            const contextMenu = [
                                                {
                                                    label: this.translate.instant('general.send'),
                                                    icon: 'pi pi-envelope',
                                                    command: (event: Event) => {
                                                        this.onEmail(defect.id, defect.identifier);
                                                    }
                                                }
                                            ];

                                            if (deletePerm) {
                                                contextMenu.push({
                                                    label: this.translate.instant('general.delete'),
                                                    icon: 'pi pi-trash',
                                                    command: (event: Event) => {
                                                        this.onDelete(defect.id, defect.identifier);
                                                    }
                                                });
                                            }

                                            return {
                                                ...defect,
                                                img: defect.images.length > 0 ?
                                                    this.media.getMediaLink(defect.images[0]) :
                                                    this.media.getNoImageLink(),
                                                thumb: defect.images.length > 0 ?
                                                    this.media.getMediaThumbLink(defect.images[0]) :
                                                    this.media.getNoImageLink(),
                                                contextMenu,
                                                navLink: [
                                                    '/',
                                                    'defects',
                                                    'browse',
                                                    defect.link
                                                ]
                                            };
                                        });

                                        return {
                                            defects,
                                            totalRows,
                                        };
                                    });
                                } else {
                                    return new Promise((resolve) => {
                                        resolve({
                                            defects: [],
                                            totalRows: 0,
                                        });
                                    });
                                }

                            })).subscribe(
                            ({
                                 defects: defects,
                                 totalRows: totalRows,
                             }) => {
                                this.defects = defects;
                                this.totalRecords = totalRows;
                            });
                    });
                }
            });
        });

        this.subs.sink = forkJoin([
            this.translate.get('general_tag.occurred_at'),
            this.translate.get('general_tag.created_at'),
            this.translate.get('general_tag.severity.title'),
            this.translate.get('defects.deadline'),
        ]).subscribe(result => {
            this.sortColumns = [
                { label: result[0], value: {dbField: 'td.occurred_at', name: result[0]} },
                { label: result[1], value: {dbField: 'td.created_at', name: result[1]} },
                { label: result[2], value: {dbField: 'td.severity_id', name: result[2]} },
                { label: result[3], value: {dbField: 'td.deadline', name: result[3]} },
            ];
        });

        this.filters = [
            {
                field: 'state',
                dbField: 'td.state_id',
                matchMode: 'in',
                values: this.enumsHelper.statesMulti,
                label: 'general_tag.state.title',
                width: '250px'
            },
            {
                field: 'severity',
                dbField: 'td.severity_id',
                matchMode: 'in',
                values: this.enumsHelper.severitiesMulti,
                label: 'general_tag.severity.title'
            }
        ];
    }

    onCreateDefect() {
        this.store.dispatch(new DialogShow());
    }

    fetchDefects(event: object): void {
        this.store.dispatch(new TagDefectsFetch(event));
    }

    ngAfterViewInit() {
        // this.subs.sink = this.dataTable.onStateRestore.subscribe((state) => {
        //     setTimeout(() => {
        //         Object.keys(this.dataTable.filters).forEach((key) => {
        //             if (this.dataTable.filters[key].matchMode === 'dateRange') {
        //                 if (Array.isArray(this.dataTable.filters[key].value)) {
        //                     this[key] = [];
        //                     this.dataTable.filters[key].value.forEach((value, index) => {
        //                         if (value) {
        //                             this[key][index] = new Date(value);
        //                         }
        //                     });
        //                 }
        //             }
        //
        //         });
        //     }, 0);
        // });
        //
        // this.dataTable.restoreState();
    }

    loadLazy(event: LazyLoadEvent, forcedField: string = null) {
        const searchData = LazyLoadMeta.fromData(event);
        // searchData.filters = this.filter;
        this.fetchDefects(searchData);
    }

    onEdit(link: string) {
        // this.router.navigate(['edit', id], {relativeTo: this.route});
        this.router.navigate(['/', 'browse', link]);
    }

    onResolve(link: string) {
        this.router.navigate(['/', 'resolve', link]);
    }

    onDelete(id: number, identifier: string) {
        this.subs.sink = forkJoin([this.translate.get('defects.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new TagDefectDelete({defectId: id}));
                }
            });
        });
    }

    onResetClick() {
        this.dataTable.clearState();
        this.dataTable.reset();
    }

    onFilterChanged($event: any) {
        this.filter = $event;
        this.loadLazy(this.dataTable.createLazyLoadMetadata());
    }

    onEmail(id: number, identifier: string) {
        this.store.dispatch(new SendActions.DialogShow({type: EntityTypes.TAG_DEFECT, id, identifier}));
    }
}
