import { Injectable } from '@angular/core';
import {TagTypes} from '../enums/entity-enums';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

    private tagDefectState = null;
    private tagTaskState = null;
    private tagGoodPracticeState = null;

    public getTagFilterState(tagType: number) {
        switch (tagType) {
            case TagTypes.TAG_DEFECT:
                return this.tagDefectState;
            case TagTypes.TAG_TASK:
                return this.tagTaskState;
            case TagTypes.TAG_GOOD_PRACTICE:
                return this.tagGoodPracticeState;
        }
    }

    public setTagFilterState(tagType: number, state: number) {
        switch (tagType) {
            case TagTypes.TAG_DEFECT:
                this.tagDefectState = state;
                break;
            case TagTypes.TAG_TASK:
                this.tagTaskState = state;
                break;
            case TagTypes.TAG_GOOD_PRACTICE:
                this.tagGoodPracticeState = state;
                break;
        }
    }

    public clearTagFilterState(tagType: number) {
        switch (tagType) {
            case TagTypes.TAG_DEFECT:
                this.tagDefectState = null;
                break;
            case TagTypes.TAG_TASK:
                this.tagTaskState = null;
                break;
            case TagTypes.TAG_GOOD_PRACTICE:
                this.tagGoodPracticeState = null;
                break;
        }
    }
}
