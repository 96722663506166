import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {BaseComponent} from '../../../shared/base/base.component';
import {Subject, User} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../shared/services/global.service';
import {getSubjects} from '../store/subjects.selector';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {SubjectDelete, SubjectsFetch} from '../store/subjects.actions';

@Component({
  selector: 'app-subjects-list',
  templateUrl: './subjects-list.component.html',
  styleUrls: ['./subjects-list.component.css']
})
export class SubjectsListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public cols: any[];
    public subjects: Subject[];

    public selectedUser: User;
    public loading: boolean;
    public totalRecords: number;
    public layoutContentHeight: number;
    public sortColumns: any;

    public filtersDef: any;

    private subjectTypes: any[];
    public showAllButtonValue: boolean;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        private ps: CustomPermissionsService,
        private translate: TranslateService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        super.ngOnInit();

        this.subjectTypes = [
            {label: 'Type', value: null },
            {label: 'OSH', value: 1 },
            {label: 'Investor', value: 2},
            {label: 'Subject', value: 3},
        ];

        this.subs.sink = forkJoin([
            this.translate.get('general.created_at'),
            this.translate.get('subjects.name'),
            this.translate.get('subjects.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                { label: result[0], value: {dbField: 's.created_at', name: 'general.created_at'} },
                { label: result[1], value: {dbField: 's.name', name: 'subjects.name'} },
            ];

            this.store.dispatch(new SetBreadcrumb([
                {label: result[2], routerLink: '/subjects-list'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createSubjects').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.store.dispatch(new UpdateToolbarButtons([
                            {
                                id: 'createSubjectButton',
                                name: 'subjects.create',
                                action: 'onCreateSubject',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }])
                        );
                    }
                });
            });

            this.subs.sink = this.store.select(getSubjects).pipe(
                map(state => {
                    return {
                        subjects: state.subjects,
                        totalRows: state.totalRows,
                    };
                }),
                switchMap(({
                               subjects: subjects,
                               totalRows: totalRows,
                           }) => {
                    if (subjects.length > 0) {

                        const promises = [];

                        subjects.forEach(record => {
                            if (record.type === 1) {
                                promises.push(this.ps.hasSubjectPermission(record, 'updateSubjectsOsh'));
                                promises.push(this.ps.hasSubjectPermission(record, 'deleteSubjectsOsh'));
                            } else if (record.type === 2) {
                                promises.push(this.ps.hasSubjectPermission(record, 'updateSubjectsInvestor'));
                                promises.push(this.ps.hasSubjectPermission(record, 'deleteSubjectsInvestor'));
                            } else if (record.type === 3) {
                                promises.push(this.ps.hasSubjectPermission(record, 'updateSubjects'));
                                promises.push(this.ps.hasSubjectPermission(record, 'deleteSubjects'));
                            }
                        });

                        return Promise.all(
                            promises
                        ).then( results => {
                            subjects = subjects.map((subject, index) => {
                                const idx1 = (index * 2);
                                const idx2 = (index * 2) + 1;

                                const updatePerm = results[idx1];
                                const deletePerm = results[idx2];

                                const contextMenu = [];

                                if (deletePerm) {
                                    contextMenu.push({
                                        label: this.translate.instant('general.delete'),
                                        icon: 'pi pi-trash',
                                        command: (event: Event) => {
                                            this.onDelete(subject.id, subject.name);
                                        }
                                    });
                                }

                                const navLink = ['/', 'subjects'];
                                if (updatePerm) {
                                    navLink.push('edit');
                                }
                                navLink.push(subject.id);

                                return {
                                    ...subject,
                                    typeLabel: this.subjectTypes.find(r => r.value === subject.type).label,
                                    contextMenu,
                                    navLink
                                };
                            });

                            return {
                                subjects,
                                totalRows,
                            };
                        });
                    } else {
                        return new Promise((resolve) => {
                            resolve({
                                subjects: [],
                                totalRows: 0,
                            });
                        });
                    }

                })).subscribe(
                ({
                     subjects: subjects,
                     totalRows: totalRows,
                 }) => {
                    this.subjects = subjects;
                    this.totalRecords = totalRows;
                });
        });
    }

    onCreateSubject() {
        this.router.navigate(['create'], {relativeTo: this.route});
    }

    fetchSubjects(event: object): void {
        this.store.dispatch(new SubjectsFetch(event));
    }

    loadLazy(event: any) {
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchSubjects(searchData);
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onEdit(id: number) {
        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    onDelete(id: number, identifier: string) {
        forkJoin([this.translate.get('subjects.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new SubjectDelete({subjectId: id}));
                }
            });
        });
    }

    onSaveSubject() {
        if (this.router.url.includes('create')) {
            this.showAllButtonValue = true;
        }
        this.onToolbarButtonClick('onSaveSubject');
    }

    onResetClick() {
    }
}
