import {Injectable} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {Project, Subject} from '../../../generated/graphql';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomPermissionsService {

    private permissions: any = null;
    private rules: string[] = [];
    public permissionsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private ngxPerm: NgxPermissionsService
    ) {}

    loadPermissions(permissions: any) {
        if (permissions) {
            this.permissions = JSON.parse(permissions);
            this.setRules();
            this.ngxPerm.flushPermissions();
            this.ngxPerm.loadPermissions(this.rules);

            this.permissionsLoaded$.next(true);
        }
    }

    permissionsLoaded() {
        return this.permissionsLoaded$;
    }

    getNgxPermissions$() {
        return this.ngxPerm.permissions$;
    }

    getNgxPermissions() {
        return this.ngxPerm.getPermissions();
    }

    private setRules() {
        const rules = [];
        if (this.permissions.osh.rules.length > 0) {
            rules.push(...this.permissions.osh.rules);
        }

        if (this.permissions.subjectsRoles.length > 0) {
            this.permissions.subjectsRoles.map((subjectRole) => {
                subjectRole.rules.map((rule) => {
                    rules.push(subjectRole.roleNumber + '.' + rule);
                    if (rules.indexOf(rule) === -1) {
                        rules.push(rule);
                    }
                });
            });
        }

        if (this.permissions.projectsRoles.length > 0) {
            this.permissions.projectsRoles.map((projectRole) => {
                projectRole.rules.map((rule) => {
                    rules.push(projectRole.roleNumber + '.' + rule);
                    if (rules.indexOf(rule) === -1) {
                        rules.push(rule);
                    }
                });
            });
        }

        this.rules = rules;
    }

    getAllowedRules(permissions: string[]) {
        const rules = [];
        if (this.permissions) {
            if (this.permissions.osh.rules.length > 0) {
                return this.permissions.osh.rules.filter((rule) => {
                    return permissions.indexOf(rule) !== -1;
                });
            }

            permissions.map((permission) => {
                this.permissions.subjectsRoles.map((subjectRole) => {
                    rules.push(subjectRole.roleNumber + '.' + permission);
                });
            });

            permissions.map((permission) => {
                this.permissions.projectsRoles.map((projectRole) => {
                    rules.push(projectRole.roleNumber + '.' + permission);
                });
            });
        }
        return rules;
    }

    getSubjectAllowedRules(subject: Subject, permissions: string[]) {
        const rules = [];
        if (this.permissions) {

            if (this.permissions.osh.rules.length > 0) {
                return this.permissions.osh.rules.filter((rule) => {
                    return permissions.indexOf(rule) !== -1;
                });
            }

            if (permissions.length > 0) {
                permissions.map((permission) => {
                    this.permissions.subjectsRoles.map((subjectRole) => {
                        if (subjectRole.subjects.indexOf(subject?.id) !== -1) {
                            rules.push(subjectRole.roleNumber + '.' + permission);
                        }
                    });
                });
            }
        }
        return rules;
    }

    getProjectAllowedRules(project: Project, permissions: string[]) {
        const rules = [];
        if (this.permissions) {

            if (this.permissions.osh.rules.length > 0) {
                return this.permissions.osh.rules.filter((rule) => {
                    return permissions.indexOf(rule) !== -1;
                });
            }

            if (permissions.length > 0) {
                permissions.map((permission) => {
                    this.permissions.projectsRoles.map((projectRole) => {
                        if (projectRole.projects.indexOf(project?.id) !== -1) {
                            rules.push(projectRole.roleNumber + '.' + permission);
                        }
                    });
                });
            }
        }
        return rules;
    }

    getPermissions(permissions: string | string[]) {
        const tPermissions = [];
        if (typeof permissions === 'string') {
            tPermissions.push(permissions);
        } else {
            permissions.map((permission) => {
                tPermissions.push(permission);
            });
        }

        const rules = this.getAllowedRules(tPermissions);
        rules.push(...['superadmin', 'administrator']);
        return rules;
    }

    hasPermission(permissions: string | string[]) {
        return this.ngxPerm.hasPermission([
            ...this.getPermissions(permissions)
        ]);
    }

    getSubjectPermissions(subject: Subject, permissions: string | string[]) {
        const tPermissions = [];
        if (typeof permissions === 'string') {
            tPermissions.push(permissions);
        } else {
            permissions.map((permission) => {
                tPermissions.push(permission);
            });
        }

        const rules = this.getSubjectAllowedRules(subject, tPermissions);
        rules.push(...['superadmin', 'administrator']);
        return rules;
    }

    hasSubjectPermission(subject: Subject, permissions: string | string[]) {
        return this.ngxPerm.hasPermission([
            ...this.getSubjectPermissions(subject, permissions)
        ]);
    }

    getProjectPermissions(project: Project, permissions: string | string[]) {
        const tPermissions = [];
        if (typeof permissions === 'string') {
            tPermissions.push(permissions);
        } else {
            permissions.map((permission) => {
                tPermissions.push(permission);
            });
        }

        const rules = this.getProjectAllowedRules(project, tPermissions);
        rules.push(...['superadmin', 'administrator']);
        return rules;
    }

    hasProjectPermission(project: Project, permissions: string | string[]) {
        return this.ngxPerm.hasPermission([
            ...this.getProjectPermissions(project, permissions)
        ]);
    }

    getProjectRole(project: Project) {
        if (this.permissions) {
            const roles = [];

            if (this.permissions.osh.rules.length > 0) {
                this.permissions.osh.rules.filter((rule) => {
                    if (rule === 'superadmin') {
                        roles.push(0);
                    } else if (rule === 'administrator') {
                        roles.push(10);
                    }
                });
            }

            this.permissions.projectsRoles.map((projectRole) => {
                if (projectRole.projects.indexOf(project?.id) !== -1) {
                    roles.push(projectRole.roleNumber);
                }
            });
            return Math.min.apply(Math, roles);
        }
        return 1000;
    }
}
