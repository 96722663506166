import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {DefectCreateComponent} from '../../../forms/tags/defect-create/defect-create.component';
import {forkJoin} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../shared/services/global.service';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb} from '../../../fixed-toolbar/fixed-toolbar.action';

@Component({
    selector: 'app-tag-defects',
    templateUrl: './tag-defects.component.html',
    styleUrls: ['./tag-defects.component.css'],
})
export class TagDefectsComponent extends BaseComponent implements OnInit {

    @ViewChild(DefectCreateComponent) defectCreateComponent: DefectCreateComponent;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.subs.sink = forkJoin([
            this.translate.get('defects.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/defects'},
            ]));
        });
    }
}
