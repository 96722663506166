import {Directive, Input} from '@angular/core';
import {FormControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appPasswordMatch]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }
    ]
})
export class PasswordMatchDirective implements Validator {
    @Input('appPasswordMatch') passwordMatch: string[] = [];

    constructor() {
    }

    validate(c: FormControl): ValidationErrors {
        if (c.value !== this.passwordMatch) {
            return {passwordMismatch: true};
        } else {
            return null;
        }
    }
}
