import {Action} from '@ngrx/store';

export const CHOOSE_SUBJECT_PROJECT_DIALOG_SHOW = '[@ChooseSubjectProject] DialogShow';
export const CHOOSE_SUBJECT_PROJECT_DIALOG_HIDE = '[@ChooseSubjectProject] DialogHide';

export class ChooseSubjectProjectDialogShow implements Action {
    readonly type = CHOOSE_SUBJECT_PROJECT_DIALOG_SHOW;
}

export class ChooseSubjectProjectDialogHide implements Action {
    readonly type = CHOOSE_SUBJECT_PROJECT_DIALOG_HIDE;
}

export type ChooseSubjectProjectActions =
    ChooseSubjectProjectDialogShow |
    ChooseSubjectProjectDialogHide;
