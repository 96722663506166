import {USER_FORM_DIALOG_HIDE, USER_FORM_DIALOG_SHOW, UserFormActions} from './user-form.actions';

export interface State {
    userFormDialogShow: boolean;
}

const initialState = {
    userFormDialogShow: false,
};

export function userFormReducer(state: State = initialState, action: UserFormActions) {
    switch (action.type) {
        case USER_FORM_DIALOG_SHOW:
            state = {
                ...state,
                userFormDialogShow: true
            };
            break;
        case USER_FORM_DIALOG_HIDE:
            state = {
                ...state,
                userFormDialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
