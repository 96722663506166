import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {delay, map} from 'rxjs/operators';
import {BaseComponent} from '../../../shared/base/base.component';
import {
    Contact,
    ContractCategory,
    ContractCategoryInput, Project, ProjectDocument,
    ProjectInput,
    Subject,
    SubjectAres,
    SubjectAresInput
} from '../../../../generated/graphql';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {SubjectType} from '../../../shared/model/subject-type.model';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Store} from '@ngrx/store';
import {ProxyService} from '../../../shared/services/proxy.service';
import {AddLoading, AddMessage, RemoveLoading} from '../../../loading/loading.action';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {GlobalService} from '../../../shared/services/global.service';
import {FileUpload} from 'primeng/fileupload';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ProjectCreate, ProjectFetch, ProjectSet, ProjectUpdate} from '../store/projects.actions';
import {SubjectCreate} from '../../subjects/store/subjects.actions';
import {ClipboardService} from 'ngx-clipboard';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('f') projectForm: NgForm;
    @ViewChild('docFileUpload') docFileUpload: FileUpload;

    private projectFormState = false;
    public editMode = false;
    private editId = null;
    contractCategorySuggestions: any;
    selectedCategory: ContractCategory[] = [];
    currentCategoryInput: string;
    public investorInfo: SubjectAres;
    public subjectInfo: SubjectAres;
    selectedInvestor: Subject;
    investorSuggestions: Subject[];
    selectedContacts: Contact;
    contactSuggestions: Contact[];
    supervisors: string[];
    technicians: string[];
    selectedSubjects: Subject[];
    subjectsSuggestions: Subject[];
    subjectTechnicians: any[] = [];
    public loadedProject: Project;
    public documentFiles: any = [];

    public emailContacts: Map<string, string> = new Map([]);

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        protected proxyService: ProxyService,
        protected messageService: MessageService,
        private translate: TranslateService,
        private ps: CustomPermissionsService,
        private clipboardApi: ClipboardService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            if (this.editMode) {
                this.fetchProject(this.editId);
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    ngAfterViewInit() {
        this.buttons = [
            {
                id: 'saveProjectButton',
                name: 'projects.save',
                action: 'onSaveProject',
                class: 'p-button-success',
                icon: 'pi pi-save p-mr-1',
                disabled: false,
            }
        ];

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.subs.sink = this.store.select(currentStateGetCurrentSubject).subscribe((subject) => {
                this.ps.hasSubjectPermission(subject, 'createProjects').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.buttons.push(
                            {
                                id: 'createProjectButton',
                                name: 'projects.create',
                                action: 'onCreateProject',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }
                        );
                    }
                    this.store.dispatch(new SetToolbarButtons(this.buttons));
                });
            });
        });

        if (this.editMode) {
            this.subs.sink = this.store.select('projects').pipe(
                delay(0),
                map(projectState => {
                    return {loadedProject: projectState.loadedProject};
                })).subscribe(
                ({loadedProject: loadedProject}) => {
                    if (loadedProject) {
                        this.projectForm.form.patchValue({
                                ...loadedProject,
                                contractCategory: [loadedProject.contractCategory],
                                expectedStartDate: new Date(loadedProject.expectedStartDate.date),
                                expectedEndDate: new Date(loadedProject.expectedEndDate.date),
                                projectId: loadedProject.id,
                                subjects: loadedProject.subjects?.map(e => e.subject),
                            }
                        );

                        this.documentFiles = [];
                        for (const document of loadedProject.documents ?? []) {
                            const doc = {
                                id: this.documentFiles.length,
                                ...document,
                            } as ProjectDocument;
                            this.documentFiles.push(doc);
                        }

                        for (const row of loadedProject?.subjects ?? []) {
                            this.subjectTechnicians[row.subject.id] = row.technicians;
                        }

                        this.loadedProject = loadedProject;

                        this.investorInfo = {
                            companyName: this.loadedProject.investor.name,
                            companyCode: this.loadedProject.investor.companyCode,
                            city: this.loadedProject.investor?.address?.city,
                            street: this.loadedProject.investor?.address?.street,
                            zip: this.loadedProject.investor?.address?.zip,
                            country: this.loadedProject.investor?.address?.country,
                        } as SubjectAres;

                        this.emailContacts.set('osh',  [].concat(this.loadedProject?.supervisors, this.loadedProject?.technicians).join(', '));
                        this.emailContacts.set('investor',  this.loadedProject.contacts?.map(rec => rec.email).join(', '));
                        this.emailContacts.set('subjects',  this.loadedProject.subjects?.map(
                            rec => rec.technicians.join(', ')
                        ).filter(rec => rec.length > 0).join(', '));

                        this.store.dispatch(new ProjectSet({project: null}));
                    }
                });
        }
    }

    fetchProject(projectId: number) {
        this.store.dispatch(new ProjectFetch({projectId}));
    }

    onSubmit(form: NgForm) {

        if (form.valid) {
            const subjects = [];
            for (const row of this.selectedSubjects ?? []) {

                const subject = {...row};

                delete subject.createdAt;
                delete subject.updatedAt;
                delete subject.administrators;

                subjects.push({
                    subject,
                    technicians: this.subjectTechnicians[row.id]
                });
            }


            const documents = this.documentFiles.map((doc) => {
                const property = 'docDescription-' + doc.id;
                return {
                    name: doc.name,
                    content: doc.content,
                    description: form.value[property],
                    hash: doc.hash
                };
            });

            const projectId = this.loadedProject?.id ?? null;
            const project: ProjectInput = {
                id: projectId,
                investor: form.value.investor.id,
                active: form.value.active ? form.value.active : false,
                contractNumber: form.value.contractNumber,
                contractName: form.value.contractName,
                contractCategory: form.value.contractCategory[0].id,
                contacts: form.value.contacts?.map((contact) => contact.id),
                supervisors: form.value.supervisors,
                technicians: form.value.technicians,
                expectedStartDate: form.value.expectedStartDate,
                expectedEndDate: form.value.expectedEndDate,
                expectedScope: form.value.expectedScope,
                note: form.value.note,
                keyName: form.value.keyName,
                subjects,
                documents
            };

            if (projectId) {
                this.store.dispatch(new ProjectUpdate({project}));
            } else {
                this.store.dispatch(new ProjectCreate({project}));
            }
        } else {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsTouched();
            });

            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('errors.invalid_form_fields')], 'global'));
        }

    }

    onSaveProject() {
        this.projectForm.ngSubmit.emit();
    }

    filterCategorySuggestions(event: any) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'name', 1);
        this.proxyService.getContractCategoryList(lazy).then(contractCategories => {
            this.contractCategorySuggestions = [...contractCategories];
        });
    }

    filterInvestorSuggestions(event: any) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'name', 1);
        this.proxyService.getSubjectsList(lazy, SubjectType.INVESTOR).then(subjects => {
            this.investorSuggestions = [...subjects];
        });
    }

    filterSubjectsSuggestions(event: any) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'name', 1);
        this.proxyService.getSubjectsList(lazy, SubjectType.SUBJECT).then(subjects => {
            this.subjectsSuggestions = [...subjects];
        });
    }

    onKeyUp(event: any) {
        if (event.key === 'Enter') {
            const tokenInput = event.target as any;
            if (tokenInput.value) {
                if (typeof tokenInput.value !== 'object' ) {
                    this.currentCategoryInput = tokenInput.value;

                    this.proxyService.getContractCategory(tokenInput.value).then(contractCategoryExists => {
                        if (contractCategoryExists) {
                            const checkIfExists = param => this.selectedCategory.some(({id}) => id === param);
                            if (!checkIfExists(contractCategoryExists.id)) {
                                this.selectedCategory.push(contractCategoryExists);
                            }
                        } else {
                            forkJoin([this.translate.get('projects.create_category_confirm_message')]).subscribe(result => {
                                this.confirmationService.confirm({
                                    message: result[0],
                                    accept: () => {
                                        const value: ContractCategoryInput = {
                                            name: this.currentCategoryInput,
                                            description: ''
                                        };
                                        this.proxyService.createContractCategory(value).then(contractCategory => {
                                            this.selectedCategory.push(contractCategory);
                                        });
                                    }
                                });
                            });
                        }
                    });
                } else {
                    this.selectedCategory.push(tokenInput.value);
                }
                tokenInput.value = '';
            }
        }
    }

    checkCompanyCode() {
        // TODO for now it is only for Czech Republic

        if (!this.projectForm.value.companyCode) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('subjects.company-code-required')], 'global'));
        } else {
            this.store.dispatch(new AddLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            this.proxyService.getSubjectAres(
                this.projectForm.value.companyCode,
                'cz'
            ).then(subjectAres => {
                this.investorInfo = {...subjectAres};
                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            }).catch(reason => {
                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            });
        }
    }

    onAddInvestor() {
        const investorSubject: SubjectAresInput = {
            companyCode: this.investorInfo.companyCode,
            country: this.investorInfo.country,
            type: SubjectType.INVESTOR,
            enabled: true,
        };
        this.store.dispatch(new SubjectCreate({subject: investorSubject as SubjectAresInput, redirect: false}));
    }

    filterContactSuggestions(event: any) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'firstName', order: 1}, {field: 'lastName', order: 1}], [{key: 'showAll', value: '1'}]);
        this.proxyService.getContactsList(lazy).then(contacts => {
            this.contactSuggestions = [...contacts];
        });
    }

    // filterSupervisorSuggestions(event: any) {
    //     const query = event.query;
    //     const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
    //         [{field: 'firstName', order: 1}, {field: 'lastName', order: 1}]);
    //     this.proxyService.getUserList(lazy).then(users => {
    //         this.supervisorSuggestions = [...users];
    //     });
    // }

    validateEmail(event: any) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(event.value)) {
            return true;
        }

        const index = this.supervisors.indexOf(event.value);
        if (index !== -1) {
            this.supervisors.splice(index, 1);
        }

        this.store.dispatch(new AddMessage([FlashMessage.warningMessage('subjects.not-an-email-address')], 'global'));
        return false;
    }

    validateEmailTechnicians(event: any) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(event.value)) {
            return true;
        }

        const index = this.technicians.indexOf(event.value);
        if (index !== -1) {
            this.technicians.splice(index, 1);
        }

        this.store.dispatch(new AddMessage([FlashMessage.warningMessage('subjects.not-an-email-address')], 'global'));
        return false;
    }

    checkSubjectCompanyCode() {
        if (!this.projectForm.value.subjectCompanyCode) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('subjects.company-code-required')], 'global'));
        } else {
            // @ts-ignore
            this.proxyService.getSubjectAres(
                this.projectForm.value.subjectCompanyCode,
                'cz'
            ).then(subjectAres => {
                this.subjectInfo = {...subjectAres};
            });
        }
    }

    onAddSubjectTechnicianToProject(event: any, subjectId: number) {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(event.value)) {
            const index = this.subjectTechnicians[subjectId].indexOf(event.value);
            if (index !== -1) {
                this.subjectTechnicians[subjectId].splice(index, 1);
            }

            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('projects.not-an-email-address')], 'global'));
            return false;
        }
    }

    onRemoveSubjectFromProject(event: any) {
        delete this.subjectTechnicians[event.id];
    }

    onAddSubject() {
        const subject: SubjectAresInput = {
            companyCode: this.subjectInfo.companyCode,
            country: this.subjectInfo.country,
            type: SubjectType.SUBJECT,
            enabled: true,
        };

        this.store.dispatch(new SubjectCreate({subject: subject as SubjectAresInput, redirect: false}));
        this.subjectInfo = null;
    }

    checkKeyName(event: any) {
        this.proxyService.checkProjectKeyName(this.projectForm.value.contractName, this.projectForm.value.keyName).then((result) => {
            if (!result.state) {
                this.store.dispatch(new AddMessage([FlashMessage.warningMessage('projects.key-name-already-exists')], 'global'));
            }
        });
    }

    onClearInvestorAres() {
        this.investorInfo = null;
    }

    onClearSubjectAres() {
        this.subjectInfo = null;
    }

    onDocumentUpload(event: any) {
        const documentFile = event.files[0];

        if (documentFile.size > 2000000) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('projects.document-file-too-big')], 'global'));
        } else {
            const myReader = new FileReader();
            myReader.onloadend = (e) => {

                let docId = 0;
                this.documentFiles.forEach((doc) => {
                    if (docId < doc.id + 1) {
                        docId = doc.id + 1;
                    }
                });

                this.documentFiles.push({
                    id: docId,
                    name: documentFile.name,
                    description: null,
                    content: myReader.result,
                    hash: null
                });
            };
            myReader.readAsDataURL(documentFile);
        }

        this.docFileUpload.clear();
    }

    onRemoveDocument(documentName: any) {
        this.documentFiles = this.documentFiles.filter((doc) => {
            return doc.name !== documentName;
        });
    }

    onError(event: any) {
        console.log('Class: ProjectEditComponent, Function: onError, Line 560: '
        , event);
    }

    copyText(event: any, content: string) {
        event.preventDefault();
        this.clipboardApi.copyFromContent(content);
    }
}
