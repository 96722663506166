import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './loading.model';
import { MODULE_ID } from './loading.constant';

export const getSlice = createFeatureSelector<State>(MODULE_ID);

export const getGlobalLoading = createSelector(getSlice, (s) => {
    return s.loadingData.get('global');
});

export const getModalLoading = createSelector(getSlice, (s) => {
    return s.loadingData.get('modal');
});

export const getGlobalErrorMessages = createSelector(getSlice, (s) => {
    return s.errorMessages.get('global');
});

export const getEmptyLayoutErrorMessages = createSelector(getSlice, (s) => {
    return s.errorMessages.get('emptyLayout');
});

// export const getMap = createSelector(getGlobalLoading, (d) => d.reduce((mp, id) => {
//     mp[id] = true;
//     return mp;
// }, {}));
//
// export const getByKey = (key: string) => createSelector(
//     getMap,
//     (mp) => mp[key]
// );
