import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type AddressInput = {
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryInput>;
};

export type Auth = {
  __typename?: 'Auth';
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  token: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  expiresIn: Scalars['Int'];
  permissions: Scalars['String'];
};

export type CountryInput = {
  id?: Maybe<Scalars['Int']>;
  isoCode?: Maybe<Scalars['String']>;
  isoCode2?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** PasswordReset mutation description */
  PasswordReset?: Maybe<Scalars['Boolean']>;
  /** FinishUser mutation description */
  FinishUser?: Maybe<Scalars['Boolean']>;
  /** CreateSubjectOsh mutation description */
  CreateSubjectOsh?: Maybe<Scalars['Boolean']>;
  /** ResendSubjectOshConfirmation mutation description */
  ResendSubjectOshConfirmation?: Maybe<Scalars['Boolean']>;
};


export type MutationPasswordResetArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationFinishUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  user?: Maybe<UserInput>;
  accountCreationCode: Scalars['String'];
};


export type MutationCreateSubjectOshArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subject?: Maybe<SubjectOshInput>;
};


export type MutationResendSubjectOshConfirmationArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  companyCode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Authenticate query description */
  Authenticate?: Maybe<Auth>;
  /** ForgetPassword query description */
  ForgetPassword?: Maybe<Scalars['Boolean']>;
  /** ValidateSubject query description */
  ValidateSubject?: Maybe<SubjectValidation>;
};


export type QueryAuthenticateArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryForgetPasswordArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryValidateSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
};

export type SubjectOshInput = {
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
  licenceCount: Scalars['Int'];
  administrators?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubjectValidation = {
  __typename?: 'SubjectValidation';
  isValid?: Maybe<Scalars['Boolean']>;
  alreadyExists?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
};

export type UserInput = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  blocked?: Maybe<Scalars['Boolean']>;
  emailLogin?: Maybe<Scalars['Boolean']>;
  googleLogin?: Maybe<Scalars['Boolean']>;
  facebookLogin?: Maybe<Scalars['Boolean']>;
  superadmin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  signatureFileBase64?: Maybe<Scalars['String']>;
  signatureDescription?: Maybe<Scalars['String']>;
};

export type AuthenticateQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthenticateQuery = (
  { __typename?: 'Query' }
  & { Authenticate?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'id' | 'email' | 'firstName' | 'lastName' | 'token' | 'refreshToken' | 'expiresIn' | 'permissions'>
  )> }
);

export type ForgetPasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgetPasswordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ForgetPassword'>
);

export type ValidateSubjectQueryVariables = Exact<{
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
}>;


export type ValidateSubjectQuery = (
  { __typename?: 'Query' }
  & { ValidateSubject?: Maybe<(
    { __typename?: 'SubjectValidation' }
    & Pick<SubjectValidation, 'isValid' | 'alreadyExists' | 'isConfirmed' | 'companyName'>
  )> }
);

export type CreateSubjectOshMutationVariables = Exact<{
  subject: SubjectOshInput;
}>;


export type CreateSubjectOshMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateSubjectOsh'>
);

export type ResendSubjectOshConfirmationMutationVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type ResendSubjectOshConfirmationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendSubjectOshConfirmation'>
);

export type PasswordResetMutationVariables = Exact<{
  hash: Scalars['String'];
  password: Scalars['String'];
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PasswordReset'>
);

export type FinishUserMutationVariables = Exact<{
  user?: Maybe<UserInput>;
  accountCreationCode: Scalars['String'];
}>;


export type FinishUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FinishUser'>
);

export const AuthenticateDocument = gql`
    query Authenticate {
  Authenticate {
    id
    email
    firstName
    lastName
    token
    refreshToken
    expiresIn
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticateGQL extends Apollo.Query<AuthenticateQuery, AuthenticateQueryVariables> {
    document = AuthenticateDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgetPasswordDocument = gql`
    query ForgetPassword($email: String!) {
  ForgetPassword(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgetPasswordGQL extends Apollo.Query<ForgetPasswordQuery, ForgetPasswordQueryVariables> {
    document = ForgetPasswordDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateSubjectDocument = gql`
    query ValidateSubject($companyCode: String!, $countryIsoCode2: String!) {
  ValidateSubject(companyCode: $companyCode, countryIsoCode2: $countryIsoCode2) {
    isValid
    alreadyExists
    isConfirmed
    companyName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateSubjectGQL extends Apollo.Query<ValidateSubjectQuery, ValidateSubjectQueryVariables> {
    document = ValidateSubjectDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubjectOshDocument = gql`
    mutation CreateSubjectOsh($subject: SubjectOshInput!) {
  CreateSubjectOsh(subject: $subject)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubjectOshGQL extends Apollo.Mutation<CreateSubjectOshMutation, CreateSubjectOshMutationVariables> {
    document = CreateSubjectOshDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendSubjectOshConfirmationDocument = gql`
    mutation ResendSubjectOshConfirmation($companyCode: String!) {
  ResendSubjectOshConfirmation(companyCode: $companyCode)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendSubjectOshConfirmationGQL extends Apollo.Mutation<ResendSubjectOshConfirmationMutation, ResendSubjectOshConfirmationMutationVariables> {
    document = ResendSubjectOshConfirmationDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PasswordResetDocument = gql`
    mutation PasswordReset($hash: String!, $password: String!) {
  PasswordReset(hash: $hash, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PasswordResetGQL extends Apollo.Mutation<PasswordResetMutation, PasswordResetMutationVariables> {
    document = PasswordResetDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishUserDocument = gql`
    mutation FinishUser($user: UserInput, $accountCreationCode: String!) {
  FinishUser(user: $user, accountCreationCode: $accountCreationCode)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishUserGQL extends Apollo.Mutation<FinishUserMutation, FinishUserMutationVariables> {
    document = FinishUserDocument;
    client = 'auth';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V>
    extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}
    
  interface QueryOptionsAlone<V>
    extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}
    
  interface MutationOptionsAlone<T, V>
    extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}
    
  interface SubscriptionOptionsAlone<V>
    extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class ConPointAuthService {
    constructor(
      private authenticateGql: AuthenticateGQL,
      private forgetPasswordGql: ForgetPasswordGQL,
      private validateSubjectGql: ValidateSubjectGQL,
      private createSubjectOshGql: CreateSubjectOshGQL,
      private resendSubjectOshConfirmationGql: ResendSubjectOshConfirmationGQL,
      private passwordResetGql: PasswordResetGQL,
      private finishUserGql: FinishUserGQL
    ) {}
      
    authenticate(variables?: AuthenticateQueryVariables, options?: QueryOptionsAlone<AuthenticateQueryVariables>) {
      return this.authenticateGql.fetch(variables, options)
    }
    
    authenticateWatch(variables?: AuthenticateQueryVariables, options?: WatchQueryOptionsAlone<AuthenticateQueryVariables>) {
      return this.authenticateGql.watch(variables, options)
    }
    
    forgetPassword(variables: ForgetPasswordQueryVariables, options?: QueryOptionsAlone<ForgetPasswordQueryVariables>) {
      return this.forgetPasswordGql.fetch(variables, options)
    }
    
    forgetPasswordWatch(variables: ForgetPasswordQueryVariables, options?: WatchQueryOptionsAlone<ForgetPasswordQueryVariables>) {
      return this.forgetPasswordGql.watch(variables, options)
    }
    
    validateSubject(variables: ValidateSubjectQueryVariables, options?: QueryOptionsAlone<ValidateSubjectQueryVariables>) {
      return this.validateSubjectGql.fetch(variables, options)
    }
    
    validateSubjectWatch(variables: ValidateSubjectQueryVariables, options?: WatchQueryOptionsAlone<ValidateSubjectQueryVariables>) {
      return this.validateSubjectGql.watch(variables, options)
    }
    
    createSubjectOsh(variables: CreateSubjectOshMutationVariables, options?: MutationOptionsAlone<CreateSubjectOshMutation, CreateSubjectOshMutationVariables>) {
      return this.createSubjectOshGql.mutate(variables, options)
    }
    
    resendSubjectOshConfirmation(variables: ResendSubjectOshConfirmationMutationVariables, options?: MutationOptionsAlone<ResendSubjectOshConfirmationMutation, ResendSubjectOshConfirmationMutationVariables>) {
      return this.resendSubjectOshConfirmationGql.mutate(variables, options)
    }
    
    passwordReset(variables: PasswordResetMutationVariables, options?: MutationOptionsAlone<PasswordResetMutation, PasswordResetMutationVariables>) {
      return this.passwordResetGql.mutate(variables, options)
    }
    
    finishUser(variables: FinishUserMutationVariables, options?: MutationOptionsAlone<FinishUserMutation, FinishUserMutationVariables>) {
      return this.finishUserGql.mutate(variables, options)
    }
  }