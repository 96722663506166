import {MessageAction, State} from './loading.model';
import {ACTION_MESSAGE_KEY, ACTION_KEY} from './loading.constant';
import { LoadingAction } from './loading.model';
import { Actions } from './loading.action';
import {Action} from '@ngrx/store';

const initialState = new State();

export function reducer(
    state = initialState,
    action: Action | Actions | LoadingAction | MessageAction
): State {
    // any action that has fxLoading property with `add` or `remove`
    if (action[ACTION_KEY]) {
        if (action[ACTION_KEY].action === 'add') {
            const ids = action[ACTION_KEY].ids;
            const values = state.loadingData.get(action[ACTION_KEY].type).filter((id) => {
                return !ids.find(e => e.name === id.name);
            });
            values.push(...ids);
            state.loadingData.set(action[ACTION_KEY].type, values);
            state = {...state};
        }

        if (action[ACTION_KEY].action === 'remove') {
            const ids = action[ACTION_KEY].ids;
            if (action[ACTION_KEY].type === 'all') {
                state.loadingData.forEach((typeValues, key) => {
                    const values = typeValues.filter((id) => {
                        return !ids.find(e => e.name === id.name);
                    });
                    state.loadingData.set(key, values);
                });

            } else {
                const values = state.loadingData.get(action[ACTION_KEY].type).filter((id) => {
                    return !ids.find(e => e.name === id.name);
                });
                state.loadingData.set(action[ACTION_KEY].type, values);
            }

            state = {...state};
        }
    }

    if (action[ACTION_MESSAGE_KEY]) {
        if (action[ACTION_MESSAGE_KEY].action === 'add') {
            const messages = action[ACTION_MESSAGE_KEY].messages;
            const values = state.errorMessages.get(action[ACTION_MESSAGE_KEY].type).filter((message) => {
                return !messages.find(e => e.id === message.id);
            });
            values.push(...messages);
            state.errorMessages.set(action[ACTION_MESSAGE_KEY].type, values);
            state = {...state};
        }

        if (action[ACTION_MESSAGE_KEY].action === 'remove') {
            const messages = action[ACTION_MESSAGE_KEY].messages;
            if (action[ACTION_MESSAGE_KEY].type === 'all') {
                state.errorMessages.forEach((typeValues, key) => {
                    const values = typeValues.filter((message) => {
                        return !messages.find(e => e.id === message.id);
                    });
                    state.errorMessages.set(key, values);
                });

            } else {
                const values = state.errorMessages.get(action[ACTION_MESSAGE_KEY].type).filter((message) => {
                    return !messages.find(e => e.id === message.id);
                });
                state.errorMessages.set(action[ACTION_MESSAGE_KEY].type, values);
            }

            state = {...state};
        }
    }

    return state;
}
