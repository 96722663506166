import {ActionReducerMap} from '@ngrx/store';

import * as fromAuth from '../components/auth/store/auth.reducer';
import * as fromUsers from '../components/users/store/users.reducer';
import * as fromUserForm from '../forms/user-form/store/user-form.reducer';
import * as fromContacts from '../components/contacts/store/contacts.reducer';
import * as fromContactForm from '../forms/contact-form/store/contact-form.reducer';
import * as fromSubjects from '../components/subjects/store/subjects.reducer';
import * as fromProjects from '../components/projects/store/projects.reducer';
import * as fromBreadcrumb from '../fixed-toolbar/fixed-toolbar.reducer';
import * as fromCurrentState from './current-state/current-state.reducer';
import * as fromTagDefectCreate from '../forms/tags/defect-create/store/defect-create-form.reducer';
import * as fromTagDefects from '../components/tags/tag-defects/store/tag-defects.reducer';
import * as fromTagTaskCreate from '../forms/tags/task-create/store/task-create-form.reducer';
import * as fromTagTasks from '../components/tags/tag-tasks/store/tag-tasks.reducer';
import * as fromTagGoodPracticeCreate from '../forms/tags/good-practice-create/store/good-practice-create-form.reducer';
import * as fromTagGoodPractices from '../components/tags/tag-good-practice/store/tag-good-practice.reducer';
import * as fromGenericText from '../forms/generic-text-form/store/generic-text-form.reducer';
import * as fromGenericTexts from '../components/generic-texts/store/generic-texts.reducer';
import * as fromSendTag from '../forms/send-tag-form/store/send-tag-form.reducer';
import * as fromReports from '../components/reports/store/reports.reducer';
import * as fromAreasOf from '../components/area-of/store/areas-of.reducer';
import * as fromChooseSubjectProject from '../forms/choose-subject-project/store/choose-subject-project.reducer';
import * as fromGeneral from '../store/general/general.reducer';
import * as fromOshSubject from '../components/settings/osh-subject/store/osh-subject.reducer';
import * as fromAccounts from '../components/management/accounts/store/accounts.reducer';

import {authReducer} from '../components/auth/store/auth.reducer';
import {usersReducer} from '../components/users/store/users.reducer';
import {userFormReducer} from '../forms/user-form/store/user-form.reducer';
import {contactsReducer} from '../components/contacts/store/contacts.reducer';
import {contactFormReducer} from '../forms/contact-form/store/contact-form.reducer';
import {subjectsReducer} from '../components/subjects/store/subjects.reducer';
import {projectsReducer} from '../components/projects/store/projects.reducer';
import {fixedToolbarReducer} from '../fixed-toolbar/fixed-toolbar.reducer';
import {currentStateReducer} from './current-state/current-state.reducer';
import {tagDefectsReducer} from '../components/tags/tag-defects/store/tag-defects.reducer';
import {tagDefectCreateReducer} from '../forms/tags/defect-create/store/defect-create-form.reducer';
import {tagTasksReducer} from '../components/tags/tag-tasks/store/tag-tasks.reducer';
import {tagTaskCreateReducer} from '../forms/tags/task-create/store/task-create-form.reducer';
import {tagGoodPracticeReducer} from '../components/tags/tag-good-practice/store/tag-good-practice.reducer';
import {tagGoodPracticeCreateReducer} from '../forms/tags/good-practice-create/store/good-practice-create-form.reducer';
import {genericTextReducer} from '../forms/generic-text-form/store/generic-text-form.reducer';
import {genericTextsReducer} from '../components/generic-texts/store/generic-texts.reducer';
import {sendReducer} from '../forms/send-tag-form/store/send-tag-form.reducer';
import {reportsReducer} from '../components/reports/store/reports.reducer';
import {areasOfReducer} from '../components/area-of/store/areas-of.reducer';
import {chooseSubjectProjectReducer} from '../forms/choose-subject-project/store/choose-subject-project.reducer';
import {generalReducer} from './general/general.reducer';
import {oshSubjectReducer} from '../components/settings/osh-subject/store/osh-subject.reducer';
import {accountsReducer} from '../components/management/accounts/store/accounts.reducer';

export interface AppState {
    auth: fromAuth.State;
    users: fromUsers.State;
    userForm: fromUserForm.State;
    contacts: fromContacts.State;
    contactForm: fromContactForm.State;
    subjects: fromSubjects.State;
    projects: fromProjects.State;
    breadcrumb: fromBreadcrumb.State;
    currentState: fromCurrentState.State;
    tagDefects: fromTagDefects.State;
    tagDefectCreate: fromTagDefectCreate.State;
    tagTaskCreate: fromTagTaskCreate.State;
    tagTasks: fromTagTasks.State;
    tagGoodPracticeCreate: fromTagGoodPracticeCreate.State;
    tagGoodPractices: fromTagGoodPractices.State;
    genericText: fromGenericText.State;
    genericTexts: fromGenericTexts.State;
    sendTag: fromSendTag.State;
    reports: fromReports.State;
    areasOf: fromAreasOf.State;
    chooseSubjectProject: fromChooseSubjectProject.State;
    general: fromGeneral.State;
    oshSubject: fromOshSubject.State;
    accounts: fromAccounts.State;
}

export const appReducer: ActionReducerMap<AppState> = {
    auth: authReducer,
    users: usersReducer,
    userForm: userFormReducer,
    contacts: contactsReducer,
    contactForm: contactFormReducer,
    subjects: subjectsReducer,
    projects: projectsReducer,
    breadcrumb: fixedToolbarReducer,
    currentState: currentStateReducer,
    tagDefects: tagDefectsReducer,
    tagDefectCreate: tagDefectCreateReducer,
    tagTasks: tagTasksReducer,
    tagTaskCreate: tagTaskCreateReducer,
    tagGoodPractices: tagGoodPracticeReducer,
    tagGoodPracticeCreate: tagGoodPracticeCreateReducer,
    genericText: genericTextReducer,
    genericTexts: genericTextsReducer,
    sendTag: sendReducer,
    reports: reportsReducer,
    areasOf: areasOfReducer,
    chooseSubjectProject: chooseSubjectProjectReducer,
    general: generalReducer,
    oshSubject: oshSubjectReducer,
    accounts: accountsReducer,
};
