import {AreasOfActions, AreasOfActionTypes} from './areas-of.actions';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {AreaOf} from '../../../../generated/graphql';

export interface State {
    areasOf: AreaOf[];
    currentLazyLoad: LazyLoadMeta;
    loadedAreaOf: AreaOf;
    totalRows: number;
}

const initialState = {
    areasOf: [],
    currentLazyLoad: null,
    loadedAreaOf: null,
    totalRows: 0,
};

export function areasOfReducer(state: State = initialState, action: AreasOfActions) {
    switch (action.type) {
        case AreasOfActionTypes.AreasOfSet:
            state =  {
                ...state,
                areasOf: [...action.payload.areasOf],
                totalRows: action.payload.totalRows
            };
            break;
        case AreasOfActionTypes.AreaOfSet:
            state =  {
                ...state,
                loadedAreaOf: action.payload.areaOf
            };
            break;
        case AreasOfActionTypes.AreasOfFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case AreasOfActionTypes.AreasOfError:
            state =  {
                ...state,
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
