import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {SubjectsActionTypes, SubjectsFetch} from './store/subjects.actions';
import {Subject} from '../../../generated/graphql';
import {AppState} from '../../store/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class SubjectsResolverService implements Resolve<Subject[]> {
    constructor(private store: Store<AppState>,
                private actions$: Actions) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Subject[]> | Promise<Subject[]> | Subject[] {

        return this.store.select('subjects').pipe(
            take(1),
            map(subjectsState => {
                return subjectsState.subjects;
            }),
            switchMap(subjects => {
                if (subjects.length === 0) {
                    this.store.dispatch(new SubjectsFetch(null));
                    return this.actions$.pipe(
                        ofType(SubjectsActionTypes.SubjectsSet),
                        take(1)
                    );
                } else {
                    return of(subjects);
                }
            })
        );
    }
}
