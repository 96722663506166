import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {User} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../shared/services/global.service';
import {forkJoin} from 'rxjs';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {getUsers} from '../store/users.selector';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {UserFormDialogShow} from '../../../forms/user-form/store/user-form.actions';
import {UserDelete, UsersFetch} from '../store/users.actions';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.css']
})
export class UsersListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public users: User[];
    public loading: boolean;
    public totalRecords: number;
    public sortColumns: any;
    public showAllButtonValue = false;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        private ps: CustomPermissionsService,
        private translate: TranslateService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = forkJoin([
            this.translate.get('general.created_at'),
            this.translate.get('users.name'),
            this.translate.get('users.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                { label: result[0], value: {dbField: 'u.created_at', name: 'general.created_at'} },
                { label: result[1], value: {dbField: 'u.firstName', name: 'users.first_name'} },
            ];

            this.store.dispatch(new SetBreadcrumb([
                {label: result[2], routerLink: '/users'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createUsers').then((permission) => {
                    if (permission) {
                        this.store.dispatch(new UpdateToolbarButtons([
                            {
                                id: 'createUserButton',
                                name: 'users.create',
                                action: 'onCreateUser',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }])
                        );
                    }
                });
            });

            this.subs.sink = this.store.select(getUsers).pipe(
                map(state => {
                    return {
                        users: state.users,
                        totalRows: state.totalRows,
                    };
                }),
                switchMap(({
                               users: users,
                               totalRows: totalRows,
                           }) => {
                    if (users.length > 0) {

                        const promises = [];

                        users.forEach(record => {
                            promises.push(this.ps.hasSubjectPermission(currentSubject, 'updateUsers'));
                            promises.push(this.ps.hasSubjectPermission(currentSubject, 'deleteUsers'));
                        });

                        return Promise.all(
                            promises
                        ).then( results => {
                            users = users.map((user, index) => {
                                const idx1 = (index * 2);
                                const idx2 = (index * 2) + 1;

                                let updatePerm = results[idx1];
                                const deletePerm = results[idx2];

                                const userData: {
                                    id
                                } = JSON.parse(localStorage.getItem('user-data'));

                                if (user.id === userData.id) {
                                    updatePerm = true;
                                }

                                const contextMenu = [];

                                if (deletePerm) {
                                    contextMenu.push({
                                        label: this.translate.instant('general.delete'),
                                        icon: 'pi pi-trash',
                                        command: (event: Event) => {
                                            this.onDelete(user.id, user.name ?? user.email);
                                        }
                                    });
                                }

                                const navLink = ['/', 'users'];
                                if (updatePerm) {
                                    navLink.push('edit');
                                }
                                navLink.push(user.id);

                                return {
                                    ...user,
                                    contextMenu,
                                    navLink
                                };
                            });

                            return {
                                users,
                                totalRows,
                            };
                        });
                    } else {
                        return new Promise((resolve) => {
                            resolve({
                                users: [],
                                totalRows: 0,
                            });
                        });
                    }

                })).subscribe(
                ({
                     users: users,
                     totalRows: totalRows,
                 }) => {
                    this.users = users;
                    this.totalRecords = totalRows;
                });
        });
    }

    onCreateUser() {
        // this.router.navigate(['create'], {relativeTo: this.route});
        this.store.dispatch(new UserFormDialogShow());
    }

    fetchUsers(event: object): void {
        this.store.dispatch(new UsersFetch(event));
    }

    filterChanged(event: any) {
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchUsers(searchData);
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onEdit(id: number) {
        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    onDelete(id: number, identifier: string) {
        forkJoin([this.translate.get('users.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new UserDelete({id}));
                }
            });
        });
    }

    onSaveUser() {
        this.showAllButtonValue = true;
        this.onToolbarButtonClick('onSaveUser');
    }

    onResetClick() {
    }
}
