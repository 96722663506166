import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {AreaOf, Subject} from '../../../../generated/graphql';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {select, Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {getAreasOf} from '../store/areas-of.selector';
import {map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {PresetFilterService} from '../../../shared/services/preset-filter.service';
import {Table} from 'primeng/table';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {AreaOfDelete, AreasOfFetch} from '../store/areas-of.actions';

@Component({
    selector: 'app-area-of-list',
    templateUrl: './area-of-list.component.html',
    styleUrls: ['./area-of-list.component.css']
})
export class AreaOfListComponent extends BaseComponent implements OnInit, OnDestroy {

    public areasOf: AreaOf[];

    public totalRecords: number;
    public breadcrumbItems: MenuItem[];

    @ViewChild('dt') dataTable: Table;
    public createdAt: any;
    public updatedAt: any;
    public currentSubject: Subject;
    public sortColumns = [];
    public layoutContentHeight: number;

    public editPermissions: string[];
    public deletePermissions: string[];
    public type = 1;
    public filters: any;
    public presetFilters: any[] = [];

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        public ps: CustomPermissionsService,
        private translate: TranslateService,
        private presetFilterService: PresetFilterService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.subs.sink = this.presetFilterService.areaOfPresetFilter.subscribe((filters) => {
            this.presetFilters = filters;
        });

        this.subs.sink = forkJoin([
            // this.translate.get('general.created_at'),
            this.translate.get('area_of.name'),
            this.translate.get('area_of.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                // { label: result[0], value: {dbField: 'gt.created_at', name: 'general.created_at'} },
                { label: result[0], value: {dbField: 'a.name', name: 'area_of.name'} },
            ];

            this.store.dispatch(new SetBreadcrumb([
                {label: result[1], routerLink: '/areas-of'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((subject) => {
            this.currentSubject = subject;

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createAreaOf').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.store.dispatch(new UpdateToolbarButtons([
                            {
                                id: 'createAreaOfButton',
                                name: 'area_of.create',
                                action: 'onCreateAreaOf',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }])
                        );
                    }
                });
            });

            this.subs.sink = this.store.select(getAreasOf).pipe(
                map(state => {
                    return {
                        areasOf: state.areasOf,
                        totalRows: state.totalRows,
                    };
                }),
                switchMap(({
                               areasOf: areasOf,
                               totalRows: totalRows,
                           }) => {
                    if (areasOf.length > 0) {


                        areasOf = areasOf.map((text, index) => {
                            const contextMenu = [];

                            contextMenu.push({
                                label: this.translate.instant('general.delete'),
                                icon: 'pi pi-trash',
                                command: (event: Event) => {
                                    this.onDelete(text.id, text.name);
                                }
                            });

                            const navLink = ['/', 'areas-of'];
                            navLink.push('edit');
                            navLink.push(text.id);

                            return {
                                ...text,
                                contextMenu,
                                navLink
                            };
                        });

                        return new Promise((resolve) => {
                            resolve({
                                areasOf,
                                totalRows,
                            });
                        });
                    } else {
                        return new Promise((resolve) => {
                            resolve({
                                areasOf: [],
                                totalRows: 0,
                            });
                        });
                    }

                })).subscribe(
                ({
                     areasOf: areasOf,
                     totalRows: totalRows,
                 }) => {
                    this.areasOf = areasOf;
                    this.totalRecords = totalRows;
                });
        });


        this.filters = [
            {
                field: 'type',
                dbField: 'a.type',
                matchMode: 'is',
                values: [{id: 1, label: 'defects.title_m'}, {id: 2, label: 'tasks.title_m'}, {id: 3, label: 'good_practice.title_m'}],
                label: 'areas_of.type',
                width: '250px'
            }
        ];

        super.ngOnInit();
    }

    onCreateAreaOf() {
        this.router.navigate(['create'], {relativeTo: this.route});
    }

    fetchAreasOf(lazyLoad: LazyLoadMeta, type: number): void {
        this.store.dispatch(new AreasOfFetch({lazyLoad, type: null}));
    }

    loadLazy(lazyLoad: LazyLoadEvent) {
        const searchData = LazyLoadMeta.fromData(lazyLoad);
        this.fetchAreasOf(searchData, this.type);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        super.ngOnDestroy();
    }

    onEdit(id: any) {
        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    onDelete(id: any, identifier: string) {
        forkJoin([this.translate.get('area_of.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new AreaOfDelete({id, type: this.type}));
                }
            });
        });
    }
}
