import {Action} from '@ngrx/store';


export enum ContactFormActionTypes {
    ContactFormDialogShow = '[@ContactForm] DialogShow',
    ContactFormDialogHide = '[@ContactForm] DialogHide'
}

export class ContactFormDialogShow implements Action {
    readonly type = ContactFormActionTypes.ContactFormDialogShow;
}

export class ContactFormDialogHide implements Action {
    readonly type = ContactFormActionTypes.ContactFormDialogHide;
}

export type ContactFormActions =
    ContactFormDialogShow |
    ContactFormDialogHide;
