import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {Contact} from '../../../../generated/graphql';
import {ContactsActions, ContactsActionTypes} from './contacts.actions';

export interface State {
    contacts: Contact[];
    loadedContact: Contact;
    contactFormDialogShow: boolean;
    totalRows: number;
    currentLazyLoad: LazyLoadMeta;
}

const initialState = {
    contacts: [],
    currentLazyLoad: null,
    loadedContact: null,
    contactFormDialogShow: false,
    totalRows: 0,
};

export function contactsReducer(state: State = initialState, action: ContactsActions) {
    switch (action.type) {
        case ContactsActionTypes.ContactCreateSuccess:
        case ContactsActionTypes.ContactUpdateSuccess:
            state = {
                ...state
            };
            break;
        case ContactsActionTypes.ContactsSet:
            state = {
                ...state,
                contacts: [...action.payload.contacts],
                totalRows: action.payload.totalRows
            };
            break;
        case ContactsActionTypes.ContactSet:
            state = {
                ...state,
                loadedContact: action.payload.contact
            };
            break;
        case ContactsActionTypes.ContactsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case ContactsActionTypes.ContactsError:
            state = {
                ...state
            };
            break;
        default:
            return state;
            break;
    }
    return state;
}
