import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getGlobalErrorMessages} from '../loading/loading.selector';
import {RemoveMessage} from '../loading/loading.action';
import {MessageService} from 'primeng/api';
import {SubSink} from 'subsink';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.css']
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    constructor(private store: Store<any>,
                private messageService: MessageService,
                private translator: TranslateService
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.store.pipe(select(getGlobalErrorMessages)).subscribe(
          (data) => {
              if (data.length > 0) {
                  data.forEach((message => {
                      this.messageService.add({
                          key: 'tst',
                          severity: message.severity,
                          summary: this.translator.instant(message.summary),
                          detail: this.translator.instant(message.data)
                      });
                  }));
                  this.store.dispatch(new RemoveMessage(data, 'global'));
              }
          }
      );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}
