import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AreaOfCreate,
    AreaOfDelete,
    AreaOfSet,
    AreaOfUpdate,
    AreasOfError,
    AreasOfFetch,
    AreasOfFetchLazyMeta,
    AreasOfSet
} from './areas-of.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {
    AreaOfCreateSuccess,
    AreaOfDeleteSuccess,
    AreaOfFetch,
    AreasOfActionTypes,
    AreaOfUpdateSuccess
} from './areas-of.actions';
import {Router} from '@angular/router';
import {AppState} from '../../../store/app.reducer';
import {State} from './areas-of.reducer';

const handleError = (errorRes: ApolloError) => {
    return of(new AreasOfError());
};

@Injectable()
export class AreasOfEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect()
    fetchAreasOf = this.actions$.pipe(
        ofType(AreasOfActionTypes.AreasOfFetch),
        switchMap((areasOfActions: AreasOfFetch) => {
            this.store.dispatch(new AreasOfFetchLazyMeta({lazyLoad: areasOfActions.payload.lazyLoad}));
            return this.conPointService.getAreasOfList({
                lazyLoad: areasOfActions.payload.lazyLoad,
                type: areasOfActions.payload.type
            }).pipe(
                map((result) => {
                    const areasOf = result.data.GetAreasOfList.result?.map(areaOf => {
                        return {...areaOf};
                    });
                    return {
                        areasOf,
                        total: result.data.GetAreasOfList.metadata.total,
                        count: result.data.GetAreasOfList.metadata.count
                    };
                }),
                map(({areasOf, total, count}) => {
                    return new AreasOfSet({areasOf: areasOf ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    areaOfFetchById = this.actions$.pipe(
        ofType(AreasOfActionTypes.AreaOfFetch),
        switchMap((areasOfActions: AreaOfFetch) => {
            return this.conPointService.getAreaOf({id: areasOfActions.payload.id}).pipe(
                map((result) => {
                    return new AreaOfSet({areaOf: result.data.GetAreaOf});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    areaOfCreate = this.actions$.pipe(
        ofType(AreasOfActionTypes.AreaOfCreate),
        withLatestFrom(this.store.select('areasOf')),
        switchMap<[AreaOfCreate, State], Observable<any>>(
            ([areaOfActions, areasOfState]) => {
            return this.conPointService.createAreaOf({areaOf: areaOfActions.payload.areaOf}).pipe(
                map(() => {
                    // this.store.dispatch(new AreasOfFetch({
                    //     lazyLoad: areasOfState.currentLazyLoad,
                    //     type: areaOfActions.payload.areaOf.type
                    // }));
                    return new AreaOfCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    areaOfUpdate = this.actions$.pipe(
        ofType(AreasOfActionTypes.AreaOfUpdate),
        withLatestFrom(this.store.select('areasOf')),
        switchMap<[AreaOfUpdate, State], Observable<any>>(
            ([areaOfActions, areasOfState]) => {

            const areaOf = {...areaOfActions.payload.areaOf};
            delete areaOf['createdAt'];
            delete areaOf['updatedAt'];

            return this.conPointService.updateAreaOf({areaOf}).pipe(
                map(() => {
                    this.store.dispatch(new AreasOfFetch({
                        lazyLoad: areasOfState.currentLazyLoad,
                        type: areaOf.type
                    }));
                    return new AreaOfUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    areaOfDelete = this.actions$.pipe(
        ofType(AreasOfActionTypes.AreaOfDelete),
        withLatestFrom(this.store.select('areasOf')),
        switchMap<[AreaOfDelete, State], Observable<any>>(
            ([areaOfActions, areasOfState]) => {
            return this.conPointService.deleteAreaOf({id: areaOfActions.payload.id}).pipe(
                map(() => {
                    this.store.dispatch(new AreasOfFetch({
                        lazyLoad: areasOfState.currentLazyLoad,
                        type: areaOfActions.payload.type
                    }));
                    return new AreaOfDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
