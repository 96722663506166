import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-standalone-table-filter',
    templateUrl: './standalone-table-filter.component.html',
    styleUrls: ['./standalone-table-filter.component.css']
})
export class StandaloneTableFilterComponent implements OnInit, OnChanges {
    @Input() identifier: string;
    @Input() filtersDef: {rows: []};

    @Output() filterChanged = new EventEmitter();

    private filters: Map<string, {field: string, value: string, matchMode: string}> = new Map();

    filterDebounceTime = 600;
    filterTextChanged: Subject<any> = new Subject<any>();

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filtersDef) {
            changes.filtersDef.currentValue?.rows.map((row) => {
                row.map((filter) => {
                    if (filter.matchMode === 'dateRange') {
                        this[filter.field] = null;
                    }
                });
            });
        }
    }

    private emitFilters() {
        this.filterChanged.emit(Array.from(this.filters.values()));
    }

    onInput(filter: any, event: any) {
        if (event.target.value === undefined || event.target.value.length === 0) {
            this.filters.delete(filter.field);
        } else {
            this.filterTextChanged.pipe(debounceTime(this.filterDebounceTime), distinctUntilChanged()).subscribe(query => {
                const filterValue = {
                    field: filter.dbField,
                    value: event.target.value,
                    matchMode: filter.matchMode
                };
                this.filters.set(filter.field, filterValue);
                this.emitFilters();
            });
            this.filterTextChanged.next({ event, filter });
        }
    }

    onDropDownChange(filter: any, event: any) {
        if (event.value === undefined || event.value.length === 0) {
            this.filters.delete(filter.field);
        } else {
            const filterValue = {
                field: filter.dbField,
                value: JSON.stringify(event.value),
                matchMode: filter.matchMode
            };
            this.filters.set(filter.field, filterValue);
        }
        this.emitFilters();
    }

    onDateChange(filter: any, event: any) {
        if (event === null || this[filter.field] === undefined || this[filter.field].length === 0) {
            this.filters.delete(filter.field);
        } else {
            const filterValue = {
                field: filter.dbField,
                value: JSON.stringify(this[filter.field]),
                matchMode: filter.matchMode
            };
            this.filters.set(filter.field, filterValue);
        }
        this.emitFilters();
    }
}
