import { Action } from '@ngrx/store';
import {MessageAction, LoadingAction} from './loading.model';
import {Message} from 'primeng/api';

export enum ActionTypes {
    Add = '[@fx/ngrx/loading] Add',
    Remove = '[@fx/ngrx/loading] Remove'
}

export class AddLoading implements Action, Partial<LoadingAction> {
    readonly type = ActionTypes.Add;
    fxLoading: { action: string, ids: any[], type: string };
    constructor(ids: any[], type: string) {
        this.fxLoading = { action: 'add', ids, type };
    }
}

export class RemoveLoading implements Action, Partial<LoadingAction> {
    readonly type = ActionTypes.Remove;
    fxLoading: { action: string, ids: any[], type: string };
    constructor(ids: any[], type: string) {
        this.fxLoading = { action: 'remove', ids, type };
    }
}

export class AddMessage implements Action, Partial<MessageAction> {
    readonly type = ActionTypes.Add;
    fxMessage: { action: string, messages: Message[], type: string };
    constructor(messages: Message[], type: string) {
        this.fxMessage = { action: 'add', messages, type };
    }
}

export class RemoveMessage implements Action, Partial<MessageAction> {
    readonly type = ActionTypes.Remove;
    fxMessage: { action: string, messages: Message[], type: string };
    constructor(messages: Message[], type: string) {
        this.fxMessage = { action: 'remove', messages, type };
    }
}

export type Actions = AddLoading | RemoveLoading | AddMessage | RemoveMessage;
