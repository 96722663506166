import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {select, Store} from '@ngrx/store';
import {
    currentStateChooseSubjectProject,
    currentStateGetPermissions, currentStateReload,
} from './store/current-state/current-state.selector';
import {CustomPermissionsService} from './shared/services/custom-permissions.service';
import {Project, Subject} from '../generated/graphql';
import {SubSink} from 'subsink';
import {GlobalService} from './shared/services/global.service';
import {User} from './components/auth/user.model';
import {UserStorage} from './components/auth/user-storage.model';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {getProjects} from './components/projects/store/projects.selector';
import {HttpClient} from '@angular/common/http';
import {
    CurrentStateActionTypes, CurrentStatePermissionsSet,
    CurrentStateProjectSet, CurrentStateReload,
    CurrentStateSubjectSet,
    CurrentStateSubjectsProjectsFetch, CurrentStateSubjectsProjectsSet
} from './store/current-state/current-state.actions';
import {AuthActionTypes, AuthLogout, AuthPermissionsFetch, AuthPermissionsFetchSuccess} from './components/auth/store/auth.actions';
import {TagDefectsSet} from './components/tags/tag-defects/store/tag-defects.actions';
import {TagTasksSet} from './components/tags/tag-tasks/store/tag-tasks.actions';
import {TagGoodPracticesSet} from './components/tags/tag-good-practice/store/tag-good-practice.actions';
import {ProjectFetch} from './components/projects/store/projects.actions';
import {AppActions} from './store/app-actions.service';
import {Language} from './shared/model/language.model';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.css']
})
export class AppMenuComponent implements OnInit, OnDestroy {

    private subs = new SubSink();

    user: any;
    public appSubjectList: Subject[];
    public selectedSubject: Subject;
    public selectedSubjectOption: number;

    public appProjectList: Project[];
    public selectedProject: Project = null;
    public selectedProjectOption: number;

    public appLanguageList: Language[];
    public selectedLanguage: Language = null;
    public selectedLanguageOption: number;

    public environmentName: string;

    model: any[];

    private userLoadedFromStorage = false;

    constructor(
        public appMain: AppMainComponent,
        private store: Store<any>,
        private router: Router,
        private permissionsService: CustomPermissionsService,
        private gs: GlobalService,
        private http: HttpClient,
        private appActions: AppActions,
    ) {
    }

    ngOnInit() {
        this.environmentName = this.gs.getEnvironment().environmentName;

        this.subs.sink = this.store.select(currentStateChooseSubjectProject).subscribe((chooseSubjectProject) => {
            if (!chooseSubjectProject) {
                this.subs.sink = this.store.pipe(select(currentStateGetPermissions)).subscribe(
                    (permissions) => {
                        this.permissionsService.loadPermissions(permissions);
                    }
                );

                this.subs.sink = this.store.pipe(select(currentStateReload)).subscribe(
                    (reloadState) => {
                        if (reloadState) {
                            this.setCurrentState();
                            this.store.dispatch(new CurrentStateReload({value: false}));
                        }
                    }
                );

                this.setCurrentState();
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    saveCurrentProject() {
        const user: {
            email: string;
            id: number;
            token: string;
            tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));

        let userStorageX: {
            currentSubject: Subject;
            currentProject: Project;
            currentLanguage: Language;
        } = JSON.parse(localStorage.getItem('user-storage-' + user.id));

        userStorageX = {
            ...userStorageX,
            currentProject: this.selectedProject
        };
        localStorage.setItem('user-storage-' + user.id, JSON.stringify(userStorageX));
    }

    saveCurrentLanguage() {
        const user: {
            email: string;
            id: number;
            token: string;
            tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));

        let userStorageX: {
            currentSubject: Subject;
            currentProject: Project;
            currentLanguage: Language;
        } = JSON.parse(localStorage.getItem('user-storage-' + user.id));

        userStorageX = {
            ...userStorageX,
            currentLanguage: this.selectedLanguage
        };
        localStorage.setItem('user-storage-' + user.id, JSON.stringify(userStorageX));
    }

    saveCurrentSubject() {
        const user: {
            email: string;
            id: number;
            token: string;
            tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));

        let userStorageX: {
            currentSubject: Subject;
            currentProject: Project;
            currentLanguage: Language;
        } = JSON.parse(localStorage.getItem('user-storage-' + user.id));

        userStorageX = {
            ...userStorageX,
            currentSubject: this.selectedSubject
        };
        localStorage.setItem('user-storage-' + user.id, JSON.stringify(userStorageX));
    }

    setCurrentState() {
        this.appActions.dispatch(
            new CurrentStateSubjectsProjectsFetch(),
            [CurrentStateActionTypes.CurrentStateSubjectsProjectsSet]
        ).subscribe((a) => {
            if (a instanceof CurrentStateSubjectsProjectsSet) {
                const userData: User = JSON.parse(localStorage.getItem('user-data'));
                this.user = userData;

                this.appLanguageList = [{id: 0, name: 'Čeština', value: 'cs'}, {id: 1, name: 'Angličtina', value: 'en'}];
                this.appSubjectList = [...a.payload.subjects];

                const userStorage: UserStorage = JSON.parse(localStorage.getItem('user-storage-' + userData.id));
                let selectedSubject = null;
                let selectedProject = null;
                let selectedLanguage = null;
                if (userStorage) {
                    if (userStorage.currentSubject) {
                        selectedSubject = userStorage.currentSubject;
                    }

                    if (userStorage.currentProject) {
                        selectedProject = userStorage.currentProject;
                    }

                    if (userStorage.currentLanguage) {
                        selectedLanguage = userStorage.currentLanguage;
                    }
                }

                if (selectedLanguage === null) {
                    selectedLanguage = this.appLanguageList[0];
                }

                const subjectExists = this.appSubjectList?.find(x => x.id === selectedSubject?.id);
                if (!subjectExists) {
                    selectedSubject = this.appSubjectList[0];
                }
                this.appProjectList = [...this.appSubjectList.find(x => x.id === selectedSubject.id)?.projects];

                if (this.appProjectList.length > 0) {
                    const projectExists = this.appProjectList?.find(x => x.id === selectedProject?.id);
                    if (!projectExists) {
                        selectedProject = this.appProjectList[0];
                    }
                }

                this.store.dispatch(new CurrentStateSubjectSet({subject: selectedSubject}));
                this.store.dispatch(new CurrentStateProjectSet({project: selectedProject}));

                this.selectedSubject = selectedSubject;
                this.selectedSubjectOption = selectedSubject?.id;
                this.selectedProject = selectedProject;
                this.selectedProjectOption = selectedProject?.id;
                this.selectedLanguage = selectedLanguage;
                this.selectedLanguageOption = selectedLanguage?.id;

                this.saveCurrentSubject();
                this.saveCurrentProject();
                this.saveCurrentLanguage();

                this.setPermissions();
            }
        });
    }

    setPermissions() {
        this.appActions.dispatch(
            new AuthPermissionsFetch(), [AuthActionTypes.AuthPermissionsFetchSuccess]
        ).subscribe((a) => {
            if (a instanceof AuthPermissionsFetchSuccess) {
                this.appActions.dispatch(new CurrentStatePermissionsSet({permissions: a.payload.permissions})).toPromise().then(() => {
                    this.subs.sink = this.permissionsService.getNgxPermissions$().pipe(take(1)).subscribe(async () => {
                        this.model = [];
                        this.model.push({label: 'menu.dashboard', icon: 'pi pi-home', routerLink: ['/']});

                        await this.setMenuItem(
                            'users',
                            {label: 'menu.users', icon: 'pi pi-users', routerLink: ['/users']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getUsers'
                        );

                        await this.setMenuItem(
                            'contacts',
                            {label: 'menu.contacts', icon: 'pi pi-id-card', routerLink: ['/contacts']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getContacts'
                        );

                        await this.setMenuItem(
                            'subjects',
                            {label: 'menu.subjects', icon: 'pi pi-share-alt', routerLink: ['/subjects']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getSubjects'
                        );

                        await this.setMenuItem(
                            'projects',
                            {label: 'menu.projects', icon: 'pi pi-th-large', routerLink: ['/projects']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getProjects'
                        );

                        await this.setProjectMenuItem(
                            'defects',
                            {label: 'menu.defects', icon: 'pi pi-ban', routerLink: ['/defects']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getDefects'
                        );

                        await this.setProjectMenuItem(
                            'tasks',
                            {label: 'menu.tasks', icon: 'pi pi-check-square p-mr-1', routerLink: ['/tasks']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getTasks'
                        );

                        await this.setProjectMenuItem(
                            'goodPractice',
                            {label: 'menu.good_practice', icon: 'pi pi-check p-mr-1', routerLink: ['/good-practice']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getGoodPractice'
                        );

                        await this.setProjectMenuItem(
                            'reports',
                            {label: 'menu.reports', icon: 'pi pi-file-pdf', routerLink: ['/reports']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getReports'
                        );

                        await this.setProjectMenuItem(
                            'genericTexts',
                            {label: 'menu.generic_texts', icon: 'pi pi-list', routerLink: ['/generic-texts']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getGenericTexts'
                        );

                        await this.setProjectMenuItem(
                            'areaOf',
                            {label: 'menu.areas_of', icon: 'pi pi-map', routerLink: ['/areas-of']},
                            this.selectedProject,
                            this.selectedSubject,
                            'getAreaOf'
                        );

                        await this.setMenuItem(
                            'settings',
                            {
                                label: 'menu.settings.title', icon: 'pi pi-cog', routerLink: ['/settings'],
                                items: [
                                    {label: 'menu.settings.osh_subject', icon: 'pi pi-columns', routerLink: ['/settings/osh-subject']},
                                ]
                            },
                            null,
                            this.selectedSubject,
                            'getOshSettings'
                        );

                        await this.setMenuItem(
                            'management',
                            {
                                label: 'menu.management.title', icon: 'pi pi-globe', routerLink: ['/management'],
                                items: [
                                    {label: 'menu.management.accounts', icon: 'pi pi-desktop', routerLink: ['/management/accounts']},
                                ]
                            },
                            null,
                            this.selectedSubject,
                            'superadmin'
                        );
                    });
                });
            }
        });
    }

    async setMenuItem(menuItemId: string, menuItem: any, project: Project, subject: Subject, permission: string | string[] = []) {
        await this.permissionsService.hasSubjectPermission(subject, permission).then(async (result) => {
            this.model = this.model.filter((element) => {
                return element.menuItemId !== menuItemId;
            });

            if (result) {
                this.model.push({
                    ...menuItem,
                    menuItemId
                });
            } else {
                await this.setProjectMenuItem(menuItemId, menuItem, project, subject, permission);
            }
        });
    }

    async setProjectMenuItem(menuItemId: string, menuItem: any, project: Project, subject: Subject, permission: string | string[] = []) {
        await this.permissionsService.hasProjectPermission(project, permission).then((result) => {
            this.model = this.model.filter((element) => {
                return element.menuItemId !== menuItemId;
            });

            if (result) {
                this.model.push({
                    ...menuItem,
                    menuItemId
                });
            }
        });
    }

    onMenuClick() {
        this.appMain.menuClick = true;
    }

    onChangeOshSubject(event: any) {
        this.appProjectList = [];
        this.selectedSubject = this.appSubjectList.find(x => x.id === event.value);
        this.selectedSubjectOption = event.value;
        this.selectedProject = null;

        this.saveCurrentSubject();
        this.saveCurrentProject();

        this.setCurrentState();

        this.router.navigate(['/']);
    }

    onLogout() {
        this.store.dispatch(new AuthLogout());
    }

    onProfileEdit(userId) {
        this.router.navigate(['/', 'user', 'edit', userId]);
    }

    async onDownloadApp() {
        await this.appMain.onDownloadApp();
    }

    onChangeProject(event: any) {
        this.selectedProject = this.appProjectList.find(x => x.id === event.value);
        this.selectedProjectOption = event.value;

        this.saveCurrentSubject();
        this.saveCurrentProject();

        this.setCurrentState();

        this.store.dispatch(new TagDefectsSet({defects: [], totalRows: 0}));
        this.store.dispatch(new TagTasksSet({tasks: [], totalRows: 0}));
        this.store.dispatch(new TagGoodPracticesSet({goodPractices: [], totalRows: 0}));
        this.store.dispatch(new ProjectFetch({projectId: event.value}));

        this.router.navigate(['/']);
    }

    onChangeLanguage(event: any) {
        this.selectedLanguage = this.appLanguageList.find(x => x.id === event.value);
        this.selectedLanguageOption = event.value;

        this.saveCurrentSubject();
        this.saveCurrentProject();
        this.saveCurrentLanguage();

        this.setCurrentState();
    }
}
