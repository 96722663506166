import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {LazyLoadMeta} from '../../shared/model/lazy-load-meta.model';
import {ContactFormComponent} from '../../forms/contact-form/contact-form.component';
import {BaseComponent} from '../../shared/base/base.component';
import {Contact} from '../../../generated/graphql';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../shared/services/global.service';
import {Table} from 'primeng/table';
import {AppState} from '../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../fixed-toolbar/fixed-toolbar.action';
import {ContactDelete, ContactFetch, ContactSet, ContactsFetch} from './store/contacts.actions';
import {ContactFormDialogShow} from '../../forms/contact-form/store/contact-form.actions';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    cols: any[];
    contacts: Contact[];
    selectedContact: Contact;
    loading: boolean;
    totalRecords: number;
    contactFormDisplay = false;
    @ViewChild(ContactFormComponent) contactForm: ContactFormComponent;
    @ViewChild('dt') dataTable: Table;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        forkJoin([
            this.translate.get('contacts.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/contacts'}
            ]));
        });

        this.store.dispatch(new UpdateToolbarButtons([
            {
                id: 'createContactButton',
                name: 'contacts.create',
                action: 'onCreateContact',
                class: 'p-button-success',
                icon: 'pi pi-plus',
                disabled: false,
            },
        ]));

        this.cols = [
            { field: 'firstName', header: 'users.first_name', display: 'table-cell' },
            { field: 'lastName', header: 'users.last_name', display: 'table-cell' },
            { field: 'email', header: 'users.email', display: 'table-cell' },
            { field: 'phoneNumber', header: 'users.phone_number', display: 'table-cell' },
            { field: 'createdAt', header: 'general_tag.created_at', display: 'table-cell' },
        ];

        this.subs.sink = this.store.select('contacts').pipe(
            map(contactsState => {
                return {
                    contacts: contactsState.contacts,
                    totalRows: contactsState.totalRows,
                    currentLazyLoad: contactsState.currentLazyLoad
                };
                })).subscribe(
                ({contacts: contacts, totalRows: totalRows, currentLazyLoad: currentLazyLoad}) => {
                    this.contacts = contacts;
                    this.loading = false;
                    this.totalRecords = totalRows;
                });

        this.loading = true;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    fetchContacts(event: object): void {
        this.store.dispatch(new ContactsFetch(event));
    }

    lazyLoad(event: LazyLoadEvent) {
        this.loading = true;

        if (this.dataTable) {
            this.dataTable.saveState();
        }

        const searchData = LazyLoadMeta.fromData(event);
        this.fetchContacts(searchData);
    }

    ngAfterViewInit() {
        this.subs.sink = this.dataTable.onStateRestore.subscribe((state) => {
            setTimeout(() => {
                // Object.keys(this.dataTable.filters).forEach((key) => {
                //     if (this.dataTable.filters[key].matchMode === 'dateRange') {
                //         if (Array.isArray(this.dataTable.filters[key].value)) {
                //             this[key] = [];
                //             this.dataTable.filters[key].value.forEach((value, index) => {
                //                 if (value) {
                //                     this[key][index] = new Date(value);
                //                 }
                //             });
                //         }
                //     }
                // });
            }, 0);
        });

        this.dataTable.restoreState();
    }

    toggleContactForm() {
        // this.contactForm.dialogToggle();
        this.store.dispatch(new ContactFormDialogShow());
    }

    onEdit(contactId: number) {
        this.subs.sink = this.store.select('contacts').pipe(
            map(contactsState => {
                return {loadedContact: contactsState.loadedContact};
            })).subscribe(
            ({loadedContact: editedContact}) => {
                if (editedContact) {
                    this.contactForm.editContact(editedContact);
                    this.store.dispatch(new ContactFormDialogShow());
                    this.store.dispatch(new ContactSet({contact: null}));
                }
            });

        this.store.dispatch(new ContactFetch({contactId}));
    }

    onCreateContact() {
        this.toggleContactForm();
    }

    onDelete(id: number) {
        forkJoin([this.translate.get('contacts.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0],
                accept: () => {
                    // this.store.dispatch(new AddMessage([FlashMessage.infoMessage('Not yet implemented')], 'global'));
                    this.store.dispatch(new ContactDelete({contactId: id}));
                }
            });
        });
    }
}
