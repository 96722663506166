import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../../loading';
import { MessageAction } from '../../../../loading/loading.model';
import { FlashMessage} from '../../../../shared/model/flash-message.model';
import { TagTask, TagTaskInput} from '../../../../../generated/graphql';

export enum TagTasksActionTypes {
    TagTasksFetchLazyMeta = '[@TagTasks] FetchLazyMeta',
    TagTasksSet = '[@TagTasks] Set',
    TagTaskSet = '[@TagTask] Set',
    TagTasksFetch = '[@TagTasks] Fetch',
    TagTaskFetch = '[@TagTask] Fetch',
    TagTaskBrowseFetch = '[@TagTask] Browse Fetch',
    TagTaskCreate = '[@TagTask] Create',
    TagTaskCreateSuccess = '[@TagTask] SaveSuccess',
    TagTaskUpdate = '[@TagTask] Update',
    TagTaskUpdateSuccess = '[@TagTask] UpdateSuccess',
    TagTaskDelete = '[@TagTask] Delete',
    TagTaskDeleteSuccess = '[@TagTask] DeleteSuccess',
    TagTasksError = '[@TagTasks] Error',
}

export class TagTasksSet implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTasksSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagTasksActionTypes.TagTasksFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {tasks: TagTask[], totalRows: number }) {}
}

export class TagTasksFetch implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTasksFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTasksFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class TagTaskSet implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskSet;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: TagTasksActionTypes.TagTaskFetch, spinner: 'sp_llcl'},
            {name: TagTasksActionTypes.TagTaskBrowseFetch, spinner: 'sp_llcl'}
        ],
        type: 'global'
    };
    constructor(public payload: {task: TagTask }) {}
}

export class TagTaskFetch implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTaskFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {taskId: number}) {}
}

export class TagTaskBrowseFetch implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskBrowseFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTaskBrowseFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {projectKeyName: string, tagSequenceNumber: number}) {}
}

export class TagTaskCreate implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTaskCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {task: TagTaskInput}) {}
}

export class TagTaskCreateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagTasksActionTypes.TagTaskCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagTasksActionTypes.TagTaskCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('tasks.created')],
        type: 'global'
    };
}

export class TagTaskUpdate implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTaskUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {task: TagTaskInput, action: string}) {}
}

export class TagTaskUpdateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagTasksActionTypes.TagTaskUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagTasksActionTypes.TagTaskUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('tasks.updated')],
        type: 'global'
    };
}

export class TagTaskDelete implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: TagTasksActionTypes.TagTaskDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {taskId: number}) {}
}

export class TagTaskDeleteSuccess implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTaskDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagTasksActionTypes.TagTaskDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('tasks.deleted')],
        type: 'global'
    };
}

export class TagTasksError implements Action, LoadingAction {
    readonly type = TagTasksActionTypes.TagTasksError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: TagTasksActionTypes.TagTasksFetch, spinner: 'sp_lll'},
            {name: TagTasksActionTypes.TagTaskFetch, spinner: 'sp_llcl'},
            {name: TagTasksActionTypes.TagTaskBrowseFetch, spinner: 'sp_llcl'},
            {name: TagTasksActionTypes.TagTaskCreate, spinner: 'sp_lll'},
            {name: TagTasksActionTypes.TagTaskUpdate, spinner: 'sp_llb'},
            {name: TagTasksActionTypes.TagTaskDelete, spinner: 'sp_lll'}
        ],
        type: 'all'
    };

    constructor() {}
}

export class TagTasksFetchLazyMeta implements Action {
    readonly type = TagTasksActionTypes.TagTasksFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type TagTasksActions =
    TagTasksFetch |
    TagTaskCreate |
    TagTaskCreateSuccess |
    TagTaskUpdate |
    TagTaskUpdateSuccess |
    TagTaskDelete |
    TagTaskDeleteSuccess |
    TagTasksSet |
    TagTaskSet |
    TagTaskFetch |
    TagTaskBrowseFetch |
    TagTasksFetchLazyMeta |
    TagTasksError;
