import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, NgForm} from '@angular/forms';
import {ContactPartComponent} from '../../../forms/parts/contact-part.component';
import {AddressInput, Contact, ContactInput} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {delay, map} from 'rxjs/operators';
import {AddMessage} from '../../../loading/loading.action';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {MessageService} from 'primeng/api';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ContactCreate, ContactFetch, ContactSet, ContactUpdate} from '../store/contacts.actions';
import {ContactFormDialogHide} from '../../../forms/contact-form/store/contact-form.actions';

@Component({
    selector: 'app-contacts-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() type: string;
    @ViewChild('f') contactForm: NgForm;
    @ViewChild(ContactPartComponent) contactPart: ContactPartComponent;

    display: boolean;
    newContact = true;
    private loadedContact: Contact;
    private editMode: boolean;
    private editId: number;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        private messageService: MessageService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private ps: CustomPermissionsService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            if (this.editMode) {
                this.fetchContact(this.editId);
            }
        });

        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.buttons = [
            {
                id: 'saveContactButton',
                name: 'contacts.save',
                action: 'onSaveContact',
                class: 'p-button-success',
                icon: 'pi pi-save p-mr-1',
                disabled: false,
            }
        ];

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.ps.hasPermission('createUsers').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'createContactButton',
                            name: 'contacts.create',
                            action: 'onCreateContact',
                            class: 'p-button-primary',
                            icon: 'pi pi-plus',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });
        });

        this.subs.sink = this.store.select('contacts').pipe(
            delay(0),
            map(contactsState => {
                return {
                    loadedContact: contactsState.loadedContact,
                };
            })).subscribe(
            ({
                 loadedContact: loadedContact,
             }) => {
                if (loadedContact) {
                    this.editContact(loadedContact);
                    this.store.dispatch(new ContactSet({contact: null}));
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchContact(contactId: number) {
        this.store.dispatch(new ContactFetch({contactId}));
    }

    editContact(contact: Contact) {
        this.contactForm.resetForm();
        if (this.editMode) {
            this.loadedContact = contact;
            this.contactForm.form.patchValue({
                    contactData: {
                        ...contact
                    },
                    addressData: {
                        ...contact.address,
                        addressId: contact.address?.id
                    },
                }
            );

            this.newContact = false;
            this.display = true;
        }
    }

    onHide(event) {
        if (!this.newContact) {
            this.contactForm.resetForm();
        }

        this.newContact = true;
        this.store.dispatch(new ContactFormDialogHide());
    }

    onCancel() {
        this.contactForm.resetForm();
        this.store.dispatch(new ContactFormDialogHide());
    }

    onReset() {
        this.contactForm.resetForm();
    }

    onSaveContact() {
        this.contactForm.ngSubmit.emit();
    }

    onCreateContact() {
        this.contactForm.resetForm();
    }

    onSubmit(form: NgForm) {
        if (form.valid) {

            // let address = null;
            // if (
            //     form.value.addressData.street &&
            //     form.value.addressData.zip &&
            //     form.value.addressData.city &&
            //     form.value.addressData.country
            // ) {
            //     address = {
            //         id: form.value.addressData.addressId ? form.value.addressData.addressId : null,
            //         street: form.value.addressData.street,
            //         zip: form.value.addressData.zip,
            //         city: form.value.addressData.city,
            //         country: form.value.addressData.country,
            //     } as AddressInput;
            // }

            const contactId = this.loadedContact?.id ?? null;

            const contact: ContactInput = {
                id: this.loadedContact?.id,
                firstName: form.value.contactData.firstName,
                lastName: form.value.contactData.lastName,
                email: form.value.contactData.email,
                phoneNumber: form.value.contactData.phoneNumber,
                companyName: form.value.contactData.companyName,
                function: form.value.contactData.function,
                address: null,
                enabled: true,
            };

            if (contactId) {
                this.store.dispatch(new ContactUpdate({contact}));
            } else {
                this.store.dispatch(new ContactCreate({contact}));
            }
        }  else {
            Object.keys(form.controls).forEach(group => {
                const formGroup = form.controls[group] as FormGroup;
                Object.keys(formGroup.controls).forEach(key => {
                    formGroup.controls[key].markAsTouched();
                });
            });

            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('errors.invalid_form_fields')], 'global'));
        }
    }
}
