import { Action } from '@ngrx/store';
import { LoadingAction } from '../../loading';

export enum GeneralActionTypes {
    GeneralSaveForm = '[@General] SaveForm',
}

export class GeneralSaveFormAction implements Action, LoadingAction {
    readonly type = GeneralActionTypes.GeneralSaveForm;
    fxLoading = {
        action: 'add',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {id: string, data: string}) {}
}

export type GeneralActions =
    GeneralSaveFormAction;
