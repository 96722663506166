import {createFeatureSelector, createSelectorFactory, MemoizedProjection} from '@ngrx/store';
import { State } from './tag-good-practice.reducer';
import {AnyFn} from '@ngrx/store/src/selector';

export const getGoodPracticeFeature = createFeatureSelector<State>('tagGoodPractices');

export function isEqual(a: any, b: any): boolean {
    return a.totalRows === b.totalRows &&
        a.goodPractices.every(fromA => b.goodPractices.includes(fromA));
}

export function customMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export const getGoodPractices = createSelectorFactory(customMemoize)(
    getGoodPracticeFeature,
    (state) => {
        return {
            currentLazyLoad: state.currentLazyLoad,
            goodPractices: state.goodPractices,
            totalRows: state.totalRows
        };
    }
);
