import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../user.model';
import {AuthService} from '../auth.service';
import {ConPointAuthService} from '../../../../generated/graphql_auth';
import {ApolloError} from '@apollo/client/core';
import {Store} from '@ngrx/store';
import {ConPointService} from '../../../../generated/graphql';
import {
    AuthActionTypes,
    AuthError,
    AuthForgetPassword,
    AuthLogin,
    AuthLoginSuccess, AuthPasswordReset,
    AuthPermissionsFetchSuccess,
    AuthSuccess
} from './auth.actions';
import {AppState} from '../../../store/app.reducer';
import {CurrentStateChooseSubjectProject} from '../../../store/current-state/current-state.actions';
import {ChooseSubjectProjectDialogShow} from '../../../forms/choose-subject-project/store/choose-subject-project.actions';

export interface AuthResponseData {
    king: string;
    token: string;
    email: string;
    refreshToken: string;
    expiresIn: string;
    id: number;
    registered?: boolean;
}

const handleAuthentication = (email: string, userId: number, token: string, expiresIn: number) => {
    const expirationDate = new Date(new Date().getTime() + +expiresIn * 1000);

    const user: User = new User(
        email,
        userId,
        token,
        expirationDate);

    localStorage.setItem('user-data', JSON.stringify(user));

    return new AuthLoginSuccess({
        email,
        userId,
        token,
        expirationDate,
        redirect: true
    });
};

const handleError = (errorRes: ApolloError) => {
    return of(new AuthError());
};


@Injectable()
export class AuthEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private router: Router,
                private authService: AuthService,
                private store: Store<AppState>,
                private conPointAuthService: ConPointAuthService,
                private conPointService: ConPointService
    ) {}
    @Effect()
    authLogin = this.actions$.pipe(
        ofType(AuthActionTypes.AuthLogin),
        switchMap((authData: AuthLogin) => {
            const authString = btoa(authData.payload.email + ':' + authData.payload.password);
            return this.conPointAuthService.authenticate(null, {
                context: {
                    headers: {
                        Authorization: 'Basic ' + authString
                    }
                }
            }).pipe(
                tap(resData => {
                    this.authService.setLogoutTimer(+resData.data.Authenticate.expiresIn * 1000);
                }),
                map(resData => {
                    this.store.dispatch(new CurrentStateChooseSubjectProject({value: true}));
                    this.store.dispatch(new ChooseSubjectProjectDialogShow());
                    return handleAuthentication(
                        resData.data.Authenticate.email,
                        resData.data.Authenticate.id,
                        resData.data.Authenticate.token,
                        +resData.data.Authenticate.expiresIn);
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    authForgetPassword = this.actions$.pipe(
        ofType(AuthActionTypes.AuthForgetPassword),
        switchMap((data: AuthForgetPassword) => {
            return this.conPointAuthService.forgetPassword({email: data.payload.email}).pipe(
                map(() => {
                    return new AuthSuccess('users.forget-password-success');
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    authPasswordReset = this.actions$.pipe(
        ofType(AuthActionTypes.AuthPasswordReset),
        switchMap((data: AuthPasswordReset) => {
            return this.conPointAuthService.passwordReset({
                hash: data.payload.hash,
                password: data.payload.password
            }).pipe(
                map(() => {
                    return new AuthSuccess('users.password-reset-success');
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    authPermissionsFetch = this.actions$.pipe(
        ofType(AuthActionTypes.AuthPermissionsFetch),
        switchMap(() => {
            return this.conPointService.getPermissions().pipe(
                map(resData => {
                    return new AuthPermissionsFetchSuccess(
                        {permissions: resData.data.GetPermissions.permissions}
                    );
                })
            );
        }),
    );

    @Effect({dispatch: false})
    authRedirect = this.actions$.pipe(
        ofType(AuthActionTypes.AuthLoginSuccess),
        tap((authSuccessAction: AuthLoginSuccess) => {
            if (authSuccessAction.payload.redirect) {
                this.router.navigate(['/']);
            }
        })
    );

    @Effect({dispatch: false})
    authSuccess = this.actions$.pipe(
        ofType(AuthActionTypes.AuthSuccess),
        tap(() => {
            this.router.navigate(['/']);
        })
    );

    @Effect({dispatch: false})
    authLogout = this.actions$.pipe(
        ofType(AuthActionTypes.AuthLogout),
        tap(() => {
            this.authService.clearLogoutTimer();
            localStorage.removeItem('user-data');
            // this.router.navigate(['/login']);
            window.location.reload();
        })
    );

    @Effect()
    autoLogin = this.actions$.pipe(
    ofType(AuthActionTypes.AuthAutoLogin),
    map(() => {
        const userData: {
            email: string;
            id: number;
            pToken: string;
            pTokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));
        if (!userData) {
            return {type: 'DUMMY'};
        }
        const loadedUser = new User(userData.email, userData.id, userData.pToken, new Date(userData.pTokenExpirationDate));
        if (loadedUser.token) {
            const expirationDuration = (new Date(userData.pTokenExpirationDate).getTime() - new Date().getTime());
            this.authService.setLogoutTimer(expirationDuration);

            return new AuthLoginSuccess({
                email: userData.email,
                userId: userData.id,
                token: userData.pToken,
                expirationDate: new Date(userData.pTokenExpirationDate),
                redirect: false
            });
        }
        return {type: 'DUMMY'};
        })
    );

}
