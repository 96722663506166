import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private gs: GlobalService) {
    }

    public getMediaLink(fileCode: string) {
        return this.gs.getEnvironment().mediaUrl + 'media/' + fileCode;
    }

    public getMediaThumbLink(fileCode: string) {
        return this.gs.getEnvironment().mediaUrl + 'media/thumb/' + fileCode;
    }

    public getNoImageLink() {
        return this.gs.getEnvironment().mediaUrl + 'assets/images/no-image.jpg';
    }
}
