import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {Contact} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ProxyService} from '../../../shared/services/proxy.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ContactFetch} from '../store/contacts.actions';

@Component({
    selector: 'app-contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent extends BaseComponent implements OnInit {

    private editId = null;
    public loadedContact: Contact;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected route: ActivatedRoute,
        protected proxyService: ProxyService,
        private translate: TranslateService,
        private ps: CustomPermissionsService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.fetchContact(this.editId);

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createContacts').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.buttons.push(
                            {
                                id: 'createContactButton',
                                name: 'contacts.create',
                                action: 'onCreateContact',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }
                        );
                    }
                    this.store.dispatch(new SetToolbarButtons(this.buttons));
                });
            });
        });
    }

    fetchContact(contactId: number) {
        this.subs.sink = this.store.select('contacts').subscribe(state => {
            this.loadedContact = state.loadedContact;
        });
        this.store.dispatch(new ContactFetch({contactId}));
    }
}
