import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AddressInput, Contact, ContactInput} from '../../../generated/graphql';
import {ContactPartComponent} from '../parts/contact-part.component';
import {SubSink} from 'subsink';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {MenuItem, MessageService} from 'primeng/api';
import {AppState} from '../../store/app.reducer';
import {ContactFormDialogHide} from './store/contact-form.actions';
import {ContactCreate, ContactUpdate} from '../../components/contacts/store/contacts.actions';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.css'],
    providers: [MessageService],
})
export class ContactFormComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    @Input() type: string;
    @ViewChild('f') contactForm: NgForm;
    @ViewChild(ContactPartComponent) contactPart: ContactPartComponent;

    display: boolean;
    items: MenuItem[];
    activeIndex = 0;
    activeStepName = 'contact-info';
    maxStepIndex = 0;
    newContact = true;
    private loadedContact: Contact;

    constructor(
        private store: Store<AppState>,
        private messageService: MessageService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.store.select('contactForm').pipe().subscribe((data) => {
            this.display = data.contactFormDialogShow;
        });

        let index = 0;

        forkJoin([
            this.translate.get('contacts.menu'),
            this.translate.get('address.menu')
        ]).subscribe(result => {
            this.items = [
                {
                    label: result[0],
                    automationId: index++,
                    tabindex: 'contact-info',
                    command: (event: any) => {
                        this.activeIndex = event.item.automationId;
                        this.activeStepName = event.item.tabindex;
                        this.messageService.add({severity: 'info', summary: 'Contact info', detail: event.item.label});
                    }
                }
            ];

            this.items.push(
                {
                    label: result[1],
                    automationId: index++,
                    tabindex: 'address',
                    command: (event: any) => {
                        this.activeIndex = event.item.automationId;
                        this.activeStepName = event.item.tabindex;
                        this.messageService.add({severity: 'info', summary: 'Address', detail: event.item.label});
                    }
                },
            );

            this.maxStepIndex = this.items.length - 1;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    editContact(contact: Contact) {
        this.loadedContact = contact;
        this.contactForm.form.patchValue({
                contactData: {
                    ...contact
                },
                addressData: {
                    ...contact.address,
                    addressId: contact.address?.id
                },
            }
        );

        this.newContact = false;
        this.display = true;
    }

    onHide(event) {
        if (!this.newContact) {
            this.contactForm.resetForm();
        }

        this.newContact = true;
        this.store.dispatch(new ContactFormDialogHide());
    }

    onCancel() {
        this.contactForm.resetForm();
        this.store.dispatch(new ContactFormDialogHide());
    }

    onReset() {
        this.contactForm.resetForm();
    }

    onPrevious() {
        this.setStep('prev');
    }

    onNext() {
        this.setStep('next');
    }

    setStep(type) {
        if (type === 'next') {
            this.activeIndex++;
        } else {
            this.activeIndex--;
        }
        this.items.forEach((value) => {
            if (value.automationId === this.activeIndex) {
                this.activeStepName = value.tabindex;
            }
        });
    }

    onSubmit(form: NgForm) {
        let address = null;
        if (
            form.value.addressData.street &&
            form.value.addressData.zip &&
            form.value.addressData.city &&
            form.value.addressData.country
        ) {
            const addressTmp: AddressInput = {
                id: form.value.addressData.addressId ? form.value.addressData.addressId : null,
                street: form.value.addressData.street,
                zip: form.value.addressData.zip,
                city: form.value.addressData.city,
                country: form.value.addressData.country,
            };

            address = addressTmp;
        }

        const contactId = this.loadedContact?.id ?? null;

        if (this.type === 'contactForm') {
            const contact: ContactInput = {
                id: this.loadedContact?.id,
                firstName: form.value.contactData.firstName,
                lastName: form.value.contactData.lastName,
                email: form.value.contactData.email,
                phoneNumber: form.value.contactData.phoneNumber,
                address,
                enabled: true,
            };

            if (contactId) {
                this.store.dispatch(new ContactUpdate({contact}));
            } else {
                this.store.dispatch(new ContactCreate({contact}));
            }
        }
    }
}
