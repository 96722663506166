import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './current-state.reducer';

export const getCurrentStateFeature = createFeatureSelector<State>('currentState');

export const currentStateGetSubjects = createSelector(
    getCurrentStateFeature,
    (state) => state.subjects
);


export const currentStateGetCurrentSubject = createSelector(
    getCurrentStateFeature,
    (state) => state.selectedSubject
);

export const currentStateGetCurrentProject = createSelector(
    getCurrentStateFeature,
    (state) => {
        return state.selectedProject;
    }
);

export const currentStateGetPermissions = createSelector(
    getCurrentStateFeature,
    (state) => state.permissions
);

export const currentStateGetSubjectAndPermissions = createSelector(
    getCurrentStateFeature,
    (state) => {
        return {subject: state.selectedSubject, permissions: [...state.permissions]};
    }
);

export const currentStateReload = createSelector(
    getCurrentStateFeature,
    (state) => state.reloadState
);

export const currentStateChooseSubjectProject = createSelector(
    getCurrentStateFeature,
    (state) => state.chooseSubjectProject
);
