import * as SubjectsActions from './subjects.actions';
import {SubjectsActionTypes} from './subjects.actions';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {Subject} from '../../../../generated/graphql';

export interface State {
    subjects: Subject[];
    currentLazyLoad: LazyLoadMeta;
    loadedSubject: Subject;
    totalRows: number;
    appConf: {subjects: Subject[]};
}

const initialState = {
    subjects: [],
    currentLazyLoad: null,
    loadedSubject: null,
    totalRows: 0,
    oshSubjects: [],
    appConf: {subjects: []},
};

export function subjectsReducer(state: State = initialState, action: SubjectsActions.SubjectsActions) {
    switch (action.type) {
        case SubjectsActionTypes.SubjectsSet:
            state =  {
                ...state,
                subjects: [...action.payload.subjects],
                totalRows: action.payload.totalRows
            };
            break;
        case SubjectsActionTypes.SubjectSet:
            state =  {
                ...state,
                loadedSubject: action.payload.subject,
            };
            break;
        case SubjectsActionTypes.SubjectsAppSet:
            state =  {
                ...state,
                appConf: {subjects: [...action.payload.subjects]},
            };
            break;
        case SubjectsActionTypes.SubjectsError:
            state =  {
                ...state,
            };
            break;
        case SubjectsActionTypes.SubjectsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
