import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {BaseComponent} from '../../../shared/base/base.component';
import {Project, Subject} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {getProjects} from '../store/projects.selector';
import {GlobalService} from '../../../shared/services/global.service';
import {Table} from 'primeng/table';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ProjectDelete, ProjectsFetch} from '../store/projects.actions';

@Component({
    selector: 'app-projects',
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent extends BaseComponent implements OnInit, OnDestroy {

    public projects: Project[];

    public totalRecords: number;
    public breadcrumbItems: MenuItem[];

    @ViewChild('dt') dataTable: Table;
    createdAt: any;
    updatedAt: any;
    public currentSubject: Subject;
    public sortColumns = [];
    public layoutContentHeight: number;

    public editPermissions: string[];
    public deletePermissions: string[];

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        public ps: CustomPermissionsService,
        private translate: TranslateService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = forkJoin([
            this.translate.get('general.created_at'),
            this.translate.get('projects.contract_name'),
            this.translate.get('projects.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                { label: result[0], value: {dbField: 'p.created_at', name: 'general.created_at'} },
                { label: result[1], value: {dbField: 'p.contract_name', name: 'projects.contract_name'} },
            ];

            this.store.dispatch(new SetBreadcrumb([
                {label: result[2], routerLink: '/projects-list'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((subject) => {
            this.currentSubject = subject;

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasSubjectPermission(subject, 'createProjects').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.store.dispatch(new UpdateToolbarButtons([
                            {
                                id: 'createProjectButton',
                                name: 'projects.create',
                                action: 'onCreateProject',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }])
                        );
                    }
                });
            });

            this.subs.sink = this.store.select(getProjects).pipe(
                map(state => {
                    return {
                        projects: state.projects,
                        totalRows: state.totalRows,
                    };
                }),
                switchMap(({
                   projects: projects,
                   totalRows: totalRows,
                }) => {
                    if (projects.length > 0) {

                        const promises = [];

                        projects.forEach(record => {
                            promises.push(this.ps.hasProjectPermission(record, 'updateProjects'));
                            promises.push(this.ps.hasProjectPermission(record, 'deleteProjects'));
                        });

                        return Promise.all(
                            promises
                        ).then( results => {
                            projects = projects.map((project, index) => {
                                const idx1 = (index * 2);
                                const idx2 = (index * 2) + 1;

                                const updatePerm = results[idx1];
                                const deletePerm = results[idx2];

                                const contextMenu = [];

                                if (deletePerm) {
                                    contextMenu.push({
                                        label: this.translate.instant('general.delete'),
                                        icon: 'pi pi-trash',
                                        command: (event: Event) => {
                                            this.onDelete(project.id, project.keyName);
                                        }
                                    });
                                }

                                const navLink = ['/', 'projects'];
                                if (updatePerm) {
                                    navLink.push('edit');
                                }
                                navLink.push(project.id);

                                return {
                                    ...project,
                                    contextMenu,
                                    navLink
                                };
                            });

                            return {
                                projects,
                                totalRows,
                            };
                        });
                    } else {
                        return new Promise((resolve) => {
                            resolve({
                                projects: [],
                                totalRows: 0,
                            });
                        });
                    }

                })).subscribe(
                ({
                     projects: projects,
                     totalRows: totalRows,
                 }) => {
                    this.projects = projects;
                    this.totalRecords = totalRows;
                });
        });

        super.ngOnInit();
    }

    onCreateProject() {
        this.router.navigate(['create'], {relativeTo: this.route});
    }

    fetchProjects(event: object): void {
        this.store.dispatch(new ProjectsFetch(event));
    }

    loadLazy(event: LazyLoadEvent) {
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchProjects(searchData);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        super.ngOnDestroy();
    }

    onEdit(id: any) {
        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    onDelete(id: any, identifier: string) {
        forkJoin([this.translate.get('projects.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new ProjectDelete({projectId: id}));
                }
            });
        });
    }
}
