import {LazyLoadEvent} from 'primeng/api';


export class LazyLoadMeta {
    public first?: number;
    public rows?: number;
    public sortField?: string;
    public sortOrder?: number;
    public multiSortMeta?: {field: string, order: number}[];
    public filters?: {field: string, value: string, matchMode: string}[];
    public globalFilter?: any;
    public customData?: {key: string, value: any}[];

    static fromData(event: any): LazyLoadMeta {
        const lazyLoad = event.lazyLoad as LazyLoadEvent;
        const customData = event.customData as {key: string, value: any}[];

        const sortMeta = event.multiSortMeta?.map(data => {
            return {field: data.field, order: data.order};
        });

        const filtersMeta = [];

        for (const [key, data] of Object.entries(lazyLoad?.filters ?? {})) {

            const value = JSON.stringify(data.value);
            filtersMeta.push({field: key, value, matchMode: data.matchMode});
        }

        return new this(
            lazyLoad?.first,
            lazyLoad?.rows,
            filtersMeta,
            lazyLoad?.globalFilter,
            lazyLoad?.sortField,
            lazyLoad?.sortOrder,
            sortMeta,
            customData
        );
    }

    constructor(
        first?: number,
        rows?: number,
        filters?: {field: string, value: string, matchMode: string}[],
        globalFilter?: string,
        sortField?: string,
        sortOrder?: number,
        multiSortMeta?: {field: string, order: number}[],
        customData?: {key: string, value: string}[]
    ) {
        this.first = first;
        this.rows = rows;
        this.filters = filters;
        this.globalFilter = globalFilter;
        this.sortField = sortField;
        this.sortOrder = sortOrder;
        this.multiSortMeta = multiSortMeta;
        this.customData = customData;
    }
}
