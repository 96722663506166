import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    ContactFetch,
    ContactsFetch,
    ContactCreateSuccess,
    ContactUpdateSuccess,
    ContactDeleteSuccess, ContactsError, ContactsActionTypes, ContactsFetchLazyMeta, ContactsSet, ContactSet, ContactUpdate, ContactDelete,
} from './contacts.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {AppState} from '../../../store/app.reducer';
import {State} from './contacts.reducer';
import {ContactFormDialogHide} from '../../../forms/contact-form/store/contact-form.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new ContactsError());
};

@Injectable()
export class ContactsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private conPointService: ConPointService) {}

    @Effect()
    contactsFetch = this.actions$.pipe(
        ofType(ContactsActionTypes.ContactsFetch),
        switchMap((contactsActions: ContactsFetch) => {
            this.store.dispatch(new ContactsFetchLazyMeta({lazyLoad: contactsActions.payload}));
            return this.conPointService.getContactsList({lazyLoad: contactsActions.payload}).pipe(
                map((result) => {
                    const contacts = result.data.GetContactsList.result?.map(contact => {
                        return {...contact};
                    });

                    return {
                        contacts,
                        total: result.data.GetContactsList.metadata.total,
                        count: result.data.GetContactsList.metadata.count
                    };
                }),
                map(({contacts, total, count}) => {
                    return new ContactsSet({contacts: contacts ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    contactFetch = this.actions$.pipe(
        ofType(ContactsActionTypes.ContactFetch),
        switchMap((contactsActions: ContactFetch) => {
            return this.conPointService.getContact({contactId: contactsActions.payload.contactId}).pipe(
                map((result) => {
                    return new ContactSet({contact: result.data.GetContact});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    contactCreate = this.actions$.pipe(
        ofType(ContactsActionTypes.ContactCreate),
        withLatestFrom(this.store.select('contacts')),
        switchMap<[ContactUpdate, State], Observable<any>>(([contactsActions, contactState]) => {
            return this.conPointService.createContact(
                {contact: contactsActions.payload.contact}).pipe(
                map(() => {
                    this.store.dispatch(new ContactFormDialogHide());
                    const currentLazyLoad = {
                        ...contactState.currentLazyLoad,
                        customData: [...contactState.currentLazyLoad.customData]
                    };

                    currentLazyLoad.customData = currentLazyLoad.customData.filter((rec) => rec.key !== 'showAll');
                    currentLazyLoad.customData.push({key: 'showAll', value: 1});

                    this.store.dispatch(new ContactsFetch(currentLazyLoad));
                    return new ContactCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    contactUpdate = this.actions$.pipe(
        ofType(ContactsActionTypes.ContactUpdate),
        withLatestFrom(this.store.select('contacts')),
        switchMap<[ContactUpdate, State], Observable<any>>(([contactsActions, contactsState]) => {
            return this.conPointService.updateContact({contact: contactsActions.payload.contact}).pipe(
                map(() => {
                    this.store.dispatch(new ContactFormDialogHide());
                    this.store.dispatch(new ContactsFetch(contactsState.currentLazyLoad));
                    return new ContactUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    contactDelete = this.actions$.pipe(
        ofType(ContactsActionTypes.ContactDelete),
        withLatestFrom(this.store.select('contacts')),
        switchMap<[ContactDelete, State], Observable<any>>(([contactsActions, contactsState]) => {
            return this.conPointService.deleteContact({contactId: contactsActions.payload.contactId}).pipe(
                map(() => {
                    this.store.dispatch(new ContactsFetch(contactsState.currentLazyLoad));
                    return new ContactDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
