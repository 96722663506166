import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import { MessageAction } from '../../../loading/loading.model';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Contact, ContactInput} from '../../../../generated/graphql';

export enum ContactsActionTypes {
    ContactsFetchLazyMeta = '[@Contacts] FetchLazyMeta',
    ContactsSet = '[@Contacts] Set',
    ContactSet = '[@Contact] Set',
    ContactsFetch = '[@Contacts] Fetch',
    ContactFetch = '[@Contact] Fetch',
    ContactCreate = '[@Contact] Create',
    ContactCreateSuccess = '[@Contact] SaveSuccess',
    ContactUpdate = '[@Contact] Update',
    ContactUpdateSuccess = '[@Contact] UpdateSuccess',
    ContactDelete = '[@Contact] Delete',
    ContactDeleteSuccess = '[@Contact] DeleteSuccess',
    ContactsError = '[@Contacts] Error',
}

export class ContactsSet implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ContactsActionTypes.ContactsFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {contacts: Contact[], totalRows: number }) {
    }
}

export class ContactsFetch implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ContactsActionTypes.ContactsFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class ContactSet implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ContactsActionTypes.ContactFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {contact: Contact }) {}
}

export class ContactFetch implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ContactsActionTypes.ContactFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {contactId: number}) {}
}

export class ContactCreate implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: ContactsActionTypes.ContactCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {contact: ContactInput}) {}
}

export class ContactCreateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = ContactsActionTypes.ContactCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ContactsActionTypes.ContactCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('contacts.created')],
        type: 'global'
    };
}

export class ContactUpdate implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: ContactsActionTypes.ContactUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {contact: ContactInput}) {}
}

export class ContactUpdateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = ContactsActionTypes.ContactUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ContactsActionTypes.ContactUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('contacts.updated')],
        type: 'global'
    };
}

export class ContactDelete implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: ContactsActionTypes.ContactDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {contactId: number}) {}
}

export class ContactDeleteSuccess implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ContactsActionTypes.ContactDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('contacts.deleted')],
        type: 'global'
    };
}

export class ContactsError implements Action, LoadingAction {
    readonly type = ContactsActionTypes.ContactsError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: ContactsActionTypes.ContactsFetch, spinner: 'sp_lll'},
            {name: ContactsActionTypes.ContactFetch, spinner: 'sp_llcl'},
            {name: ContactsActionTypes.ContactCreate, spinner: 'sp_lll'},
            {name: ContactsActionTypes.ContactUpdate, spinner: 'sp_llb'},
            {name: ContactsActionTypes.ContactDelete, spinner: 'sp_lll'},
        ],
        type: 'all'
    };
    constructor() {}
}

export class ContactsFetchLazyMeta implements Action {
    readonly type = ContactsActionTypes.ContactsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type ContactsActions =
    ContactsFetch |
    ContactCreate |
    ContactCreateSuccess |
    ContactUpdate |
    ContactUpdateSuccess |
    ContactDelete |
    ContactDeleteSuccess |
    ContactsSet |
    ContactSet |
    ContactFetch |
    ContactsFetchLazyMeta |
    ContactsError;
