// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    showDebugBar: true,
    environmentName: 'Development',
    envShortName: 'dev',
    mediaUrl: 'https://api.dev.conpoint.cz/',
    baseUrl: 'https://app.dev.conpoint.cz/',
    apiUrl: 'https://api.dev.conpoint.cz/',
    apiImagesUrl: 'https://api.conpoint.cz:8091/',
    graphQLOrigin: 'https://api.dev.conpoint.cz/graphql/',
    webOrigin: 'https://api.dev.conpoint.cz/',
    webUrl: 'https://dev.conpoint.cz/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
