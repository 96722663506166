import {Action} from '@ngrx/store';

export const GOOD_PRACTICE_CREATE_FORM_DIALOG_SHOW = '[@GoodPracticeCreate] DialogShow';
export const GOOD_PRACTICE_CREATE_FORM_DIALOG_HIDE = '[@GoodPracticeCreate] DialogHide';

export class DialogShow implements Action {
    readonly type = GOOD_PRACTICE_CREATE_FORM_DIALOG_SHOW;
}

export class DialogHide implements Action {
    readonly type = GOOD_PRACTICE_CREATE_FORM_DIALOG_HIDE;
}

export type GoodPracticeCreateFormActions =
    DialogShow |
    DialogHide;
