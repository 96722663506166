import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {AreaOf, AreaOfInput} from '../../../../generated/graphql';

export enum AreasOfActionTypes {
    AreasOfFetchLazyMeta = '[@AreasOf] FetchLazyMeta',
    AreasOfSet = '[@AreasOf] Set',
    AreaOfSet = '[@AreaOf] Set',
    AreasOfFetch = '[@AreasOf] Fetch',
    AreaOfFetch = '[@AreaOf] Fetch',
    AreaOfCreate = '[@AreaOf] Create',
    AreaOfCreateSuccess = '[@AreaOf] CreateSuccess',
    AreaOfUpdate = '[@AreaOf] Update',
    AreaOfUpdateSuccess = '[@AreaOf] UpdateSuccess',
    AreaOfDelete = '[@AreaOf] Delete',
    AreaOfDeleteSuccess = '[@AreaOf] DeleteSuccess',

    AreasOfError = '[@AreasOf] Error',
}

export class AreasOfSet implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreasOfSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: AreasOfActionTypes.AreasOfFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {areasOf: AreaOf[], totalRows: number }) {}
}

export class AreasOfFetch implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreasOfFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: AreasOfActionTypes.AreasOfFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {lazyLoad: LazyLoadMeta, type: number}) {}
}

export class AreaOfSet implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: AreasOfActionTypes.AreaOfFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {areaOf: AreaOf}) {}
}

export class AreaOfFetch implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: AreasOfActionTypes.AreaOfFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {id: number}) {}
}

export class AreaOfCreate implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: AreasOfActionTypes.AreaOfCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {areaOf: AreaOfInput}) {}
}

export class AreaOfCreateSuccess implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: AreasOfActionTypes.AreaOfCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('area_of.created')],
        type: 'global'
    };
}

export class AreaOfUpdate implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: AreasOfActionTypes.AreaOfUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {areaOf: AreaOfInput}) {}
}

export class AreaOfUpdateSuccess implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: AreasOfActionTypes.AreaOfUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('area_of.updated')],
        type: 'global'
    };
}

export class AreaOfDelete implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: AreasOfActionTypes.AreaOfDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {id: number, type: number}) {}
}

export class AreaOfDeleteSuccess implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreaOfDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: AreasOfActionTypes.AreaOfDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('area_of.deleted')],
        type: 'global'
    };
}

export class AreasOfFetchLazyMeta implements Action {
    readonly type = AreasOfActionTypes.AreasOfFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export class AreasOfError implements Action, LoadingAction {
    readonly type = AreasOfActionTypes.AreasOfError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: AreasOfActionTypes.AreasOfFetch, spinner: 'sp_lll'},
            {name: AreasOfActionTypes.AreaOfFetch, spinner: 'sp_llcl'},
            {name: AreasOfActionTypes.AreaOfUpdate, spinner: 'sp_llb'},
            {name: AreasOfActionTypes.AreaOfCreate, spinner: 'sp_lll'},
            {name: AreasOfActionTypes.AreaOfDelete, spinner: 'sp_lll'},
        ],
        type: 'all'
    };

    constructor() {}
}

export type AreasOfActions =
    AreasOfFetch |
    AreasOfSet |
    AreaOfFetch |
    AreaOfSet |
    AreaOfCreate |
    AreaOfCreateSuccess |
    AreaOfUpdate |
    AreaOfUpdateSuccess |
    AreaOfDelete |
    AreaOfDeleteSuccess |
    AreasOfFetchLazyMeta |
    AreasOfError;
