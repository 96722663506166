import {ProjectsActions, ProjectsActionTypes} from './projects.actions';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {Project} from '../../../../generated/graphql';

export interface State {
    projects: Project[];
    currentLazyLoad: LazyLoadMeta;
    loadedProject: Project;
    totalRows: number;
    appConf: {projects: Project[]};
}

const initialState = {
    projects: [],
    currentLazyLoad: null,
    loadedProject: null,
    totalRows: 0,
    appConf: {projects: []},
};

export function projectsReducer(state: State = initialState, action: ProjectsActions) {
    switch (action.type) {
        case ProjectsActionTypes.ProjectsSet:
            state =  {
                ...state,
                projects: [...action.payload.projects],
                totalRows: action.payload.totalRows
            };
            break;
        case ProjectsActionTypes.ProjectSet:
            state =  {
                ...state,
                loadedProject: action.payload.project
            };
            break;
        case ProjectsActionTypes.ProjectsAppSet:
            state =  {
                ...state,
                appConf: {projects: [...action.payload.projects]},
                loadedProject: null,
            };
            break;
        case ProjectsActionTypes.ProjectsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case ProjectsActionTypes.ProjectsError:
            state =  {
                ...state,
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
