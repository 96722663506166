import {CurrentStateActions, CurrentStateActionTypes} from './current-state.actions';
import {Project, Subject} from '../../../generated/graphql';

export interface State {
    subjects: Subject[];
    selectedSubject: Subject;
    selectedProject: Project;
    permissions: any;
    reloadState: boolean;
    chooseSubjectProject: boolean;
}

const initialState = {
    subjects: [],
    selectedSubject: null,
    selectedProject: null,
    permissions: null,
    reloadState: false,
    chooseSubjectProject: false,
};

export function currentStateReducer(state: State = initialState, action: CurrentStateActions) {
    switch (action.type) {
        case CurrentStateActionTypes.CurrentStateReload:
            state = {
                ...state,
                reloadState: action.payload.value
            };
            break;
        case CurrentStateActionTypes.CurrentStateChooseSubjectProject:
            state = {
                ...state,
                chooseSubjectProject: action.payload.value
            };
            break;
        case CurrentStateActionTypes.CurrentStateSubjectsProjectsSet:
            state =  {
                ...state,
                subjects: [...action.payload.subjects],
            };
            break;
        case CurrentStateActionTypes.CurrentStateProjectSet:
            state =  {
                ...state,
                selectedProject: action.payload.project,
            };
            break;
        case CurrentStateActionTypes.CurrentStateSubjectSet:
            state =  {
                ...state,
                selectedSubject: action.payload.subject
            };
            break;
        case CurrentStateActionTypes.CurrentStatePermissionsSet:
            state =  {
                ...state,
                permissions: action.payload.permissions
            };
            break;
        case CurrentStateActionTypes.CurrentStateError:
            state =  {
                ...state,
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
