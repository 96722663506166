import { Action } from '@ngrx/store';
import { LoadingAction } from '../../loading';
import {Project, Subject} from '../../../generated/graphql';

export enum CurrentStateActionTypes {
    CurrentStateReload = '[@CurrentState] Reload',
    CurrentStateChooseSubjectProject = '[@CurrentState] ChooseSubjectProject',
    CurrentStateSubjectsProjectsFetch = '[@CurrentState] SubjectsProjectsFetch',
    CurrentStateSubjectsProjectsSet = '[@CurrentState] SubjectsProjectsSet',
    CurrentStateProjectSet = '[@CurrentState] ProjectSet',
    CurrentStateSubjectSet = '[@CurrentState] SubjectSet',
    CurrentStatePermissionsSet = '[@CurrentState] PermissionsSet',
    CurrentStateError = '[@CurrentState] Error',
}

export class CurrentStateReload implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateReload;
    fxLoading = {
        action: 'add',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {value: boolean}) {}
}

export class CurrentStateChooseSubjectProject implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateChooseSubjectProject;
    fxLoading = {
        action: 'add',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {value: boolean}) {}
}

export class CurrentStateSubjectsProjectsFetch implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateSubjectsProjectsFetch;
    fxLoading = {
        action: 'add',
        ids: [],
        type: 'global'
    };
    constructor() {}
}

export class CurrentStateSubjectsProjectsSet implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateSubjectsProjectsSet;
    fxLoading = {
        action: 'remove',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {subjects: Subject[]}) {}
}

export class CurrentStateProjectSet implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateProjectSet;
    fxLoading = {
        action: 'remove',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {project: Project}) {}
}

export class CurrentStateSubjectSet implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateSubjectSet;
    fxLoading = {
        action: 'remove',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {subject: Subject }) {}
}

export class CurrentStatePermissionsSet implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStatePermissionsSet;
    fxLoading = {
        action: 'remove',
        ids: [],
        type: 'global'
    };
    constructor(public payload: {permissions: string }) {}
}

export class CurrentStateError implements Action, LoadingAction {
    readonly type = CurrentStateActionTypes.CurrentStateError;
    fxLoading = {
        action: 'remove',
        ids: [
        ],
        type: 'all'
    };

    constructor() {}
}

export type CurrentStateActions =
    CurrentStateChooseSubjectProject |
    CurrentStateReload |
    CurrentStateSubjectsProjectsFetch |
    CurrentStateSubjectsProjectsSet |
    CurrentStateProjectSet |
    CurrentStateSubjectSet |
    CurrentStatePermissionsSet |
    CurrentStateError;
