import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {BaseComponent} from '../../../shared/base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {map, take} from 'rxjs/operators';
import {getGeneralSettings, getReports} from '../store/reports.selector';
import {Report, ReportSettings} from '../../../../generated/graphql';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as SendTagActions from '../../../forms/send-tag-form/store/send-form.actions';
import {EntityTypes} from '../../../shared/enums/entity-enums';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ReportDelete, ReportGeneralSettingsFetch, ReportsFetch, ReportUpdateGeneralSettings} from '../store/reports.actions';
import {NgForm} from '@angular/forms';
import {UserStorage} from '../../auth/user-storage.model';
import {User} from '../../auth/user.model';

@Component({
    selector: 'app-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent extends BaseComponent implements OnInit, AfterViewInit {

    @ViewChild('fGeneralSettings') fGeneralSettings: NgForm;

    public reports: Report[] = [];
    public selectedReport: Report = null;
    public totalRecords = 0;
    public cols: any;
    public tableInfoParam: { first: number, last: number, totalRecords: number };

    constructor(
        protected store: Store<AppState>,
        public gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected translate: TranslateService,
        protected confirmationService: ConfirmationService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.subs.sink = forkJoin([
            this.translate.get('reports.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/reports'}
            ]));
        });

        this.cols = [
            {field: 'name', header: 'reports.name', display: 'table-cell', width: '35%'},
            {field: 'customName', header: 'reports.custom-name', display: 'table-cell', width: '15%'},
            {field: 'createdBy', header: 'general.created_by', display: 'table-cell', width: '15%'},
            {field: 'createdAt', header: 'general.created_at', display: 'table-cell', width: '10%'},
            {field: 'updatedAt', header: 'general.updated_at', display: 'table-cell', width: '10%'},
        ];

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.store.select(getReports).pipe(
                map(reportsState => {
                    return {
                        reports: reportsState.reports,
                        totalRows: reportsState.totalRows,
                    };
                })
            ).subscribe(data => {
                this.reports = data.reports;
                this.totalRecords = data.totalRows;
            });
        });

        this.buttons.push(
            {
                id: 'saveReportGeneralSettingsButton',
                name: 'reports.save-general-settings',
                action: 'onSaveReportGeneralSettings',
                class: 'p-button-success',
                icon: 'pi pi-plus p-mr-1',
                disabled: false,
            }
        );

        this.buttons.push(
            {
                id: 'createReportButton',
                name: 'reports.create',
                action: 'onCreateReport',
                class: 'p-button-primary',
                icon: 'pi pi-plus p-mr-1',
                disabled: false,
            }
        );

        this.store.dispatch(new UpdateToolbarButtons(this.buttons));
    }

    public ngAfterViewInit() {
        this.subs.sink = this.store.select(getGeneralSettings).subscribe(settings => {
            this.fGeneralSettings.form.patchValue({
                    ...settings,
                }
            );
        });

        this.store.dispatch(new ReportGeneralSettingsFetch());
    }

    public onCreateReport() {
        this.router.navigate(['create'], {relativeTo: this.route});
    }

    public fetchReports(lazyLoad: LazyLoadMeta) {
        console.log(lazyLoad);
        this.store.dispatch(new ReportsFetch(lazyLoad));
    }

    loadLazy(event: LazyLoadEvent) {
        const searchData = LazyLoadMeta.fromData({lazyLoad: event});
        // searchData.filters = this.filter;
        this.fetchReports(searchData);
    }

    onEdit(id: number) {

    }

    onEmail(id: number, identifier: string) {
        this.store.dispatch(new SendTagActions.DialogShow({type: EntityTypes.REPORT, id, identifier}));
    }

    onDelete(id: number, name: string) {
        this.subs.sink = forkJoin([this.translate.get('reports.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + name + ']',
                accept: () => {
                    this.store.dispatch(new ReportDelete({id}));
                }
            });
        });
    }

    onDownload(code: string) {
        const userData: User = JSON.parse(localStorage.getItem('user-data'));
        const userStorage: UserStorage = JSON.parse(localStorage.getItem('user-storage-' + userData.id));

        if (userStorage.currentLanguage.value !== 'cs') {
            window.open(
                this.gs.getEnvironment().mediaUrl + 'media/' + code + '?lang=' + userStorage.currentLanguage.value,
                '_blank'
            );
        } else {
            window.open(
                this.gs.getEnvironment().mediaUrl + 'media/' + code,
                '_blank'
            );
        }
    }

    onSaveReportGeneralSettings() {
        this.fGeneralSettings.ngSubmit.emit();
    }

    onSubmitGeneralSettings() {
        const settings = {
            customDefectTitle: this.fGeneralSettings.value?.customDefectTitle,
            customTaskTitle: this.fGeneralSettings.value?.customTaskTitle,
            customGoodPracticeTitle: this.fGeneralSettings.value?.customGoodPracticeTitle,
        } as ReportSettings;

        this.store.dispatch(new ReportUpdateGeneralSettings({settings}));
    }
}
