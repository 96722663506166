import {ACTION_MESSAGE_KEY, ACTION_KEY} from './loading.constant';
import {Message} from 'primeng/api';

export class State {
    loadingData: Map<string, any[]>;
    errorMessages: Map<string, Message[]>;

    constructor() {
        this.loadingData = new Map();
        this.loadingData.set('global', []);
        this.loadingData.set('modal', []);

        this.errorMessages = new Map();
        this.errorMessages.set('global', []);
        this.errorMessages.set('modal', []);
        this.errorMessages.set('emptyLayout', []);
    }
}

export interface LoadingAction {
    [ACTION_KEY]: {
        action: string;
        // ids: {name: string, spinner: string}[];
        ids: any[];
        type: string;
    };
}

export interface MessageAction {
    [ACTION_MESSAGE_KEY]: {
        action: string;
        messages: Message[];
        type: string;
    };
}
