import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppComponent} from '../../app.component';
import {getGlobalLoadingData, getModalLoadingData} from '../../loading';
import {NgxSpinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import {NgxSpinnerService} from 'ngx-spinner';
import {types} from 'util';

@Component({
  selector: 'app-modal-loading-spinner',
  templateUrl: './modal-loading-spinner.component.html',
  styleUrls: ['./modal-loading-spinner.component.css']
})
export class ModalLoadingSpinnerComponent implements OnInit {
    private spinnerVisible: Map<string, boolean> = new Map();

    constructor(public app: AppComponent,
                private store: Store<any>,
                private spinner: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(getGlobalLoadingData)).subscribe((result) => {
            if (result.length > 0) {
                result.forEach((loading) => {

                    let spinner = loading.spinner;

                    if (loading.append) {
                        if (this.spinnerVisible.get(loading.append)) {
                            spinner = loading.append;
                        }
                    }

                    if (!this.spinnerVisible.get(spinner)) {
                        this.spinner.show(spinner, {
                            // type: 'ball-clip-rotate-multiple',
                            bdColor: 'rgba(51, 51, 51, 0.4)',
                            color: '#d8dee9'
                        }).then();
                        this.spinnerVisible.set(spinner, true);
                    }
                });
            }

            this.spinnerVisible.forEach((value: boolean, spinner: string) => {
                if (!result.find(e => e.spinner === spinner)) {
                    if (this.spinnerVisible.get(spinner)) {
                        if (!result.find(e => e.append === spinner)) {
                            this.spinner.hide(spinner).then();
                            this.spinnerVisible.set(spinner, false);
                        }
                    }
                }
            });
        });
    }

}
