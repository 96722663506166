import {Action} from '@ngrx/store';

export const USER_FORM_DIALOG_SHOW = '[UserForm] User Form Dialog Show';
export const USER_FORM_DIALOG_HIDE = '[UserForm] User Form Dialog Hide';

export class UserFormDialogShow implements Action {
    readonly type = USER_FORM_DIALOG_SHOW;
}

export class UserFormDialogHide implements Action {
    readonly type = USER_FORM_DIALOG_HIDE;
}

export type UserFormActions =
    UserFormDialogShow |
    UserFormDialogHide;
