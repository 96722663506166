import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {User} from '../../../../generated/graphql';
import {UsersActions, UsersActionTypes} from './users.actions';

export interface State {
    users: User[];
    loadedUser: User;
    userFormDialogShow: boolean;
    totalRows: number;
    currentLazyLoad: LazyLoadMeta;
}

const initialState = {
    users: [],
    currentLazyLoad: null,
    loadedUser: null,
    userFormDialogShow: false,
    totalRows: 0,
};

export function usersReducer(state: State = initialState, action: UsersActions) {
    switch (action.type) {
        case UsersActionTypes.UserCreateSuccess:
        case UsersActionTypes.UserUpdateSuccess:
            state = {
                ...state
            };
            break;
        case UsersActionTypes.UsersSet:
            state = {
                ...state,
                users: [...action.payload.users],
                totalRows: action.payload.totalRows
            };
            break;
        case UsersActionTypes.UserSet:
            state = {
                ...state,
                loadedUser: action.payload.user
            };
            break;
        case UsersActionTypes.UsersFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case UsersActionTypes.UsersError:
            state = {
                ...state
            };
            break;
        default:
            return state;
            break;
    }
    return state;
}
