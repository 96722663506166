import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import {
    SubjectsFetch,
    SubjectsActionTypes,
    SubjectFetch,
    SubjectCreateSuccess,
    SubjectUpdateSuccess,
    SubjectDeleteSuccess,
    SubjectsAppFetch,
    SubjectsError,
    SubjectsFetchLazyMeta,
    SubjectsSet,
    SubjectsAppSet,
    SubjectSet,
    SubjectCreate,
    SubjectUpdate,
    SubjectDelete,
    SubjectCreateOsh,
    SubjectCreateOshSuccess,
    SubjectResendOshConfirmation,
    SubjectResendOshConfirmationSuccess
} from './subjects.actions';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {ApolloError} from '@apollo/client/core';
import {ConPointService} from '../../../../generated/graphql';
import {AppState} from '../../../store/app.reducer';
import {State} from './subjects.reducer';
import {ConPointAuthService} from '../../../../generated/graphql_auth';
import {UsersActionTypes} from '../../users/store/users.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new SubjectsError());
};

@Injectable()
export class SubjectsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService,
                private conPointAuthService: ConPointAuthService,
    ) {}

    @Effect()
    subjectsFetch = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectsFetch),
        switchMap((subjectsActions: SubjectsFetch) => {
            this.store.dispatch(new SubjectsFetchLazyMeta({lazyLoad: subjectsActions.payload}));
            return this.conPointService.getSubjectsList({lazyLoad: subjectsActions.payload}).pipe(
                map((result) => {
                    const subjects = result.data.GetSubjectsList.result?.map(user => {
                        return {...user};
                    });

                    return {subjects, total: result.data.GetSubjectsList.metadata.total, count: result.data.GetSubjectsList.metadata.count};
                }),
                map(({subjects, total, count}) => {
                    return new SubjectsSet({subjects: subjects ?? [], totalRows: total});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectsAppFetch = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectsAppFetch),
        switchMap((subjectsActions: SubjectsAppFetch) => {
            return this.conPointService.getSubjectsAppList().pipe(
                map((result) => {
                    const subjects = result.data.GetSubjectsList.result?.map(subject => {
                        return {...subject};
                    });
                    return new SubjectsAppSet({subjects: subjects ?? []});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectFetch = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectFetch),
        switchMap((subjectsActions: SubjectFetch) => {
            return this.conPointService.getSubject({subjectId: subjectsActions.payload.subjectId}).pipe(
                map((result) => {
                    return new SubjectSet({subject: result.data.GetSubject});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectCreate = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectCreate),
        withLatestFrom(this.store.select('subjects')),
        switchMap<[SubjectCreate, State], Observable<any>>(([subjectActions, subjectsState]) => {
            return this.conPointService.createSubject({subject: subjectActions.payload.subject}).pipe(
                map(() => {
                    const currentLazyLoad = {
                        ...subjectsState.currentLazyLoad,
                        customData: [...subjectsState.currentLazyLoad.customData]
                    };

                    currentLazyLoad.customData = currentLazyLoad.customData.filter((rec) => rec.key !== 'showAll');
                    currentLazyLoad.customData.push({key: 'showAll', value: 1});

                    this.store.dispatch(new SubjectsFetch(currentLazyLoad));
                    return new SubjectCreateSuccess({redirect: subjectActions.payload.redirect});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectOshCreate = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectCreateOsh),
        withLatestFrom(this.store.select('subjects')),
        switchMap<[SubjectCreateOsh, State], Observable<any>>(([subjectActions, subjectsState]) => {
            return this.conPointAuthService.createSubjectOsh({subject: subjectActions.payload.subject}).pipe(
                map(() => {
                    return new SubjectCreateOshSuccess({redirect: subjectActions.payload.redirect});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectOshResendConfirmation = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectResendOshConfirmation),
        withLatestFrom(this.store.select('subjects')),
        switchMap<[SubjectResendOshConfirmation, State], Observable<any>>(([subjectActions]) => {
            return this.conPointAuthService.resendSubjectOshConfirmation({companyCode: subjectActions.payload.companyCode}).pipe(
                map(() => {
                    return new SubjectResendOshConfirmationSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectUpdate = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectUpdate),
        withLatestFrom(this.store.select('subjects')),
        switchMap<[SubjectUpdate, State], Observable<any>>(([subjectActions, subjectsState]) => {

            const subject = {...subjectActions.payload.subject};
            delete subject['createdAt'];
            delete subject['updatedAt'];

            return this.conPointService.updateSubject({subject}).pipe(
                map(() => {
                    this.store.dispatch(new SubjectsFetch(subjectsState.currentLazyLoad));
                    return new SubjectUpdateSuccess({redirect: subjectActions.payload.redirect});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    subjectDelete = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectDelete),
        withLatestFrom(this.store.select('subjects')),
        switchMap<[SubjectDelete, State], Observable<any>>(([subjectActions, subjectsState]) => {
            return this.conPointService.deleteSubject({subjectId: subjectActions.payload.subjectId}).pipe(
                map(() => {
                    this.store.dispatch(new SubjectsFetch(subjectsState.currentLazyLoad));
                    return new SubjectDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect({dispatch: false})
    subjectCreateOshRedirect = this.actions$.pipe(
        ofType(SubjectsActionTypes.SubjectCreateOshSuccess),
        tap((data) => {
            this.router.navigate(['/subject-create/created']);
        })
    );
}
