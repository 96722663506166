import { Component, OnInit } from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {FormsHelperService} from '../forms-helper.service';

@Component({
    selector: 'app-create-user-part',
    template: `
        <div ngModelGroup="userData">
            <div class="p-fluid form-group">
                <div class="p-col-12 ui-md-12" style="margin-bottom: -10px;">
                    <label for="firstname">{{'users.first_name' | translate}} <span style="color: red;">*</span></label>
                </div>
                <div class="p-col-12 ui-md-12">
                    <input
                        id="firstname"
                        type="text"
                        pInputText
                        ngModel
                        name="firstName"
                        required
                        autofocus
                        #firstName="ngModel"
                        [ngClass]="{'ng-dirty': formsHelper.checkValidity(firstName)}"
						autocomplete="new-password"
                    >
                </div>
            </div>
            <div class="p-fluid form-group">
                <div class="p-col-12 ui-md-12" style="margin-bottom: -10px;">
                    <label for="lastname">{{'users.last_name' | translate}} <span style="color: red;">*</span></label>
                </div>
                <div class="p-col-12 ui-md-12">
                    <input
                        id="lastname"
                        type="text"
                        pInputText
                        ngModel
                        name="lastName"
                        required
                        #lastName="ngModel"
                        [ngClass]="{'ng-dirty': formsHelper.checkValidity(lastName)}"
						autocomplete="new-password"
                    >
                </div>
            </div>
            <div class="p-fluid form-group">
                <div class="p-col-12 ui-md-12" style="margin-bottom: -10px;">
                    <label for="phoneNumber">{{'users.phone_number' | translate}}</label>
                </div>
                <div class="p-col-12 ui-md-12">

                    <input
                        id="phoneNumber"
                        type="text"
                        pInputText
                        ngModel
                        name="phoneNumber"
                        pattern="^\\+(?:[0-9]?){6,14}[0-9]$"
                        #phoneNumber="ngModel"
                        [ngClass]="{'ng-dirty': formsHelper.checkValidity(phoneNumber)}"
						autocomplete="new-password"
                    >
                </div>
            </div>
            <div class="p-fluid form-group">
                <div class="p-col-12 ui-md-12" style="margin-bottom: -10px;">
                    <label for="password">{{'users.password' | translate}} <span style="color: red;">*</span></label>
                </div>
                <div class="p-col-12 ui-md-12">
                    <input
                        id="password"
                        type="password"
                        pPassword
                        ngModel
                        name="password"
                        required
                        #password="ngModel"
                        [feedback]="false"
						autocomplete="new-password"
						(ngModelChange)="passwordConfirm.reset(null)"
                    >
                </div>
            </div>
            <div class="p-fluid form-group">
                <div class="p-col-12 ui-md-12" style="margin-bottom: -10px;">
                    <label for="passwordConfirm">{{'users.confirm_password' | translate}} <span style="color: red;">*</span></label>
                </div>
                <div class="p-col-12 ui-md-12">
                    <input
                        id="passwordConfirm"
                        type="password"
                        pInputText
                        ngModel
                        name="passwordConfirm"
                        [appPasswordMatch]="password.value"
                        required
                        #passwordConfirm="ngModel"
						autocomplete="new-password"
                    >
                </div>
                <div class="p-col-12 ui-md-12">
                    <p-message severity="error" text="{{'users.password_mismatch'|translate}}"
                               *ngIf="formsHelper.checkValidity(passwordConfirm) && passwordConfirm.errors?.passwordMismatch"></p-message>
                </div>
            </div>
        </div>
    `,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CreateUserPartComponent implements OnInit {
    constructor(
        public formsHelper: FormsHelperService
    ) { }

    ngOnInit(): void {
    }
}
