import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, QueryList,
    SimpleChanges,
    TemplateRef, ViewChildren
} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {SubSink} from 'subsink';
import {StorageService} from '../../shared/services/storage.service';
import {LiteListState} from './lite-list-state.model';
import {ContextMenu} from 'primeng/contextmenu';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-lite-list',
    templateUrl: './lite-list.component.html',
    styleUrls: ['./lite-list.component.css']
})
export class LiteListComponent implements OnInit, OnDestroy, OnChanges {

    private subSink = new SubSink();
    public rows = 25;

    public boxOffset = 88;

    public lazyLoadEvent = {first: 0, rows: this.rows} as LazyLoadEvent;

    private value$ = new BehaviorSubject<any[]>([]);
    public totalRecords$ = new BehaviorSubject<number>(0);

    public selectedFilters: any[] = [];

    public selection: any;

    @Input() set value(value: any[]) {
        this.value$.next(value);
    }
    @Output() valueChange = new EventEmitter<any>();

    @Input() set totalRecords(value: number) {
        this.totalRecords$.next(value);
    }

    @Input() height: number;
    @Input() rowHeight = 122;
    @Input() stateKey: string;
    @Input() stateStorage: string;
    @Input() sortColumns: any[];
    @Input() filtersDef: any[];
    @Input() showContextMenu = false;
    @Input() showAllButton = false;
    @Input() set showAllButtonValue(value: boolean) {
        this.showAllButtonState = value;
        this.onRefresh();
    }

    @Input() set presetFilter(value: any) {
        this.presetFilterValues = value;
        this.setPresetFilter();
    }

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onFilterChange = new EventEmitter<any>();

    @ContentChild(TemplateRef) liteListTemplate;
    @ContentChild('contentTemplate') contentTemplate: TemplateRef<any>;

    @ViewChildren('contextMenuComponent') contextMenus: QueryList<any>;

    private filters: Map<string, {field: string, dbField: string, value: any, matchMode: string}> = new Map();

    filterDebounceTime = 600;
    filterTextChanged: Subject<any> = new Subject<any>();

    public selectedSortColumn: any;
    public sortDirection = 0;

    public selectedSeverities: any[];
    public severities: any[];

    public selectedStates: any[];
    public states: any[];
    public globalFilterText: string = null;
    public showAllButtonState: boolean;
    private presetFilterValues: [] = [];

    private pageChanged = false;

    constructor(
        private router: Router
    ) {}

    ngOnInit(): void {
        this?.filtersDef?.forEach((filter) => {
            if (filter.matchMode === 'is') {
                this[filter.field] = filter.values[0].id;
            }
        });

        this.restoreState();

        this.setPresetFilter();

        this.setDefaultRadioFilter(true);

        if (this.sortColumns?.length > 0) {
            this.selectedSortColumn = this.sortColumns[0].value;
        }

        this.subSink.sink = this.value$.subscribe(items => {
            if (items !== undefined) {
                if (items?.length > 0) {
                    if (!this.selection) {
                        this.selection = items[0];
                    } else {
                        if (items.find(element => element.id === this.selection.id) === undefined && !this.pageChanged) {
                            this.selection = items[0];
                        }
                        this.pageChanged = false;
                    }

                    if (this.selection && this.selection.navLink) {
                        this.router.navigate([...this.selection.navLink]).then();
                    }
                }
            }
        });
    }

    ngOnDestroy() {
        this.saveState();
        this.subSink.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filtersDef) {
            changes.filtersDef.currentValue?.map((filter) => {
                if (filter.matchMode === 'dateRange') {
                    this[filter.field] = null;
                }
            });
        }
    }

    get value() {
        return this.value$.getValue();
    }

    get totalRecords() {
        return this.totalRecords$.getValue();
    }

    public setDefaultRadioFilter(processChanges: boolean) {
        let hasRadioFilters = false;
        this?.filtersDef?.forEach((filter) => {
            if (filter.matchMode === 'is') {
                const filterValue = {
                    field: filter.field,
                    dbField: filter.dbField,
                    value: this[filter.field],
                    matchMode: filter.matchMode
                };
                this.filters.set(filter.dbField, filterValue);

                this.lazyLoadEvent.first = 0;
                hasRadioFilters = true;
            }
        });

        if (hasRadioFilters && processChanges) {
            this.processChange();
        }
    }

    public setPresetFilter() {
        this?.filtersDef?.forEach((filter, index) => {
            const presetFilter = this.presetFilterValues.find(
                (pFilter: {field: string, value: any}
                ) => pFilter.field === filter.field) as {field: string, value: any};

            if (presetFilter && presetFilter.value !== null) {
                if (presetFilter.value === 0) {
                    this.filters.clear();
                    this.processChange();
                } else {
                    if (filter.matchMode === 'in') {
                        filter.values.subscribe(data => {
                            this.selectedFilters[index] = [data.find(option => option.value.id === presetFilter.value).value];
                            const filterValue = {
                                field: filter.field,
                                dbField: filter.dbField,
                                value: [data.find(option => option.value.id === presetFilter.value).value],
                                matchMode: filter.matchMode
                            };
                            this.filters.set(filter.dbField, filterValue);
                            this.processChange();
                        });
                    } else if (filter.matchMode === 'is') {
                        const radioFilter = filter.values.find(x => x.id === +presetFilter.value);
                        this[filter.field] = radioFilter.id;
                        const filterValue = {
                            field: filter.field,
                            dbField: filter.dbField,
                            value: radioFilter.id,
                            matchMode: filter.matchMode
                        };
                        this.filters.set(filter.dbField, filterValue);
                        this.processChange();
                    }
                }
            }
        });
    }

    public selectItem(record: any) {
        this.selection = record;
        this.saveState();
    }

    public clearFilters() {
        this.globalFilterText = null;
        this.selection = null;
        this.selectedFilters = [];
        this.filters = new Map();
        this.lazyLoadEvent = {first: 0, rows: this.rows} as LazyLoadEvent;
        this.lazyLoadEvent = {
            ...this.lazyLoadEvent,
            sortOrder: this.sortDirection,
            sortField: this.selectedSortColumn.dbField,
            first: 0,
            rows: 25,
        };

        this.setDefaultRadioFilter(false);
        this.saveState();
        this.processChange();
    }

    private saveState() {
        const state: LiteListState = {
            selection: this.selection,
            selectedFilters: this.selectedFilters,
            filters: this.convertDataFromMapToArray(this.filters),
            lazyLoadEvent: this.lazyLoadEvent,
            sortDirection: this.sortDirection,
            selectedSortColumn: this.selectedSortColumn,
        };
        StorageService.storeKeyValuePair(this.stateKey, state);
    }

    private restoreState() {
        const state = StorageService.getKeyValuePair<LiteListState>(this.stateKey);
        if (state) {
            this.selection = state.selection;
            this.selectedFilters = state.selectedFilters;
            this.filters = this.convertDataFromArrayToMap(state.filters);
            this.lazyLoadEvent = state.lazyLoadEvent;
            this.sortDirection = state.sortDirection ?? 0;
            this.selectedSortColumn = state.selectedSortColumn ?? 0;

            this?.filters?.forEach((filter) => {
                if (filter.matchMode === 'is') {
                    this[filter.field] = filter.value;
                }
            });
        } else {
            this.lazyLoadEvent = {
                first: 0,
                rows: 25,
                globalFilter: null,
                sortOrder: 1
            };
        }

        this.processChange();
    }

    public next() {
        this.pageChanged = true;
        this.lazyLoadEvent = {...this.lazyLoadEvent, first: this.lazyLoadEvent.first + this.rows};
        this.processChange();
    }

    public previous() {
        this.pageChanged = true;
        this.lazyLoadEvent = {...this.lazyLoadEvent, first: this.lazyLoadEvent.first - this.rows};
        this.processChange();
    }

    onRefresh() {
        this.processChange();
    }

    onSortDirectionChanged(sortDirection: number) {
        this.sortDirection = sortDirection;
        this.lazyLoadEvent = {...this.lazyLoadEvent, sortField: this.selectedSortColumn.dbField, sortOrder: this.sortDirection};
        this.processChange();
    }

    onSortChanged() {
        this.lazyLoadEvent = {...this.lazyLoadEvent, sortField: this.selectedSortColumn.dbField};
        this.processChange();
    }

    private convertDataFromMapToArray(data: Map<string, any>) {
        const filters = {};
        for (const [key, value] of data.entries()) {
            filters[key] = {...value};
        }
        return filters;
    }

    private convertDataFromArrayToMap(data: any) {
        const filters: Map<string, any> = new Map();
        Object.entries(data).map((record: [string, any]) => {
            filters.set(record[0], {...record[1]});
        });
        return filters;
    }

    public processChange() {
        if (this.globalFilterText?.length > 0) {
            this.filters.set('global', {field: 'global', dbField: 'global', value: this.globalFilterText, matchMode: 'contains'});
            this.lazyLoadEvent.globalFilter = this.globalFilterText;
        } else {
            this.filters.delete('global');
            this.lazyLoadEvent.globalFilter = null;
        }

        this.lazyLoadEvent = {...this.lazyLoadEvent, filters: this.convertDataFromMapToArray(this.filters)};
        this.saveState();

        const customData: {key: string, value: any}[] = [];

        if (this.showAllButton) {
            customData.push({key: 'showAll', value: this.showAllButtonState ? 1 : 0});
        }

        this.onFilterChange.emit({lazyLoad: this.lazyLoadEvent, customData});
    }

    onDateChange(filter: any, event: any) {
        if (event === null || this[filter.field] === undefined || this[filter.field].length === 0) {
            this.filters.delete(filter.dbField);
        } else {
            const filterValue = {
                field: filter.field,
                dbField: filter.dbField,
                value: JSON.stringify(this[filter.field]),
                matchMode: filter.matchMode
            };
            this.filters.set(filter.dbField, filterValue);
        }
        this.lazyLoadEvent.first = 0;
        this.processChange();
    }

    onDropDownChange(filter: any, event: any) {
        if (event.value === undefined || event.value.length === 0) {
            this.filters.delete(filter.dbField);
        } else {
            const filterValue = {
                field: filter.field,
                dbField: filter.dbField,
                value: event.value,
                matchMode: filter.matchMode
            };
            this.filters.set(filter.dbField, filterValue);
        }
        this.lazyLoadEvent.first = 0;
        this.processChange();
    }

    onInput(filter: any, event: any) {
        if (event.target.value === undefined || event.target.value.length === 0) {
            this.filters.delete(filter.dbField);
        } else {
            this.filterTextChanged.pipe(debounceTime(this.filterDebounceTime), distinctUntilChanged()).subscribe(query => {
                const filterValue = {
                    field: filter.field,
                    dbField: filter.dbField,
                    value: event.target.value,
                    matchMode: filter.matchMode
                };
                this.filters.set(filter.dbField, filterValue);

                this.lazyLoadEvent.first = 0;
                this.processChange();
            });
            this.filterTextChanged.next({ event, filter });
        }
    }

    onGlobalFilterChange() {
        this.lazyLoadEvent.first = 0;
        this.filterTextChanged.pipe(debounceTime(this.filterDebounceTime), distinctUntilChanged()).subscribe(query => {
            this.processChange();
        });
        this.filterTextChanged.next();
    }

    onContextMenuShow(index: number) {
        this.contextMenus.forEach((contextMenu: ContextMenu) => {
            if (parseInt(contextMenu.el.nativeElement.getAttribute('index'), 0) !== index) {
                contextMenu.hide();
            }
        });
    }

    toggleContextMenu(index: number, event: MouseEvent) {
        event.stopPropagation();

        this.contextMenus.forEach((contextMenu: ContextMenu) => {
            if (parseInt(contextMenu.el.nativeElement.getAttribute('index'), 0) === index) {
                contextMenu.show(event);
            }
        });
    }

    onRadioChange(filter: any, event: Event) {
        const filterValue = {
            field: filter.field,
            dbField: filter.dbField,
            value: this[filter.field],
            matchMode: filter.matchMode
        };
        this.filters.set(filter.dbField, filterValue);

        this.lazyLoadEvent.first = 0;
        this.processChange();
    }

    onShowAllClick() {
        this.showAllButtonState = !this.showAllButtonState;
        this.processChange();
    }
}
