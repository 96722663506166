import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AuthAutoLogin} from './components/auth/store/auth.actions';
import {AppState} from './store/app.reducer';

import {
    trigger,
    state,
    style,
    animate,
    transition,
    group
} from '@angular/animations';

export const slideInAnimation = trigger('slideIn', [
    state('*', style({width: '100%', opacity: 1})),
    transition('void => *', [
        style({width: '50%', transform: 'translateX(0)', opacity: 0}),
        group([
            animate('0.4s 0.1s ease', style({
                transform: 'translateX(0)',
                width: '100%'
            })),
            animate('0.3s ease', style({
                opacity: 1
            }))
        ])
    ]),
]);

@Component({
    selector: 'app-root',
    styleUrls: ['app.component.css'],
    templateUrl: './app.component.html',
    animations: [slideInAnimation]
})
export class AppComponent implements OnInit {

    horizontalMenu: boolean;
    darkMode = false;
    menuColorMode = 'light';
    menuColor = 'layout-menu-light';
    themeColor = 'blue';
    layoutColor = 'blue';
    ripple = true;
    inputStyle = 'outlined';

    constructor(
        private store: Store<AppState>,
        public translate: TranslateService,
        private primengConfig: PrimeNGConfig,
    ) {
        this.translate.setDefaultLang('cs');
        this.translate.use('cs');

        this.translate.get('primeng').subscribe(res => {
            this.primengConfig.setTranslation(res);
        });
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.store.dispatch(new AuthAutoLogin());
    }
}
