import {LazyLoadMeta} from '../../../../shared/model/lazy-load-meta.model';
import {TagDefect} from '../../../../../generated/graphql';
import {TagDefectsActions, TagDefectsActionTypes} from './tag-defects.actions';

export interface State {
    defects: TagDefect[];
    loadedTagDefect: TagDefect;
    defectFormDialogShow: boolean;
    totalRows: number;
    currentLazyLoad: LazyLoadMeta;
}

const initialState = {
    defects: [],
    currentLazyLoad: null,
    loadedTagDefect: null,
    defectFormDialogShow: false,
    totalRows: 0,
};

export function tagDefectsReducer(state: State = initialState, action: TagDefectsActions) {
    switch (action.type) {
        case TagDefectsActionTypes.TagDefectCreateSuccess:
        case TagDefectsActionTypes.TagDefectUpdateSuccess:
            state = {
                ...state
            };
            break;
        case TagDefectsActionTypes.TagDefectsSet:
            state = {
                ...state,
                defects: [...action.payload.defects],
                totalRows: action.payload.totalRows
            };
            break;
        case TagDefectsActionTypes.TagDefectSet:
            state = {
                ...state,
                loadedTagDefect: action.payload.defect
            };
            break;
        case TagDefectsActionTypes.TagDefectsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case TagDefectsActionTypes.TagDefectsError:
            state = {
                ...state
            };
            break;
        default:
            state = {
                ...state
            };
            break;
    }
    return state;
}
