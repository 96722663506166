import {User} from '../user.model';
import {AuthActions, AuthActionTypes} from './auth.actions';

export interface State {
    user: User;
    permissions: any;
}

const initialState: State = {
    user: null,
    permissions: null
};

export function authReducer(state = initialState, action: AuthActions) {
    switch (action.type) {
        case AuthActionTypes.AuthLoginSuccess:
            const user = new User(
                action.payload.email,
                action.payload.userId,
                action.payload.token,
                action.payload.expirationDate
            );

            state = {
                ...state,
                user,
            };
            break;
        case AuthActionTypes.AuthPermissionsFetchSuccess:
            state = {
                ...state,
                permissions: JSON.parse(action.payload.permissions),
            };
            break;
        case AuthActionTypes.AuthLogout:
            state = {
                ...state,
                user: null
            };
            break;
            case AuthActionTypes.AuthLogin:
            state = {
                ...state,
            };
            break;
        case AuthActionTypes.AuthError:
            return {
                ...state,
                user: null,
            };
            break;
        default:
            state = {
                ...state
            };
            break;
    }
    return state;
}
