import {createFeatureSelector, createSelector, createSelectorFactory, MemoizedProjection} from '@ngrx/store';
import { State } from './projects.reducer';
import {AnyFn} from '@ngrx/store/src/selector';

export const getProjectsFeature = createFeatureSelector<State>('projects');

export function isEqual(a: any, b: any): boolean {
    return a.totalRows === b.totalRows &&
        a.projects.every(fromA => b.projects.includes(fromA));
}

export function isAppEqual(a: any, b: any): boolean {
    return a.projects.length === b.projects.length &&
        a.projects.every(fromA => b.projects.includes(fromA));
}

export function customMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export function customAppMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isAppEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export const getProjects = createSelectorFactory(customMemoize)(
    getProjectsFeature,
    (state) => {
        return {
            projects: state.projects,
            totalRows: state.totalRows,
            currentLazyLoad: state.currentLazyLoad
        };
    }
);

export const getAppProjects = createSelectorFactory(customAppMemoize)(
    getProjectsFeature,
    (state) => {
        return {
            projects: state.appConf.projects,
        };
    }
);

export const getLoadedProject = createSelector(
    getProjectsFeature,
    (state) => {
        return {
            project: state.loadedProject,
        };
    }
);
