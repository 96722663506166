import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {accountsError, accountsFetch, accountsFetchSuccess} from './store/accounts.actions';
import {BaseComponent} from '../../../shared/base/base.component';
import {GlobalService} from '../../../shared/services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {getAccounts} from './store/accounts.selector';
import {Subject} from '../../../../generated/graphql';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {SetBreadcrumb} from '../../../fixed-toolbar/fixed-toolbar.action';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.css']
})
export class AccountsComponent extends BaseComponent  implements OnInit {

    public accounts: Subject[] = [];

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected translate: TranslateService,
        public ps: CustomPermissionsService,
        private router: Router
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = forkJoin({
            accounts: this.translate.get('accounts.title_m'),
        }).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result.accounts, routerLink: '/management/accounts'},
            ]));
        });

        this.subs.sink = this.ps.permissionsLoaded().subscribe((loaded) => {
            if (loaded) {
                this.ps.hasPermission('superadmin').then((hasPermission) => {
                    if (!hasPermission) {
                        this.router.navigate(['accessdenied']);
                    } else {
                        this.subs.sink = this.store.pipe(select(getAccounts)).subscribe((subjects) => {
                            this.accounts = [...subjects];
                        });

                        console.log('WTF');

                        this.store.dispatch(accountsFetch());
                    }
                });
            }
        });
    }
}
