import {Injectable} from '@angular/core';
import {ProxyService} from '../../shared/services/proxy.service';
import {LazyLoadMeta} from '../../shared/model/lazy-load-meta.model';
import {SubjectType} from '../../shared/model/subject-type.model';
import {ConPointImagesService} from '../../../generated/graphql_images';
import {TagTypes} from '../../shared/enums/entity-enums';
import {AreaOfInput, Subject} from '../../../generated/graphql';
import {Store} from '@ngrx/store';
import {map, skip, take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {AppState} from '../../store/app.reducer';
import {DialogShow} from '../../forms/generic-text-form/store/generic-text-form.actions';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

    constructor(
        private store: Store<AppState>,
        private proxyService: ProxyService,
        private conPointImagesService: ConPointImagesService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) { }

    uploadImages(files: any) {
        return this.conPointImagesService.multipleUpload({files}).toPromise()
            .then(data => data.data.multipleUpload)
            .catch((error) => {
                return [];
            });
    }

    deleteImage(file: any) {
        return this.conPointImagesService.deleteUpload({file}).toPromise()
            .then(data => data.data.deleteUpload)
            .catch((error) => {
                return false;
            });
    }

    getProjectsList(query: string) {
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'contractName', order: 1}]);
        return this.proxyService.getProjectsList(lazy);
    }

    getSubjectsList(query: string, projectId: number) {
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'name', order: 1}]);
        return this.proxyService.getSubjectsProjectList(lazy, projectId, SubjectType.SUBJECT)
            .then(data => data);
    }

    getUsersList(query: string, subjectIds: number[]) {
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'firstName', order: 1}, {field: 'lastName', order: 1}]);
        return this.proxyService.getUsersListBySubject(lazy, subjectIds);
    }

    getProjectUsersList(query: string, projectId: number) {
        return this.proxyService.getProjectUsersList(projectId)
            .then((data) => {
                return data.filter((user) => {
                    if (user.email.indexOf(query) !== -1 || user.firstName.indexOf(query) !== -1 || user.lastName.indexOf(query) !== -1) {
                        return true;
                    }
                });
            });
    }

    getAreaOfList(query: string, type: number) {
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'name', order: 1}]);
        return this.proxyService.getAreaOfList(lazy, type);
    }

    getGeneralTypesList(query: string, type: number, areaOf: number) {
        const lazy = new LazyLoadMeta(0, 15, [], query, null, null,
            [{field: 'name', order: 1}]);
        return this.proxyService.getGenericTextsList(lazy, type, areaOf);
    }

    checkAndCreateAreaOf(inputValue: any) {
        return new Promise((resolve) => {
            if (inputValue) {
                if (typeof inputValue !== 'object') {
                    this.proxyService.getAreaOf(inputValue, TagTypes.TAG_DEFECT).then(areaOfCheck => {
                        if (areaOfCheck.data) {
                            resolve(areaOfCheck.data);
                        } else {

                            forkJoin([this.translate.get('area_of.confirm_message')]).subscribe(result => {
                                this.confirmationService.confirm({
                                    message: result[0],
                                    accept: () => {
                                        const value: AreaOfInput = {
                                            id: null,
                                            name: areaOfCheck.name,
                                            description: '',
                                            type: TagTypes.TAG_DEFECT
                                        };
                                        this.proxyService.createAreaOf(value).then(areaOf => {
                                            resolve(areaOf);
                                        });
                                    }
                                });
                            });
                        }
                    });
                } else {
                    resolve(inputValue);
                }
            }
        });
    }

    checkAndCreateGenericText(inputValue: any, type: number) {
        return new Promise((resolve) => {
            if (inputValue) {
                if (typeof inputValue !== 'object') {
                    this.proxyService.getGenericTextByName(inputValue, type).then(generalType => {
                        if (generalType.data) {
                            resolve(generalType.data);
                        } else {
                            this.store.dispatch(new DialogShow({action: 'create'}));
                            this.store.select('genericText').pipe(skip(1), map(data => {
                                resolve(data.genericText);
                            })).subscribe();
                        }
                    });
                } else {
                    resolve(inputValue);
                }
            }
        });
    }
}
