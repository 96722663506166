import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {NgForm} from '@angular/forms';
import {ProxyService} from '../../shared/services/proxy.service';
import {AreaOf, GenericText, GenericTextInput} from '../../../generated/graphql';
import {SubSink} from 'subsink';
import {AppState} from '../../store/app.reducer';
import {DialogHide, GeneralTypeAdded} from './store/generic-text-form.actions';
import {map} from 'rxjs/operators';
import {GenericTextCreate, GenericTextSet, GenericTextUpdate} from '../../components/generic-texts/store/generic-texts.actions';
import {TagsService} from '../../components/tags/tags.service';

@Component({
    selector: 'app-generic-text-form',
    templateUrl: './generic-text-form.component.html',
    styleUrls: ['./generic-text-form.component.css']
})
export class GenericTextFormComponent implements OnInit, OnDestroy {

    private subs = new SubSink();

    @ViewChild('f') dialogForm: NgForm;

    public display: boolean;
    public shouldDisplay = false;
    public type: number;
    public typeName: string;
    public tagType: string;
    private loadedGenericText: GenericText;

    public areaOfSuggestions: AreaOf[];
    public selectedAreaOf: AreaOf = null;
    public editMode = false;
    private createFormSubmitted = false;

    constructor(
        private store: Store<AppState>,
        private proxyService: ProxyService,
        protected tagsService: TagsService,
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.store.select('genericText').subscribe((data) => {
            if (data.action === 'create') {
                this.editMode = false;
            } else if (data.action === 'edit') {
                this.editMode = true;
            }
            this.shouldDisplay = data.dialogShow;

            if (!data.dialogShow) {
                this.display = data.dialogShow;
            }
        });

        this.subs.sink = this.store.select('genericTexts').pipe(
            map(genericTextsState => {
                return {
                    loadedGenericText: genericTextsState.loadedGenericText,
                };
            })).subscribe(
            ({
                 loadedGenericText: loadedGenericText,
             }) => {
                if (this.dialogForm) {
                    if (this.editMode) {
                        if (loadedGenericText) {
                            this.dialogForm.form.patchValue({
                                    ...loadedGenericText,
                                    genericTextId: loadedGenericText.id,
                                }
                            );
                            this.store.dispatch(new GenericTextSet({genericText: null}));
                            this.loadedGenericText = loadedGenericText;
                            this.type = this.loadedGenericText.type;
                            this.display = this.shouldDisplay;
                        }
                    } else {
                        if (!this.createFormSubmitted) {
                            this.dialogForm.resetForm();
                        }
                        this.display = this.shouldDisplay;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onHide($event: any) {
        this.store.dispatch(new DialogHide());
    }

    onSubmit(f: NgForm) {
        const genericText = {
            id: (this.editMode) ? +this.loadedGenericText.id : null,
            name: f.value.name,
            description: f.value.description,
            precautionSuggestion: f.value.precautionSuggestion,
            type: this.type,
            areaOf: this.selectedAreaOf?.id,
        } as GenericTextInput;

        if (this.editMode) {
            this.store.dispatch(new GenericTextUpdate({genericText}));
        } else {
            this.createFormSubmitted = true;
            this.store.dispatch(new GenericTextCreate({genericText}));
        }
    }

    onCancel() {
        this.dialogForm.resetForm();
        this.store.dispatch(new DialogHide());
    }

    onTextAreaContentChange(elData: HTMLTextAreaElement, el: HTMLSpanElement) {
        el.innerText = '(' + elData.maxLength + '/' + elData.textLength + ')';
    }

    filterAreaOfSuggestions(event: any) {
        this.tagsService.getAreaOfList(event.query, 1).then(data => {
            this.areaOfSuggestions = [...data];
        });
    }
}
