import {RouterModule, UrlSegment} from '@angular/router';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import {SubjectsListComponent} from './components/subjects/subjects-list/subjects-list.component';
import {SubjectEditComponent} from './components/subjects/subject-edit/subject-edit.component';
import {SubjectsResolverService} from './components/subjects/subjects-resolver.service';
import {ProjectsListComponent} from './components/projects/projects-list/projects-list.component';
import {ProjectEditComponent} from './components/projects/project-edit/project-edit.component';
import {ProjectsResolverService} from './components/projects/projects-resolver.service';
import {TagDefectsListComponent} from './components/tags/tag-defects/tag-defects-list/tag-defects-list.component';
import {TagDefectEditComponent} from './components/tags/tag-defects/tag-defect-edit/tag-defect-edit.component';
import {TagDefectsResolverService} from './components/tags/tag-defects/tag-defects-resolver.service';
import {TagTasksListComponent} from './components/tags/tag-tasks/tag-tasks-list/tag-tasks-list.component';
import {TagTaskEditComponent} from './components/tags/tag-tasks/tag-task-edit/tag-task-edit.component';
import {TagTasksResolverService} from './components/tags/tag-tasks/tag-tasks-resolver.service';
import {TagGoodPracticeListComponent} from './components/tags/tag-good-practice/tag-good-practice-list/tag-good-practice-list.component';
import {TagGoodPracticeEditComponent} from './components/tags/tag-good-practice/tag-good-practice-edit/tag-good-practice-edit.component';
import {TagGoodPracticeResolverService} from './components/tags/tag-good-practice/tag-good-practice-resolver.service';
import {OshSubjectComponent} from './components/settings/osh-subject/osh-subject.component';
import {EmptyLayoutComponent} from './empty-layout/empty-layout.component';
import {AuthComponent} from './components/auth/auth.component';
import {AuthGuard} from './components/auth/auth.guard';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuthCheckGuard} from './components/auth/auth-check.guard';
import {AccountCreateComponent} from './components/users/account-create/account-create.component';
import {ProjectDetailComponent} from './components/projects/project-detail/project-detail.component';
import {SubjectDetailComponent} from './components/subjects/subject-detail/subject-detail.component';
import {UsersListComponent} from './components/users/users-list/users-list.component';
import {UserEditComponent} from './components/users/user-edit/user-edit.component';
import {ContactsListComponent} from './components/contacts/contacts-list/contacts-list.component';
import {ContactEditComponent} from './components/contacts/contact-edit/contact-edit.component';
import {UserDetailComponent} from './components/users/user-detail/user-detail.component';
import {ContactDetailComponent} from './components/contacts/contact-detail/contact-detail.component';
import {ReportsListComponent} from './components/reports/reports-list/reports-list.component';
import {ReportEditComponent} from './components/reports/report-edit/report-edit.component';
import {ReportsComponent} from './components/reports/reports.component';
import {ReportCreateComponent} from './components/reports/report-create/report-create.component';
import {GenericTextsListComponent} from './components/generic-texts/generic-texts-list/generic-texts-list.component';
import {GenericTextEditComponent} from './components/generic-texts/generic-text-edit/generic-text-edit.component';
import {AreaOfListComponent} from './components/area-of/area-of-list/area-of-list.component';
import {AreaOfEditComponent} from './components/area-of/area-of-edit/area-of-edit.component';
import {NgModule} from '@angular/core';
import {AppInvalidInvitationComponent} from './pages/app.invalidinvitation.component';
import {SubjectCreateComponent} from './components/subjects/subject-create/subject-create.component';
import {AccountsComponent} from './components/management/accounts/accounts.component';
import {AccountsGuard} from './components/management/accounts/accounts.guard';


export function matchTagBrowseSegments(url: UrlSegment[], type: string, action: string) {

    let regExp = /^$/g;
    if (type === 'D') {
        regExp = /^([a-zA-Z0-9\-]+)-D([0-9]+)$/gm;
    } else if ( type === 'T') {
        regExp = /^([a-zA-Z0-9\-]+)-T([0-9]+)$/gm;
    } else if (type === 'G') {
        regExp = /^([a-zA-Z0-9\-]+)-G([0-9]+)$/gm;
    }

    const parts = regExp.exec(url[1].path);

    if (parts && parts.length === 3) {
        return {
            consumed: url,
            posParams: {
                fullId: new UrlSegment(url[1].path, {}),
                projectKeyName: new UrlSegment(parts[1], {}),
                tagSequenceNumber: new UrlSegment(parts[2], {}),
                action: new UrlSegment(action, {}),
            }
        };
    }

    return null;
}

export function defectsBrowseMatcher(url: UrlSegment[]) {
    if (url.length === 2 && url[0].path === 'browse') {
        return matchTagBrowseSegments(url, 'D', 'update');
    } else if (url.length === 2 && url[0].path === 'resolve') {
        return matchTagBrowseSegments(url, 'D', 'resolve');
    }
    return null;
}

export function tasksBrowseMatcher(url: UrlSegment[]) {
    if (url.length === 2 && url[0].path === 'browse') {
        return matchTagBrowseSegments(url, 'T', 'update');
    } else if (url.length === 2 && url[0].path === 'resolve') {
        return matchTagBrowseSegments(url, 'T', 'resolve');
    }
    return null;
}

export function goodPracticeBrowseMatcher(url: UrlSegment[]) {
    if (url.length === 2 && url[0].path === 'browse') {
        return matchTagBrowseSegments(url, 'G', 'update');
    } else if (url.length === 2 && url[0].path === 'resolve') {
        return matchTagBrowseSegments(url, 'G', 'resolve');
    }
    return null;
}


@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', component: AppMainComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', component: DashboardComponent},
                    { path: 'user', children: [
                            { path: 'edit/:id', component: UserEditComponent },
                        ]},
                    { path: 'users', component: UsersListComponent, children: [
                            { path: 'create', component: UserEditComponent },
                            { path: 'edit/:id', component: UserEditComponent },
                            { path: ':id', component: UserDetailComponent },
                        ]},
                    { path: 'contacts', component: ContactsListComponent, children: [
                            { path: 'create', component: ContactEditComponent },
                            { path: 'edit/:id', component: ContactEditComponent },
                            { path: ':id', component: ContactDetailComponent },
                        ]},
                    { path: 'subjects', component: SubjectsListComponent, children: [
                            // { path: '', component: SubjectsListComponent, pathMatch: 'full' },
                            { path: 'create', component: SubjectEditComponent },
                            { path: 'edit/:id', component: SubjectEditComponent, resolve: [SubjectsResolverService] },
                            { path: ':id', component: SubjectDetailComponent },
                        ]},
                    { path: 'projects', component: ProjectsListComponent, children: [
                            // { path: '', component: ProjectsListComponent, pathMatch: 'full' },
                            { path: 'create', component: ProjectEditComponent },
                            { path: 'edit/:id', component: ProjectEditComponent, resolve: [ProjectsResolverService] },
                            { path: ':id', component: ProjectDetailComponent },
                        ]},
                    { path: 'defects', component: TagDefectsListComponent, children: [
                            // { path: '', component: TagDefectsListComponent, pathMatch: 'full' },
                            { path: 'edit/:id', component: TagDefectEditComponent, resolve: [TagDefectsResolverService] },
                            { path: ':id', component: TagDefectEditComponent, resolve: [TagDefectsResolverService] },
                            { matcher: defectsBrowseMatcher, component: TagDefectEditComponent },
                        ]},
                    { path: 'tasks', component: TagTasksListComponent, children: [
                            // { path: '', component: TagTasksListComponent, pathMatch: 'full' },
                            { path: 'edit/:id', component: TagTaskEditComponent, resolve: [TagTasksResolverService] },
                            { path: ':id', component: TagTaskEditComponent, resolve: [TagTasksResolverService] },
                            { matcher: tasksBrowseMatcher, component: TagTaskEditComponent },
                        ]},
                    { path: 'good-practice', component: TagGoodPracticeListComponent, children: [
                            // { path: '', component: TagGoodPracticeListComponent, pathMatch: 'full' },
                            { path: 'edit/:id', component: TagGoodPracticeEditComponent, resolve: [TagGoodPracticeResolverService] },
                            { path: ':id', component: TagGoodPracticeEditComponent, resolve: [TagGoodPracticeResolverService] },
                            { matcher: goodPracticeBrowseMatcher, component: TagGoodPracticeEditComponent },
                        ]},
                    { path: 'reports', component: ReportsComponent, children: [
                            { path: '', component: ReportsListComponent, pathMatch: 'full' },
                            { path: 'edit/:id', component: ReportEditComponent },
                            { path: 'create', component: ReportCreateComponent },
                        ]},
                    { path: 'generic-texts', component: GenericTextsListComponent, children: [
                            { path: 'edit/:id', component: GenericTextEditComponent },
                            { path: 'create', component: GenericTextEditComponent },
                        ]},
                    { path: 'areas-of', component: AreaOfListComponent, children: [
                            { path: 'edit/:id', component: AreaOfEditComponent },
                            { path: 'create', component: AreaOfEditComponent },
                        ]},
                    { path: 'settings',  children: [
                            { path: 'osh-subject', component: OshSubjectComponent, children: [
                                    { path: '', component: OshSubjectComponent, pathMatch: 'full' },
                                ]},
                        ]},
                    { path: 'management',  children: [{
                        // canActivate: [AccountsGuard],
                        path: 'accounts', component: AccountsComponent, children: [{
                            path: '', component: AccountsComponent, pathMatch: 'full' },
                        ]},
                    ]},
                    { matcher: defectsBrowseMatcher, component: TagDefectEditComponent },
                    { matcher: tasksBrowseMatcher, component: TagTaskEditComponent },
                    { matcher: goodPracticeBrowseMatcher, component: TagGoodPracticeEditComponent },
                ]
            },
            { path: '', component: EmptyLayoutComponent, children: [
                    { path: 'login', component: AuthComponent, canActivate: [AuthCheckGuard]},
                    { path: 'forget-password', component: AuthComponent},
                    { path: 'password-reset/:hash', component: AuthComponent},
                    { path: 'account-create/:accountCreationCode', component: AccountCreateComponent},
                    { path: 'subject-create', component: SubjectCreateComponent},
                    { path: 'subject-create/:action', component: SubjectCreateComponent},
                    { path: 'invalid-invitation/:type', component: AppInvalidInvitationComponent},
                ]},
            {path: 'error', component: AppErrorComponent},
            {path: 'error/:type', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            // {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
