import { Component, OnInit } from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {FormsHelperService} from '../forms-helper.service';
import {ProxyService} from '../../shared/services/proxy.service';
import {CustomPermissionsService} from '../../shared/services/custom-permissions.service';

@Component({
    selector: 'app-user-part',
    template: `
        <div class="card card-w-title" ngModelGroup="userData">
            <input
                id="userId"
                type="number"
                ngModel
                name="userId"
                #userId="ngModel"
                hidden
            >
            <div class="p-d-flex form-group">
                <div class="p-col-12 ui-md-12">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="firstname">{{'users.first_name' | translate}} <span style="color: red;">*</span></label>
                            <input
                                id="firstname"
                                type="text"
                                pInputText
                                ngModel
                                name="firstName"
                                required
                                autofocus
                                #firstName="ngModel"
                                [ngClass]="!firstName.valid && (firstName.dirty || firstName.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="lastname">{{'users.last_name' | translate}} <span style="color: red;">*</span></label>
                            <input
                                id="lastname"
                                type="text"
                                pInputText
                                ngModel
                                name="lastName"
                                required
                                #lastName="ngModel"
                                [ngClass]="!lastName.valid && (lastName.dirty || lastName.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="email">{{'users.email' | translate}} <span style="color: red;">*</span></label>
                            <input
                                id="email"
                                type="text"
                                pInputText
                                ngModel
                                name="email"
                                required
                                email
                                #email="ngModel"
                                [ngClass]="!email.valid && (email.dirty || email.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="phoneNumber">{{'users.phone_number' | translate}}</label>
                            <input
                                id="phoneNumber"
                                type="text"
                                pInputText
                                ngModel
                                name="phoneNumber"
                                pattern="^\\+(?:[0-9]?){6,14}[0-9]$"
                                #phoneNumber="ngModel"
                                [ngClass]="!phoneNumber.valid && (phoneNumber.dirty || phoneNumber.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex form-group">
                <div class="p-col-11 ui-md-11">
                    <div *ngIf="userId.value">
                        <p-button
                            type="button"
                            label="{{changePasswordLabel | translate}}"
                            class="ui-button-info"
                            (onClick)="onChangePasswordToggle()"
                        ></p-button>
                    </div>
                </div>
            </div>
            <div *ngIf="!userId.value || changePassword">
                <div class="p-d-flex form-group">
                    <div class="p-col-12 ui-md-12">
                        <div class="p-fluid">
                            <div class="p-field">
                                <label for="password">{{'users.password' | translate}} <span style="color: red;">*</span></label>
                                <input
                                    id="password"
                                    type="password"
                                    pPassword
                                    ngModel
                                    name="password"
                                    required
                                    #password="ngModel"
                                    [ngClass]="!password.valid && (password.dirty || password.touched) ? 'ng-dirty ng-invalid' : ''"
                                >
                            </div>
                        </div>
                        <div class="p-fluid">
                            <div class="p-field">
                                <label for="passwordConfirm">{{'users.confirm_password' | translate}} <span style="color: red;">*</span></label>
                                <input
                                    id="passwordConfirm"
                                    type="password"
                                    pInputText
                                    ngModel
                                    name="passwordConfirm"
                                    [appPasswordMatch]="password.value"
                                    required
                                    #passwordConfirm="ngModel"
                                    [ngClass]="!passwordConfirm.valid && (passwordConfirm.dirty || passwordConfirm.touched) ? 'ng-dirty ng-invalid' : ''"
                                >
                            </div>
                        </div>
                        <div class="p-fluid">
                            <div class="p-field">
                                <p-message severity="error" [text]="'Password mismatch'"
                                           *ngIf="!passwordConfirm.valid && (passwordConfirm.dirty || passwordConfirm.touched) &&
                                           passwordConfirm.errors?.passwordMismatch"></p-message>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex form-group" *ngxPermissionsOnly="'superadmin'">
                <div class="p-col-11" style="margin-bottom:10px">
                    <p-checkbox
                        id="superadmin"
                        name="superadmin"
                        label="Superadmin"
                        [binary]="true"
                        ngModel
                    ></p-checkbox>

                </div>
            </div>

<!--            <div class="p-d-flex form-group">-->
<!--                <div class="p-col-11 ui-md-11">-->
<!--                    <p-checkbox id="blocked" label="{{'users.blocked' | translate}}" [binary]="true" ngModel name="blocked"></p-checkbox>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="p-d-flex form-group">-->
<!--                <div class="p-col-11 ui-md-11">-->
<!--                    <p-checkbox-->
<!--                            id="emailLogin"-->
<!--                            label="{{'users.email_login' | translate}}"-->
<!--                            [binary]="true"-->
<!--                            ngModel-->
<!--                            name="emailLogin"></p-checkbox>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="p-d-flex form-group">-->
<!--                <div class="p-col-11 ui-md-11">-->
<!--                    <p-checkbox-->
<!--                            id="googleLogin"-->
<!--                            label="{{'users.google_login' | translate}}"-->
<!--                            [binary]="true"-->
<!--                            ngModel-->
<!--                            name="googleLogin"></p-checkbox>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="p-d-flex form-group">-->
<!--                <div class="p-col-11 ui-md-11">-->
<!--                    <p-checkbox-->
<!--                            id="facebookLogin"-->
<!--                            label="{{'users.facebook_login' | translate}}"-->
<!--                            [binary]="true"-->
<!--                            ngModel-->
<!--                            name="facebookLogin"></p-checkbox>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    `,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class UserPartComponent implements OnInit {
    public filteredRoles: any;
    public changePassword = false;
    public changePasswordLabel = 'Change password';

    constructor(
        private proxyService: ProxyService,
        public formsHelper: FormsHelperService,
        public ps: CustomPermissionsService
    ) { }

    ngOnInit(): void {
    }

    onChangePasswordToggle() {
        this.changePassword = !this.changePassword;

        if (this.changePassword) {
            this.changePasswordLabel = 'users.dont_change_password';
        } else {
            this.changePasswordLabel = 'users.change_password';
        }
    }
}
