import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BaseComponent} from '../../../shared/base/base.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {delay, map} from 'rxjs/operators';
import {Country, Subject, SubjectAres, SubjectAresInput, User} from '../../../../generated/graphql';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {Store} from '@ngrx/store';
import {ProxyService} from '../../../shared/services/proxy.service';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {GlobalService} from '../../../shared/services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {AddLoading, AddMessage, RemoveLoading} from '../../../loading/loading.action';
import {SelectItem} from 'primeng/api';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {SubjectCreate, SubjectFetch, SubjectSet, SubjectUpdate} from '../store/subjects.actions';

@Component({
  selector: 'app-subject-edit',
  templateUrl: './subject-edit.component.html',
  styleUrls: ['./subject-edit.component.css']
})
export class SubjectEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('f') subjectForm: NgForm;

    public editMode = false;
    private editId = null;
    public filteredCountries: Country[];
    public subjectTypes: SelectItem[];
    public selectedType: number;
    public filteredUsers: User[];
    public subjectInfo: SubjectAres;
    public loadedSubject: Subject = null;
    public selectedCountry: Country;
    public administrators: string[] = [];

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected proxyService: ProxyService,
        protected ps: CustomPermissionsService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            if (this.editMode) {
                this.fetchSubject(this.editId);
            }

            this.buttons = [
                {
                    id: 'saveSubjectButton',
                    name: 'subjects.save',
                    action: 'onSaveSubject',
                    class: 'p-button-success',
                    icon: 'pi pi-save p-mr-1',
                    disabled: false,
                }
            ];

            this.subs.sink = forkJoin({
                chooseType: this.translate.get('subjects.types.choose_type'),
                oshType: this.translate.get('subjects.types.osh'),
                investorType: this.translate.get('subjects.types.investor'),
                subjectType: this.translate.get('subjects.types.subject'),
            }).subscribe(result => {
                this.subjectTypes = [
                    {label: result.chooseType, value: null },
                    {label: result.investorType, value: 2},
                    {label: result.subjectType, value: 3},
                ];
            });

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createSubjects').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.buttons.push(
                            {
                                id: 'createSubjectButton',
                                name: 'subjects.create',
                                action: 'onCreateSubject',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }
                        );
                    }
                    this.store.dispatch(new SetToolbarButtons(this.buttons));
                });

                this.ps.hasPermission('superadmin').then((permission) => {
                    if (permission) {
                        this.subs.sink = forkJoin({
                            oshType: this.translate.get('subjects.types.osh'),
                        }).subscribe(result => {
                            this.subjectTypes.push({label: result.oshType, value: 1 });
                        });
                    }
                });
            });
        });


        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngAfterViewInit() {
        if (this.editMode) {
            this.subs.sink = this.store.select('subjects').pipe(
                delay(0),
                map(subjectState => {
                    return {
                        loadedSubject: subjectState.loadedSubject,
                    };
                })).subscribe(
                ({
                     loadedSubject: loadedSubject,
                 }) => {
                    if (loadedSubject) {
                        this.subjectForm.form.patchValue({
                                ...loadedSubject,
                                subjectId: loadedSubject.id,
                                country: loadedSubject.address?.country,
                                administrators: loadedSubject.administrators?.map(admin => {
                                    return admin.email;
                                })
                            }
                        );
                        this.loadedSubject = loadedSubject;
                        this.store.dispatch(new SubjectSet({subject: null}));
                    }
                });
        }
    }

    fetchSubject(subjectId: number) {
        this.store.dispatch(new SubjectFetch({subjectId}));
    }

    onSubmit(form: NgForm) {
        if (form.valid) {
            const subject: SubjectAresInput = {
                id: this.loadedSubject?.id ?? null,
                type: +form.value.type,
                companyCode: form.value.companyCode,
                country: form.value.country,
                enabled: form.value.enabled ? form.value.enabled : false,
                administrators: this.administrators
            };

            if (this.editMode) {
                this.store.dispatch(new SubjectUpdate({subject, redirect: true}));
            } else {
                this.store.dispatch(new SubjectCreate({subject, redirect: true}));
            }
        } else {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsTouched();
            });

            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('errors.invalid_form_fields')], 'global'));
        }
    }

    onSaveSubject() {
        this.subjectForm.ngSubmit.emit();
    }

    checkCompanyCode() {
        if (!this.subjectForm.value.country || !this.subjectForm.value.companyCode) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage(this.translate.instant('general.required-country-and-company-code'))], 'global'));
        } else {
            this.store.dispatch(new AddLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            this.proxyService.getSubjectAres(
                this.subjectForm.value.companyCode,
                this.subjectForm.value.country.isoCode2
            ).then(subjectAres => {
                this.subjectInfo = subjectAres;
                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            }).catch(reason => {
                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_llcl'}], 'global'));
            });
        }
    }

    filterCountries(event) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'name', 1);
        this.proxyService.getCountries(lazy).then(countries => {
            this.filteredCountries = [...countries];
        });
    }

    filterUsers(event) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'email', 1);
        this.proxyService.getUsersList(lazy).then(users => {
            this.filteredUsers = [...users];
        });
    }

    validateAdministratorEmail(event: any) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(event.value)) {
            return true;
        }

        const index = this.administrators.indexOf(event.value);
        if (index !== -1) {
            this.administrators.splice(index, 1);
        }

        this.store.dispatch(new AddMessage([FlashMessage.warningMessage('subjects.not-an-email-address')], 'global'));
        return false;
    }
}
