import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import {ToolbarButton} from './fixed-toolbar/toolbar-button.model';

@Injectable()
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();
    private buttonsSource = new Subject<ToolbarButton[]>();

    itemsHandler = this.itemsSource.asObservable();
    buttonsHandler = this.buttonsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    setButtons(buttons: ToolbarButton[]) {
        this.buttonsSource.next(buttons);
    }
}
