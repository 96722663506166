export * from './loading.module';
export { LoadingAction } from './loading.model';
export {
    getGlobalLoading as getGlobalLoadingData,
    getModalLoading as getModalLoadingData,
    // getMap as getLoadingMap,
    // getByKey as getLoadingByKey
} from './loading.selector';
export {
  ActionTypes as LoadingActionTypes,
  AddLoading as LoadingAdd,
  RemoveLoading as LoadingRemove
} from './loading.action';
