import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Subject, SubjectAresInput} from '../../../../generated/graphql';
import {SubjectOshInput} from '../../../../generated/graphql_auth';

export enum SubjectsActionTypes {
    SubjectsFetchLazyMeta = '[@Subjects] FetchLazyMeta',
    SubjectsSet = '[@Subjects] Set',
    SubjectSet = '[@Subject] Set',
    SubjectsFetch = '[@Subjects] Fetch',
    SubjectFetch = '[@Subject] Fetch',
    SubjectCreate = '[@Subject] Create',
    SubjectCreateSuccess = '[@Subject] CreateSuccess',
    SubjectCreateOsh = '[@Subject] CreateOsh',
    SubjectCreateOshSuccess = '[@Subject] CreateOshSuccess',
    SubjectResendOshConfirmation = '[@Subject] ResendOshConfirmation',
    SubjectResendOshConfirmationSuccess = '[@Subject] ResendOshConfirmationSuccess',
    SubjectUpdate = '[@Subject] Update',
    SubjectUpdateSuccess = '[@Subject] UpdateSuccess',
    SubjectDelete = '[@Subject] Delete',
    SubjectDeleteSuccess = '[@Subject] DeleteSuccess',
    SubjectsAppFetch = '[@Subjects] AppFetch',
    SubjectsAppSet = '[@Subjects] AppSet',

    SubjectsError = '[@Subjects] Error',
}

export class SubjectsSet implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectsFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {subjects: Subject[], totalRows: number}) {}
}

export class SubjectsFetch implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectsFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class SubjectSet implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {subject: Subject}) {}
}

export class SubjectFetch implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {subjectId: number}) {}
}

export class SubjectsAppFetch implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectsAppFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectsAppFetch, spinner: 'bar'}],
        type: 'global'
    };
}

export class SubjectsAppSet implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectsAppSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectsAppFetch, spinner: 'bar'}],
        type: 'global'
    };
    constructor(public payload: {subjects: Subject[]}) {}
}

export class SubjectCreate implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectCreate, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {subject: SubjectAresInput, redirect: boolean}) {}
}

export class SubjectCreateSuccess implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectCreate, spinner: 'sp_llcl'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('subjects.created')],
        type: 'global'
    };

    constructor(public payload: {redirect: boolean} = {redirect: true}) {}
}

export class SubjectCreateOsh implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectCreateOsh;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectCreateOsh, spinner: 'sp_al'}],
        type: 'global'
    };
    constructor(public payload: {subject: SubjectOshInput, redirect: boolean}) {}
}

export class SubjectCreateOshSuccess implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectCreateOshSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectCreateOsh, spinner: 'sp_al'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('subjects.account-created')],
        type: 'global'
    };

    constructor(public payload: {redirect: boolean} = {redirect: true}) {}
}


export class SubjectResendOshConfirmation implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectResendOshConfirmation;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectResendOshConfirmation, spinner: 'sp_al'}],
        type: 'global'
    };
    constructor(public payload: {companyCode: string}) {}
}

export class SubjectResendOshConfirmationSuccess implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectResendOshConfirmationSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectResendOshConfirmation, spinner: 'sp_al'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('subjects.account-confirmation-resent')],
        type: 'global'
    };

    constructor(public payload: {redirect: boolean} = {redirect: true}) {}
}

export class SubjectUpdate implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {subject: SubjectAresInput, redirect: boolean}) {}
}

export class SubjectUpdateSuccess implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('subjects.updated')],
        type: 'global'
    };

    constructor(public payload: {redirect: boolean} = {redirect: true}) {}
}

export class SubjectDelete implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: SubjectsActionTypes.SubjectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {subjectId: number}) {}
}

export class SubjectDeleteSuccess implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: SubjectsActionTypes.SubjectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('subjects.deleted')],
        type: 'global'
    };
}

export class SubjectsError implements Action, LoadingAction {
    readonly type = SubjectsActionTypes.SubjectsError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: SubjectsActionTypes.SubjectsFetch, spinner: 'sp_lll'},
            {name: SubjectsActionTypes.SubjectFetch, spinner: 'sp_llcl'},
            {name: SubjectsActionTypes.SubjectUpdate, spinner: 'sp_llb'},
            {name: SubjectsActionTypes.SubjectDelete, spinner: 'sp_lll'},
            {name: SubjectsActionTypes.SubjectCreate, spinner: 'sp_llcl'},
            {name: SubjectsActionTypes.SubjectCreateOsh, spinner: 'sp_al'},
            {name: SubjectsActionTypes.SubjectResendOshConfirmation, spinner: 'sp_al'},
            {name: SubjectsActionTypes.SubjectsAppFetch, spinner: 'bar'},
        ],
        type: 'all'
    };

    constructor() {}
}

export class SubjectsFetchLazyMeta implements Action {
    readonly type = SubjectsActionTypes.SubjectsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type SubjectsActions =
    SubjectsFetch |
    SubjectsSet |
    SubjectFetch |
    SubjectSet |
    SubjectsAppFetch |
    SubjectsAppSet |
    SubjectCreateSuccess |
    SubjectUpdateSuccess |
    SubjectDeleteSuccess |
    SubjectsError |
    SubjectsFetchLazyMeta;
