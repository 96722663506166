import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    UserFetch,
    UsersFetch,
    UserCreate,
    UserCreateSuccess,
    UserUpdateSuccess,
    UserDeleteSuccess,
    UserFinishAccount,
    UserFinishAccountSuccess,
    UsersActionTypes,
    UsersError,
    UsersFetchLazyMeta,
    UsersSet,
    UserSet,
    UserUpdate, UserDelete,
} from './users.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {Router} from '@angular/router';
import {ConPointAuthService} from '../../../../generated/graphql_auth';
import {getUsers} from './users.selector';
import {AppState} from '../../../store/app.reducer';
import {UserFormDialogHide} from '../../../forms/user-form/store/user-form.actions';
import {State} from './users.reducer';

const handleError = (errorRes: ApolloError) => {
    return of(new UsersError());
};

@Injectable()
export class UsersEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private conPointService: ConPointService,
                private conPointAuthService: ConPointAuthService,
                private router: Router
    ) {}

    @Effect()
    usersFetch = this.actions$.pipe(
        ofType(UsersActionTypes.UsersFetch),
        switchMap((usersActions: UsersFetch) => {
            this.store.dispatch(new UsersFetchLazyMeta({lazyLoad: usersActions.payload}));
            return this.conPointService.getUsersList({lazyLoad: usersActions.payload}).pipe(
                map((result) => {
                    const users = result.data.GetUsersList.result?.map(user => {
                        return {...user};
                    });

                    return {
                        users,
                        total: result.data.GetUsersList.metadata.total,
                        count: result.data.GetUsersList.metadata.count
                    };
                }),
                map(({users, total, count}) => {
                    return new UsersSet({users: users ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    userFetch = this.actions$.pipe(
        ofType(UsersActionTypes.UserFetch),
        switchMap((usersActions: UserFetch) => {
            return this.conPointService.getUser({id: usersActions.payload.userId}).pipe(
                map((result) => {
                    return new UserSet({user: result.data.GetUser});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    userCreate = this.actions$.pipe(
        ofType(UsersActionTypes.UserCreate),
        switchMap((usersActions: UserCreate) => {
            return this.conPointService.createUser(
                {user: usersActions.payload.user}).pipe(
                map(() => {
                    this.store.dispatch(new UserFormDialogHide());
                    return new UserCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    userFinishAccount = this.actions$.pipe(
        ofType(UsersActionTypes.UserFinishAccount),
        switchMap((usersActions: UserFinishAccount) => {
            return this.conPointAuthService.finishUser(
                {user: usersActions.payload.user, accountCreationCode: usersActions.payload.accountCreationCode}).pipe(
                map(() => {
                    return new UserFinishAccountSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );


    @Effect()
    userUpdate = this.actions$.pipe(
        ofType(UsersActionTypes.UserUpdate),
        withLatestFrom(this.store.select(getUsers)),
        switchMap<[UserUpdate, State], Observable<any>>(([usersActions, usersState]) => {
            return this.conPointService.updateUser({user: usersActions.payload.user}).pipe(
                map(() => {
                    this.store.dispatch(new UserFormDialogHide());
                    this.store.dispatch(new UsersFetch(usersState.currentLazyLoad));
                    return new UserUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    userDelete = this.actions$.pipe(
        ofType(UsersActionTypes.UserDelete),
        withLatestFrom(this.store.select(getUsers)),
        switchMap<[UserDelete, State], Observable<any>>(([usersActions, usersState]) => {
            return this.conPointService.deleteUser({id: usersActions.payload.id}).pipe(
                map(() => {
                    this.store.dispatch(new UsersFetch(usersState.currentLazyLoad));
                    return new UserDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect({dispatch: false})
    usersNavigate = this.actions$.pipe(
        ofType(UsersActionTypes.UserFinishAccountSuccess),
        // withLatestFrom(this.store.select('subjects')),
        tap((data) => {
            this.router.navigate(['/login']);
        })
    );
}
