import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../../loading';
import { MessageAction } from '../../../../loading/loading.model';
import { FlashMessage} from '../../../../shared/model/flash-message.model';
import { TagDefect, TagDefectInput} from '../../../../../generated/graphql';

export enum TagDefectsActionTypes {
    TagDefectsFetchLazyMeta = '[@TagDefects] FetchLazyMeta',
    TagDefectsSet = '[@TagDefects] Set',
    TagDefectSet = '[@TagDefect] Set',
    TagDefectsFetch = '[@TagDefects] Fetch',
    TagDefectFetch = '[@TagDefect] Fetch',
    TagDefectBrowseFetch = '[@TagDefect] Browse Fetch',
    TagDefectCreate = '[@TagDefect] Create',
    TagDefectCreateSuccess = '[@TagDefect] SaveSuccess',
    TagDefectUpdate = '[@TagDefect] Update',
    TagDefectUpdateSuccess = '[@TagDefect] UpdateSuccess',
    TagDefectDelete = '[@TagDefect] Delete',
    TagDefectDeleteSuccess = '[@TagDefect] DeleteSuccess',
    TagDefectsError = '[@TagDefects] Error',
}

export class TagDefectsSet implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagDefectsActionTypes.TagDefectsFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {defects: TagDefect[], totalRows: number }) {}
}

export class TagDefectsFetch implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectsFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class TagDefectSet implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectSet;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: TagDefectsActionTypes.TagDefectFetch, spinner: 'sp_llcl'},
            {name: TagDefectsActionTypes.TagDefectBrowseFetch, spinner: 'sp_llcl'}
        ],
        type: 'global'
    };
    constructor(public payload: {defect: TagDefect }) {}
}

export class TagDefectFetch implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {defectId: number}) {}
}

export class TagDefectBrowseFetch implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectBrowseFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectBrowseFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {projectKeyName: string, tagSequenceNumber: number}) {}
}


export class TagDefectCreate implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {defect: TagDefectInput}) {}
}

export class TagDefectCreateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagDefectsActionTypes.TagDefectCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagDefectsActionTypes.TagDefectCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('defects.created')],
        type: 'global'
    };
}

export class TagDefectUpdate implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {defect: TagDefectInput, action: string}) {}
}

export class TagDefectUpdateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagDefectsActionTypes.TagDefectUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagDefectsActionTypes.TagDefectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('defects.updated')],
        type: 'global'
    };
}

export class TagDefectDelete implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: TagDefectsActionTypes.TagDefectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {defectId: number}) {}
}

export class TagDefectDeleteSuccess implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagDefectsActionTypes.TagDefectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('defects.deleted')],
        type: 'global'
    };
}

export class TagDefectsError implements Action, LoadingAction {
    readonly type = TagDefectsActionTypes.TagDefectsError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name : TagDefectsActionTypes.TagDefectsFetch, spinner: 'sp_lll'},
            {name: TagDefectsActionTypes.TagDefectFetch, spinner: 'sp_llcl'},
            {name: TagDefectsActionTypes.TagDefectBrowseFetch, spinner: 'sp_llcl'},
            {name: TagDefectsActionTypes.TagDefectCreate, spinner: 'sp_lll'},
            {name: TagDefectsActionTypes.TagDefectUpdate, spinner: 'sp_llb'},
            {name: TagDefectsActionTypes.TagDefectDelete, spinner: 'sp_lll'},
        ],
        type: 'all'
    };

    constructor() {}
}

export class TagDefectsFetchLazyMeta implements Action {
    readonly type = TagDefectsActionTypes.TagDefectsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type TagDefectsActions =
    TagDefectsFetch |
    TagDefectCreate |
    TagDefectCreateSuccess |
    TagDefectUpdate |
    TagDefectUpdateSuccess |
    TagDefectDelete |
    TagDefectDeleteSuccess |
    TagDefectsSet |
    TagDefectSet |
    TagDefectFetch |
    TagDefectBrowseFetch |
    TagDefectsFetchLazyMeta |
    TagDefectsError;
