import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './osh-subject.reducer';

export const getOshSubjectFeature = createFeatureSelector<State>('oshSubject');

export const getOshSubject = createSelector(
    getOshSubjectFeature,
    (state) => {
        return state.subject;
    }
);
