import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {ConPointService} from '../../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {Router} from '@angular/router';
import {AppState} from '../../../../store/app.reducer';
import {accountsError, accountsFetch, accountsFetchSuccess} from './accounts.actions';

const handleError = (errorRes: ApolloError) => {
    return of(accountsError());
};

@Injectable()
export class AccountsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    accountsFetch$ = createEffect(() => this.actions$.pipe(
        ofType(accountsFetch),
        switchMap((a) => {
            return this.conPointService.getSubjectsAppList().pipe(
                map((result) => {
                    const subjects = result.data.GetSubjectsList.result?.map(subject => {
                        return {...subject};
                    });
                    return accountsFetchSuccess({subjects: subjects ?? [], totalRows: subjects.length});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    ));
}
