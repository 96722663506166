import {Actions, Effect, ofType} from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {of} from 'rxjs';
import {ConPointService} from '../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {Router} from '@angular/router';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {
    CurrentStateActionTypes,
    CurrentStateError,
    CurrentStateSubjectsProjectsFetch,
    CurrentStateSubjectsProjectsSet
} from './current-state.actions';
import {AppState} from '../app.reducer';
import {AppActions} from '../app-actions.service';

const handleError = (errorRes: ApolloError) => {
    return of(new CurrentStateError());
};

@Injectable()
export class CurrentStateEffects {
    constructor(private actions$: Actions,
                private appActions: AppActions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect({dispatch: false})
    allActions = this.actions$.pipe(
        tap((a) => {
            this.appActions.actions$.next(a);
        })
    );

    @Effect({dispatch: false})
    subjectsProjectsFetch = this.actions$.pipe(
        ofType(CurrentStateActionTypes.CurrentStateSubjectsProjectsFetch),
        switchMap((currentStateActions: CurrentStateSubjectsProjectsFetch) => {
            return this.conPointService.getSubjectsAppList().pipe(
                map((result) => {
                    const subjects = result.data.GetSubjectsList.result?.map(subject => {
                        return subject;
                    });

                    return {subjects};
                }),
                map(({subjects}) => {
                    this.store.dispatch(new CurrentStateSubjectsProjectsSet({
                        subjects: subjects ?? []
                    }));
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
