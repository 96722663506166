import * as GenericTextsActions from './generic-texts.actions';
import {GenericTextsActionTypes} from './generic-texts.actions';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {GenericText} from '../../../../generated/graphql';

export interface State {
    genericTexts: GenericText[];
    currentLazyLoad: LazyLoadMeta;
    loadedGenericText: GenericText;
    totalRows: number;
}

const initialState = {
    genericTexts: [],
    currentLazyLoad: null,
    loadedGenericText: null,
    totalRows: 0,
};

export function genericTextsReducer(state: State = initialState, action: GenericTextsActions.GenericTextsActions) {
    switch (action.type) {
        case GenericTextsActionTypes.GenericTextsSet:
            state =  {
                ...state,
                genericTexts: [...action.payload.genericTexts],
                totalRows: action.payload.totalRows
            };
            break;
        case GenericTextsActionTypes.GenericTextSet:
            state =  {
                ...state,
                loadedGenericText: action.payload.genericText
            };
            break;
        case GenericTextsActionTypes.GenericTextsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case GenericTextsActions.GenericTextsActionTypes.GenericTextsError:
            state =  {
                ...state,
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
