import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {GlobalService} from '../../../shared/services/global.service';
import {AddMessage} from '../../../loading/loading.action';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {AreaOf, GenericText, Project, Subject, User} from '../../../../generated/graphql';
import {TagsService} from '../tags.service';
import {TagTypes} from '../../../shared/enums/entity-enums';
import {NgForm} from '@angular/forms';
import {DialogHide} from '../../../forms/tags/defect-create/store/defect-create-form.actions';
import * as GeneralTypeActions from '../../../forms/generic-text-form/store/generic-text-form.actions';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {UploadManager} from '../../../shared/classes/upload-manager';
import {Gallery, GalleryItem} from '@ngx-gallery/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-tag-base',
    templateUrl: './tag-base.component.html',
    styleUrls: ['./tag-base.component.css']
})
export class TagBaseComponent extends BaseComponent implements OnInit, OnDestroy {

    tagType: number;

    @ViewChild('f') tagForm: NgForm;
    @ViewChild('image1Upload') image1Upload;
    @ViewChild('image2Upload') image2Upload;

    states: any;
    selectedState: {id: 1};

    severities: any;
    selectedSeverity = {id: 1};

    projectsSuggestions: Project[];
    selectedProject: Project = null;

    usersSuggestions: User[];
    selectedUser: User = null;

    subjectsSuggestions: Subject[];
    selectedSubjects: Subject[] = [];

    areaOfSuggestions: AreaOf[];
    selectedAreaOf: AreaOf = null;

    precautionSuggestionGeneralTypesList: GenericText[];
    selectedPrecautionSuggestionGeneralType: GenericText = null;

    generalTypesList: GenericText[];
    selectedGeneralType: GenericText = null;

    uploading1State = false;
    uploading2State = false;

    uploadManager1: UploadManager;
    uploadManager2: UploadManager;

    galleryImages1: GalleryItem[] = [];
    galleryImages2: GalleryItem[] = [];

    images1Id = 'images1';
    images2Id = 'images2';

    loadedImages1: string[];
    loadedImages2: string[];

    isLoading = true;

    permission: string;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected tagsService: TagsService,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
        protected gallery: Gallery,
    ) {
        super(store, gs);
    }

    ngOnInit() {
        super.ngOnInit();

        this.uploadManager1 = new UploadManager(this.store, this.tagsService);
        this.uploadManager2 = new UploadManager(this.store, this.tagsService);

        this.galleryImages1 = [];
        this.galleryImages2 = [];

        this.subs.sink = this.uploadManager1.uploadingState.subscribe((state) => {
            this.uploading1State = state;
        });

        this.subs.sink = this.uploadManager2.uploadingState.subscribe((state) => {
            this.uploading2State = state;
        });

        this.severities = this.enums.severities;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.uploadManager1.clearUploadedFiles(null);
        this.uploadManager2.clearUploadedFiles(null);
    }

    onSelectionAssigneesChanged(event: any) {
        if (this.selectedSubjects.length > 0) {
            this.fetchSubjectsUsers(this.tagForm.value.assignedToUser?.email, this.selectedSubjects.map(s => s.id)).then(() => {
                if (!Array.isArray(this.usersSuggestions) || this.usersSuggestions.length === 0) {
                    this.selectedUser = null;
                }
            });
        } else {
            this.selectedUser = null;
        }
    }

    filterProjectsSuggestions(event: any) {
        this.tagsService.getProjectsList(event.query).then(data => {
            this.projectsSuggestions = [...data];
        });
    }

    filterUsersSuggestions(event: any) {
        const subjectIds = this.selectedSubjects.map(s => s.id);
        if (subjectIds.length > 0) {
            this.fetchSubjectsUsers(event.query, subjectIds).then();
        } else {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('general_tag.select-subject-first')], 'global'));
        }
    }

    filterSubjectsSuggestions(event: any) {
        if (this.selectedProject) {
            this.tagsService.getSubjectsList(event.query, this.selectedProject.id).then(data => {
                this.subjectsSuggestions = [...data];
            });
        } else {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('general_tag.select-project-first')], 'global'));
        }
    }

    filterAreaOfSuggestions(event: any, type: number) {
        this.tagsService.getAreaOfList(event.query, type).then(data => {
            this.areaOfSuggestions = [...data];
        });
    }

    fetchSubjectsUsers(query: string, subjectIds: number[]) {
        return this.tagsService.getUsersList(query, subjectIds).then(data => {
            this.usersSuggestions = [...data];
        });
    }

    filterGeneralTypes(event: any) {
        this.tagsService.getGeneralTypesList(event.query, 1, this.tagForm.value.areaOf?.id).then(data => {
            this.generalTypesList = [...data];
        });
    }

    filterPrecautionSuggestionGeneralTypes(event: any) {
        this.tagsService.getGeneralTypesList(event.query, 2, this.tagForm.value.areaOf?.id).then(data => {
            this.precautionSuggestionGeneralTypesList = [...data];
        });
    }

    onGeneralTypeEnter(event: any, type: number) {
        const tokenInput = event.target;
        this.tagsService.checkAndCreateGenericText(tokenInput.value, type).then((result: GenericText) => {
            this.selectedGeneralType = result;
            this.generalTypeSelected();
            this.store.dispatch(new DialogHide());
        });
        tokenInput.target = '';
    }

    generalTypeSelected() {
        this.tagForm.form.patchValue({description: this.selectedGeneralType.description});
    }

    onPrecautionGeneralTypeEnter(event: any, type: number) {
        const tokenInput = event.target;
        this.tagsService.checkAndCreateGenericText(tokenInput.value, type).then((result: GenericText) => {
            this.selectedPrecautionSuggestionGeneralType = result;
            this.precautionSuggestionGeneralTypeSelected();
            this.store.dispatch(new GeneralTypeActions.DialogHide());
        });
        tokenInput.target = '';
    }

    precautionSuggestionGeneralTypeSelected() {
        this.tagForm.form.patchValue({precautionSuggestion: this.selectedPrecautionSuggestionGeneralType.precautionSuggestion});
    }

    onAreaOfEnter(event: any) {
        const tokenInput = event.target;
        this.tagsService.checkAndCreateAreaOf(tokenInput.value).then(result => {
            this.selectedAreaOf = result;
        });
        tokenInput.target = '';
    }

    subscribeTagStates(project, loadedTag) {

        const role = this.ps.getProjectRole(project);

        this.enums.states.subscribe((states) => {
            if (states.length === 5) {
                states[0].inactive = false;
                states[1].inactive = false;
                states[2].inactive = false;
                states[3].inactive = false;
                states[4].inactive = false;

                if (states.length === 5 && role > 34 && this.tagType !== TagTypes.TAG_GOOD_PRACTICE) {
                    states[0].inactive = true;
                    states[1].inactive = !(loadedTag?.state?.id === 2);
                    states[2].inactive = !(loadedTag?.state?.id === 2);
                    states[3].inactive = true;
                    states[4].inactive = true;
                } else if (states.length === 5 && role >= 30) {
                    states[0].inactive = (loadedTag?.state?.id >= 4);
                    states[1].inactive = (loadedTag?.state?.id >= 4);
                    states[2].inactive = (loadedTag?.state?.id >= 4);
                    states[3].inactive = true;
                    states[4].inactive = true;
                }
                this.states = states;
            }
        });
    }

    onRemoveLoadedImages1(fileCode: string) {
        this.galleryImages1 = this.galleryImages1.filter((file) => file.data.code !== fileCode);
        const galleryRef = this.gallery.ref(this.images1Id);
        galleryRef.load(this.galleryImages1);
        this.loadedImages1 = [...this.loadedImages1.filter((file) => file !== fileCode)];
    }

    onRemoveLoadedImages2(fileCode: string) {
        this.galleryImages2 = this.galleryImages2.filter((file) => file.data.code !== fileCode);
        const galleryRef = this.gallery.ref(this.images2Id);
        galleryRef.load(this.galleryImages2);
        this.loadedImages2 = [...this.loadedImages2.filter((file) => file !== fileCode)];
    }

    dropImages(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.galleryImages1, event.previousIndex, event.currentIndex);
    }

    dropResolvedImages(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.galleryImages2, event.previousIndex, event.currentIndex);
    }
}
