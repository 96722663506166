import {Action, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {AppState} from './app.reducer';

@Injectable(
    {providedIn: 'root'}
)
export class AppActions {
    actions$ = new Subject<Action>();

    constructor(
        private store: Store<AppState>) {
    }

    /**
     * WARNING!!! call this method before you dispatch new Action
     * returns first one
     */
    ofType(type: string | string[]) {
        if (typeof type === 'string') {
            type = [type];
        }
        return this.actions$.pipe(filter((action: Action) => type.includes(action.type)), take(1));
    }

    dispatch<T extends Action>(action: T): Observable<T>;
    dispatch(action: Action, actionToListen: string, ...otherActions: string[]): Observable<Action>;
    dispatch(action: Action, actionsToListen: string[]): Observable<Action>;

    /**
     * It is not necessary to unsubscribe
     */
    dispatch(action: Action, actionsToListen: string[] | string = null, ...otherActions: string[]): Observable<Action> {
        if (!actionsToListen) {
            actionsToListen = [action.type];
        } else if (Array.isArray(actionsToListen)) {
            actionsToListen = [...actionsToListen, ...otherActions];
        } else {
            actionsToListen = [actionsToListen, ...otherActions];
        }
        return new Observable((subscriber) => {
            this.ofType(actionsToListen).subscribe((a) => {
                subscriber.next(a);
                subscriber.complete();
            });
            this.store.dispatch(action);
        });
    }
}
