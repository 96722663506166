import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import * as FixedToolbarActions from '../../fixed-toolbar/fixed-toolbar.action';
import {AppState} from '../../store/app.reducer';
import {SetBreadcrumb} from '../../fixed-toolbar/fixed-toolbar.action';

@Component({
    selector: 'app-area-of',
    templateUrl: './area-of.component.html',
    styleUrls: ['./area-of.component.css']
})
export class AreaOfComponent extends BaseComponent implements OnInit {

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = forkJoin([
            this.translate.get('area_of.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/areas-of'},
            ]));
        });
    }
}
