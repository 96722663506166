import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {AuthLogout} from './store/auth.actions';


@Injectable({providedIn: 'root'})
export class AuthService {
    private expirationTimer: any = null;

    constructor(private http: HttpClient,
                private router: Router,
                private store: Store<AppState>) {
    }

    setLogoutTimer(expirationDuration: number) {
        this.expirationTimer = setTimeout(() => {
            this.store.dispatch(new AuthLogout());
        }, expirationDuration);
    }

    clearLogoutTimer() {
        if (this.expirationTimer) {
            clearTimeout(this.expirationTimer);
            this.expirationTimer = null;
        }
    }

}
