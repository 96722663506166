import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Params} from '@angular/router';
import {ProxyService} from '../../../shared/services/proxy.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {GlobalService} from '../../../shared/services/global.service';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {SubjectFetch} from '../store/subjects.actions';

@Component({
    selector: 'app-subject-detail',
    templateUrl: './subject-detail.component.html',
    styleUrls: ['./subject-detail.component.css']
})
export class SubjectDetailComponent extends BaseComponent implements OnInit, OnDestroy {

    private editId = null;
    public loadedSubject: Subject;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected route: ActivatedRoute,
        protected proxyService: ProxyService,
        private translate: TranslateService,
        private ps: CustomPermissionsService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.fetchSubject(this.editId);

            this.buttons = [];

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createSubjects').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.buttons.push(
                            {
                                id: 'createSubjectButton',
                                name: 'subjects.create',
                                action: 'onCreateSubject',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }
                        );
                    }
                    this.store.dispatch(new SetToolbarButtons(this.buttons));
                });
            });
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchSubject(subjectId: number) {
        this.subs.sink = this.store.select('subjects').subscribe(state => {
            this.loadedSubject = state.loadedSubject;
        });
        this.store.dispatch(new SubjectFetch({subjectId}));
    }

}
