import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    public static storeKeyValuePair(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static getKeyValuePair<U>(key) {
        return JSON.parse(localStorage.getItem(key)) as U;
    }
}
