import * as OshSubjectActions from './osh-subject.actions';
import {OshSubjectActionTypes} from './osh-subject.actions';
import {SubjectDetails} from '../../../../../generated/graphql';

export interface State {
    subject: SubjectDetails;
}

const initialState = {
    subject: null,
};

export function oshSubjectReducer(state: State = initialState, action: OshSubjectActions.OshSubjectActions) {
    switch (action.type) {
        case OshSubjectActionTypes.OshSubjectSet:
            state =  {
                ...state,
                subject: action.payload.oshSubject,
            };
            break;
        case OshSubjectActionTypes.OshSubjectError:
            state =  {
                ...state,
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
