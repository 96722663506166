import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ConPointService, Project} from '../../../generated/graphql';
import {map} from 'rxjs/operators';
import {BaseComponent} from '../../shared/base/base.component';
import {GlobalService} from '../../shared/services/global.service';
import {ProxyService} from '../../shared/services/proxy.service';
import {StatisticsService} from '../../shared/services/statistics.service';
import {TagTypes} from '../../shared/enums/entity-enums';
import {Router} from '@angular/router';
import {currentStateChooseSubjectProject, currentStateGetCurrentProject} from '../../store/current-state/current-state.selector';
import {getLoadedProject} from '../projects/store/projects.selector';
import {AppState} from '../../store/app.reducer';
import {SetBreadcrumb, SetToolbarButtons} from '../../fixed-toolbar/fixed-toolbar.action';
import {ProjectFetch} from '../projects/store/projects.actions';
import {CustomPermissionsService} from '../../shared/services/custom-permissions.service';
import {AppMainComponent} from '../../app.main.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit {

    public displayDashboard = false;
    public loadedProject: Project;
    public stateStatistics: {
        pending: [0, 0, 0],
        inProgress: [0, 0, 0],
        waitingForApproval: [0, 0, 0],
        approved: [0, 0, 0],
        afterDeadline: [0, 0]
    };
    role = 1000;

    constructor(
        public appMain: AppMainComponent,
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected conPointService: ConPointService,
        protected proxyService: ProxyService,
        protected statisticsService: StatisticsService,
        protected ps: CustomPermissionsService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.store.dispatch(new SetBreadcrumb([
            {label: 'Dashboard', routerLink: '/'}
        ]));

        if (this.appMain.isDesktop()) {
            this.store.dispatch(new SetToolbarButtons([]));

            this.subs.sink = this.store.select(currentStateChooseSubjectProject).subscribe((chooseSubjectProject) => {
                if (!chooseSubjectProject) {
                    this.displayDashboard = true;
                    this.subs.sink = this.store.select(getLoadedProject).pipe(
                        map(projectState => {
                            return {loadedProject: projectState.project};
                        })).subscribe(
                        ({loadedProject: loadedProject}) => {
                            this.loadedProject = loadedProject;
                        });

                    this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                        this.subs.sink = this.store.pipe(select(currentStateGetCurrentProject)).subscribe(
                            (project) => {
                                if (project) {
                                    this.role = this.ps.getProjectRole(project);

                                    this.proxyService.getStateStatistics().then(stats => {
                                        this.stateStatistics = stats as
                                            {
                                                pending: [0, 0, 0],
                                                inProgress: [0, 0, 0],
                                                waitingForApproval: [0, 0, 0],
                                                approved: [0, 0, 0],
                                                afterDeadline: [0, 0],
                                            };
                                    });
                                    this.store.dispatch(new ProjectFetch({projectId: project.id}));
                                } else {
                                    this.stateStatistics = null;
                                }
                            });
                    });
                }
            });
        }
    }


    onDownloadDocument(hash: string) {
        window.open(
            this.gs.getEnvironment().mediaUrl + 'media/' + hash,
            '_blank'
        );
    }

    onStateClick(tagType: number, state: number) {
        this.statisticsService.setTagFilterState(tagType, state);
        let urlPart = '';
        switch (tagType) {
            case TagTypes.TAG_DEFECT:
                urlPart = 'defects';
                break;
            case TagTypes.TAG_TASK:
                urlPart = 'tasks';
                break;
            case TagTypes.TAG_GOOD_PRACTICE:
                urlPart = 'good-practice';
                break;
        }
        this.router.navigate(['/', urlPart]);
    }

    async onDownloadApp() {
        await this.appMain.onDownloadApp();
    }
}
