import {Action} from '@ngrx/store';
import {LoadingAction} from '../../../loading';
import {MessageAction} from '../../../loading/loading.model';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Permissions} from '../../../../generated/graphql';
import {TagDefectsActionTypes} from '../../tags/tag-defects/store/tag-defects.actions';

export enum AuthActionTypes {
    AuthAutoLogin = '[@Auth] AutoLogin',
    AuthLogin = '[@Auth] Login',
    AuthForgetPassword = '[@Auth] ForgetPassword',
    AuthPasswordReset = '[@Auth] PasswordReset',
    AuthLoginSuccess = '[@Auth] LoginSuccess',
    AuthPermissionsFetch = '[@Auth] PermissionsFetch',
    AuthPermissionsFetchSuccess = '[@Auth] PermissionsFetchSuccess',
    AuthLogout = '[@Auth] Logout',
    AuthError = '[@Auth] Error',
    AuthSuccess = '[@Auth] Success',
}

export class AuthLogin implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthLogin;
    fxLoading = {
        action: 'add',
        ids: [{name: AuthActionTypes.AuthLogin, spinner: 'modalLoading'}],
        type: 'global'
    };
    constructor(public payload: {email: string, password: string}) {}
}

export class AuthLoginSuccess implements Action, LoadingAction, MessageAction {
  readonly type = AuthActionTypes.AuthLoginSuccess;

    fxLoading = {
        action: 'remove',
        ids: [{name: AuthActionTypes.AuthLogin, spinner: 'modalLoading'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('users.user-login-successful')],
        type: 'global'
    };

    constructor(public payload: {
        email: string,
        userId: number,
        token: string,
        expirationDate: Date,
        redirect: boolean
    }) {}
}

export class AuthSuccess implements Action, LoadingAction, MessageAction {
    readonly type = AuthActionTypes.AuthSuccess;

    fxLoading = {
        action: 'remove',
        ids: [
            {name: AuthActionTypes.AuthForgetPassword, spinner: 'modalLoading'},
            {name: AuthActionTypes.AuthPasswordReset, spinner: 'modalLoading'}
        ],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [],
        type: 'global'
    };

    constructor(successMessage: string) {
        this.fxMessage.messages.push(FlashMessage.successMessage(successMessage));
    }
}

export class AuthForgetPassword implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthForgetPassword;
    fxLoading = {
        action: 'add',
        ids: [{name: AuthActionTypes.AuthForgetPassword, spinner: 'modalLoading'}],
        type: 'global'
    };
    constructor(public payload: {email: string}) {}
}

export class AuthPasswordReset implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthPasswordReset;
    fxLoading = {
        action: 'add',
        ids: [{name: AuthActionTypes.AuthPasswordReset, spinner: 'modalLoading'}],
        type: 'global'
    };
    constructor(public payload: {hash: string, password: string}) {}
}

export class AuthPermissionsFetch implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthPermissionsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: AuthActionTypes.AuthPermissionsFetch, spinner: 'modalLoading'}],
        type: 'global'
    };
    constructor() {}
}

export class AuthPermissionsFetchSuccess implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthPermissionsFetchSuccess;

    fxLoading = {
        action: 'remove',
        ids: [{name: AuthActionTypes.AuthPermissionsFetch, spinner: 'modalLoading'}],
        type: 'global'
    };

    constructor(public payload: {
        permissions: string
    }) {}
}

export class AuthLogout implements Action {
    readonly type = AuthActionTypes.AuthLogout;
}

export class AuthError implements Action, LoadingAction {
    readonly type = AuthActionTypes.AuthError;

    fxLoading = {
        action: 'remove',
        ids: [
            {name: AuthActionTypes.AuthLogin, spinner: 'modalLoading'},
            {name: AuthActionTypes.AuthForgetPassword, spinner: 'modalLoading'},
            {name: AuthActionTypes.AuthPasswordReset, spinner: 'modalLoading'},
            {name: AuthActionTypes.AuthPermissionsFetch, spinner: 'modalLoading'},
        ],
        type: 'all'
    };

    constructor() {}
}

export class AuthAutoLogin implements Action {
  readonly type = AuthActionTypes.AuthAutoLogin;
}

export type AuthActions =
    AuthLoginSuccess |
    AuthLogout |
    AuthLogin |
    AuthError |
    AuthAutoLogin |
    AuthPermissionsFetchSuccess;
