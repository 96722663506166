import {AddMessage} from '../../loading/loading.action';
import {FlashMessage} from '../model/flash-message.model';
import {TagsService} from '../../components/tags/tags.service';
import {Store} from '@ngrx/store';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MediaFileStateEnum} from '../enums/media-file-state-enum';
import {MediaFileInput} from '../../../generated/graphql';
import {AppState} from '../../store/app.reducer';

@Injectable()
export class UploadManager {

    private uploadedFiles: Map<string, string> = new Map();

    @Output() uploadingState: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private store: Store<AppState>,
        private tagsService: TagsService,
        private type?: string
    ) {
    }

    uploadImages(event: any) {

        this.uploadingState.emit(true);

        this.tagsService.uploadImages(event.files).then((data) => {
            data.forEach((file) => {
                this.uploadedFiles.set(file.filename, file.id + '-' + file.filename);
            });

            this.uploadingState.emit(false);

            this.store.dispatch(new AddMessage([FlashMessage.successMessage('images.uploaded')], 'global'));
        });
    }

    removeImage(event: any) {
        const fileToDelete = this.uploadedFiles.get(event.file.name);

        if (fileToDelete) {
            this.uploadingState.emit(true);
            this.tagsService.deleteImage(fileToDelete).then(() => {
                this.uploadedFiles.delete(event.file.name);
                this.uploadingState.emit(false);
                this.store.dispatch(new AddMessage([FlashMessage.successMessage('images.removed')], 'global'));
            });
        }
    }

    clearUploadedFiles(event: any) {
        const filesToBeRemoved = Array.from(this.uploadedFiles).map(([key, value]) => value);
        if (filesToBeRemoved.length > 0) {
            this.uploadingState.emit(true);
            Promise.all(filesToBeRemoved.map(async (file: any) => {
                this.uploadedFiles.delete(file);
                return this.tagsService.deleteImage(file);
            })).then(() => {
                this.uploadingState.emit(false);
                this.uploadedFiles.clear();
                this.store.dispatch(new AddMessage([FlashMessage.successMessage('images.removed')], 'global'));
            });
        }
    }

    clearUploadedFilesList() {
        this.uploadedFiles.clear();
    }

    getFilesNameArray() {
        return Array.from(this.uploadedFiles).map(([key, value]) => {
            const mediaFile: MediaFileInput = {
                fileName: value,
                fileState: MediaFileStateEnum.STATE_NEW
            };
            return mediaFile;
        });
    }

}
