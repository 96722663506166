import {
    CHOOSE_SUBJECT_PROJECT_DIALOG_HIDE,
    CHOOSE_SUBJECT_PROJECT_DIALOG_SHOW,
    ChooseSubjectProjectActions
} from './choose-subject-project.actions';

export interface State {
    dialogShow: boolean;
}

const initialState = {
    dialogShow: false,
};

export function chooseSubjectProjectReducer(state: State = initialState, action: ChooseSubjectProjectActions) {
    switch (action.type) {
        case CHOOSE_SUBJECT_PROJECT_DIALOG_SHOW:
            state = {
                ...state,
                dialogShow: true
            };
            break;
        case CHOOSE_SUBJECT_PROJECT_DIALOG_HIDE:
            state = {
                ...state,
                dialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
