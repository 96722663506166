import {NgModule} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {AppState} from './store/app.reducer';
import {AddMessage} from './loading/loading.action';
import {FlashMessage} from './shared/model/flash-message.model';
import {createUploadLink} from 'apollo-upload-client';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {ApolloLink, InMemoryCache} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {HttpLink} from 'apollo-angular/http';

const uriCommon = environment.apiUrl + 'graphql/common'; // <-- add the URL of the GraphQL server here
const uriAuth   = environment.apiUrl + 'graphql/auth'; // <-- add the URL of the GraphQL server here
const uriImages = environment.apiImagesUrl + 'graphql'; // <-- add the URL of the GraphQL server here

@NgModule({
  exports: [HttpClientModule],
  providers: [],
})
export class GraphQLModule {
    constructor(
        private store: Store<AppState>,
        apollo: Apollo,
        httpLink: HttpLink
    ) {

        const cleanTypeName = new ApolloLink((operation, forward) => {
            if (operation.variables) {
                const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
                operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
            }
            return forward(operation).map((data) => {
                return data;
            });
        });

        const authMiddleware = setContext((_, {headers, ...context}) => {
            // Get the authentication token from local storage if it exists
            const userData: {
                email: string;
                id: number;
                pToken: string;
                pTokenExpirationDate: string;
            } = JSON.parse(localStorage.getItem('user-data'));

            return {
                headers: {
                    Authorization: `Bearer ${userData.pToken}`
                },
            };
        });

        const variablesMiddleware = setContext(async (operation) => {
            const userData: {
                id
            } = JSON.parse(localStorage.getItem('user-data'));

            const userStorage: {
                currentSubject,
                currentProject
            } = JSON.parse(localStorage.getItem('user-storage-' + userData.id));

            const currentSubject = 'currentSubject';
            const currentProject = 'currentProject';

            operation.variables[currentProject] = userStorage?.currentProject?.id ?? null;
            operation.variables[currentSubject] = userStorage?.currentSubject?.id ?? null;

            return operation;
        });

        const onErrorMiddleware = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path }) => {
                        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                        this.store.dispatch(new AddMessage([FlashMessage.errorMessage(message)], 'global'));
                    }
                );
            }

            if (networkError) {
                // console.log(`[Network error]: ${networkError}`);
                this.store.dispatch(new AddMessage([FlashMessage.errorMessage(networkError.message)], 'global'));
            }
        });

        apollo.create({
            link: ApolloLink.from([authMiddleware, variablesMiddleware, cleanTypeName, onErrorMiddleware, httpLink.create({uri: uriCommon})]),
            cache: new InMemoryCache({addTypename: true}),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            }
        });

        apollo.createNamed('auth', {
            link: ApolloLink.from([onErrorMiddleware, httpLink.create({uri: uriAuth})]),
            cache: new InMemoryCache({addTypename: false}),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            }
        });

        apollo.createNamed('images', {
            link: ApolloLink.from([authMiddleware, onErrorMiddleware, createUploadLink({uri: uriImages})]),
            cache: new InMemoryCache({addTypename: false}),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            }
        });
    }
}
