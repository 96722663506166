import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TagBaseComponent} from '../tag-base/tag-base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {GlobalService} from '../../../shared/services/global.service';
import {TagsService} from '../tags.service';
import {UploadManager} from '../../../shared/classes/upload-manager';
import {Subscription} from 'rxjs';
import {DialogHide} from '../../../forms/tags/defect-create/store/defect-create-form.actions';
import {GenericText} from '../../../../generated/graphql';
import * as GeneralTypeActions from '../../../forms/generic-text-form/store/generic-text-form.actions';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {Gallery} from '@ngx-gallery/core';

@Component({
    selector: 'app-tag-dialog-base-component',
    templateUrl: './tag-dialog-base-component.component.html',
    styleUrls: ['./tag-dialog-base-component.component.css']
})
export class TagDialogBaseComponentComponent extends TagBaseComponent implements OnInit {

    display: boolean;
    public uploadingState = false;
    public uploadManager: UploadManager;
    protected imagesStored = false;


    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected tagsService: TagsService,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
        protected gallery: Gallery,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {
    }

    onClear() {
        const project = this.tagForm.value.project;
        this.tagForm.resetForm();
        this.selectedProject = project;
        this.tagForm.form.patchValue({
            project: this.selectedProject
        });
    }
}
