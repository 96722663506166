import * as GenericTextFormActions from './generic-text-form.actions';
import {GenericText} from '../../../../generated/graphql';

export interface State {
    dialogShow: boolean;
    action: string;
    genericText: GenericText;
}

const initialState = {
    dialogShow: false,
    action: null,
    genericText: {name: '', description: '', precautionSuggestion: ''} as GenericText,
};

export function genericTextReducer(state: State = initialState, action: GenericTextFormActions.GenericTextFormActions) {
    switch (action.type) {
        case GenericTextFormActions.GENERIC_TEXT_FORM_ADDED:
            state = {
                ...state,
                genericText: {...action.payload.data},
            };
            break;
        case GenericTextFormActions.GENERIC_TEXT_FORM_DIALOG_SHOW:
            state = {
                ...state,
                action: action.payload.action,
                dialogShow: true
            };
            break;
        case GenericTextFormActions.GENERIC_TEXT_FORM_DIALOG_HIDE:
            state = {
                ...state,
                dialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
