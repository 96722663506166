import { Injectable } from '@angular/core';
import {
    AreaOf,
    Contact,
    ContractCategory,
    ContractCategoryInput,
    Country,
    ConPointService, Project,
    Subject,
    SubjectAres,
    User, ProjectKeyNameResult, AreaOfInput, GenericText, GenericTextInput
} from '../../../generated/graphql';
import {LazyLoadMeta} from '../model/lazy-load-meta.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {SubjectType} from '../model/subject-type.model';
import {ConPointAuthService, SubjectValidation} from '../../../generated/graphql_auth';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {

    constructor(
        private store: Store<AppState>,
        private conPointService: ConPointService,
        private conPointAuthService: ConPointAuthService
    ) {}

    getContractCategory(name: string) {
        return this.conPointService.getContractCategory({name})
            .toPromise()
            .then(res => res.data.GetContractCategory as ContractCategory)
            .then(data => data);
    }

    getContractCategoryList(lazy: LazyLoadMeta) {
        return this.conPointService.getContractCategoryList({lazyLoad: lazy})
            .toPromise()
            .then(res => res.data.GetContractCategoryList.result as ContractCategory[])
            .then(data => data);
    }

    createContractCategory(contractCategory: ContractCategoryInput) {
        return this.conPointService.createContractCategory({contractCategory})
            .toPromise()
            .then(res => res.data.CreateContractCategory as ContractCategory)
            .then(data => data);
    }

    getSubjectsList(lazy: LazyLoadMeta, subjectType: SubjectType) {
        return this.conPointService.getSubjectsList({lazyLoad: lazy, type: subjectType})
            .toPromise()
            .then(res => res.data.GetSubjectsList.result as Subject[])
            .then(data => data);
    }

    getSubjectsListOptions(subjectType: SubjectType) {
        return this.getSubjectsList(null, subjectType).then(data => {
            return data.map(record => ({
                label: record.name,
                value: {id: record.id, name: record.name}
            }));
        });
    }

    getSubjectsProjectList(lazy: LazyLoadMeta, projectId: number, subjectType: SubjectType) {
        return this.conPointService.subjectsProjectList({lazyLoad: lazy, projectId, type: subjectType})
            .toPromise()
            .then(res => res.data.GetSubjectsList.result as Subject[])
            .then(data => data);
    }

    getSubjectAres(companyCode: string, countryIsoCode2: string) {

        return this.conPointService.getSubjectAresInfo({companyCode, countryIsoCode2})
            .toPromise()
            .then(res => res.data.GetAresSubjectInfo as SubjectAres)
            .then(data => data);
    }

    validateSubject(companyCode: string, countryIsoCode2: string) {

        return this.conPointAuthService.validateSubject({companyCode, countryIsoCode2})
            .toPromise()
            .then(res => res.data.ValidateSubject as SubjectValidation)
            .then(data => data);
    }

    getCountries(lazy: LazyLoadMeta) {
        return this.conPointService.getCountryList({lazyLoad: lazy})
            .toPromise()
            .then(res => res.data.GetCountryList.result as Country[])
            .then(data => data);
    }

    getUsersList(lazy: LazyLoadMeta) {
        return this.conPointService.getUsersDropdown({lazyLoad: lazy})
            .toPromise()
            .then(res => res.data.GetUsersList.result as User[])
            .then(data => data);
    }

    getUsersListBySubject(lazy: LazyLoadMeta, subjectIds: number[]) {
        return this.conPointService.getUsersBySubjectDropdown({lazyLoad: lazy, subjectIds})
            .toPromise()
            .then(res => res.data.GetUsersList.result as User[])
            .then(data => data);
    }

    getProjectUsersList(projectId: number) {
        return this.conPointService.getProjectUsers({projectId})
            .toPromise()
            .then(res => res.data.GetProjectUsers as User[])
            .then(data => data);
    }

    getContactsList(lazy: LazyLoadMeta) {
        return this.conPointService.getContactsList({lazyLoad: lazy})
            .toPromise()
            .then(res => res.data.GetContactsList.result as Contact[])
            .then(data => data);
    }

    getProjectsList(lazy: LazyLoadMeta) {
        return this.conPointService.getProjectsDropdown({lazyLoad: lazy})
            .toPromise()
            .then(res => res.data.GetProjectsList.result as Project[])
            .then(data => data);
    }

    getAreaOf(name: string, type: number) {
        return this.conPointService.getAreaOfByName({name, type})
            .toPromise()
            .then(res => res.data.GetAreaOfByName as AreaOf)
            .then(data => {
                return {data, name};
            });
    }

    createAreaOf(areaOf: AreaOfInput) {
        return this.conPointService.createAreaOf({areaOf})
            .toPromise()
            .then(res => res.data.CreateAreaOf as AreaOf)
            .then(data => data);
    }

    getAreaOfList(lazy: LazyLoadMeta, type: number) {
        return this.conPointService.getAreasOfList({lazyLoad: lazy, type})
            .toPromise()
            .then(res => res.data.GetAreasOfList.result as AreaOf[])
            .then(data => data);
    }

    getGenericTextsList(lazy: LazyLoadMeta, type: number, areaOf: number) {
        return this.conPointService.getGenericTextsList({lazyLoad: lazy, type, areaOf})
            .toPromise()
            .then(res => res.data.GetGenericTextsList.result as GenericText[])
            .then(data => data);
    }

    getUserPermissions() {
        return this.conPointService.getPermissions()
            .toPromise()
            .then(res => res.data.GetPermissions.permissions as string)
            .then(data => data);
    }

    checkProjectKeyName(projectName: string, checkKeyName: string) {
        return this.conPointService.getProjectKeyName({projectName, checkKeyName})
            .toPromise()
            .then(res => res.data.GetProjectKeyName as ProjectKeyNameResult)
            .then(data => data);
    }

    getGenericTextByName(name: string, type: number) {
        return this.conPointService.getGenericTextByName({name, type})
            .toPromise()
            .then(res => res.data.GetGenericTextByName as GenericText)
            .then(data => {
                return {data, name};
            });
    }

    createGenericText(genericText: GenericTextInput) {
        return this.conPointService.createGenericText({genericText})
            .toPromise()
            .then(res => res.data.CreateGenericText as GenericText)
            .then(data => data);
    }

    sendTag(type: number, id: number, emails: string[]) {
        return this.conPointService.sendEntity({type, id, emails})
            .toPromise()
            .then(res => res.data.SendEntity)
            .then(data => data);
    }

    sendInvitation(emails: string[]) {
        return this.conPointService.sendInvitation({emails})
            .toPromise()
            .then(res => res.data.SendInvitation)
            .then(data => data);
    }

    getStateStatistics() {
        return this.conPointService.getStateStatistics()
            .toPromise()
            .then(res => res.data.GetStateStatistics)
            .then(data => data);
    }

    getReportLVSettings() {
        return this.conPointService.getReportLvSettings()
            .toPromise()
            .then(res => res.data.GetReportLVSettings)
            .then(data => data);
    }
}
