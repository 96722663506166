import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {GlobalService} from '../../shared/services/global.service';
import {NgForm} from '@angular/forms';
import {StorageService} from '../../shared/services/storage.service';
import {AppActions} from '../../store/app-actions.service';
import {UserStorage} from '../../components/auth/user-storage.model';
import {Project, Subject} from '../../../generated/graphql';
import {
    CurrentStateActionTypes, CurrentStateChooseSubjectProject, CurrentStateReload,
    CurrentStateSubjectsProjectsFetch,
    CurrentStateSubjectsProjectsSet
} from '../../store/current-state/current-state.actions';
import {User} from '../../components/auth/user.model';
import {ChooseSubjectProjectDialogHide} from './store/choose-subject-project.actions';

@Component({
    selector: 'app-choose-subject-project',
    templateUrl: './choose-subject-project.component.html',
    styleUrls: ['./choose-subject-project.component.css']
})
export class ChooseSubjectProjectComponent extends BaseComponent implements OnInit {

    @ViewChild('f') dialogForm: NgForm;
    display: boolean;

    public appSubjectList: Subject[];
    public selectedSubject: Subject;
    public selectedSubjectOption: number;

    public appProjectList: Project[];
    public selectedProject: Project = null;
    public selectedProjectOption: number;

    private closingDialog = true;

    constructor(protected store: Store<AppState>,
                protected gs: GlobalService,
                protected storage: StorageService,
                protected appActions: AppActions
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.store.select('chooseSubjectProject').pipe().subscribe((data) => {
            if (this.display !== data.dialogShow) {
                this.display = data.dialogShow;
                this.fetchSubjects();
            }
        });
    }

    fetchSubjects() {
        this.appActions.dispatch(
            new CurrentStateSubjectsProjectsFetch(),
            [CurrentStateActionTypes.CurrentStateSubjectsProjectsSet]
        ).subscribe((a) => {
            if (a instanceof CurrentStateSubjectsProjectsSet) {
                const userData: User = JSON.parse(localStorage.getItem('user-data'));
                this.appSubjectList = [...a.payload.subjects];

                const userStorage: UserStorage = JSON.parse(localStorage.getItem('user-storage-' + userData.id));
                let selectedSubject = null;
                if (userStorage) {
                    if (userStorage.currentSubject) {
                        selectedSubject = userStorage.currentSubject;
                    }
                }

                const subjectExists = this.appSubjectList?.find(x => x.id === selectedSubject?.id);
                if (!subjectExists) {
                    selectedSubject = this.appSubjectList[0];
                }
                this.appProjectList = [...this.appSubjectList.find(x => x.id === selectedSubject.id)?.projects];

                this.selectedSubject = selectedSubject;
                this.selectedSubjectOption = selectedSubject?.id;
            }
        });
    }

    onChangeOshSubject(event: any) {
        this.selectedSubject = this.appSubjectList.find(x => x.id === event.value);
        this.selectedSubjectOption = event.value;
        this.appProjectList = this.selectedSubject?.projects;
    }

    onProjectSelect(event: any) {
        this.selectedProject = this.appProjectList.find(x => x.id === event.value.id);

        this.saveCurrentSubject();
        this.saveCurrentProject();

        this.closingDialog = false;

        this.appActions.dispatch(new CurrentStateChooseSubjectProject({value: false})).toPromise().then(() => {
            this.store.dispatch(new CurrentStateReload({value: true}));
            this.store.dispatch(new ChooseSubjectProjectDialogHide());
        });
    }

    saveCurrentProject() {
        const user: {
            email: string;
            id: number;
            token: string;
            tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));

        let userStorageX: {
            currentSubject: Subject;
            currentProject: Project;
        } = JSON.parse(localStorage.getItem('user-storage-'  + user.id));

        userStorageX = {
            ...userStorageX,
            currentProject: this.selectedProject
        };
        localStorage.setItem('user-storage-' + user.id, JSON.stringify(userStorageX));
    }

    saveCurrentSubject() {
        const user: {
            email: string;
            id: number;
            token: string;
            tokenExpirationDate: string;
        } = JSON.parse(localStorage.getItem('user-data'));

        let userStorageX: {
            currentSubject: Subject;
            currentProject: Project;
        } = JSON.parse(localStorage.getItem('user-storage-'  + user.id));

        userStorageX = {
            ...userStorageX,
            currentSubject: this.selectedSubject
        };
        localStorage.setItem('user-storage-' + user.id, JSON.stringify(userStorageX));
    }

    onHide() {
        if (this.closingDialog) {
            this.appActions.dispatch(new CurrentStateChooseSubjectProject({value: false})).toPromise().then(() => {
                this.store.dispatch(new CurrentStateReload({value: true}));
                this.store.dispatch(new ChooseSubjectProjectDialogHide());
            });
        }
    }
}
