import {Action} from '@ngrx/store';

export const SEND_FORM_DIALOG_SHOW = '[@Send] DialogShow';
export const SEND_FORM_DIALOG_HIDE = '[@Send] DialogHide';

export class DialogShow implements Action {
    readonly type = SEND_FORM_DIALOG_SHOW;
    constructor(public payload: {type: number, id: number, identifier: string}) {}
}

export class DialogHide implements Action {
    readonly type = SEND_FORM_DIALOG_HIDE;
}

export type SendFormActions =
    DialogShow |
    DialogHide;
