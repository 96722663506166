import {Action} from '@ngrx/store';

export const TASK_CREATE_FORM_DIALOG_SHOW = '[@TaskCreate] DialogShow';
export const TASK_CREATE_FORM_DIALOG_HIDE = '[@TaskCreate] DialogHide';

export class DialogShow implements Action {
    readonly type = TASK_CREATE_FORM_DIALOG_SHOW;
}

export class DialogHide implements Action {
    readonly type = TASK_CREATE_FORM_DIALOG_HIDE;
}

export type TaskCreateFormActions =
    DialogShow |
    DialogHide;
