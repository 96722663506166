import {Action} from '@ngrx/store';

export const DEFECT_CREATE_FORM_DIALOG_SHOW = '[@DefectCreate] DialogShow';
export const DEFECT_CREATE_FORM_DIALOG_HIDE = '[@DefectCreate] DialogHide';

export class DialogShow implements Action {
    readonly type = DEFECT_CREATE_FORM_DIALOG_SHOW;
}

export class DialogHide implements Action {
    readonly type = DEFECT_CREATE_FORM_DIALOG_HIDE;
}

export type DefectCreateFormActions =
    DialogShow |
    DialogHide;
