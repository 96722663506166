import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormsHelperService} from '../../../forms/forms-helper.service';
import {NgForm} from '@angular/forms';
import {UserInput} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {AddLoading, AddMessage, RemoveLoading} from '../../../loading/loading.action';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {GlobalService} from '../../../shared/services/global.service';
import {ProxyService} from '../../../shared/services/proxy.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {SubjectOshInput, SubjectValidation} from '../../../../generated/graphql_auth';
import {Message, MessageService} from 'primeng/api';
import {SubjectCreateOsh, SubjectResendOshConfirmation} from '../store/subjects.actions';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-subject-create',
    templateUrl: './subject-create.component.html',
    styleUrls: ['./subject-create.component.css']
})
export class SubjectCreateComponent extends BaseComponent implements OnInit {
    @ViewChild('f') subjectCreateForm: NgForm;

    public subjectValidation: SubjectValidation;
    public validationMessages: Message[];
    public action?: string = null;
    public defaultLicenceCount: number = 1;
    public tosChecked: boolean = false;
    public webUrl: string = null;

    constructor(
        public formsHelper: FormsHelperService,
        public store: Store<AppState>,
        protected gs: GlobalService,
        protected proxyService: ProxyService,
        protected translate: TranslateService,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.webUrl = this.gs.getEnvironment().webUrl;

        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.action = params.action;
        });
    }

    onSubmit(form: NgForm) {
        const subject: SubjectOshInput = {
            companyCode: form.value.companyCode,
            countryIsoCode2: 'CZ',
            licenceCount: form.value.licenceCount,
            administrators: [form.value.adminEmail]
        };
        this.store.dispatch(new SubjectCreateOsh({subject,  redirect: false}));
    }

    checkCompanyCode() {
        this.validationMessages = [];
        if (!this.subjectCreateForm.value.companyCode) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage(this.translate.instant('subjects.company-code-required'))], 'global'));
        } else {
            this.store.dispatch(new AddLoading([{name: '@AresLoading', spinner: 'sp_al'}], 'global'));
            this.proxyService.validateSubject(
                this.subjectCreateForm.value.companyCode,
                'CZ'
            ).then(subjectValidation => {
                this.subjectValidation = subjectValidation;
                if (subjectValidation.isValid) {
                    if (subjectValidation.alreadyExists) {
                        if (subjectValidation.isConfirmed) {
                            this.validationMessages.push({
                                severity: 'error',
                                detail: this.translate.instant('accounts.company') + ': ' +
                                    subjectValidation.companyName + '<br /><br />' +
                                    this.translate.instant('subjects.account-already-exists')
                            });
                        } else {
                            this.validationMessages.push({
                                severity: 'warn',
                                detail: this.translate.instant('accounts.company') + ': ' +
                                    subjectValidation.companyName + '<br /><br />' +
                                    this.translate.instant('subjects.account-already-exists-but-not-confirmed')
                            });
                        }
                    } else {
                        this.validationMessages.push({
                            severity: 'success',
                            detail: this.translate.instant('accounts.company') + ': ' +
                                subjectValidation.companyName + '<br /><br />' +
                                this.translate.instant('subjects.subject-company-code-valid')
                        });
                    }
                } else {
                    this.validationMessages.push({
                        severity: 'error',
                        detail: this.translate.instant('subjects.subject-company-code-invalid')
                    });
                }

                this.validationMessages = [...this.validationMessages];

                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_al'}], 'global'));
            }).catch(reason => {
                this.store.dispatch(new RemoveLoading([{name: '@AresLoading', spinner: 'sp_al'}], 'global'));
            });
        }
    }

    backToHomePage() {
        this.document.location.href = this.gs.getEnvironment().baseUrl.replace('app.', '');
    }

    resendConfirmation() {
        this.validationMessages = [];
        this.subjectValidation = null;
        this.store.dispatch(new SubjectResendOshConfirmation({companyCode: this.subjectCreateForm.value.companyCode}));
    }
}
