import { Component } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {LazyLoadMeta} from '../../shared/model/lazy-load-meta.model';
import {Country} from '../../../generated/graphql';
import {ProxyService} from '../../shared/services/proxy.service';

@Component({
    selector: 'app-address-part',
    template: `
        <div class="card card-w-title" ngModelGroup="addressData">
            <div class="p-d-flex form-group">
                <div class="p-col-12">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="street">{{'address.street' | translate}}</label>
                            <input id="street"
                                   type="text"
                                   pInputText
                                   placeholder=""
                                   ngModel
                                   #street="ngModel"
                                   name="street"
                                   [required]="
                                       ((zip.dirty || zip.touched) && zip.value?.length > 0) ||
                                       ((city.dirty || city.touched) && city.value?.length > 0) ||
                                       ((country.dirty || country.touched) && country.value !== null)
                                   "
                                   [ngClass]="!street.valid ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="zip">{{'address.zip' | translate}}</label>
                            <input id="zip"
                                   type="text"
                                   pInputText
                                   placeholder=""
                                   ngModel
                                   #zip="ngModel"
                                   name="zip"
                                   [required]="
                                       ((street.dirty || street.touched) && street.value?.length > 0) ||
                                       ((city.dirty || city.touched) && city.value?.length > 0) ||
                                       ((country.dirty || country.touched) && country.value !== null)
                                   "
                                   [ngClass]="!zip.valid ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="city">{{'address.city' | translate}}</label>
                            <input id="city"
                                   type="text"
                                   pInputText
                                   placeholder=""
                                   ngModel=""
                                   #city="ngModel"
                                   name="city"
                                   [required]="
                                       ((zip.dirty || zip.touched) && zip.value?.length > 0) ||
                                       ((street.dirty || street.touched) && street.value?.length > 0) ||
                                       ((country.dirty || country.touched) && country.value !== null)
                                   "
                                   [ngClass]="!city.valid ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="country">{{'address.country' | translate}}</label>
                            <p-autoComplete ngModel id="country" name="country"
                                            [suggestions]="filteredCountries"
                                            (completeMethod)="filterCountries($event)"
                                            field="name"
                                            dataKey="isoCode"
                                            [size]="30"
                                            [minLength]="1"
                                            [dropdown]="true"
                                            #country="ngModel"
                                            [required]="
                                               ((zip.dirty || zip.touched) && zip.value?.length > 0) ||
                                               ((city.dirty || city.touched) && city.value?.length > 0) ||
                                               ((street.dirty || street.touched) && street.value?.length > 0)
                                            "
                                            [ngClass]="!country.valid ? 'ng-dirty ng-invalid' : ''"
                            >
                                <ng-template let-country pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div>{{country.name}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    `,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AddressPartComponent {
    public filteredCountries: Country[];

    constructor(private proxyService: ProxyService) {
    }

    filterCountries(event) {
        const query = event.query;
        const lazy = new LazyLoadMeta(0, 15, [], query, 'name', 1);
        this.proxyService.getCountries(lazy).then(countries => {
            this.filteredCountries = countries;
        });
    }
}
