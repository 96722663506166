import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserInput} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Params} from '@angular/router';
import {SubSink} from 'subsink';
import {AppState} from '../../../store/app.reducer';
import {UserFinishAccount} from '../store/users.actions';

@Component({
    selector: 'app-account-create',
    templateUrl: './account-create.component.html',
    styleUrls: ['./account-create.component.css']
})
export class AccountCreateComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    private accountCreationCode: string;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.accountCreationCode = params.accountCreationCode;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onSubmit(form: NgForm) {
        const user: UserInput = {
            id: null,
            firstName: form.value.userData.firstName,
            lastName: form.value.userData.lastName,
            email: null,
            phoneNumber: form.value.userData.phoneNumber,
            address: null,
            password: form.value.userData.password,
            blocked: false,
            emailLogin: true,
            googleLogin: false,
            facebookLogin: false,
            superadmin: false,
        };
        this.store.dispatch(new UserFinishAccount({user, accountCreationCode: this.accountCreationCode}));
    }
}
