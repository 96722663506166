import {Component, ComponentRef, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import {GlobalService} from '../services/global.service';
import {AppState} from '../../store/app.reducer';
import {ClearBreadcrumb, ClearToolbarButtons} from '../../fixed-toolbar/fixed-toolbar.action';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {
    protected subs = new SubSink();
    protected componentRef: ComponentRef<any>;
    public layoutContentHeight: number;
    public routerOutletActivated = false;
    public buttons = [];

    constructor(protected store: Store<AppState>, protected gs: GlobalService) {}

    ngOnInit(): void {
        this.subs.sink = this.gs.dimensions.subscribe((dimensions) => {
            if (dimensions) {
                let newHeight = dimensions.window.height - 340;
                if (dimensions.window.width > 991) {
                    newHeight = dimensions.window.height - 240;
                }

                if (this.layoutContentHeight !== newHeight) {
                    setTimeout(() => {
                        this.layoutContentHeight = newHeight;
                    }, 0);
                }
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new ClearToolbarButtons());
        this.store.dispatch(new ClearBreadcrumb());
        this.subs.unsubscribe();
    }

    onToolbarButtonClick(action: string) {
        const generalMethod = 'onToolbarButtonClick';
        if (this.componentRef[action] !== undefined) {
            this.componentRef[action]();
        } else if (this.componentRef[generalMethod]) {
            this.componentRef[generalMethod](action);
        } else {
            throw new Error('Button click handle not exists');
        }
    }

    onActivate(componentRef: ComponentRef<any>) {
        this.componentRef = componentRef;
        this.routerOutletActivated = true;
    }

    onDeactivated(componentRef: ComponentRef<any>) {
        this.routerOutletActivated = false;
    }

    onTextAreaContentChange(elData: HTMLTextAreaElement, el: HTMLSpanElement) {
        el.innerText = '(' + elData.maxLength + '/' + elData.textLength + ')';
    }
}
