import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {GlobalService} from '../../../shared/services/global.service';
import {forkJoin, Subscription} from 'rxjs';
import {SetBreadcrumb} from '../../../fixed-toolbar/fixed-toolbar.action';
import {TranslateService} from '@ngx-translate/core';
import {getOshSubject} from './store/osh-subject.selector';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {OshSubjectFetch, OshUserUnAssign} from './store/osh-subject.actions';
import {Project, SubjectDetails, User} from '../../../../generated/graphql';
import {UserFormDialogShow} from '../../../forms/user-form/store/user-form.actions';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-osh-subject',
    templateUrl: './osh-subject.component.html',
    styleUrls: ['./osh-subject.component.css']
})
export class OshSubjectComponent extends BaseComponent implements OnInit {

    public subject: SubjectDetails;
    public usersMenuItems = [];
    private userFormSubscription: Subscription;
    public selectedUser: User|null;
    public showDialog = false;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected translate: TranslateService,
        public ps: CustomPermissionsService,
        protected confirmationService: ConfirmationService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = forkJoin([
            this.translate.get('subjects.osh_subject'),
            this.translate.get('subjects.invite-user'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/settings/osh-subject'},
            ]));

            this.usersMenuItems = [
                {
                    label: result[1],
                    icon: 'pi pi-plus',
                    command: () => {
                        this.inviteUser();
                    }
                }
            ];
        });

        this.subs.sink = this.store.pipe(select(getOshSubject)).subscribe((subject) => {
            this.subject = subject;
            if (this.selectedUser) {
                this.onShowDialog(this.selectedUser.id);
            }

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            //     this.ps.hasPermission('createAreaOf').then((permission) => {
            //         this.buttons = [...this.buttons];
            //         if (permission) {
            //             this.store.dispatch(new UpdateToolbarButtons([
            //                 {
            //                     id: 'createAreaOfButton',
            //                     name: 'area_of.create',
            //                     action: 'onCreateAreaOf',
            //                     class: 'p-button-primary',
            //                     icon: 'pi pi-plus',
            //                     disabled: false,
            //                 }])
            //             );
            //         }
            //     });
            });
        });

        this.store.dispatch(new OshSubjectFetch());
        super.ngOnInit();
    }

    private inviteUser() {
        this.store.dispatch(new UserFormDialogShow());
        this.userFormSubscription = this.store.pipe(select('userForm')).subscribe((userForm) => {
            if (!userForm.userFormDialogShow) {
                this.store.dispatch(new OshSubjectFetch());
                this.userFormSubscription.unsubscribe();
            }
        });
    }

    onShowDialog(userId: number) {
        this.selectedUser = this.subject.users.find(x => x.id === userId);
        if (this.selectedUser !== null && this.selectedUser !== undefined) {
            this.showDialog = true;
        } else {
            this.onHide();
        }
    }

    onHide() {
        this.showDialog = false;
    }

    unAssignUser(userId: number, project: Project|null = null) {
        let confirmKey = 'subjects.un-assign-user-from-system';
        if (project !== null) {
            confirmKey = 'subjects.un-assign-user-from-project';
        }

        this.subs.sink = forkJoin([this.translate.get(confirmKey)]).subscribe(result => {
            this.showDialog = false;
            this.confirmationService.confirm({
                message: result[0] + (project !== null ? ' [' + project.contractName + ']' : ''),
                accept: () => {
                    this.store.dispatch(new OshUserUnAssign({
                        userId,
                        projectId: project?.id
                    }));
                    this.showDialog = true;
                },
                reject: () => {
                    this.showDialog = true;
                }
            });
        });
    }
}
