import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    GenericTextCreate, GenericTextDelete, GenericTextsExport,
    GenericTextsError,
    GenericTextSet,
    GenericTextsFetch,
    GenericTextsFetchLazyMeta,
    GenericTextsSet, GenericTextUpdate, GenericTextsExportSuccess
} from './generic-texts.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {
    GenericTextCreateSuccess,
    GenericTextDeleteSuccess,
    GenericTextFetch,
    GenericTextsActionTypes,
    GenericTextUpdateSuccess
} from './generic-texts.actions';
import {Router} from '@angular/router';
import {AppState} from '../../../store/app.reducer';
import {State} from './generic-texts.reducer';
import {DialogHide} from '../../../forms/generic-text-form/store/generic-text-form.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new GenericTextsError());
};

@Injectable()
export class GenericTextsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect()
    fetchGenericTexts = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextsFetch),
        switchMap((genericTextsActions: GenericTextsFetch) => {
            this.store.dispatch(new GenericTextsFetchLazyMeta({lazyLoad: genericTextsActions.payload.lazyLoad}));
            return this.conPointService.getGenericTextsList({
                lazyLoad: genericTextsActions.payload.lazyLoad,
                type: genericTextsActions.payload.type
            }).pipe(
                map((result) => {
                    const genericTexts = result.data.GetGenericTextsList.result?.map(genericText => {
                        return {...genericText};
                    });
                    return {
                        genericTexts,
                        total: result.data.GetGenericTextsList.metadata.total,
                        count: result.data.GetGenericTextsList.metadata.count
                    };
                }),
                map(({genericTexts, total, count}) => {
                    return new GenericTextsSet({genericTexts: genericTexts ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    genericTextFetchById = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextFetch),
        switchMap((genericTextsActions: GenericTextFetch) => {
            return this.conPointService.getGenericText({id: genericTextsActions.payload.id}).pipe(
                map((result) => {
                    return new GenericTextSet({genericText: result.data.GetGenericText});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    genericTextCreate = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextCreate),
        withLatestFrom(this.store.select('genericTexts')),
        switchMap<[GenericTextCreate, State], Observable<any>>(
            ([genericTextActions, genericTextsState]) => {
            return this.conPointService.createGenericText({genericText: genericTextActions.payload.genericText}).pipe(
                map(() => {
                    this.store.dispatch(new GenericTextsFetch({
                        lazyLoad: genericTextsState.currentLazyLoad,
                        type: genericTextActions.payload.genericText.type
                    }));
                    this.store.dispatch(new DialogHide());
                    return new GenericTextCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    genericTextUpdate = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextUpdate),
        withLatestFrom(this.store.select('genericTexts')),
        switchMap<[GenericTextUpdate, State], Observable<any>>(
            ([genericTextActions, genericTextsState]) => {

            const genericText = {...genericTextActions.payload.genericText};
            delete genericText['createdAt'];
            delete genericText['updatedAt'];

            return this.conPointService.updateGenericText({genericText}).pipe(
                map(() => {
                    this.store.dispatch(new GenericTextsFetch({
                        lazyLoad: genericTextsState.currentLazyLoad,
                        type: genericText.type
                    }));
                    this.store.dispatch(new DialogHide());
                    return new GenericTextUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    genericTextDelete = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextDelete),
        withLatestFrom(this.store.select('genericTexts')),
        switchMap<[GenericTextDelete, State], Observable<any>>(
            ([genericTextActions, genericTextsState]) => {
            return this.conPointService.deleteGenericText({id: genericTextActions.payload.id}).pipe(
                map(() => {
                    this.store.dispatch(new GenericTextsFetch({
                        lazyLoad: genericTextsState.currentLazyLoad,
                        type: genericTextActions.payload.type
                    }));
                    return new GenericTextDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    genericTextExport = this.actions$.pipe(
        ofType(GenericTextsActionTypes.GenericTextsExport),
        switchMap((genericTextsActions: GenericTextsExport) => {
            return this.conPointService.genericTextsExport().pipe(
                map((result) => {
                    return new GenericTextsExportSuccess({
                        type: result.data.GenericTextsExport.type,
                        fileContentBase64: result.data.GenericTextsExport.fileContentBase64
                    });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
