import {LazyLoadMeta} from '../../../../shared/model/lazy-load-meta.model';
import {TagTask} from '../../../../../generated/graphql';
import {TagTasksActions, TagTasksActionTypes} from './tag-tasks.actions';

export interface State {
    tasks: TagTask[];
    loadedTagTask: TagTask;
    taskFormDialogShow: boolean;
    totalRows: number;
    currentLazyLoad: LazyLoadMeta;
}

const initialState = {
    tasks: [],
    currentLazyLoad: null,
    loadedTagTask: null,
    taskFormDialogShow: false,
    totalRows: 0,
};

export function tagTasksReducer(state: State = initialState, action: TagTasksActions) {
    switch (action.type) {
        case TagTasksActionTypes.TagTaskCreateSuccess:
        case TagTasksActionTypes.TagTaskUpdateSuccess:
            state = {
                ...state
            };
            break;
        case TagTasksActionTypes.TagTasksSet:
            state = {
                ...state,
                tasks: [...action.payload.tasks],
                totalRows: action.payload.totalRows
            };
            break;
        case TagTasksActionTypes.TagTaskSet:
            state = {
                ...state,
                loadedTagTask: action.payload.task
            };
            break;
        case TagTasksActionTypes.TagTasksFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case TagTasksActionTypes.TagTasksError:
            state = {
                ...state
            };
            break;
        default:
            return state;
            break;
    }
    return state;
}
