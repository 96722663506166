import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../../shared/model/lazy-load-meta.model';
import {BaseComponent} from '../../../../shared/base/base.component';
import {ProxyService} from '../../../../shared/services/proxy.service';
import {GlobalService} from '../../../../shared/services/global.service';
import {currentStateGetCurrentSubject} from '../../../../store/current-state/current-state.selector';
import {CustomPermissionsService} from '../../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {MediaService} from '../../../../shared/services/media.service';
import {getTasks} from '../store/tag-tasks.selector';
import {TagTask} from '../../../../../generated/graphql';
import {EnumsHelper} from '../../../../shared/helpers/enums-helper.service';
import {EntityTypes, TagTypes} from '../../../../shared/enums/entity-enums';
import {StatisticsService} from '../../../../shared/services/statistics.service';
import * as SendActions from '../../../../forms/send-tag-form/store/send-form.actions';
import {AppState} from '../../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../../fixed-toolbar/fixed-toolbar.action';
import {DialogShow} from '../../../../forms/tags/task-create/store/task-create-form.actions';
import {TagTaskDelete, TagTasksFetch} from '../store/tag-tasks.actions';

@Component({
  selector: 'app-tag-tasks-list',
  templateUrl: './tag-tasks-list.component.html',
  styleUrls: ['./tag-tasks-list.component.css']
})
export class TagTasksListComponent extends BaseComponent implements OnInit, OnDestroy {
    breadcrumbItems: MenuItem[];

    public tasks: any;
    public totalRecords: any;
    public layoutContentHeight: number;
    public sortColumns: any[];
    public filters: any;
    public presetFilters: any[] = [];

    constructor(protected store: Store<AppState>,
                public gs: GlobalService,
                private router: Router,
                private route: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private proxyService: ProxyService,
                private ps: CustomPermissionsService,
                private translate: TranslateService,
                private media: MediaService,
                private enumsHelper: EnumsHelper,
                private statisticsService: StatisticsService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const presetStateFilter = this.statisticsService.getTagFilterState(TagTypes.TAG_TASK);
        if (presetStateFilter !== undefined) {
            this.presetFilters.push({field: 'state', value: presetStateFilter});
            this.statisticsService.clearTagFilterState(TagTypes.TAG_TASK);
        }

        forkJoin([
            this.translate.get('tasks.title_m'),
        ]).subscribe(result => {
            this.breadcrumbItems = [];
            this.breadcrumbItems.push({label: result[0], routerLink: '/tasks'});
            this.store.dispatch(new SetBreadcrumb(this.breadcrumbItems));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.ps.permissionsLoaded().subscribe((loaded) => {
                if (loaded) {
                    this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                        this.ps.hasPermission('createTasks').then((permission) => {
                            this.buttons = [...this.buttons];
                            if (permission) {
                                this.buttons.push(
                                    {
                                        id: 'createTaskButton',
                                        name: 'tasks.create',
                                        action: 'onCreateTask',
                                        class: 'p-button-primary',
                                        icon: 'pi pi-plus',
                                        disabled: false,
                                    }
                                );
                            }
                            this.store.dispatch(new UpdateToolbarButtons(this.buttons));
                        });

                        this.subs.sink = this.store.select(getTasks).pipe(
                            map(taskState => {
                                return {
                                    tasks: taskState.tasks,
                                    totalRows: taskState.totalRows,
                                };
                            }),
                            switchMap(({
                                           tasks: tasks,
                                           totalRows: totalRows,
                                       }) => {
                                if (tasks.length > 0) {

                                    const promises = [];

                                    tasks.forEach(record => {
                                        promises.push(this.ps.hasProjectPermission(record.project, 'updateTasks'));
                                        promises.push(this.ps.hasProjectPermission(record.project, 'deleteTasks'));
                                    });

                                    return Promise.all(
                                        promises
                                    ).then(results => {
                                        tasks = tasks.map((task: TagTask, index) => {
                                            const idx1 = (index * 2);
                                            const idx2 = (index * 2) + 1;

                                            const updatePerm = results[idx1];
                                            const deletePerm = results[idx2];

                                            const contextMenu = [
                                                {
                                                    label: this.translate.instant('general.send'),
                                                    icon: 'pi pi-envelope',
                                                    command: (event: Event) => {
                                                        this.onEmail(task.id, task.identifier);
                                                    }
                                                }
                                            ];

                                            if (deletePerm) {
                                                contextMenu.push({
                                                    label: this.translate.instant('general.delete'),
                                                    icon: 'pi pi-trash',
                                                    command: (event: Event) => {
                                                        this.onDelete(task.id, task.identifier);
                                                    }
                                                });
                                            }

                                            return {
                                                ...task,
                                                img: task.images.length > 0 ?
                                                    this.media.getMediaLink(task.images[0]) :
                                                    this.media.getNoImageLink(),
                                                thumb: task.images.length > 0 ?
                                                    this.media.getMediaThumbLink(task.images[0]) :
                                                    this.media.getNoImageLink(),
                                                contextMenu,
                                                navLink: ['/', 'tasks', 'browse', task.link]
                                            };
                                        });

                                        return {
                                            tasks,
                                            totalRows,
                                        };
                                    });
                                } else {
                                    return new Promise((resolve) => {
                                        resolve({
                                            tasks: [],
                                            totalRows: 0,
                                        });
                                    });
                                }

                            })).subscribe(
                            ({
                                 tasks: tasks,
                                 totalRows: totalRows,
                             }) => {
                                this.tasks = tasks;
                                this.totalRecords = totalRows;
                            });
                    });
                }
            });
        });

        this.subs.sink = forkJoin([
            this.translate.get('general_tag.created_at'),
            this.translate.get('general_tag.severity.title'),
            this.translate.get('tasks.deadline'),
        ]).subscribe(result => {
            this.sortColumns = [
                { label: result[0], value: {dbField: 'tt.created_at', name: result[0]} },
                { label: result[1], value: {dbField: 'tt.severity_id', name: result[1]} },
                { label: result[2], value: {dbField: 'tt.deadline', name: result[2]} },
            ];
        });

        this.filters = [
            {
                field: 'state',
                dbField: 'tt.state_id',
                matchMode: 'in',
                values: this.enumsHelper.statesMulti,
                label: 'general_tag.state.title',
                width: '250px'
            },
            {
                field: 'severity',
                dbField: 'tt.severity_id',
                matchMode: 'in',
                values: this.enumsHelper.severitiesMulti,
                label: 'general_tag.severity.title'
            }
        ];
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onCreateTask() {
        this.store.dispatch(new DialogShow());
    }

    fetchTasks(event: object): void {
        this.store.dispatch(new TagTasksFetch(event));
    }

    loadLazy(event: LazyLoadEvent, forcedField: string = null) {
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchTasks(searchData);
    }

    onEdit(link: string) {
        this.router.navigate(['/', 'browse', link]);
    }

    onDelete(id: number, identifier: string) {
        forkJoin([this.translate.get('tasks.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new TagTaskDelete({taskId: id}));
                }
            });
        });
    }

    private onEmail(id: any, identifier: any) {
        this.store.dispatch(new SendActions.DialogShow({type: EntityTypes.TAG_TASK, id, identifier}));
    }
}
