import {Component, OnInit} from '@angular/core';
import {SubSink} from 'subsink';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.reducer';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './app.error.component.html',
})
export class AppErrorComponent implements OnInit{
    private subs = new SubSink();

    public type: string = null;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.type = params.type;
        });
    }
}
