import {
    Component,
    AfterViewInit,
    Renderer2,
    OnDestroy,
    OnInit,
    ViewChild,
    ComponentRef, ElementRef
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import { MenuItem, MessageService} from 'primeng/api';
import {getGlobalErrorMessages} from '../loading/loading.selector';
import {RemoveMessage} from '../loading/loading.action';
import {map} from 'rxjs/operators';
import {ToolbarButton} from '../fixed-toolbar/toolbar-button.model';
import {SubSink} from 'subsink';
import {AuthLogout} from '../components/auth/store/auth.actions';

enum MenuOrientation {
    STATIC,
    OVERLAY
}

@Component({
    selector: 'app-home-layout',
    templateUrl: './home-layout.component.html',
    styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements AfterViewInit, OnDestroy, OnInit {

    private subs = new SubSink();

    activeTabIndex: number;

    sidebarActive: boolean;

    layoutMode: MenuOrientation = MenuOrientation.STATIC;

    darkMenu = false;

    topbarMenuActive: boolean;

    sidebarClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    documentClickListener: any;

    breadcrumbItems: MenuItem[];

    toolbarButtons: ToolbarButton[];

    componentRef: ComponentRef<any>;

    @ViewChild('toolbarFixed') toolbarFixed: ElementRef;
    @ViewChild('layoutMainContent') layoutMainContent: ElementRef;
    toolbarFixedWidth: number;

    constructor(public renderer: Renderer2,
                private store: Store<any>,
                private messageService: MessageService) {}

    ngOnInit() {
        this.subs.sink = this.store.select('breadcrumb').pipe(
            map(breadcrumbState => {
                return {breadcrumb: breadcrumbState.breadcrumbItems, toolbarButtons: breadcrumbState.toolbarButtons};
            })).subscribe(
            async ({breadcrumb: breadcrumb, toolbarButtons: toolbarButtons}) => {
                // This is a hack to avoid "Expression has changed after it was checked."
                setTimeout(() => {
                    this.breadcrumbItems = breadcrumb;
                    this.toolbarButtons = [];
                    toolbarButtons.forEach((button, index) => {
                        this.toolbarButtons.push(button);
                    });
                }, 0);
            });

        this.sidebarActive = true;
        this.activeTabIndex = 0;

        this.subs.sink = this.store.pipe(select(getGlobalErrorMessages)).subscribe(
            (data) => {
                if (data.length > 0) {
                    data.forEach((message => {
                        this.messageService.add({
                            key: 'tst',
                            severity: message.severity,
                            summary: message.summary,
                            detail: message.data
                        });
                    }));
                    this.store.dispatch(new RemoveMessage(data, 'global'));
                }
            }
        );
    }

    ngAfterViewInit() {

        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }

            this.topbarItemClick = false;
            this.sidebarClick = false;
        });

        setTimeout(() => {
            const parentWidth = this.layoutMainContent.nativeElement.clientWidth;
            this.toolbarFixedWidth = parentWidth;
        }, 300);
    }

    onTabClick(event, index: number) {
        if (this.activeTabIndex === index) {
            this.sidebarActive = !this.sidebarActive;
        } else {
            this.activeTabIndex = index;
            this.sidebarActive = true;
        }
        event.preventDefault();
    }

    closeSidebar(event) {
        this.sidebarActive = false;
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.sidebarClick = true;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    get overlay(): boolean {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }

        this.subs.unsubscribe();
    }

    onToolbarButtonClick(action: string) {
        const generalMethod = 'onToolbarButtonClick';

        if (this.componentRef[action] !== undefined) {
            this.componentRef[action]();
        } else if (this.componentRef[generalMethod]) {
            this.componentRef[generalMethod](action);
        } else {
            throw new Error('Button click handle not exists');
        }
    }

    onActivate(componentRef: ComponentRef<any>) {
        this.componentRef = componentRef;
    }

    onLogout() {
        this.store.dispatch(new AuthLogout());
    }
}
