import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {GenericText, Subject} from '../../../../generated/graphql';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {select, Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import * as RXJS from 'rxjs';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {getGenericTexts} from '../store/generic-texts.selector';
import {Table} from 'primeng/table';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, UpdateToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {
    GenericTextDelete,
    GenericTextFetch,
    GenericTextsActionTypes,
    GenericTextsExport,
    GenericTextsExportSuccess,
    GenericTextsFetch
} from '../store/generic-texts.actions';
import {AreasOfFetch} from '../../area-of/store/areas-of.actions';
import {getAreasOf} from '../../area-of/store/areas-of.selector';
import {DialogShow} from '../../../forms/generic-text-form/store/generic-text-form.actions';
import {AppActions} from '../../../store/app-actions.service';

@Component({
    selector: 'app-generic-texts-list',
    templateUrl: './generic-texts-list.component.html',
    styleUrls: ['./generic-texts-list.component.css']
})
export class GenericTextsListComponent extends BaseComponent implements OnInit, OnDestroy {

    public texts: GenericText[];

    public totalRecords: number;
    public breadcrumbItems: MenuItem[];

    @ViewChild('dt') dataTable: Table;
    @ViewChild('globalFilterInput') globalFilterInput;
    public createdAt: any;
    public updatedAt: any;
    public currentSubject: Subject;
    public sortColumns = [];
    public layoutContentHeight: number;

    private areaOfSuggestions$ = new RXJS.BehaviorSubject([]);

    public editPermissions: string[];
    public deletePermissions: string[];
    public type = 1;
    public filters: any;
    public cols: any;
    public filtersDef: any;

    filterDebounceTime = 600;
    filterTextChanged: RXJS.Subject<any> = new RXJS.Subject<any>();

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        public ps: CustomPermissionsService,
        private translate: TranslateService,
        private appActions: AppActions
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.cols = [
            { field: 'name', header: 'generic_texts.name', display: 'table-cell', width: '15%' },
            { field: 'areaOf', header: 'generic_texts.area-of', display: 'table-cell', width: '15%' },
            { field: 'texts', header: 'generic_texts.texts', display: 'table-cell', width: '60%' },
        ];

        this.filtersDef = {
            rows: [
                [
                    {
                        field: 'name',
                        dbField: 'gt.name',
                        matchMode: 'contains',
                        col: 'p-col-8',
                        label: 'generic_texts.name'
                    }
                ],
                // [
                //     {
                //         field: 'severity',
                //         dbField: 'td.severity_id',
                //         matchMode: 'in',
                //         values: this.enumsHelper.severitiesMulti,
                //         col: 'p-col-2',
                //         label: 'general_tag.severity.title',
                //         placeholder: 'general_tag.state.choose'
                //     },
                //     {
                //         field: 'state',
                //         dbField: 'td.state_id',
                //         matchMode: 'in',
                //         values: this.enumsHelper.statesMulti,
                //         col: 'p-col-2',
                //         label: 'general_tag.state.title',
                //         placeholder: 'general_tag.state.choose'
                //     },
                //     {
                //         field: 'subject',
                //         dbField: 's2.subject_id',
                //         matchMode: 'in',
                //         values: this.proxyService.getSubjectsListOptions(SubjectType.SUBJECT),
                //         col: 'p-col-4',
                //         label: 'subjects.title_s',
                //         placeholder: 'subjects.choose_record'
                //     }
                // ],
            ]
        };

        this.subs.sink = RXJS.forkJoin([
            // this.translate.get('general.created_at'),
            this.translate.get('generic_texts.name'),
            this.translate.get('generic_texts.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                // { label: result[0], value: {dbField: 'gt.created_at', name: 'general.created_at'} },
                { label: result[0], value: {dbField: 'gt.name', name: 'generic_texts.name'} },
            ];


            this.store.dispatch(new SetBreadcrumb([
                {label: result[1], routerLink: '/generic-texts'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((subject) => {
            this.subs.sink = this.ps.permissionsLoaded().subscribe((loaded) => {
                if (loaded) {
                    this.currentSubject = subject;
                    this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                        this.ps.hasPermission('createGenericTexts').then((permission) => {
                            this.buttons = [...this.buttons];
                            if (permission) {
                                this.store.dispatch(new UpdateToolbarButtons([
                                    {
                                        id: 'createGenericTextButton',
                                        name: 'generic_texts.create',
                                        action: 'onCreateGenericText',
                                        class: 'p-button-primary',
                                        icon: 'pi pi-plus',
                                        disabled: false,
                                    }])
                                );
                            }
                        });
                    });

                    this.subs.sink = this.store.select(getGenericTexts).pipe(
                        map(state => {
                            return {
                                texts: state.genericTexts,
                                totalRows: state.totalRows,
                            };
                        }),
                        switchMap(({
                                       texts: texts,
                                       totalRows: totalRows,
                                   }) => {
                            if (texts.length > 0) {

                                const promises = [];

                                texts.forEach(record => {
                                    promises.push(this.ps.hasProjectPermission(record, 'updateGenericTexts'));
                                    promises.push(this.ps.hasProjectPermission(record, 'deleteGenericTexts'));
                                });

                                texts = texts.map((text, index) => {
                                    const contextMenu = [];

                                    contextMenu.push({
                                        label: this.translate.instant('general.delete'),
                                        icon: 'pi pi-trash',
                                        command: (event: Event) => {
                                            this.onDelete(text.id, text.name);
                                        }
                                    });

                                    const navLink = ['/', 'generic-texts'];
                                    navLink.push('edit');
                                    navLink.push(text.id);

                                    return {
                                        ...text,
                                        contextMenu,
                                        navLink
                                    };
                                });

                                return new Promise((resolve) => {
                                    resolve({
                                        texts,
                                        totalRows,
                                    });
                                });
                            } else {
                                return new Promise((resolve) => {
                                    resolve({
                                        texts: [],
                                        totalRows: 0,
                                    });
                                });
                            }

                        })).subscribe(
                        ({
                             texts: texts,
                             totalRows: totalRows,
                         }) => {
                            this.texts = texts;
                            this.totalRecords = totalRows;
                        });
                }
            });
        });

        this.subs.sink = this.store.select(getAreasOf).pipe(
            map(state => {
                return {
                    areasOf: state.areasOf,
                    totalRows: state.totalRows,
                };
            }),
            switchMap(({
                           areasOf: areasOf,
                           totalRows: totalRows,
                       }) => {
                if (areasOf.length > 0) {
                    areasOf = areasOf.map((text, index) => {
                        return {
                            ...text,
                        };
                    });

                    return new Promise((resolve) => {
                        resolve({
                            areasOf,
                        });
                    });
                } else {
                    return new Promise((resolve) => {
                        resolve({
                            areasOf: [],
                        });
                    });
                }

            })).subscribe(
            ({
                 areasOf: areasOf,
             }) => {
                const data = areasOf.map(areaOf => {
                    return {
                        label: areaOf.name,
                        value: {
                            id: areaOf.id,
                            name: areaOf.name
                        }
                    };
                });

                this.areaOfSuggestions$.next(data);
            });

        this.filters = [
            {
                field: 'areaOf',
                dbField: 'gt.area_of_id',
                matchMode: 'in',
                values: this.areaOfSuggestions$,
                label: 'generic_texts.area-of',
                placeholder: 'subjects.choose_record',
                width: '250px'
            },
            // {
            //     field: 'type',
            //     dbField: 'gt.type',
            //     matchMode: 'is',
            //     values: [{id: 1, label: 'defects.suggestion'}, {id: 2, label: 'defects.precaution_suggestion'}],
            //     label: 'generic_texts.type',
            //     width: '250px'
            // }
        ];

        this.loadAreasOf();

        super.ngOnInit();
    }

    onCreateGenericText() {
        // this.router.navigate(['create'], {relativeTo: this.route});
        this.store.dispatch(new DialogShow({action: 'create'}));
    }

    fetchAreasOf(lazyLoad: LazyLoadMeta, type: number): void {
        this.store.dispatch(new AreasOfFetch({lazyLoad, type: null}));
    }

    loadAreasOf() {
        const searchData = new LazyLoadMeta(
            0,
            10000,
            [{field: 'a.type', value: '1', matchMode: 'is'}],
            null,
            null,
            null,
            null,
            null
        );
        this.fetchAreasOf(searchData, this.type);
    }

    fetchGenericText(id: number) {
        return this.appActions.dispatch(new GenericTextFetch({id}));
    }

    fetchGenericTexts(lazyLoad: LazyLoadMeta, type: number): void {
        this.store.dispatch(new GenericTextsFetch({lazyLoad, type: null}));
    }

    loadLazy(lazyLoad: LazyLoadEvent) {
        const event = {lazyLoad};
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchGenericTexts(searchData, this.type);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        super.ngOnDestroy();
    }

    onEdit(id: any) {
        // this.router.navigate(['edit', id], {relativeTo: this.route});
        this.fetchGenericText(id).subscribe(() => {
            this.store.dispatch(new DialogShow({action: 'edit'}));
        });
    }

    onDelete(id: any, identifier: string) {
        RXJS.forkJoin([this.translate.get('generic_texts.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0] + ' [' + identifier + ']',
                accept: () => {
                    this.store.dispatch(new GenericTextDelete({id, type: this.type}));
                }
            });
        });
    }

    createLazyLoad(lazyLoad: LazyLoadMeta, sortField: string, sortOrder = -1) {
        if (!lazyLoad) {
            lazyLoad = {} as LazyLoadMeta;
        }

        lazyLoad.first = 0;
        lazyLoad.rows = 10000;
        lazyLoad.sortField = sortField;
        lazyLoad.sortOrder = sortOrder;

        return lazyLoad;
    }

    onFilterChanged(event: any) {
        this.filterTextChanged.pipe(debounceTime(this.filterDebounceTime), distinctUntilChanged()).subscribe(query => {
            let lazyLoad = this.dataTable.createLazyLoadMetadata();
            lazyLoad.filters = [
                {field: 'global', value: event.target.value, matchMode: 'contains'}
            ];
            lazyLoad = this.createLazyLoad(lazyLoad, 'gt.name', 1);
            this.fetchGenericTexts(lazyLoad, this.type);
        });
        this.filterTextChanged.next();
    }

    clearFilter(table: Table) {
        this.globalFilterInput.nativeElement.value = '';
        table.clear();
    }

    exportCsv() {
        this.appActions.dispatch(new GenericTextsExport(), [GenericTextsActionTypes.GenericTextsExportSuccess])
            .subscribe((result) =>
        {
            if (result instanceof GenericTextsExportSuccess) {
                console.log('Class: GenericTextsListComponent, Function: , Line 398: '
                    , result);
                this.saveAsCsvFile(result.payload.fileContentBase64, 'texts');
            }
        });
    }

    saveAsCsvFile(data: any, fileName: string): void {
        import('file-saver').then(FileSaver => {
            FileSaver.saveAs(this.dataURItoBlob(data), fileName + '_export_' + new Date().getTime() + '.csv');
        });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], {type: 'application/csv'});
    }
}
