import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './accounts.reducer';

export const getAccountsFeature = createFeatureSelector<State>('accounts');

export const getAccounts = createSelector(
    getAccountsFeature,
    (state) => {
        return state.accounts;
    }
);
