import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {NgForm} from '@angular/forms';
import {
    AreaOf,
    GenericText,
    GenericTextInput, Subject,
} from '../../../../generated/graphql';
import {Gallery} from '@ngx-gallery/core';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TagsService} from '../../tags/tags.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {GenericTextCreate, GenericTextFetch, GenericTextSet, GenericTextUpdate} from '../store/generic-texts.actions';

@Component({
    selector: 'app-generic-text-edit',
    templateUrl: './generic-text-edit.component.html',
    styleUrls: ['./generic-text-edit.component.css']
})
export class GenericTextEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('f') genericTextForm: NgForm;
    public type = 1;
    private loadedGenericText: GenericText;
    private editId: number;
    public editMode: boolean;

    public areaOfSuggestions: AreaOf[];
    public selectedAreaOf: AreaOf = null;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected tagsService: TagsService,
        private gallery: Gallery,
        private translate: TranslateService,
        private ps: CustomPermissionsService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            forkJoin([
                this.translate.get('generic_texts.title_m'),
                this.translate.get('generic_texts.edit'),
                this.translate.get('generic_texts.create'),
            ]).subscribe(result => {
                if (this.editMode) {
                    this.fetchGenericText(this.editId);
                }
            });
        });
    }

    ngAfterViewInit() {
        this.buttons = [
            {
                id: 'saveGenericTextButton',
                name: 'generic_texts.save',
                action: 'onSaveGenericText',
                class: 'p-button-success',
                icon: 'pi pi-save p-mr-1',
                disabled: false,
            }
        ];

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.ps.hasPermission('createGenericTexts').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'createGenericTextButton',
                            name: 'generic_texts.create',
                            action: 'onCreateGenericText',
                            class: 'p-button-primary',
                            icon: 'pi pi-plus',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });
        });

        this.subs.sink = this.store.select('genericTexts').pipe(
            map(genericTextsState => {
                return {
                    loadedGenericText: genericTextsState.loadedGenericText,
                };
            })).subscribe(
            ({
                 loadedGenericText: loadedGenericText,
             }) => {
                if (loadedGenericText) {
                    this.genericTextForm.form.patchValue({
                            ...loadedGenericText,
                            genericTextId: loadedGenericText.id,
                        }
                    );
                    this.store.dispatch(new GenericTextSet({genericText: null}));
                    this.loadedGenericText = loadedGenericText;
                    this.type = this.loadedGenericText.type;
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchGenericText(id: number) {
        this.store.dispatch(new GenericTextFetch({id}));
    }

    onSaveGenericText() {
        this.genericTextForm.ngSubmit.emit();
    }

    onSubmit(form: NgForm) {
        const genericText: GenericTextInput = {
            id: (this.editMode) ? +this.editId : null,
            name: form.value.name,
            description: form.value.description,
            precautionSuggestion: form.value.precautionSuggestion,
            type: this.type,
            areaOf: this.selectedAreaOf?.id,
        };

        if (this.editMode) {
            this.store.dispatch(new GenericTextUpdate({genericText}));
        } else {
            this.store.dispatch(new GenericTextCreate({genericText}));
        }
    }

    filterAreaOfSuggestions(event: any) {
        this.tagsService.getAreaOfList(event.query, 1).then(data => {
            this.areaOfSuggestions = [...data];
        });
    }
}
