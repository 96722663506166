import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ProjectCreate, ProjectDelete,
    ProjectsAppFetch,
    ProjectsAppSet,
    ProjectsError,
    ProjectSet,
    ProjectsFetch, ProjectsFetchLazyMeta,
    ProjectsSet,
    ProjectUpdate
} from './projects.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {
    ProjectCreateSuccess,
    ProjectDeleteSuccess,
    ProjectFetch,
    ProjectsActionTypes,
    ProjectUpdateSuccess
} from './projects.actions';
import {Router} from '@angular/router';
import {AppState} from '../../../store/app.reducer';
import {AuthPermissionsFetch} from '../../auth/store/auth.actions';
import {CurrentStateReload} from '../../../store/current-state/current-state.actions';
import {State} from './projects.reducer';

const handleError = (errorRes: ApolloError) => {
    return of(new ProjectsError());
};

@Injectable()
export class ProjectsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect()
    projectsFetch = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectsFetch),
        switchMap((projectsActions: ProjectsFetch) => {
            this.store.dispatch(new ProjectsFetchLazyMeta({lazyLoad: projectsActions.payload}));
            return this.conPointService.getProjectsList({lazyLoad: projectsActions.payload}).pipe(
                map((result) => {
                    const projects = result.data.GetProjectsList.result?.map(project => {
                        return {...project};
                    });

                    return {
                        projects,
                        total: result.data.GetProjectsList.metadata.total,
                        count: result.data.GetProjectsList.metadata.count
                    };
                }),
                map(({projects, total, count}) => {
                    return new ProjectsSet({projects: projects ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    projectsAppFetch = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectsAppFetch),
        switchMap((projectsActions: ProjectsAppFetch) => {
            return this.conPointService.getProjectsAppList().pipe(
                map((result) => {
                    const projects = result.data.GetProjectsList.result?.map(project => {
                        return {...project};
                    });
                    return new ProjectsAppSet({projects: projects ?? []});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    projectFetch = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectFetch),
        switchMap((projectsActions: ProjectFetch) => {
            return this.conPointService.getProject({projectId: projectsActions.payload.projectId}).pipe(
                map((result) => {
                    return new ProjectSet({project: result.data.GetProject});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    projectCreate = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectCreate),
        withLatestFrom(this.store.select('projects')),
        switchMap<[ProjectCreate, State], Observable<any>>(([projectActions, projectsState]) => {
            return this.conPointService.createProject({project: projectActions.payload.project}).pipe(
                map(() => {
                    this.store.dispatch(new ProjectsFetch(projectsState.currentLazyLoad));
                    this.store.dispatch(new AuthPermissionsFetch());
                    this.store.dispatch(new CurrentStateReload({value: true}));
                    return new ProjectCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    projectUpdate = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectUpdate),
        withLatestFrom(this.store.select('projects')),
        switchMap<[ProjectUpdate, State], Observable<any>>(([projectActions, projectsState]) => {

            const project = {...projectActions.payload.project};
            delete project['createdAt'];
            delete project['updatedAt'];

            return this.conPointService.updateProject({project}).pipe(
                map(() => {
                    this.store.dispatch(new ProjectsFetch(projectsState.currentLazyLoad));
                    return new ProjectUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    projectDelete = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectDelete),
        withLatestFrom(this.store.select('projects')),
        switchMap<[ProjectDelete, State], Observable<any>>(([projectActions, projectsState]) => {
            return this.conPointService.deleteProject({projectId: projectActions.payload.projectId}).pipe(
                map(() => {
                    this.store.dispatch(new ProjectsFetch(projectsState.currentLazyLoad));
                    return new ProjectDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect({dispatch: false})
    projectNavigate = this.actions$.pipe(
        ofType(ProjectsActionTypes.ProjectUpdateSuccess, ProjectsActionTypes.ProjectCreateSuccess),
        withLatestFrom(this.store.select('projects')),
        tap(() => {
            // this.router.navigate(['/projects']);
        })
    );
}
