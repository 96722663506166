import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
    MediaFileInput,
    TagGoodPractice,
    TagGoodPracticeInput,
} from '../../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TagsService} from '../../tags.service';
import {map} from 'rxjs/operators';
import {Gallery, ImageItem} from '@ngx-gallery/core';
import {MediaFileStateEnum} from '../../../../shared/enums/media-file-state-enum';
import {GlobalService} from '../../../../shared/services/global.service';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../../shared/services/custom-permissions.service';
import {EnumsHelper} from '../../../../shared/helpers/enums-helper.service';
import {TagTypes} from '../../../../shared/enums/entity-enums';
import {AppState} from '../../../../store/app.reducer';
import {SetBreadcrumb, SetToolbarButtons} from '../../../../fixed-toolbar/fixed-toolbar.action';
import {
    TagGoodPracticeBrowseFetch,
    TagGoodPracticeFetch,
    TagGoodPracticeSet,
    TagGoodPracticeUpdate
} from '../store/tag-good-practice.actions';
import {currentStateGetCurrentProject} from '../../../../store/current-state/current-state.selector';
import {TagBaseComponent} from '../../tag-base/tag-base.component';

@Component({
  selector: 'app-tag-good-practice-edit',
  templateUrl: './tag-good-practice-edit.component.html',
  styleUrls: ['./tag-good-practice-edit.component.css']
})
export class TagGoodPracticeEditComponent extends TagBaseComponent implements OnInit, OnDestroy, AfterViewInit {

    tagType = TagTypes.TAG_GOOD_PRACTICE;

    loadedTagGoodPractice: TagGoodPractice;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected tagsService: TagsService,
        protected gallery: Gallery,
        private translate: TranslateService,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = this.route.params.subscribe((params: Params) => {

            const browseFullId = params.fullId;
            const browseProjectKeyName = params.projectKeyName;
            const browseTagSequenceNumber = +params.tagSequenceNumber;
            let browseEditMode = false;

            const editId = +params.id;
            const editMode = params.id != null;

            if (!editMode && browseFullId) {
                browseEditMode = true;
            }

            const breadCrumbItems = [];

            forkJoin([
                this.translate.get('good_practice.title_m'),
                this.translate.get('good_practice.edit'),
                this.translate.get('good_practice.create'),
            ]).subscribe(result => {
                breadCrumbItems.push({label: result[0], routerLink: ['/good-practice']});

                if (editMode) {
                    breadCrumbItems.push({label: result[1], routerLink: ['/good-practice/edit', editId]});
                    this.fetchGoodPractice(editId);
                } else if (browseEditMode) {
                    breadCrumbItems.push({label: browseProjectKeyName});
                    breadCrumbItems.push({label: browseFullId, routerLink: ['/browse/', browseFullId]});
                    this.fetchGoodPracticeBrowse(browseProjectKeyName, browseTagSequenceNumber);
                } else {
                    breadCrumbItems.push({label: result[2], routerLink: '/good-practice/create'});
                }
                this.store.dispatch(new SetBreadcrumb(breadCrumbItems));

                this.galleryImages1 = [];
                this.uploadManager1.clearUploadedFiles(null);
                this.image1Upload?.clear();
            });
        });

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {

            this.subs.sink = this.store.select(currentStateGetCurrentProject).subscribe((project) => {
                this.subscribeTagStates(project, this.loadedTagGoodPractice);

                forkJoin({
                    getPerm: this.ps.hasProjectPermission(project, 'getGoodPractice'),
                    updatePerm: this.ps.hasProjectPermission(project, 'updateGoodPractice')},
                ).subscribe(results => {
                    if (results.getPerm) {
                        this.permission = 'get';
                    }

                    if (results.updatePerm) {
                        this.permission = 'update';
                    }
                });
            });

            this.ps.hasPermission('updateGoodPractice').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'saveGoodPracticeButton',
                            name: 'good_practice.save',
                            action: 'onSaveGoodPractice',
                            class: 'p-button-success',
                            icon: 'pi pi-save p-mr-1',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });

            this.ps.hasPermission('createGoodPractice').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'createGoodPracticeButton',
                            name: 'good_practice.create',
                            action: 'onCreateGoodPractice',
                            class: 'p-button-primary',
                            icon: 'pi pi-plus',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });
        });
    }

    ngAfterViewInit() {
        // Load items into gallery
        const galleryRef = this.gallery.ref(this.images1Id, {
            thumbPosition: 'top',
            imageSize: 'contain'
        });

        this.subs.sink = this.store.select('tagGoodPractices').pipe(
            map(goodPracticeState => {
                return {
                    loadedTagGoodPractice: goodPracticeState.loadedTagGoodPractice,
                };
            })).subscribe(
            ({
                 loadedTagGoodPractice: loadedTagGoodPractice,
             }) => {
                if (loadedTagGoodPractice) {
                    this.tagForm.form.patchValue({
                            ...loadedTagGoodPractice,
                            goodPracticeId: loadedTagGoodPractice.id,
                            assignedToUser: loadedTagGoodPractice.assignedToUser,
                            occurredAt: loadedTagGoodPractice.occurredAt ? new Date(loadedTagGoodPractice.occurredAt.date) : null,
                        }
                    );
                    this.store.dispatch(new TagGoodPracticeSet({goodPractice: null}));

                    this.loadedTagGoodPractice = loadedTagGoodPractice;
                    this.selectedProject = this.loadedTagGoodPractice.project;

                    this.loadedImages1 = [...loadedTagGoodPractice.images];

                    this.loadedImages1.forEach((fileCode) => {
                        if (!this.galleryImages1.find(item => item.data.code === fileCode)) {
                            this.galleryImages1.push(new ImageItem({
                                src: this.gs.getEnvironment().mediaUrl + 'media/' + fileCode,
                                thumb: this.gs.getEnvironment().mediaUrl + 'media/thumb/' + fileCode,
                                code: fileCode
                            }));
                        }
                    });

                    galleryRef.load(this.galleryImages1);
                    this.uploadManager1.clearUploadedFilesList();
                    this.image1Upload?.clear();

                    this.isLoading = false;
                }
            });
    }

    fetchGoodPractice(goodPracticeId: number) {
        this.store.dispatch(new TagGoodPracticeFetch({goodPracticeId}));
    }

    fetchGoodPracticeBrowse(projectKeyName: string, tagSequenceNumber: number) {
        this.store.dispatch(new TagGoodPracticeBrowseFetch({projectKeyName, tagSequenceNumber}));
    }

    onSaveGoodPractice() {
        this.tagForm.ngSubmit.emit();
    }

    onSubmit(form: NgForm) {
        const goodPractice: TagGoodPracticeInput = {
            id: (form.value.goodPracticeId !== null) ? +form.value.goodPracticeId : null,
            state: form.value.state.id,
            project: this.loadedTagGoodPractice.project.id,
            assignedToSubjects: form.value.assignedToSubjects?.map(s => s.id) ?? [],
            assignedToSubjectNote: form.value?.assignedToSubjectNote ?? null,
            assignedToUser: form.value.assignedToUser?.id ?? null,
            areaOf: form.value.areaOf?.id ?? null,
            occurredAt: form.value.occurredAt,
            description: form.value.description,
            images: [...( this.galleryImages1.map((file) => {
                const mediaFile: MediaFileInput = {
                    fileName: file.data.code,
                    fileState: MediaFileStateEnum.STATE_EXISTING
                };
                return mediaFile;
            }) ?? []), ...this.uploadManager1.getFilesNameArray()],
            note: form.value.note,
            location: form.value.location
        };

        this.store.dispatch(new TagGoodPracticeUpdate({goodPractice}));
    }
}
