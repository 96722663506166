import {Action} from '@ngrx/store';
import {LoadingAction} from '../../../../loading';
import {SubjectDetails} from '../../../../../generated/graphql';

export enum OshSubjectActionTypes {
    OshSubjectSet = '[@OshSubject] Set',
    OshSubjectFetch = '[@OshSubject] Fetch',
    OshUserUnAssign = '[@OshSubject] UserUnAssign',
    OshUserUnAssignSuccess = '[@OshSubject] UserUnAssignSuccess',

    OshSubjectError = '[@OshSubject] Error',
}

export class OshSubjectSet implements Action, LoadingAction {
    readonly type = OshSubjectActionTypes.OshSubjectSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: OshSubjectActionTypes.OshSubjectFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {oshSubject: SubjectDetails }) {}
}

export class OshSubjectFetch implements Action, LoadingAction {
    readonly type = OshSubjectActionTypes.OshSubjectFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: OshSubjectActionTypes.OshSubjectFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor() {}
}

export class OshUserUnAssign implements Action, LoadingAction {
    readonly type = OshSubjectActionTypes.OshUserUnAssign;
    fxLoading = {
        action: 'add',
        ids: [{name: OshSubjectActionTypes.OshUserUnAssign, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {userId: number, projectId: number}) {}
}

export class OshUserUnAssignSuccess implements Action, LoadingAction {
    readonly type = OshSubjectActionTypes.OshUserUnAssignSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: OshSubjectActionTypes.OshUserUnAssign, spinner: 'sp_content'}],
        type: 'global'
    };
}

export class OshSubjectError implements Action, LoadingAction {
    readonly type = OshSubjectActionTypes.OshSubjectError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: OshSubjectActionTypes.OshSubjectFetch, spinner: 'sp_lll'},
            {name: OshSubjectActionTypes.OshSubjectSet, spinner: 'sp_llcl'},
            {name: OshSubjectActionTypes.OshUserUnAssign, spinner: 'sp_content'},
        ],
        type: 'all'
    };

    constructor() {}
}

export type OshSubjectActions =
    OshSubjectFetch |
    OshSubjectSet |
    OshSubjectError;
