import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, } from 'rxjs';
import {GlobalService} from '../services/global.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
    private first = true;
    private counter = 1;

    constructor(
       private gs: GlobalService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes(this.gs.getEnvironment().graphQLOrigin)) {
            let newHeaders = req.headers;
            if (!this.first) {
                newHeaders = newHeaders.append('X-Requested-With', 'XMLHttpRequest');
                const value = this.gs.tracy + '_' + this.counter;
                this.counter++;
                newHeaders = newHeaders.append('x-tracy-ajax', value);
            } else {
                this.first = false;
            }
            if (this.gs.getEnvironment().showDebugBar) {
                newHeaders = newHeaders.append('show-debug-bar', 'true');
            }
            const modifiedRequest = req.clone({
                headers: newHeaders
            });
            return next.handle(modifiedRequest);
        }
        return next.handle(req);
    }
}
