import { Action } from '@ngrx/store';
import {ToolbarButton} from './toolbar-button.model';
import {MenuItem} from 'primeng/api';

export enum ActionTypes {
    SetBreadcrumb = '[@fixedToolbar] SetBreadcrumb',
    AddBreadcrumb = '[@fixedToolbar] AddBreadcrumb',
    ClearBreadcrumb = '[@fixedToolbar] ClearBreadcrumb',
    SetToolbarButtons = '[@fixedToolbar] SetToolbarButtons',
    UpdateToolbarButtons = '[@fixedToolbar] UpdateToolbarButtons',
    ClearToolbarButtons = '[@fixedToolbar] ClearToolbarButtons',
}

export class SetBreadcrumb implements Action {
    readonly type = ActionTypes.SetBreadcrumb;
    constructor(public payload: MenuItem[]) {
    }
}

export class AddBreadcrumb implements Action {
    readonly type = ActionTypes.AddBreadcrumb;
    constructor(public payload: MenuItem[]) {
    }
}

export class ClearBreadcrumb implements Action {
  readonly type = ActionTypes.ClearBreadcrumb;
}

export class SetToolbarButtons implements Action {
    readonly type = ActionTypes.SetToolbarButtons;
    constructor(public payload: ToolbarButton[]) {
    }
}

export class UpdateToolbarButtons implements Action {
    readonly type = ActionTypes.UpdateToolbarButtons;
    constructor(public payload: ToolbarButton[]) {
    }
}
export class ClearToolbarButtons implements Action {
    readonly type = ActionTypes.ClearToolbarButtons;
}

export type FixedToolbarActions =
    AddBreadcrumb |
    SetBreadcrumb |
    ClearBreadcrumb |
    SetToolbarButtons |
    UpdateToolbarButtons |
    ClearToolbarButtons;
