import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ResponseInterceptorService} from '../app/shared/interceptors/response-interceptor.service';
import {RequestInterceptorService} from '../app/shared/interceptors/request-interceptor.service';

export const ENVIRONMENT_SPECIFIC_PROVIDERS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ResponseInterceptorService,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptorService,
        multi: true
    }
];
