import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    Project,
} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Params} from '@angular/router';
import {ProxyService} from '../../../shared/services/proxy.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {GlobalService} from '../../../shared/services/global.service';
import {AppState} from '../../../store/app.reducer';
import {ProjectFetch} from '../store/projects.actions';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent extends BaseComponent implements OnInit, OnDestroy {

    private editId = null;
    public loadedProject: Project;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected route: ActivatedRoute,
        protected proxyService: ProxyService,
        private translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.fetchProject(this.editId);
        });

        // this.store.dispatch(new SetToolbarButtons([
        //     {
        //         id: 'saveProjectButton',
        //         name: 'projects.save',
        //         action: 'onSaveProject',
        //         class: 'p-button-success',
        //         icon: 'pi pi-save p-mr-1',
        //         disabled: false,
        //     },
        // ]));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchProject(projectId: number) {
        this.subs.sink = this.store.select('projects').subscribe(projectState => {
            this.loadedProject = projectState.loadedProject;
        });
        this.store.dispatch(new ProjectFetch({projectId}));
    }
}
