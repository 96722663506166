import {Subject} from '../../../../../generated/graphql';
import {createReducer, on} from '@ngrx/store';
import {accountsFetchSuccess} from './accounts.actions';

export interface State {
    accounts: Subject[];
    totalRows: number;
}

const initialState = {
    accounts: [],
    totalRows: 0
};

export const accountsReducer = createReducer(
    initialState,
    on(accountsFetchSuccess, (state, action) => ({
        ...state,
        totalRows: action.payload.totalRows,
        accounts: [...action.payload.subjects]
    })),
);
