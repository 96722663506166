import {SEND_FORM_DIALOG_HIDE, SEND_FORM_DIALOG_SHOW, SendFormActions} from './send-form.actions';

export interface State {
    dialogShow: boolean;
    type: number;
    id: number;
    identifier: string;
}

const initialState = {
    dialogShow: false,
    type: null,
    id: null,
    identifier: null,
};

export function sendReducer(state: State = initialState, action: SendFormActions) {
    switch (action.type) {
        case SEND_FORM_DIALOG_SHOW:
            state = {
                ...state,
                type: action.payload.type,
                id: action.payload.id,
                identifier: action.payload.identifier,
                dialogShow: true
            };
            break;
        case SEND_FORM_DIALOG_HIDE:
            state = {
                ...state,
                dialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
