import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../../loading';
import { MessageAction } from '../../../../loading/loading.model';
import { FlashMessage} from '../../../../shared/model/flash-message.model';
import { TagGoodPractice, TagGoodPracticeInput} from '../../../../../generated/graphql';

export enum TagGoodPracticesActionTypes {
    TagGoodPracticesFetchLazyMeta = '[@TagGoodPractices] FetchLazyMeta',
    TagGoodPracticesSet = '[@TagGoodPractices] Set',
    TagGoodPracticeSet = '[@TagGoodPractice] Set',
    TagGoodPracticesFetch = '[@TagGoodPractices] Fetch',
    TagGoodPracticeFetch = '[@TagGoodPractice] Fetch',
    TagGoodPracticeBrowseFetch = '[@TagGoodPractice] Browse Fetch',
    TagGoodPracticeCreate = '[@TagGoodPractice] Create',
    TagGoodPracticeCreateSuccess = '[@TagGoodPractice] SaveSuccess',
    TagGoodPracticeUpdate = '[@TagGoodPractice] Update',
    TagGoodPracticeUpdateSuccess = '[@TagGoodPractice] UpdateSuccess',
    TagGoodPracticeDelete = '[@TagGoodPractice] Delete',
    TagGoodPracticeDeleteSuccess = '[@TagGoodPractice] DeleteSuccess',
    TagGoodPracticesError = '[@TagGoodPractices] Error',
}

export class TagGoodPracticesSet implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticesSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticesFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {goodPractices: TagGoodPractice[], totalRows: number }) {}
}

export class TagGoodPracticesFetch implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticesFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticesFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class TagGoodPracticeSet implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeSet;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: TagGoodPracticesActionTypes.TagGoodPracticeFetch, spinner: 'sp_llcl'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeBrowseFetch, spinner: 'sp_llcl'}
        ],
        type: 'global'
    };
    constructor(public payload: {goodPractice: TagGoodPractice }) {}
}

export class TagGoodPracticeFetch implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {goodPracticeId: number}) {}
}

export class TagGoodPracticeBrowseFetch implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeBrowseFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeBrowseFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {projectKeyName: string, tagSequenceNumber: number}) {}
}

export class TagGoodPracticeCreate implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {goodPractice: TagGoodPracticeInput}) {}
}

export class TagGoodPracticeCreateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('good_practice.created')],
        type: 'global'
    };
}

export class TagGoodPracticeUpdate implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {goodPractice: TagGoodPracticeInput}) {}
}

export class TagGoodPracticeUpdateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('good_practice.updated')],
        type: 'global'
    };
}

export class TagGoodPracticeDelete implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {goodPracticeId: number}) {}
}

export class TagGoodPracticeDeleteSuccess implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticeDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: TagGoodPracticesActionTypes.TagGoodPracticeDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('good_practice.deleted')],
        type: 'global'
    };
}

export class TagGoodPracticesError implements Action, LoadingAction {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticesError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: TagGoodPracticesActionTypes.TagGoodPracticesFetch, spinner: 'sp_lll'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeFetch, spinner: 'sp_llcl'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeBrowseFetch, spinner: 'sp_llcl'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeCreate, spinner: 'sp_lll'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeUpdate, spinner: 'sp_llb'},
            {name: TagGoodPracticesActionTypes.TagGoodPracticeDelete, spinner: 'sp_lll'},
        ],
        type: 'all'
    };

    constructor() {}
}

export class TagGoodPracticesFetchLazyMeta implements Action {
    readonly type = TagGoodPracticesActionTypes.TagGoodPracticesFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type TagGoodPracticeActions =
    TagGoodPracticesFetch |
    TagGoodPracticeCreate |
    TagGoodPracticeCreateSuccess |
    TagGoodPracticeUpdate |
    TagGoodPracticeUpdateSuccess |
    TagGoodPracticeDelete |
    TagGoodPracticeDeleteSuccess |
    TagGoodPracticesSet |
    TagGoodPracticeSet |
    TagGoodPracticeFetch |
    TagGoodPracticeBrowseFetch |
    TagGoodPracticesFetchLazyMeta |
    TagGoodPracticesError;
