import {DEFECT_CREATE_FORM_DIALOG_HIDE, DEFECT_CREATE_FORM_DIALOG_SHOW, DefectCreateFormActions} from './defect-create-form.actions';

export interface State {
    dialogShow: boolean;
}

const initialState = {
    dialogShow: false,
};

export function tagDefectCreateReducer(state: State = initialState, action: DefectCreateFormActions) {
    switch (action.type) {
        case DEFECT_CREATE_FORM_DIALOG_SHOW:
            state = {
                ...state,
                dialogShow: true
            };
            break;
        case DEFECT_CREATE_FORM_DIALOG_HIDE:
            state = {
                ...state,
                dialogShow: false
            };
            break;
        default:
            state = {
                ...state
            };
    }
    return state;
}
