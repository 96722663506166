import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresetFilterService {

    private areaOfPresetFilter$ = new BehaviorSubject<{field: string, value: any}[]>([]);

    get areaOfPresetFilter() {
        return this.areaOfPresetFilter$.asObservable();
    }

    public setAreaOfPresetFilter(filters: {field: string, value: any}[]) {
        this.areaOfPresetFilter$.next(filters);
    }

    public clearAreaOfPresetFilters() {
        this.areaOfPresetFilter$.next([]);
    }
}
