import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    TagTaskFetch,
    TagTasksFetch,
    TagTaskCreateSuccess,
    TagTaskUpdateSuccess,
    TagTaskDeleteSuccess,
    TagTaskSet,
    TagTaskBrowseFetch,
    TagTasksError,
    TagTasksActionTypes,
    TagTasksFetchLazyMeta,
    TagTasksSet,
    TagTaskUpdate, TagTaskDelete,
} from './tag-tasks.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {AppState} from '../../../../store/app.reducer';
import {State} from './tag-tasks.reducer';
import {DialogHide} from '../../../../forms/tags/task-create/store/task-create-form.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new TagTasksError());
};

@Injectable()
export class TagTasksEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private conPointService: ConPointService) {}

    @Effect()
    tasksFetch = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTasksFetch),
        switchMap((tasksActions: TagTasksFetch) => {
            this.store.dispatch(new TagTasksFetchLazyMeta({lazyLoad: tasksActions.payload}));
            return this.conPointService.getTagTasksList({lazyLoad: tasksActions.payload}).pipe(
                map((result) => {
                    const tasks = result.data.GetTagTasksList.result?.map(task => {
                        return {...task};
                    });

                    return {
                        tasks,
                        total: result.data.GetTagTasksList.metadata.total,
                        count: result.data.GetTagTasksList.metadata.count
                    };
                }),
                map(({tasks, total, count}) => {
                    return new TagTasksSet({tasks: tasks ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    taskFetch = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTaskFetch),
        switchMap((tasksActions: TagTaskFetch) => {
            return this.conPointService.getTagTask({id: tasksActions.payload.taskId}).pipe(
                map((result) => {
                    return new TagTaskSet({task: result.data.GetTagTask});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    taskBrowseFetch = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTaskBrowseFetch),
        switchMap((tasksActions: TagTaskBrowseFetch) => {
            return this.conPointService.getTagTaskBrowse({
                projectKeyName: tasksActions.payload.projectKeyName,
                tagSequenceNumber: tasksActions.payload.tagSequenceNumber,
            }).pipe(
                map((result) => {
                    return new TagTaskSet({task: result.data.GetTagTaskBrowse});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    taskCreate = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTaskCreate),
        withLatestFrom(this.store.select('tagTasks')),
        switchMap<[TagTaskUpdate, State], Observable<any>>(([tasksActions, tasksState]) => {
            return this.conPointService.createTagTask(
                {task: tasksActions.payload.task}).pipe(
                map(() => {
                    this.store.dispatch(new TagTasksFetch(tasksState.currentLazyLoad));
                    this.store.dispatch(new DialogHide());
                    return new TagTaskCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    taskUpdate = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTaskUpdate),
        withLatestFrom(this.store.select('tagTasks')),
        switchMap<[TagTaskUpdate, State], Observable<any>>(([tasksActions, tasksState]) => {
            return this.conPointService.updateTagTask({task: tasksActions.payload.task, action: tasksActions.payload.action}).pipe(
                map((data) => {
                    // this.store.dispatch(new TagTaskFormActions.DialogHide());
                    this.store.dispatch(new TagTasksFetch(tasksState.currentLazyLoad));
                    this.store.dispatch(new TagTaskSet({task: data.data.UpdateTagTask}));
                    return new TagTaskUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    taskDelete = this.actions$.pipe(
        ofType(TagTasksActionTypes.TagTaskDelete),
        withLatestFrom(this.store.select('tagTasks')),
        switchMap<[TagTaskDelete, State], Observable<any>>(([tasksActions, tasksState]) => {
            return this.conPointService.deleteTagTask({id: tasksActions.payload.taskId}).pipe(
                map(() => {
                    this.store.dispatch(new TagTasksFetch(tasksState.currentLazyLoad));
                    return new TagTaskDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
