import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Address = {
  __typename?: 'Address';
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
};

export type AddressInput = {
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryInput>;
};

export type AreaOf = {
  __typename?: 'AreaOf';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

export type AreaOfInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

export type Auth = {
  __typename?: 'Auth';
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  token: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  expiresIn: Scalars['Int'];
  permissions: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  enabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
};

export type ContactInput = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type ContractCategory = {
  __typename?: 'ContractCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ContractCategoryInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['Int']>;
  isoCode?: Maybe<Scalars['String']>;
  isoCode2?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryInput = {
  id?: Maybe<Scalars['Int']>;
  isoCode?: Maybe<Scalars['String']>;
  isoCode2?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomData = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DateTimeImmutable = {
  __typename?: 'DateTimeImmutable';
  date?: Maybe<Scalars['String']>;
  timezone_type?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
};

export type ExportFile = {
  __typename?: 'ExportFile';
  type: Scalars['String'];
  fileContentBase64?: Maybe<Scalars['String']>;
};

export type GenericText = {
  __typename?: 'GenericText';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  precautionSuggestion?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<AreaOf>;
};

export type GenericTextInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  precautionSuggestion?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
};

export type GetAreasOfListResult = {
  __typename?: 'GetAreasOfListResult';
  result?: Maybe<Array<Maybe<AreaOf>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetContactsListResult = {
  __typename?: 'GetContactsListResult';
  result?: Maybe<Array<Maybe<Contact>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetContractCategoryListResult = {
  __typename?: 'GetContractCategoryListResult';
  result?: Maybe<Array<Maybe<ContractCategory>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetCountryListResult = {
  __typename?: 'GetCountryListResult';
  result?: Maybe<Array<Maybe<Country>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetGenericTextsListResult = {
  __typename?: 'GetGenericTextsListResult';
  result?: Maybe<Array<Maybe<GenericText>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetProjectsListResult = {
  __typename?: 'GetProjectsListResult';
  result?: Maybe<Array<Maybe<Project>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetReportsListResult = {
  __typename?: 'GetReportsListResult';
  result?: Maybe<Array<Maybe<Report>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetSubjectsListResult = {
  __typename?: 'GetSubjectsListResult';
  result?: Maybe<Array<Maybe<Subject>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetTagDefectsListResult = {
  __typename?: 'GetTagDefectsListResult';
  result?: Maybe<Array<Maybe<TagDefect>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetTagGoodPracticeListResult = {
  __typename?: 'GetTagGoodPracticeListResult';
  result?: Maybe<Array<Maybe<TagGoodPractice>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetTagTasksListResult = {
  __typename?: 'GetTagTasksListResult';
  result?: Maybe<Array<Maybe<TagTask>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type GetUsersListResult = {
  __typename?: 'GetUsersListResult';
  result?: Maybe<Array<Maybe<User>>>;
  metadata?: Maybe<LazyLoadMeta>;
};

export type Invitation = {
  __typename?: 'Invitation';
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  subject?: Maybe<Subject>;
  project?: Maybe<Project>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type LazyLoad = {
  first?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  multiSortMeta?: Maybe<Array<Maybe<LazyLoadSort>>>;
  filters?: Maybe<Array<Maybe<LazyLoadFilter>>>;
  globalFilter?: Maybe<Scalars['String']>;
  customData?: Maybe<Array<Maybe<CustomData>>>;
};

export type LazyLoadFilter = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  matchMode?: Maybe<Scalars['String']>;
};

export type LazyLoadMeta = {
  __typename?: 'LazyLoadMeta';
  count?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type LazyLoadSort = {
  field?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type Licence = {
  __typename?: 'Licence';
  available?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Int']>;
};

export type MediaFileInput = {
  fileName: Scalars['String'];
  fileState: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** CreateReport mutation description */
  CreateReport?: Maybe<Report>;
  /** UpdateReport mutation description */
  UpdateReport?: Maybe<Report>;
  /** UpdateReportGeneralSettings mutation description */
  UpdateReportGeneralSettings?: Maybe<ReportSettings>;
  /** DeleteReport mutation description */
  DeleteReport?: Maybe<Scalars['Boolean']>;
  /** CreateAreaOf mutation description */
  CreateAreaOf?: Maybe<AreaOf>;
  /** UpdateAreaOf mutation description */
  UpdateAreaOf?: Maybe<AreaOf>;
  /** DeleteAreaOf mutation description */
  DeleteAreaOf?: Maybe<Scalars['Boolean']>;
  /** CreateGenericText mutation description */
  CreateGenericText?: Maybe<GenericText>;
  /** UpdateGenericText mutation description */
  UpdateGenericText?: Maybe<GenericText>;
  /** DeleteGenericText mutation description */
  DeleteGenericText?: Maybe<Scalars['Boolean']>;
  /** CreateContractCategory mutation description */
  CreateContractCategory?: Maybe<ContractCategory>;
  /** CreateProject mutation description */
  CreateProject?: Maybe<Project>;
  /** UpdateProject mutation description */
  UpdateProject?: Maybe<Project>;
  /** DeleteProject mutation description */
  DeleteProject?: Maybe<Scalars['Boolean']>;
  /** PasswordReset mutation description */
  PasswordReset?: Maybe<Scalars['Boolean']>;
  /** CreateUser mutation description */
  CreateUser?: Maybe<User>;
  /** FinishUser mutation description */
  FinishUser?: Maybe<Scalars['Boolean']>;
  /** UpdateUser mutation description */
  UpdateUser?: Maybe<User>;
  /** DeleteUser mutation description */
  DeleteUser?: Maybe<Scalars['Boolean']>;
  /** CreateContact mutation description */
  CreateContact?: Maybe<Contact>;
  /** UpdateContact mutation description */
  UpdateContact?: Maybe<Contact>;
  /** DeleteContact mutation description */
  DeleteContact?: Maybe<Scalars['Boolean']>;
  /** CreateSubject mutation description */
  CreateSubject?: Maybe<Subject>;
  /** CreateSubjectOsh mutation description */
  CreateSubjectOsh?: Maybe<Scalars['Boolean']>;
  /** ResendSubjectOshConfirmation mutation description */
  ResendSubjectOshConfirmation?: Maybe<Scalars['Boolean']>;
  /** UpdateSubject mutation description */
  UpdateSubject?: Maybe<Subject>;
  /** DeleteSubject mutation description */
  DeleteSubject?: Maybe<Scalars['Boolean']>;
  /** SubjectUserUnAssign mutation description */
  SubjectUserUnAssign?: Maybe<Scalars['Boolean']>;
  /** CreateTagDefect mutation description */
  CreateTagDefect?: Maybe<TagDefect>;
  /** UpdateTagDefect mutation description */
  UpdateTagDefect?: Maybe<TagDefect>;
  /** DeleteTagDefect mutation description */
  DeleteTagDefect?: Maybe<Scalars['Boolean']>;
  /** CreateTagTask mutation description */
  CreateTagTask?: Maybe<TagTask>;
  /** UpdateTagTask mutation description */
  UpdateTagTask?: Maybe<TagTask>;
  /** DeleteTagTask mutation description */
  DeleteTagTask?: Maybe<Scalars['Boolean']>;
  /** CreateTagGoodPractice mutation description */
  CreateTagGoodPractice?: Maybe<TagGoodPractice>;
  /** UpdateTagGoodPractice mutation description */
  UpdateTagGoodPractice?: Maybe<TagGoodPractice>;
  /** DeleteTagGoodPractice mutation description */
  DeleteTagGoodPractice?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateReportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  report?: Maybe<ReportInput>;
};


export type MutationUpdateReportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  report?: Maybe<ReportInput>;
};


export type MutationUpdateReportGeneralSettingsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  settings?: Maybe<ReportSettingsInput>;
};


export type MutationDeleteReportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationCreateAreaOfArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<AreaOfInput>;
};


export type MutationUpdateAreaOfArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<AreaOfInput>;
};


export type MutationDeleteAreaOfArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationCreateGenericTextArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  genericText?: Maybe<GenericTextInput>;
};


export type MutationUpdateGenericTextArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  genericText?: Maybe<GenericTextInput>;
};


export type MutationDeleteGenericTextArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationCreateContractCategoryArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  contractCategory?: Maybe<ContractCategoryInput>;
};


export type MutationCreateProjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectInput>;
};


export type MutationUpdateProjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectInput>;
};


export type MutationDeleteProjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type MutationPasswordResetArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  user?: Maybe<UserInput>;
};


export type MutationFinishUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  user?: Maybe<UserInput>;
  accountCreationCode: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  user?: Maybe<UserInput>;
};


export type MutationDeleteUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationCreateContactArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  contact?: Maybe<ContactInput>;
};


export type MutationUpdateContactArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  contact?: Maybe<ContactInput>;
};


export type MutationDeleteContactArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
};


export type MutationCreateSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subject?: Maybe<SubjectAresInput>;
};


export type MutationCreateSubjectOshArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subject?: Maybe<SubjectOshInput>;
};


export type MutationResendSubjectOshConfirmationArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  companyCode: Scalars['String'];
};


export type MutationUpdateSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subject?: Maybe<SubjectAresInput>;
};


export type MutationDeleteSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subjectId: Scalars['Int'];
};


export type MutationSubjectUserUnAssignArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  projectId?: Maybe<Scalars['Int']>;
};


export type MutationCreateTagDefectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  defect?: Maybe<TagDefectInput>;
};


export type MutationUpdateTagDefectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  defect?: Maybe<TagDefectInput>;
  action?: Maybe<Scalars['String']>;
};


export type MutationDeleteTagDefectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationCreateTagTaskArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  task?: Maybe<TagTaskInput>;
};


export type MutationUpdateTagTaskArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  task?: Maybe<TagTaskInput>;
  action?: Maybe<Scalars['String']>;
};


export type MutationDeleteTagTaskArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationCreateTagGoodPracticeArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  goodPractice?: Maybe<TagGoodPracticeInput>;
};


export type MutationUpdateTagGoodPracticeArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  goodPractice?: Maybe<TagGoodPracticeInput>;
};


export type MutationDeleteTagGoodPracticeArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  permissions: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  contractNumber?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractCategory?: Maybe<ContractCategory>;
  investor?: Maybe<Subject>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  supervisors?: Maybe<Array<Maybe<Scalars['String']>>>;
  technicians?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  expectedStartDate?: Maybe<DateTimeImmutable>;
  expectedEndDate?: Maybe<DateTimeImmutable>;
  expectedScope?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  subjects?: Maybe<Array<Maybe<SubjectProject>>>;
  keyName?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<ProjectDocument>>>;
};

export type ProjectDocument = {
  __typename?: 'ProjectDocument';
  name: Scalars['String'];
  description: Scalars['String'];
  hash: Scalars['String'];
};

export type ProjectDocumentInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ProjectInput = {
  id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  contractNumber: Scalars['String'];
  contractName: Scalars['String'];
  contractCategory: Scalars['Int'];
  investor: Scalars['Int'];
  contacts?: Maybe<Array<Maybe<Scalars['Int']>>>;
  supervisors?: Maybe<Array<Maybe<Scalars['String']>>>;
  technicians?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  expectedStartDate: Scalars['String'];
  expectedEndDate: Scalars['String'];
  expectedScope: Scalars['String'];
  subjects?: Maybe<Array<Maybe<SubjectProjectInput>>>;
  keyName: Scalars['String'];
  documents?: Maybe<Array<Maybe<ProjectDocumentInput>>>;
};

export type ProjectKeyNameResult = {
  __typename?: 'ProjectKeyNameResult';
  state?: Maybe<Scalars['Boolean']>;
  suggestion?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** GetCountryList query description */
  GetCountryList?: Maybe<GetCountryListResult>;
  /** SendEntity query description */
  SendEntity?: Maybe<Scalars['Boolean']>;
  /** GenerateReport query description */
  GenerateReport?: Maybe<Scalars['String']>;
  /** GetReport query description */
  GetReport?: Maybe<Report>;
  /** GetReportGeneralSettings query description */
  GetReportGeneralSettings?: Maybe<ReportSettings>;
  /** GetReportLVSettings query description */
  GetReportLVSettings?: Maybe<ReportLvSettings>;
  /** GetReportsList query description */
  GetReportsList?: Maybe<GetReportsListResult>;
  /** GetAreaOf query description */
  GetAreaOf?: Maybe<AreaOf>;
  /** GetAreaOfByName query description */
  GetAreaOfByName?: Maybe<AreaOf>;
  /** GetAreasOfList query description */
  GetAreasOfList?: Maybe<GetAreasOfListResult>;
  /** GetGenericText query description */
  GetGenericText?: Maybe<GenericText>;
  /** GetGenericTextByName query description */
  GetGenericTextByName?: Maybe<GenericText>;
  /** GetGenericTextsList query description */
  GetGenericTextsList?: Maybe<GetGenericTextsListResult>;
  /** GenericTextsExport query description */
  GenericTextsExport?: Maybe<ExportFile>;
  /** GetContractCategoryList query description */
  GetContractCategoryList?: Maybe<GetContractCategoryListResult>;
  /** GetContractCategory query description */
  GetContractCategory?: Maybe<ContractCategory>;
  /** GetProjectsList query description */
  GetProjectsList?: Maybe<GetProjectsListResult>;
  /** GetProject query description */
  GetProject?: Maybe<Project>;
  /** GetProjectUsers query description */
  GetProjectUsers?: Maybe<Array<Maybe<User>>>;
  /** GetProjectKeyName query description */
  GetProjectKeyName?: Maybe<ProjectKeyNameResult>;
  /** Authenticate query description */
  Authenticate?: Maybe<Auth>;
  /** GetPermissions query description */
  GetPermissions?: Maybe<Permissions>;
  /** GetUsersList query description */
  GetUsersList?: Maybe<GetUsersListResult>;
  /** GetUser query description */
  GetUser?: Maybe<User>;
  /** SendInvitation query description */
  SendInvitation?: Maybe<Scalars['Boolean']>;
  /** ForgetPassword query description */
  ForgetPassword?: Maybe<Scalars['Boolean']>;
  /** GetContactsList query description */
  GetContactsList?: Maybe<GetContactsListResult>;
  /** GetContact query description */
  GetContact?: Maybe<Contact>;
  /** GetSubjectsList query description */
  GetSubjectsList?: Maybe<GetSubjectsListResult>;
  /** GetSubject query description */
  GetSubject?: Maybe<Subject>;
  /** GetAresSubjectInfo query description */
  GetAresSubjectInfo?: Maybe<SubjectAres>;
  /** GetCurrentSubjectDetails query description */
  GetCurrentSubjectDetails?: Maybe<SubjectDetails>;
  /** ValidateSubject query description */
  ValidateSubject?: Maybe<SubjectValidation>;
  /** GetTagDefectsList query description */
  GetTagDefectsList?: Maybe<GetTagDefectsListResult>;
  /** GetTagDefect query description */
  GetTagDefect?: Maybe<TagDefect>;
  /** GetTagDefectBrowse query description */
  GetTagDefectBrowse?: Maybe<TagDefect>;
  /** GetTagTasksList query description */
  GetTagTasksList?: Maybe<GetTagTasksListResult>;
  /** GetTagTask query description */
  GetTagTask?: Maybe<TagTask>;
  /** GetTagTaskBrowse query description */
  GetTagTaskBrowse?: Maybe<TagTask>;
  /** GetTagGoodPracticeList query description */
  GetTagGoodPracticeList?: Maybe<GetTagGoodPracticeListResult>;
  /** GetTagGoodPractice query description */
  GetTagGoodPractice?: Maybe<TagGoodPractice>;
  /** GetTagGoodPracticeBrowse query description */
  GetTagGoodPracticeBrowse?: Maybe<TagGoodPractice>;
  /** GetStateStatistics query description */
  GetStateStatistics?: Maybe<StateStatistics>;
};


export type QueryGetCountryListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QuerySendEntityArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  type: Scalars['Int'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGenerateReportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryGetReportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetReportGeneralSettingsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryGetReportLvSettingsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryGetReportsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetAreaOfArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetAreaOfByNameArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type: Scalars['Int'];
};


export type QueryGetAreasOfListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetGenericTextArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


export type QueryGetGenericTextByNameArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};


export type QueryGetGenericTextsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGenericTextsExportArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
};


export type QueryGetContractCategoryListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetContractCategoryArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetProjectsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  reqData: Scalars['String'];
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetProjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type QueryGetProjectUsersArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type QueryGetProjectKeyNameArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectName?: Maybe<Scalars['String']>;
  checkKeyName?: Maybe<Scalars['String']>;
};


export type QueryAuthenticateArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryGetPermissionsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryGetUsersListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetUserArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QuerySendInvitationArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryForgetPasswordArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryGetContactsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  selectedSubject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetContactArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  contactId: Scalars['Int'];
};


export type QueryGetSubjectsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  reqData: Scalars['String'];
  projectId?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  subjectId: Scalars['Int'];
};


export type QueryGetAresSubjectInfoArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
};


export type QueryGetCurrentSubjectDetailsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};


export type QueryValidateSubjectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
};


export type QueryGetTagDefectsListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetTagDefectArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetTagDefectBrowseArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectKeyName?: Maybe<Scalars['String']>;
  tagSequenceNumber?: Maybe<Scalars['Int']>;
};


export type QueryGetTagTasksListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetTagTaskArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetTagTaskBrowseArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectKeyName?: Maybe<Scalars['String']>;
  tagSequenceNumber?: Maybe<Scalars['Int']>;
};


export type QueryGetTagGoodPracticeListArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  lazyLoad?: Maybe<LazyLoad>;
};


export type QueryGetTagGoodPracticeArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetTagGoodPracticeBrowseArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
  projectKeyName?: Maybe<Scalars['String']>;
  tagSequenceNumber?: Maybe<Scalars['Int']>;
};


export type QueryGetStateStatisticsArgs = {
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
};

export type Report = {
  __typename?: 'Report';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  header?: Maybe<ReportHeader>;
  defects?: Maybe<ReportTag>;
  tasks?: Maybe<ReportTag>;
  goodPractice?: Maybe<ReportTag>;
  createdBy?: Maybe<User>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
};

export type ReportHeader = {
  __typename?: 'ReportHeader';
  investorEnabled?: Maybe<Scalars['Boolean']>;
  oshEnabled?: Maybe<Scalars['Boolean']>;
  customName?: Maybe<Scalars['String']>;
};

export type ReportHeaderInput = {
  investorEnabled?: Maybe<Scalars['Boolean']>;
  oshEnabled?: Maybe<Scalars['Boolean']>;
  customName?: Maybe<Scalars['String']>;
};

export type ReportInput = {
  id?: Maybe<Scalars['Int']>;
  header?: Maybe<ReportHeaderInput>;
  defects?: Maybe<ReportTagInput>;
  tasks?: Maybe<ReportTagInput>;
  goodPractice?: Maybe<ReportTagInput>;
};

export type ReportLvSettings = {
  __typename?: 'ReportLVSettings';
  defectMessage?: Maybe<Scalars['String']>;
  taskMessage?: Maybe<Scalars['String']>;
  goodPracticeMessage?: Maybe<Scalars['String']>;
};

export type ReportSettings = {
  __typename?: 'ReportSettings';
  customDefectTitle?: Maybe<Scalars['String']>;
  customTaskTitle?: Maybe<Scalars['String']>;
  customGoodPracticeTitle?: Maybe<Scalars['String']>;
};

export type ReportSettingsInput = {
  customDefectTitle?: Maybe<Scalars['String']>;
  customTaskTitle?: Maybe<Scalars['String']>;
  customGoodPracticeTitle?: Maybe<Scalars['String']>;
};

export type ReportTag = {
  __typename?: 'ReportTag';
  enabled: Scalars['Boolean'];
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  message?: Maybe<Scalars['String']>;
};

export type ReportTagInput = {
  enabled: Scalars['Boolean'];
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  message?: Maybe<Scalars['String']>;
};

export type StateStatistics = {
  __typename?: 'StateStatistics';
  pending?: Maybe<Array<Maybe<Scalars['Int']>>>;
  inProgress?: Maybe<Array<Maybe<Scalars['Int']>>>;
  waitingForApproval?: Maybe<Array<Maybe<Scalars['Int']>>>;
  approved?: Maybe<Array<Maybe<Scalars['Int']>>>;
  afterDeadline?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Subject = {
  __typename?: 'Subject';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  administrators?: Maybe<Array<Maybe<User>>>;
  licence?: Maybe<Licence>;
  projects?: Maybe<Array<Maybe<Project>>>;
};

export type SubjectAres = {
  __typename?: 'SubjectAres';
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SubjectAresInput = {
  id?: Maybe<Scalars['Int']>;
  type: Scalars['Int'];
  companyCode: Scalars['String'];
  country?: Maybe<CountryInput>;
  enabled: Scalars['Boolean'];
  administrators?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubjectDetails = {
  __typename?: 'SubjectDetails';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  administrators?: Maybe<Array<Maybe<User>>>;
  licence?: Maybe<Licence>;
  projects?: Maybe<Array<Maybe<Project>>>;
  users?: Maybe<Array<Maybe<User>>>;
  invitations?: Maybe<Array<Maybe<Invitation>>>;
};

export type SubjectInput = {
  id?: Maybe<Scalars['Int']>;
  type: Scalars['Int'];
  address?: Maybe<AddressInput>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyCode: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type SubjectOshInput = {
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
  licenceCount: Scalars['Int'];
  administrators?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubjectProject = {
  __typename?: 'SubjectProject';
  subject?: Maybe<Subject>;
  technicians?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubjectProjectInput = {
  subject?: Maybe<SubjectInput>;
  technicians?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubjectValidation = {
  __typename?: 'SubjectValidation';
  isValid?: Maybe<Scalars['Boolean']>;
  alreadyExists?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
};

export type TagDefect = {
  __typename?: 'TagDefect';
  id?: Maybe<Scalars['Int']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<TagState>;
  severity?: Maybe<TagSeverity>;
  project?: Maybe<Project>;
  reportedByUser?: Maybe<User>;
  assignedToSubject?: Maybe<Subject>;
  assignedToSubjects?: Maybe<Array<Maybe<Subject>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<User>;
  responsiblePerson?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  completedAt?: Maybe<DateTimeImmutable>;
  occurredAt?: Maybe<DateTimeImmutable>;
  resolvedAt?: Maybe<DateTimeImmutable>;
  areaOf?: Maybe<AreaOf>;
  deadline?: Maybe<DateTimeImmutable>;
  afterDeadline?: Maybe<Scalars['Boolean']>;
  deadlineTerm?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  precautionSuggestion?: Maybe<Scalars['String']>;
  resolvedDescription?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  resolvedImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TagDefectInput = {
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  severity: Scalars['Int'];
  project: Scalars['Int'];
  assignedToSubject?: Maybe<Scalars['Int']>;
  assignedToSubjects?: Maybe<Array<Maybe<Scalars['Int']>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<Scalars['Int']>;
  responsiblePerson?: Maybe<Scalars['String']>;
  areaOf?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['String']>;
  deadlineTerm?: Maybe<Scalars['String']>;
  occurredAt?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  precautionSuggestion?: Maybe<Scalars['String']>;
  resolvedDescription?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<MediaFileInput>>>;
  resolvedImages?: Maybe<Array<Maybe<MediaFileInput>>>;
  note?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TagGoodPractice = {
  __typename?: 'TagGoodPractice';
  id?: Maybe<Scalars['Int']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<TagState>;
  project?: Maybe<Project>;
  reportedByUser?: Maybe<User>;
  assignedToSubject?: Maybe<Subject>;
  assignedToSubjects?: Maybe<Array<Maybe<Subject>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<User>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  completedAt?: Maybe<DateTimeImmutable>;
  occurredAt?: Maybe<DateTimeImmutable>;
  areaOf?: Maybe<AreaOf>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TagGoodPracticeInput = {
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  project: Scalars['Int'];
  assignedToSubject?: Maybe<Scalars['Int']>;
  assignedToSubjects?: Maybe<Array<Maybe<Scalars['Int']>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
  occurredAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<MediaFileInput>>>;
  note?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TagSeverity = {
  __typename?: 'TagSeverity';
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type TagState = {
  __typename?: 'TagState';
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type TagTask = {
  __typename?: 'TagTask';
  id?: Maybe<Scalars['Int']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<TagState>;
  severity?: Maybe<TagSeverity>;
  project?: Maybe<Project>;
  reportedByUser?: Maybe<User>;
  assignedToSubject?: Maybe<Subject>;
  assignedToSubjects?: Maybe<Array<Maybe<Subject>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<User>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  completedAt?: Maybe<DateTimeImmutable>;
  occurredAt?: Maybe<DateTimeImmutable>;
  areaOf?: Maybe<AreaOf>;
  deadline?: Maybe<DateTimeImmutable>;
  afterDeadline?: Maybe<Scalars['Boolean']>;
  deadlineTerm?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  resolvedDescription?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  resolvedImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TagTaskInput = {
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  severity: Scalars['Int'];
  project: Scalars['Int'];
  assignedToSubject?: Maybe<Scalars['Int']>;
  assignedToSubjects?: Maybe<Array<Maybe<Scalars['Int']>>>;
  assignedToSubjectNote?: Maybe<Scalars['String']>;
  assignedToUser?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
  occurredAt?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  deadlineTerm?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  resolvedDescription?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<MediaFileInput>>>;
  resolvedImages?: Maybe<Array<Maybe<MediaFileInput>>>;
  note?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  blocked?: Maybe<Scalars['Boolean']>;
  emailLogin?: Maybe<Scalars['Boolean']>;
  googleLogin?: Maybe<Scalars['Boolean']>;
  facebookLogin?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeImmutable>;
  updatedAt?: Maybe<DateTimeImmutable>;
  lastLoggedIn?: Maybe<DateTimeImmutable>;
  superadmin?: Maybe<Scalars['Boolean']>;
  signatureHash?: Maybe<Scalars['String']>;
  signatureDescription?: Maybe<Scalars['String']>;
  currentRoleName?: Maybe<Scalars['String']>;
  currentProject?: Maybe<Scalars['Int']>;
  assignedInProjects?: Maybe<Array<Maybe<Project>>>;
};

export type UserInput = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  blocked?: Maybe<Scalars['Boolean']>;
  emailLogin?: Maybe<Scalars['Boolean']>;
  googleLogin?: Maybe<Scalars['Boolean']>;
  facebookLogin?: Maybe<Scalars['Boolean']>;
  superadmin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  signatureFileBase64?: Maybe<Scalars['String']>;
  signatureDescription?: Maybe<Scalars['String']>;
};

export type AreaOfDataFragmentFragment = (
  { __typename?: 'AreaOf' }
  & Pick<AreaOf, 'id' | 'name' | 'description' | 'type'>
);

export type GetAreasOfListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  type?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetAreasOfListQuery = (
  { __typename?: 'Query' }
  & { GetAreasOfList?: Maybe<(
    { __typename?: 'GetAreasOfListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'AreaOf' }
      & AreaOfDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetAreaOfByNameQueryVariables = Exact<{
  name: Scalars['String'];
  type: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetAreaOfByNameQuery = (
  { __typename?: 'Query' }
  & { GetAreaOfByName?: Maybe<(
    { __typename?: 'AreaOf' }
    & AreaOfDataFragmentFragment
  )> }
);

export type GetAreaOfQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetAreaOfQuery = (
  { __typename?: 'Query' }
  & { GetAreaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & AreaOfDataFragmentFragment
  )> }
);

export type CreateAreaOfMutationVariables = Exact<{
  areaOf?: Maybe<AreaOfInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateAreaOfMutation = (
  { __typename?: 'Mutation' }
  & { CreateAreaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & AreaOfDataFragmentFragment
  )> }
);

export type UpdateAreaOfMutationVariables = Exact<{
  areaOf?: Maybe<AreaOfInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateAreaOfMutation = (
  { __typename?: 'Mutation' }
  & { UpdateAreaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & AreaOfDataFragmentFragment
  )> }
);

export type DeleteAreaOfMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteAreaOfMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteAreaOf'>
);

export type GetContactsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetContactsListQuery = (
  { __typename?: 'Query' }
  & { GetContactsList?: Maybe<(
    { __typename?: 'GetContactsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'companyName' | 'function' | 'enabled'>
      & { createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )> }
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetContactQueryVariables = Exact<{
  contactId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { GetContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'companyName' | 'function' | 'enabled'>
    & { createdAt?: Maybe<(
      { __typename?: 'DateTimeImmutable' }
      & Pick<DateTimeImmutable, 'date'>
    )>, updatedAt?: Maybe<(
      { __typename?: 'DateTimeImmutable' }
      & Pick<DateTimeImmutable, 'date'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'zip' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateContactMutationVariables = Exact<{
  contact?: Maybe<ContactInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { CreateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'companyName' | 'function'>
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  contact?: Maybe<ContactInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { UpdateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'companyName' | 'function'>
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  contactId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteContact'>
);

export type GetContractCategoryListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetContractCategoryListQuery = (
  { __typename?: 'Query' }
  & { GetContractCategoryList?: Maybe<(
    { __typename?: 'GetContractCategoryListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ContractCategory' }
      & Pick<ContractCategory, 'id' | 'name' | 'description'>
    )>>> }
  )> }
);

export type GetContractCategoryQueryVariables = Exact<{
  name: Scalars['String'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetContractCategoryQuery = (
  { __typename?: 'Query' }
  & { GetContractCategory?: Maybe<(
    { __typename?: 'ContractCategory' }
    & Pick<ContractCategory, 'id' | 'name' | 'description'>
  )> }
);

export type CreateContractCategoryMutationVariables = Exact<{
  contractCategory?: Maybe<ContractCategoryInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateContractCategoryMutation = (
  { __typename?: 'Mutation' }
  & { CreateContractCategory?: Maybe<(
    { __typename?: 'ContractCategory' }
    & Pick<ContractCategory, 'id' | 'name' | 'description'>
  )> }
);

export type SendEntityQueryVariables = Exact<{
  type: Scalars['Int'];
  id: Scalars['Int'];
  emails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type SendEntityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SendEntity'>
);

export type GetCountryListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
}>;


export type GetCountryListQuery = (
  { __typename?: 'Query' }
  & { GetCountryList?: Maybe<(
    { __typename?: 'GetCountryListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'isoCode' | 'isoCode2' | 'name' | 'alias'>
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GenericTextDataFragmentFragment = (
  { __typename?: 'GenericText' }
  & Pick<GenericText, 'id' | 'name' | 'description' | 'precautionSuggestion' | 'type'>
  & { areaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & Pick<AreaOf, 'id' | 'name'>
  )> }
);

export type GetGenericTextsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  type?: Maybe<Scalars['Int']>;
  areaOf?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetGenericTextsListQuery = (
  { __typename?: 'Query' }
  & { GetGenericTextsList?: Maybe<(
    { __typename?: 'GetGenericTextsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'GenericText' }
      & GenericTextDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetGenericTextByNameQueryVariables = Exact<{
  name: Scalars['String'];
  type?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetGenericTextByNameQuery = (
  { __typename?: 'Query' }
  & { GetGenericTextByName?: Maybe<(
    { __typename?: 'GenericText' }
    & GenericTextDataFragmentFragment
  )> }
);

export type GetGenericTextQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetGenericTextQuery = (
  { __typename?: 'Query' }
  & { GetGenericText?: Maybe<(
    { __typename?: 'GenericText' }
    & GenericTextDataFragmentFragment
  )> }
);

export type GenericTextsExportQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GenericTextsExportQuery = (
  { __typename?: 'Query' }
  & { GenericTextsExport?: Maybe<(
    { __typename?: 'ExportFile' }
    & Pick<ExportFile, 'type' | 'fileContentBase64'>
  )> }
);

export type CreateGenericTextMutationVariables = Exact<{
  genericText?: Maybe<GenericTextInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateGenericTextMutation = (
  { __typename?: 'Mutation' }
  & { CreateGenericText?: Maybe<(
    { __typename?: 'GenericText' }
    & GenericTextDataFragmentFragment
  )> }
);

export type UpdateGenericTextMutationVariables = Exact<{
  genericText?: Maybe<GenericTextInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateGenericTextMutation = (
  { __typename?: 'Mutation' }
  & { UpdateGenericText?: Maybe<(
    { __typename?: 'GenericText' }
    & GenericTextDataFragmentFragment
  )> }
);

export type DeleteGenericTextMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteGenericTextMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteGenericText'>
);

export type GetProjectsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectsListQuery = (
  { __typename?: 'Query' }
  & { GetProjectsList?: Maybe<(
    { __typename?: 'GetProjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'contractName' | 'contractNumber' | 'expectedScope' | 'active' | 'supervisors' | 'technicians' | 'keyName'>
      & { contractCategory?: Maybe<(
        { __typename?: 'ContractCategory' }
        & Pick<ContractCategory, 'id' | 'name' | 'description'>
      )>, expectedStartDate?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, expectedEndDate?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, investor?: Maybe<(
        { __typename?: 'Subject' }
        & Pick<Subject, 'name'>
      )> }
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetProjectsDropdownQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectsDropdownQuery = (
  { __typename?: 'Query' }
  & { GetProjectsList?: Maybe<(
    { __typename?: 'GetProjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'contractName'>
    )>>> }
  )> }
);

export type GetProjectsAppListQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectsAppListQuery = (
  { __typename?: 'Query' }
  & { GetProjectsList?: Maybe<(
    { __typename?: 'GetProjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'contractName' | 'keyName'>
    )>>> }
  )> }
);

export type GetProjectQueryVariables = Exact<{
  projectId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { GetProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'contractName' | 'contractNumber' | 'supervisors' | 'technicians' | 'expectedScope' | 'note' | 'active' | 'keyName'>
    & { contractCategory?: Maybe<(
      { __typename?: 'ContractCategory' }
      & Pick<ContractCategory, 'id' | 'name' | 'description'>
    )>, investor?: Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'name' | 'companyCode'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'city' | 'zip'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )> }
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'email' | 'firstName' | 'lastName'>
    )>>>, subjects?: Maybe<Array<Maybe<(
      { __typename?: 'SubjectProject' }
      & Pick<SubjectProject, 'technicians'>
      & { subject?: Maybe<(
        { __typename?: 'Subject' }
        & Pick<Subject, 'id' | 'type' | 'name' | 'companyCode' | 'enabled'>
      )> }
    )>>>, expectedStartDate?: Maybe<(
      { __typename?: 'DateTimeImmutable' }
      & Pick<DateTimeImmutable, 'date'>
    )>, expectedEndDate?: Maybe<(
      { __typename?: 'DateTimeImmutable' }
      & Pick<DateTimeImmutable, 'date'>
    )>, documents?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectDocument' }
      & Pick<ProjectDocument, 'name' | 'description' | 'hash'>
    )>>> }
  )> }
);

export type GetProjectUsersQueryVariables = Exact<{
  projectId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectUsersQuery = (
  { __typename?: 'Query' }
  & { GetProjectUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )>>> }
);

export type GetProjectKeyNameQueryVariables = Exact<{
  projectName: Scalars['String'];
  checkKeyName?: Maybe<Scalars['String']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetProjectKeyNameQuery = (
  { __typename?: 'Query' }
  & { GetProjectKeyName?: Maybe<(
    { __typename?: 'ProjectKeyNameResult' }
    & Pick<ProjectKeyNameResult, 'state' | 'suggestion'>
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  project?: Maybe<ProjectInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { CreateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type UpdateProjectMutationVariables = Exact<{
  project?: Maybe<ProjectInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { UpdateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteProject'>
);

export type ReportDataFragmentFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'name' | 'code'>
  & { header?: Maybe<(
    { __typename?: 'ReportHeader' }
    & Pick<ReportHeader, 'investorEnabled' | 'oshEnabled' | 'customName'>
  )>, defects?: Maybe<(
    { __typename?: 'ReportTag' }
    & Pick<ReportTag, 'enabled' | 'ids' | 'message'>
  )>, tasks?: Maybe<(
    { __typename?: 'ReportTag' }
    & Pick<ReportTag, 'enabled' | 'ids' | 'message'>
  )>, goodPractice?: Maybe<(
    { __typename?: 'ReportTag' }
    & Pick<ReportTag, 'enabled' | 'ids' | 'message'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  )>, createdAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, updatedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )> }
);

export type ReportSettingsFragmentFragment = (
  { __typename?: 'ReportSettings' }
  & Pick<ReportSettings, 'customDefectTitle' | 'customTaskTitle' | 'customGoodPracticeTitle'>
);

export type ReportLvSettingsFragmentFragment = (
  { __typename?: 'ReportLVSettings' }
  & Pick<ReportLvSettings, 'defectMessage' | 'taskMessage' | 'goodPracticeMessage'>
);

export type GetReportsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetReportsListQuery = (
  { __typename?: 'Query' }
  & { GetReportsList?: Maybe<(
    { __typename?: 'GetReportsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Report' }
      & ReportDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetReportQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { GetReport?: Maybe<(
    { __typename?: 'Report' }
    & ReportDataFragmentFragment
  )> }
);

export type GetReportGeneralSettingsQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetReportGeneralSettingsQuery = (
  { __typename?: 'Query' }
  & { GetReportGeneralSettings?: Maybe<(
    { __typename?: 'ReportSettings' }
    & ReportSettingsFragmentFragment
  )> }
);

export type GetReportLvSettingsQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetReportLvSettingsQuery = (
  { __typename?: 'Query' }
  & { GetReportLVSettings?: Maybe<(
    { __typename?: 'ReportLVSettings' }
    & ReportLvSettingsFragmentFragment
  )> }
);

export type CreateReportMutationVariables = Exact<{
  report?: Maybe<ReportInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { CreateReport?: Maybe<(
    { __typename?: 'Report' }
    & ReportDataFragmentFragment
  )> }
);

export type UpdateReportMutationVariables = Exact<{
  report?: Maybe<ReportInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { UpdateReport?: Maybe<(
    { __typename?: 'Report' }
    & ReportDataFragmentFragment
  )> }
);

export type UpdateReportGeneralSettingsMutationVariables = Exact<{
  settings: ReportSettingsInput;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateReportGeneralSettingsMutation = (
  { __typename?: 'Mutation' }
  & { UpdateReportGeneralSettings?: Maybe<(
    { __typename?: 'ReportSettings' }
    & ReportSettingsFragmentFragment
  )> }
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteReport'>
);

export type GetStateStatisticsQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetStateStatisticsQuery = (
  { __typename?: 'Query' }
  & { GetStateStatistics?: Maybe<(
    { __typename?: 'StateStatistics' }
    & Pick<StateStatistics, 'pending' | 'inProgress' | 'waitingForApproval' | 'approved' | 'afterDeadline'>
  )> }
);

export type GetSubjectsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  type?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetSubjectsListQuery = (
  { __typename?: 'Query' }
  & { GetSubjectsList?: Maybe<(
    { __typename?: 'GetSubjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'name' | 'description' | 'type' | 'companyCode' | 'enabled'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'city' | 'zip'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )>, createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, administrators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )>>> }
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetSubjectsAppListQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetSubjectsAppListQuery = (
  { __typename?: 'Query' }
  & { GetSubjectsList?: Maybe<(
    { __typename?: 'GetSubjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'name' | 'description' | 'type' | 'companyCode' | 'enabled'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'city' | 'zip'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )>, createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, administrators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )>>>, projects?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'contractName' | 'contractNumber'>
      )>>> }
    )>>> }
  )> }
);

export type SubjectsProjectListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  projectId: Scalars['Int'];
  type?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type SubjectsProjectListQuery = (
  { __typename?: 'Query' }
  & { GetSubjectsList?: Maybe<(
    { __typename?: 'GetSubjectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'name' | 'description' | 'type' | 'companyCode' | 'enabled'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'city' | 'zip'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )>, createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, administrators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )>>> }
    )>>> }
  )> }
);

export type GetSubjectQueryVariables = Exact<{
  subjectId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetSubjectQuery = (
  { __typename?: 'Query' }
  & { GetSubject?: Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id' | 'type' | 'name' | 'companyCode' | 'enabled'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'city' | 'zip'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )>, administrators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )>>>, licence?: Maybe<(
      { __typename?: 'Licence' }
      & Pick<Licence, 'available' | 'used'>
    )> }
  )> }
);

export type GetCurrentSubjectDetailsQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetCurrentSubjectDetailsQuery = (
  { __typename?: 'Query' }
  & { GetCurrentSubjectDetails?: Maybe<(
    { __typename?: 'SubjectDetails' }
    & Pick<SubjectDetails, 'id' | 'type' | 'name' | 'companyCode' | 'enabled'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'city' | 'zip'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )>, administrators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )>>>, licence?: Maybe<(
      { __typename?: 'Licence' }
      & Pick<Licence, 'available' | 'used'>
    )>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      & { assignedInProjects?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'contractName'>
      )>>> }
    )>>>, invitations?: Maybe<Array<Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'state' | 'type' | 'role'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, subject?: Maybe<(
        { __typename?: 'Subject' }
        & Pick<Subject, 'name'>
      )>, project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'contractName'>
      )>, createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )> }
    )>>> }
  )> }
);

export type GetSubjectAresInfoQueryVariables = Exact<{
  companyCode: Scalars['String'];
  countryIsoCode2: Scalars['String'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetSubjectAresInfoQuery = (
  { __typename?: 'Query' }
  & { GetAresSubjectInfo?: Maybe<(
    { __typename?: 'SubjectAres' }
    & Pick<SubjectAres, 'companyName' | 'companyCode' | 'city' | 'street' | 'zip'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )> }
  )> }
);

export type UserUnAssignMutationVariables = Exact<{
  userId: Scalars['Int'];
  projectId?: Maybe<Scalars['Int']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UserUnAssignMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubjectUserUnAssign'>
);

export type CreateSubjectMutationVariables = Exact<{
  subject?: Maybe<SubjectAresInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateSubjectMutation = (
  { __typename?: 'Mutation' }
  & { CreateSubject?: Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id'>
  )> }
);

export type UpdateSubjectMutationVariables = Exact<{
  subject?: Maybe<SubjectAresInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateSubjectMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSubject?: Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id'>
  )> }
);

export type DeleteSubjectMutationVariables = Exact<{
  subjectId: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteSubjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteSubject'>
);

export type DefectDataFragmentFragment = (
  { __typename?: 'TagDefect' }
  & Pick<TagDefect, 'id' | 'precautionSuggestion' | 'description' | 'resolvedDescription' | 'images' | 'resolvedImages' | 'note' | 'assignedToSubjectNote' | 'responsiblePerson' | 'afterDeadline' | 'deadlineTerm' | 'link' | 'identifier' | 'location'>
  & { state?: Maybe<(
    { __typename?: 'TagState' }
    & Pick<TagState, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'contractName'>
  )>, severity?: Maybe<(
    { __typename?: 'TagSeverity' }
    & Pick<TagSeverity, 'id' | 'name'>
  )>, areaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & Pick<AreaOf, 'id' | 'name'>
  )>, assignedToSubjects?: Maybe<Array<Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id' | 'name' | 'type' | 'companyCode' | 'enabled'>
  )>>>, assignedToUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, reportedByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, deadline?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, createdAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, updatedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, completedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, occurredAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, resolvedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )> }
);

export type GetTagDefectsListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagDefectsListQuery = (
  { __typename?: 'Query' }
  & { GetTagDefectsList?: Maybe<(
    { __typename?: 'GetTagDefectsListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'TagDefect' }
      & DefectDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetTagDefectQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagDefectQuery = (
  { __typename?: 'Query' }
  & { GetTagDefect?: Maybe<(
    { __typename?: 'TagDefect' }
    & DefectDataFragmentFragment
  )> }
);

export type GetTagDefectBrowseQueryVariables = Exact<{
  projectKeyName: Scalars['String'];
  tagSequenceNumber: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagDefectBrowseQuery = (
  { __typename?: 'Query' }
  & { GetTagDefectBrowse?: Maybe<(
    { __typename?: 'TagDefect' }
    & DefectDataFragmentFragment
  )> }
);

export type CreateTagDefectMutationVariables = Exact<{
  defect?: Maybe<TagDefectInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateTagDefectMutation = (
  { __typename?: 'Mutation' }
  & { CreateTagDefect?: Maybe<(
    { __typename?: 'TagDefect' }
    & Pick<TagDefect, 'id'>
  )> }
);

export type UpdateTagDefectMutationVariables = Exact<{
  defect?: Maybe<TagDefectInput>;
  action?: Maybe<Scalars['String']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateTagDefectMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTagDefect?: Maybe<(
    { __typename?: 'TagDefect' }
    & DefectDataFragmentFragment
  )> }
);

export type DeleteTagDefectMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteTagDefectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTagDefect'>
);

export type GoodPracticeDataFragmentFragment = (
  { __typename?: 'TagGoodPractice' }
  & Pick<TagGoodPractice, 'id' | 'description' | 'images' | 'assignedToSubjectNote' | 'link' | 'identifier' | 'location' | 'note'>
  & { state?: Maybe<(
    { __typename?: 'TagState' }
    & Pick<TagState, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'contractName'>
  )>, areaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & Pick<AreaOf, 'id' | 'name'>
  )>, assignedToSubjects?: Maybe<Array<Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id' | 'name'>
  )>>>, assignedToUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, reportedByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, createdAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, updatedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, completedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, occurredAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )> }
);

export type GetTagGoodPracticeListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagGoodPracticeListQuery = (
  { __typename?: 'Query' }
  & { GetTagGoodPracticeList?: Maybe<(
    { __typename?: 'GetTagGoodPracticeListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'TagGoodPractice' }
      & GoodPracticeDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetTagGoodPracticeQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagGoodPracticeQuery = (
  { __typename?: 'Query' }
  & { GetTagGoodPractice?: Maybe<(
    { __typename?: 'TagGoodPractice' }
    & GoodPracticeDataFragmentFragment
  )> }
);

export type GetTagGoodPracticeBrowseQueryVariables = Exact<{
  projectKeyName: Scalars['String'];
  tagSequenceNumber: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagGoodPracticeBrowseQuery = (
  { __typename?: 'Query' }
  & { GetTagGoodPracticeBrowse?: Maybe<(
    { __typename?: 'TagGoodPractice' }
    & GoodPracticeDataFragmentFragment
  )> }
);

export type CreateTagGoodPracticeMutationVariables = Exact<{
  goodPractice?: Maybe<TagGoodPracticeInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateTagGoodPracticeMutation = (
  { __typename?: 'Mutation' }
  & { CreateTagGoodPractice?: Maybe<(
    { __typename?: 'TagGoodPractice' }
    & Pick<TagGoodPractice, 'id'>
  )> }
);

export type UpdateTagGoodPracticeMutationVariables = Exact<{
  goodPractice?: Maybe<TagGoodPracticeInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateTagGoodPracticeMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTagGoodPractice?: Maybe<(
    { __typename?: 'TagGoodPractice' }
    & GoodPracticeDataFragmentFragment
  )> }
);

export type DeleteTagGoodPracticeMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteTagGoodPracticeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTagGoodPractice'>
);

export type TaskDataFragmentFragment = (
  { __typename?: 'TagTask' }
  & Pick<TagTask, 'id' | 'description' | 'resolvedDescription' | 'images' | 'resolvedImages' | 'note' | 'assignedToSubjectNote' | 'afterDeadline' | 'deadlineTerm' | 'link' | 'identifier' | 'location'>
  & { state?: Maybe<(
    { __typename?: 'TagState' }
    & Pick<TagState, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'contractName'>
  )>, severity?: Maybe<(
    { __typename?: 'TagSeverity' }
    & Pick<TagSeverity, 'id' | 'name'>
  )>, areaOf?: Maybe<(
    { __typename?: 'AreaOf' }
    & Pick<AreaOf, 'id' | 'name'>
  )>, assignedToSubjects?: Maybe<Array<Maybe<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id' | 'name' | 'type' | 'companyCode' | 'enabled'>
  )>>>, assignedToUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, reportedByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>, deadline?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, createdAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, updatedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, completedAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )>, occurredAt?: Maybe<(
    { __typename?: 'DateTimeImmutable' }
    & Pick<DateTimeImmutable, 'date'>
  )> }
);

export type GetTagTasksListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagTasksListQuery = (
  { __typename?: 'Query' }
  & { GetTagTasksList?: Maybe<(
    { __typename?: 'GetTagTasksListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'TagTask' }
      & TaskDataFragmentFragment
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetTagTaskQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagTaskQuery = (
  { __typename?: 'Query' }
  & { GetTagTask?: Maybe<(
    { __typename?: 'TagTask' }
    & TaskDataFragmentFragment
  )> }
);

export type GetTagTaskBrowseQueryVariables = Exact<{
  projectKeyName: Scalars['String'];
  tagSequenceNumber: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetTagTaskBrowseQuery = (
  { __typename?: 'Query' }
  & { GetTagTaskBrowse?: Maybe<(
    { __typename?: 'TagTask' }
    & TaskDataFragmentFragment
  )> }
);

export type CreateTagTaskMutationVariables = Exact<{
  task?: Maybe<TagTaskInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateTagTaskMutation = (
  { __typename?: 'Mutation' }
  & { CreateTagTask?: Maybe<(
    { __typename?: 'TagTask' }
    & Pick<TagTask, 'id'>
  )> }
);

export type UpdateTagTaskMutationVariables = Exact<{
  task?: Maybe<TagTaskInput>;
  action?: Maybe<Scalars['String']>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateTagTaskMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTagTask?: Maybe<(
    { __typename?: 'TagTask' }
    & TaskDataFragmentFragment
  )> }
);

export type DeleteTagTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteTagTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTagTask'>
);

export type GetUsersListQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetUsersListQuery = (
  { __typename?: 'Query' }
  & { GetUsersList?: Maybe<(
    { __typename?: 'GetUsersListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'enabled' | 'superadmin' | 'blocked' | 'emailLogin' | 'googleLogin' | 'facebookLogin' | 'currentRoleName' | 'currentProject'>
      & { createdAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, updatedAt?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )>, lastLoggedIn?: Maybe<(
        { __typename?: 'DateTimeImmutable' }
        & Pick<DateTimeImmutable, 'date'>
      )> }
    )>>>, metadata?: Maybe<(
      { __typename?: 'LazyLoadMeta' }
      & Pick<LazyLoadMeta, 'count' | 'limit' | 'offset' | 'total'>
    )> }
  )> }
);

export type GetUsersDropdownQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetUsersDropdownQuery = (
  { __typename?: 'Query' }
  & { GetUsersList?: Maybe<(
    { __typename?: 'GetUsersListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>>> }
  )> }
);

export type GetUsersBySubjectDropdownQueryVariables = Exact<{
  lazyLoad?: Maybe<LazyLoad>;
  subjectIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetUsersBySubjectDropdownQuery = (
  { __typename?: 'Query' }
  & { GetUsersList?: Maybe<(
    { __typename?: 'GetUsersListResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>>> }
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { GetUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'enabled' | 'superadmin' | 'blocked' | 'emailLogin' | 'googleLogin' | 'facebookLogin' | 'signatureHash' | 'signatureDescription'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'zip' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetPermissionsQueryVariables = Exact<{
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type GetPermissionsQuery = (
  { __typename?: 'Query' }
  & { GetPermissions?: Maybe<(
    { __typename?: 'Permissions' }
    & Pick<Permissions, 'permissions'>
  )> }
);

export type SendInvitationQueryVariables = Exact<{
  emails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type SendInvitationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SendInvitation'>
);

export type CreateUserMutationVariables = Exact<{
  user?: Maybe<UserInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { CreateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  user?: Maybe<UserInput>;
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { UpdateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
  currentSubject?: Maybe<Scalars['Int']>;
  currentProject?: Maybe<Scalars['Int']>;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteUser'>
);

export const AreaOfDataFragmentFragmentDoc = gql`
    fragment AreaOfDataFragment on AreaOf {
  id
  name
  description
  type
}
    `;
export const GenericTextDataFragmentFragmentDoc = gql`
    fragment GenericTextDataFragment on GenericText {
  id
  name
  description
  precautionSuggestion
  type
  areaOf {
    id
    name
  }
}
    `;
export const ReportDataFragmentFragmentDoc = gql`
    fragment ReportDataFragment on Report {
  id
  name
  code
  header {
    investorEnabled
    oshEnabled
    customName
  }
  defects {
    enabled
    ids
    message
  }
  tasks {
    enabled
    ids
    message
  }
  goodPractice {
    enabled
    ids
    message
  }
  createdBy {
    email
    firstName
    lastName
  }
  createdAt {
    date
  }
  updatedAt {
    date
  }
}
    `;
export const ReportSettingsFragmentFragmentDoc = gql`
    fragment ReportSettingsFragment on ReportSettings {
  customDefectTitle
  customTaskTitle
  customGoodPracticeTitle
}
    `;
export const ReportLvSettingsFragmentFragmentDoc = gql`
    fragment ReportLVSettingsFragment on ReportLVSettings {
  defectMessage
  taskMessage
  goodPracticeMessage
}
    `;
export const DefectDataFragmentFragmentDoc = gql`
    fragment DefectDataFragment on TagDefect {
  id
  state {
    id
    name
  }
  project {
    id
    contractName
  }
  severity {
    id
    name
  }
  areaOf {
    id
    name
  }
  precautionSuggestion
  description
  resolvedDescription
  images
  resolvedImages
  note
  assignedToSubjects {
    id
    name
    type
    companyCode
    enabled
  }
  assignedToSubjectNote
  assignedToUser {
    id
    firstName
    lastName
    email
  }
  responsiblePerson
  reportedByUser {
    id
    firstName
    lastName
    email
  }
  deadline {
    date
  }
  afterDeadline
  deadlineTerm
  createdAt {
    date
  }
  updatedAt {
    date
  }
  completedAt {
    date
  }
  occurredAt {
    date
  }
  resolvedAt {
    date
  }
  link
  identifier
  location
}
    `;
export const GoodPracticeDataFragmentFragmentDoc = gql`
    fragment GoodPracticeDataFragment on TagGoodPractice {
  id
  state {
    id
    name
  }
  project {
    id
    contractName
  }
  description
  images
  areaOf {
    id
    name
  }
  assignedToSubjects {
    id
    name
  }
  assignedToSubjectNote
  assignedToUser {
    id
    firstName
    lastName
    email
  }
  reportedByUser {
    id
    firstName
    lastName
    email
  }
  createdAt {
    date
  }
  updatedAt {
    date
  }
  completedAt {
    date
  }
  occurredAt {
    date
  }
  link
  identifier
  location
  note
}
    `;
export const TaskDataFragmentFragmentDoc = gql`
    fragment TaskDataFragment on TagTask {
  id
  state {
    id
    name
  }
  project {
    id
    contractName
  }
  severity {
    id
    name
  }
  areaOf {
    id
    name
  }
  description
  resolvedDescription
  images
  resolvedImages
  note
  assignedToSubjects {
    id
    name
    type
    companyCode
    enabled
  }
  assignedToSubjectNote
  assignedToUser {
    id
    firstName
    lastName
    email
  }
  reportedByUser {
    id
    firstName
    lastName
    email
  }
  deadline {
    date
  }
  afterDeadline
  deadlineTerm
  createdAt {
    date
  }
  updatedAt {
    date
  }
  completedAt {
    date
  }
  occurredAt {
    date
  }
  link
  identifier
  location
}
    `;
export const GetAreasOfListDocument = gql`
    query GetAreasOfList($lazyLoad: LazyLoad, $type: Int, $currentSubject: Int, $currentProject: Int) {
  GetAreasOfList(
    lazyLoad: $lazyLoad
    type: $type
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...AreaOfDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${AreaOfDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAreasOfListGQL extends Apollo.Query<GetAreasOfListQuery, GetAreasOfListQueryVariables> {
    document = GetAreasOfListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAreaOfByNameDocument = gql`
    query GetAreaOfByName($name: String!, $type: Int!, $currentSubject: Int, $currentProject: Int) {
  GetAreaOfByName(
    name: $name
    type: $type
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...AreaOfDataFragment
  }
}
    ${AreaOfDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAreaOfByNameGQL extends Apollo.Query<GetAreaOfByNameQuery, GetAreaOfByNameQueryVariables> {
    document = GetAreaOfByNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAreaOfDocument = gql`
    query GetAreaOf($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetAreaOf(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...AreaOfDataFragment
  }
}
    ${AreaOfDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAreaOfGQL extends Apollo.Query<GetAreaOfQuery, GetAreaOfQueryVariables> {
    document = GetAreaOfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAreaOfDocument = gql`
    mutation CreateAreaOf($areaOf: AreaOfInput, $currentSubject: Int, $currentProject: Int) {
  CreateAreaOf(
    areaOf: $areaOf
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...AreaOfDataFragment
  }
}
    ${AreaOfDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAreaOfGQL extends Apollo.Mutation<CreateAreaOfMutation, CreateAreaOfMutationVariables> {
    document = CreateAreaOfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAreaOfDocument = gql`
    mutation UpdateAreaOf($areaOf: AreaOfInput, $currentSubject: Int, $currentProject: Int) {
  UpdateAreaOf(
    areaOf: $areaOf
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...AreaOfDataFragment
  }
}
    ${AreaOfDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAreaOfGQL extends Apollo.Mutation<UpdateAreaOfMutation, UpdateAreaOfMutationVariables> {
    document = UpdateAreaOfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAreaOfDocument = gql`
    mutation DeleteAreaOf($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteAreaOf(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAreaOfGQL extends Apollo.Mutation<DeleteAreaOfMutation, DeleteAreaOfMutationVariables> {
    document = DeleteAreaOfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactsListDocument = gql`
    query GetContactsList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetContactsList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      firstName
      lastName
      email
      phoneNumber
      companyName
      function
      enabled
      createdAt {
        date
      }
      updatedAt {
        date
      }
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactsListGQL extends Apollo.Query<GetContactsListQuery, GetContactsListQueryVariables> {
    document = GetContactsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactDocument = gql`
    query GetContact($contactId: Int!, $currentSubject: Int, $currentProject: Int) {
  GetContact(
    contactId: $contactId
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    firstName
    lastName
    email
    phoneNumber
    companyName
    function
    enabled
    createdAt {
      date
    }
    updatedAt {
      date
    }
    address {
      street
      zip
      city
      country {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactGQL extends Apollo.Query<GetContactQuery, GetContactQueryVariables> {
    document = GetContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation CreateContact($contact: ContactInput, $currentSubject: Int, $currentProject: Int) {
  CreateContact(
    contact: $contact
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    firstName
    lastName
    email
    phoneNumber
    companyName
    function
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContactDocument = gql`
    mutation UpdateContact($contact: ContactInput, $currentSubject: Int, $currentProject: Int) {
  UpdateContact(
    contact: $contact
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    firstName
    lastName
    email
    phoneNumber
    companyName
    function
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContactGQL extends Apollo.Mutation<UpdateContactMutation, UpdateContactMutationVariables> {
    document = UpdateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteContactDocument = gql`
    mutation DeleteContact($contactId: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteContact(
    contactId: $contactId
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
    document = DeleteContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContractCategoryListDocument = gql`
    query GetContractCategoryList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetContractCategoryList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContractCategoryListGQL extends Apollo.Query<GetContractCategoryListQuery, GetContractCategoryListQueryVariables> {
    document = GetContractCategoryListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContractCategoryDocument = gql`
    query GetContractCategory($name: String!, $currentSubject: Int, $currentProject: Int) {
  GetContractCategory(
    name: $name
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContractCategoryGQL extends Apollo.Query<GetContractCategoryQuery, GetContractCategoryQueryVariables> {
    document = GetContractCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContractCategoryDocument = gql`
    mutation CreateContractCategory($contractCategory: ContractCategoryInput, $currentSubject: Int, $currentProject: Int) {
  CreateContractCategory(
    contractCategory: $contractCategory
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContractCategoryGQL extends Apollo.Mutation<CreateContractCategoryMutation, CreateContractCategoryMutationVariables> {
    document = CreateContractCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEntityDocument = gql`
    query SendEntity($type: Int!, $id: Int!, $emails: [String], $currentSubject: Int, $currentProject: Int) {
  SendEntity(
    type: $type
    id: $id
    emails: $emails
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEntityGQL extends Apollo.Query<SendEntityQuery, SendEntityQueryVariables> {
    document = SendEntityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountryListDocument = gql`
    query GetCountryList($lazyLoad: LazyLoad) {
  GetCountryList(lazyLoad: $lazyLoad) {
    result {
      id
      isoCode
      isoCode2
      name
      alias
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountryListGQL extends Apollo.Query<GetCountryListQuery, GetCountryListQueryVariables> {
    document = GetCountryListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGenericTextsListDocument = gql`
    query GetGenericTextsList($lazyLoad: LazyLoad, $type: Int, $areaOf: Int, $currentSubject: Int, $currentProject: Int) {
  GetGenericTextsList(
    lazyLoad: $lazyLoad
    type: $type
    areaOf: $areaOf
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...GenericTextDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${GenericTextDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGenericTextsListGQL extends Apollo.Query<GetGenericTextsListQuery, GetGenericTextsListQueryVariables> {
    document = GetGenericTextsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGenericTextByNameDocument = gql`
    query GetGenericTextByName($name: String!, $type: Int, $currentSubject: Int, $currentProject: Int) {
  GetGenericTextByName(
    name: $name
    type: $type
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GenericTextDataFragment
  }
}
    ${GenericTextDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGenericTextByNameGQL extends Apollo.Query<GetGenericTextByNameQuery, GetGenericTextByNameQueryVariables> {
    document = GetGenericTextByNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGenericTextDocument = gql`
    query GetGenericText($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetGenericText(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GenericTextDataFragment
  }
}
    ${GenericTextDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGenericTextGQL extends Apollo.Query<GetGenericTextQuery, GetGenericTextQueryVariables> {
    document = GetGenericTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenericTextsExportDocument = gql`
    query GenericTextsExport($currentSubject: Int, $currentProject: Int) {
  GenericTextsExport(
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    type
    fileContentBase64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenericTextsExportGQL extends Apollo.Query<GenericTextsExportQuery, GenericTextsExportQueryVariables> {
    document = GenericTextsExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGenericTextDocument = gql`
    mutation CreateGenericText($genericText: GenericTextInput, $currentSubject: Int, $currentProject: Int) {
  CreateGenericText(
    genericText: $genericText
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GenericTextDataFragment
  }
}
    ${GenericTextDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGenericTextGQL extends Apollo.Mutation<CreateGenericTextMutation, CreateGenericTextMutationVariables> {
    document = CreateGenericTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGenericTextDocument = gql`
    mutation UpdateGenericText($genericText: GenericTextInput, $currentSubject: Int, $currentProject: Int) {
  UpdateGenericText(
    genericText: $genericText
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GenericTextDataFragment
  }
}
    ${GenericTextDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGenericTextGQL extends Apollo.Mutation<UpdateGenericTextMutation, UpdateGenericTextMutationVariables> {
    document = UpdateGenericTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGenericTextDocument = gql`
    mutation DeleteGenericText($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteGenericText(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGenericTextGQL extends Apollo.Mutation<DeleteGenericTextMutation, DeleteGenericTextMutationVariables> {
    document = DeleteGenericTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsListDocument = gql`
    query GetProjectsList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetProjectsList(
    lazyLoad: $lazyLoad
    reqData: "list"
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      contractName
      contractNumber
      contractCategory {
        id
        name
        description
      }
      expectedStartDate {
        date
      }
      expectedEndDate {
        date
      }
      expectedScope
      createdAt {
        date
      }
      updatedAt {
        date
      }
      active
      investor {
        name
      }
      supervisors
      technicians
      keyName
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsListGQL extends Apollo.Query<GetProjectsListQuery, GetProjectsListQueryVariables> {
    document = GetProjectsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsDropdownDocument = gql`
    query GetProjectsDropdown($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetProjectsList(
    lazyLoad: $lazyLoad
    reqData: "list"
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      contractName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsDropdownGQL extends Apollo.Query<GetProjectsDropdownQuery, GetProjectsDropdownQueryVariables> {
    document = GetProjectsDropdownDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsAppListDocument = gql`
    query GetProjectsAppList($currentSubject: Int, $currentProject: Int) {
  GetProjectsList(
    reqData: "app-list"
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      contractName
      keyName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsAppListGQL extends Apollo.Query<GetProjectsAppListQuery, GetProjectsAppListQueryVariables> {
    document = GetProjectsAppListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectDocument = gql`
    query GetProject($projectId: Int!, $currentSubject: Int, $currentProject: Int) {
  GetProject(
    projectId: $projectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    contractName
    contractNumber
    contractCategory {
      id
      name
      description
    }
    investor {
      id
      name
      companyCode
      address {
        street
        city
        zip
        country {
          id
          name
        }
      }
    }
    contacts {
      id
      email
      firstName
      lastName
    }
    supervisors
    technicians
    subjects {
      subject {
        id
        type
        name
        companyCode
        enabled
      }
      technicians
    }
    expectedStartDate {
      date
    }
    expectedEndDate {
      date
    }
    expectedScope
    note
    active
    keyName
    documents {
      name
      description
      hash
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectGQL extends Apollo.Query<GetProjectQuery, GetProjectQueryVariables> {
    document = GetProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectUsersDocument = gql`
    query GetProjectUsers($projectId: Int!, $currentSubject: Int, $currentProject: Int) {
  GetProjectUsers(
    projectId: $projectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectUsersGQL extends Apollo.Query<GetProjectUsersQuery, GetProjectUsersQueryVariables> {
    document = GetProjectUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectKeyNameDocument = gql`
    query GetProjectKeyName($projectName: String!, $checkKeyName: String, $currentSubject: Int, $currentProject: Int) {
  GetProjectKeyName(
    projectName: $projectName
    checkKeyName: $checkKeyName
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    state
    suggestion
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectKeyNameGQL extends Apollo.Query<GetProjectKeyNameQuery, GetProjectKeyNameQueryVariables> {
    document = GetProjectKeyNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectDocument = gql`
    mutation CreateProject($project: ProjectInput, $currentSubject: Int, $currentProject: Int) {
  CreateProject(
    project: $project
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation UpdateProject($project: ProjectInput, $currentSubject: Int, $currentProject: Int) {
  UpdateProject(
    project: $project
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteProject(
    projectId: $projectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectGQL extends Apollo.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> {
    document = DeleteProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportsListDocument = gql`
    query GetReportsList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetReportsList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...ReportDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${ReportDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportsListGQL extends Apollo.Query<GetReportsListQuery, GetReportsListQueryVariables> {
    document = GetReportsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportDocument = gql`
    query GetReport($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetReport(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportDataFragment
  }
}
    ${ReportDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportGQL extends Apollo.Query<GetReportQuery, GetReportQueryVariables> {
    document = GetReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportGeneralSettingsDocument = gql`
    query GetReportGeneralSettings($currentSubject: Int, $currentProject: Int) {
  GetReportGeneralSettings(
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportSettingsFragment
  }
}
    ${ReportSettingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportGeneralSettingsGQL extends Apollo.Query<GetReportGeneralSettingsQuery, GetReportGeneralSettingsQueryVariables> {
    document = GetReportGeneralSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportLvSettingsDocument = gql`
    query GetReportLVSettings($currentSubject: Int, $currentProject: Int) {
  GetReportLVSettings(
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportLVSettingsFragment
  }
}
    ${ReportLvSettingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportLvSettingsGQL extends Apollo.Query<GetReportLvSettingsQuery, GetReportLvSettingsQueryVariables> {
    document = GetReportLvSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReportDocument = gql`
    mutation CreateReport($report: ReportInput, $currentSubject: Int, $currentProject: Int) {
  CreateReport(
    report: $report
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportDataFragment
  }
}
    ${ReportDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReportGQL extends Apollo.Mutation<CreateReportMutation, CreateReportMutationVariables> {
    document = CreateReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportDocument = gql`
    mutation UpdateReport($report: ReportInput, $currentSubject: Int, $currentProject: Int) {
  UpdateReport(
    report: $report
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportDataFragment
  }
}
    ${ReportDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportGQL extends Apollo.Mutation<UpdateReportMutation, UpdateReportMutationVariables> {
    document = UpdateReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportGeneralSettingsDocument = gql`
    mutation UpdateReportGeneralSettings($settings: ReportSettingsInput!, $currentSubject: Int, $currentProject: Int) {
  UpdateReportGeneralSettings(
    settings: $settings
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...ReportSettingsFragment
  }
}
    ${ReportSettingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportGeneralSettingsGQL extends Apollo.Mutation<UpdateReportGeneralSettingsMutation, UpdateReportGeneralSettingsMutationVariables> {
    document = UpdateReportGeneralSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteReport(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportGQL extends Apollo.Mutation<DeleteReportMutation, DeleteReportMutationVariables> {
    document = DeleteReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStateStatisticsDocument = gql`
    query GetStateStatistics($currentSubject: Int, $currentProject: Int) {
  GetStateStatistics(
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    pending
    inProgress
    waitingForApproval
    approved
    afterDeadline
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStateStatisticsGQL extends Apollo.Query<GetStateStatisticsQuery, GetStateStatisticsQueryVariables> {
    document = GetStateStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubjectsListDocument = gql`
    query GetSubjectsList($lazyLoad: LazyLoad, $type: Int, $currentSubject: Int, $currentProject: Int) {
  GetSubjectsList(
    lazyLoad: $lazyLoad
    reqData: "list"
    type: $type
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      name
      description
      type
      companyCode
      enabled
      address {
        street
        city
        zip
        country {
          id
          name
        }
      }
      createdAt {
        date
      }
      updatedAt {
        date
      }
      administrators {
        id
        email
      }
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubjectsListGQL extends Apollo.Query<GetSubjectsListQuery, GetSubjectsListQueryVariables> {
    document = GetSubjectsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubjectsAppListDocument = gql`
    query GetSubjectsAppList($currentSubject: Int, $currentProject: Int) {
  GetSubjectsList(
    reqData: "app-list"
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      name
      description
      type
      companyCode
      enabled
      address {
        street
        city
        zip
        country {
          id
          name
        }
      }
      createdAt {
        date
      }
      updatedAt {
        date
      }
      administrators {
        id
        email
      }
      projects {
        id
        contractName
        contractNumber
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubjectsAppListGQL extends Apollo.Query<GetSubjectsAppListQuery, GetSubjectsAppListQueryVariables> {
    document = GetSubjectsAppListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubjectsProjectListDocument = gql`
    query SubjectsProjectList($lazyLoad: LazyLoad, $projectId: Int!, $type: Int, $currentSubject: Int, $currentProject: Int) {
  GetSubjectsList(
    lazyLoad: $lazyLoad
    reqData: "project-list"
    projectId: $projectId
    type: $type
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      name
      description
      type
      companyCode
      enabled
      address {
        street
        city
        zip
        country {
          id
          name
        }
      }
      createdAt {
        date
      }
      updatedAt {
        date
      }
      administrators {
        id
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubjectsProjectListGQL extends Apollo.Query<SubjectsProjectListQuery, SubjectsProjectListQueryVariables> {
    document = SubjectsProjectListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubjectDocument = gql`
    query GetSubject($subjectId: Int!, $currentSubject: Int, $currentProject: Int) {
  GetSubject(
    subjectId: $subjectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    type
    name
    companyCode
    enabled
    address {
      street
      city
      zip
      country {
        id
        name
      }
    }
    administrators {
      id
      email
    }
    licence {
      available
      used
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubjectGQL extends Apollo.Query<GetSubjectQuery, GetSubjectQueryVariables> {
    document = GetSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentSubjectDetailsDocument = gql`
    query GetCurrentSubjectDetails($currentSubject: Int, $currentProject: Int) {
  GetCurrentSubjectDetails(
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    type
    name
    companyCode
    enabled
    address {
      street
      city
      zip
      country {
        id
        name
      }
    }
    administrators {
      id
      email
    }
    licence {
      available
      used
    }
    users {
      id
      email
      firstName
      lastName
      assignedInProjects {
        id
        contractName
      }
    }
    invitations {
      id
      user {
        email
      }
      subject {
        name
      }
      project {
        contractName
      }
      createdAt {
        date
      }
      updatedAt {
        date
      }
      state
      type
      role
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentSubjectDetailsGQL extends Apollo.Query<GetCurrentSubjectDetailsQuery, GetCurrentSubjectDetailsQueryVariables> {
    document = GetCurrentSubjectDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubjectAresInfoDocument = gql`
    query GetSubjectAresInfo($companyCode: String!, $countryIsoCode2: String!, $currentSubject: Int, $currentProject: Int) {
  GetAresSubjectInfo(
    companyCode: $companyCode
    countryIsoCode2: $countryIsoCode2
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    country {
      id
      name
    }
    companyName
    companyCode
    city
    street
    zip
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubjectAresInfoGQL extends Apollo.Query<GetSubjectAresInfoQuery, GetSubjectAresInfoQueryVariables> {
    document = GetSubjectAresInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUnAssignDocument = gql`
    mutation UserUnAssign($userId: Int!, $projectId: Int, $currentSubject: Int, $currentProject: Int) {
  SubjectUserUnAssign(
    userId: $userId
    projectId: $projectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUnAssignGQL extends Apollo.Mutation<UserUnAssignMutation, UserUnAssignMutationVariables> {
    document = UserUnAssignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubjectDocument = gql`
    mutation CreateSubject($subject: SubjectAresInput, $currentSubject: Int, $currentProject: Int) {
  CreateSubject(
    subject: $subject
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubjectGQL extends Apollo.Mutation<CreateSubjectMutation, CreateSubjectMutationVariables> {
    document = CreateSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubjectDocument = gql`
    mutation UpdateSubject($subject: SubjectAresInput, $currentSubject: Int, $currentProject: Int) {
  UpdateSubject(
    subject: $subject
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubjectGQL extends Apollo.Mutation<UpdateSubjectMutation, UpdateSubjectMutationVariables> {
    document = UpdateSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSubjectDocument = gql`
    mutation DeleteSubject($subjectId: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteSubject(
    subjectId: $subjectId
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSubjectGQL extends Apollo.Mutation<DeleteSubjectMutation, DeleteSubjectMutationVariables> {
    document = DeleteSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagDefectsListDocument = gql`
    query GetTagDefectsList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetTagDefectsList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...DefectDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${DefectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagDefectsListGQL extends Apollo.Query<GetTagDefectsListQuery, GetTagDefectsListQueryVariables> {
    document = GetTagDefectsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagDefectDocument = gql`
    query GetTagDefect($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagDefect(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...DefectDataFragment
  }
}
    ${DefectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagDefectGQL extends Apollo.Query<GetTagDefectQuery, GetTagDefectQueryVariables> {
    document = GetTagDefectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagDefectBrowseDocument = gql`
    query GetTagDefectBrowse($projectKeyName: String!, $tagSequenceNumber: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagDefectBrowse(
    projectKeyName: $projectKeyName
    tagSequenceNumber: $tagSequenceNumber
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...DefectDataFragment
  }
}
    ${DefectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagDefectBrowseGQL extends Apollo.Query<GetTagDefectBrowseQuery, GetTagDefectBrowseQueryVariables> {
    document = GetTagDefectBrowseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTagDefectDocument = gql`
    mutation CreateTagDefect($defect: TagDefectInput, $currentSubject: Int, $currentProject: Int) {
  CreateTagDefect(
    defect: $defect
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTagDefectGQL extends Apollo.Mutation<CreateTagDefectMutation, CreateTagDefectMutationVariables> {
    document = CreateTagDefectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTagDefectDocument = gql`
    mutation UpdateTagDefect($defect: TagDefectInput, $action: String, $currentSubject: Int, $currentProject: Int) {
  UpdateTagDefect(
    defect: $defect
    action: $action
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...DefectDataFragment
  }
}
    ${DefectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTagDefectGQL extends Apollo.Mutation<UpdateTagDefectMutation, UpdateTagDefectMutationVariables> {
    document = UpdateTagDefectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagDefectDocument = gql`
    mutation DeleteTagDefect($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteTagDefect(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagDefectGQL extends Apollo.Mutation<DeleteTagDefectMutation, DeleteTagDefectMutationVariables> {
    document = DeleteTagDefectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagGoodPracticeListDocument = gql`
    query GetTagGoodPracticeList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetTagGoodPracticeList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...GoodPracticeDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${GoodPracticeDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagGoodPracticeListGQL extends Apollo.Query<GetTagGoodPracticeListQuery, GetTagGoodPracticeListQueryVariables> {
    document = GetTagGoodPracticeListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagGoodPracticeDocument = gql`
    query GetTagGoodPractice($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagGoodPractice(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GoodPracticeDataFragment
  }
}
    ${GoodPracticeDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagGoodPracticeGQL extends Apollo.Query<GetTagGoodPracticeQuery, GetTagGoodPracticeQueryVariables> {
    document = GetTagGoodPracticeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagGoodPracticeBrowseDocument = gql`
    query GetTagGoodPracticeBrowse($projectKeyName: String!, $tagSequenceNumber: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagGoodPracticeBrowse(
    projectKeyName: $projectKeyName
    tagSequenceNumber: $tagSequenceNumber
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GoodPracticeDataFragment
  }
}
    ${GoodPracticeDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagGoodPracticeBrowseGQL extends Apollo.Query<GetTagGoodPracticeBrowseQuery, GetTagGoodPracticeBrowseQueryVariables> {
    document = GetTagGoodPracticeBrowseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTagGoodPracticeDocument = gql`
    mutation CreateTagGoodPractice($goodPractice: TagGoodPracticeInput, $currentSubject: Int, $currentProject: Int) {
  CreateTagGoodPractice(
    goodPractice: $goodPractice
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTagGoodPracticeGQL extends Apollo.Mutation<CreateTagGoodPracticeMutation, CreateTagGoodPracticeMutationVariables> {
    document = CreateTagGoodPracticeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTagGoodPracticeDocument = gql`
    mutation UpdateTagGoodPractice($goodPractice: TagGoodPracticeInput, $currentSubject: Int, $currentProject: Int) {
  UpdateTagGoodPractice(
    goodPractice: $goodPractice
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...GoodPracticeDataFragment
  }
}
    ${GoodPracticeDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTagGoodPracticeGQL extends Apollo.Mutation<UpdateTagGoodPracticeMutation, UpdateTagGoodPracticeMutationVariables> {
    document = UpdateTagGoodPracticeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagGoodPracticeDocument = gql`
    mutation DeleteTagGoodPractice($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteTagGoodPractice(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagGoodPracticeGQL extends Apollo.Mutation<DeleteTagGoodPracticeMutation, DeleteTagGoodPracticeMutationVariables> {
    document = DeleteTagGoodPracticeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagTasksListDocument = gql`
    query GetTagTasksList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetTagTasksList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      ...TaskDataFragment
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    ${TaskDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagTasksListGQL extends Apollo.Query<GetTagTasksListQuery, GetTagTasksListQueryVariables> {
    document = GetTagTasksListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagTaskDocument = gql`
    query GetTagTask($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagTask(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...TaskDataFragment
  }
}
    ${TaskDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagTaskGQL extends Apollo.Query<GetTagTaskQuery, GetTagTaskQueryVariables> {
    document = GetTagTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagTaskBrowseDocument = gql`
    query GetTagTaskBrowse($projectKeyName: String!, $tagSequenceNumber: Int!, $currentSubject: Int, $currentProject: Int) {
  GetTagTaskBrowse(
    projectKeyName: $projectKeyName
    tagSequenceNumber: $tagSequenceNumber
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...TaskDataFragment
  }
}
    ${TaskDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagTaskBrowseGQL extends Apollo.Query<GetTagTaskBrowseQuery, GetTagTaskBrowseQueryVariables> {
    document = GetTagTaskBrowseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTagTaskDocument = gql`
    mutation CreateTagTask($task: TagTaskInput, $currentSubject: Int, $currentProject: Int) {
  CreateTagTask(
    task: $task
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTagTaskGQL extends Apollo.Mutation<CreateTagTaskMutation, CreateTagTaskMutationVariables> {
    document = CreateTagTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTagTaskDocument = gql`
    mutation UpdateTagTask($task: TagTaskInput, $action: String, $currentSubject: Int, $currentProject: Int) {
  UpdateTagTask(
    task: $task
    action: $action
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    ...TaskDataFragment
  }
}
    ${TaskDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTagTaskGQL extends Apollo.Mutation<UpdateTagTaskMutation, UpdateTagTaskMutationVariables> {
    document = UpdateTagTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagTaskDocument = gql`
    mutation DeleteTagTask($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteTagTask(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagTaskGQL extends Apollo.Mutation<DeleteTagTaskMutation, DeleteTagTaskMutationVariables> {
    document = DeleteTagTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersListDocument = gql`
    query GetUsersList($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetUsersList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      firstName
      lastName
      email
      phoneNumber
      enabled
      createdAt {
        date
      }
      updatedAt {
        date
      }
      lastLoggedIn {
        date
      }
      superadmin
      blocked
      emailLogin
      googleLogin
      facebookLogin
      currentRoleName
      currentProject
    }
    metadata {
      count
      limit
      offset
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersListGQL extends Apollo.Query<GetUsersListQuery, GetUsersListQueryVariables> {
    document = GetUsersListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDropdownDocument = gql`
    query GetUsersDropdown($lazyLoad: LazyLoad, $currentSubject: Int, $currentProject: Int) {
  GetUsersList(
    lazyLoad: $lazyLoad
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersDropdownGQL extends Apollo.Query<GetUsersDropdownQuery, GetUsersDropdownQueryVariables> {
    document = GetUsersDropdownDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersBySubjectDropdownDocument = gql`
    query GetUsersBySubjectDropdown($lazyLoad: LazyLoad, $subjectIds: [Int], $currentSubject: Int, $currentProject: Int) {
  GetUsersList(
    lazyLoad: $lazyLoad
    subjectIds: $subjectIds
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    result {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersBySubjectDropdownGQL extends Apollo.Query<GetUsersBySubjectDropdownQuery, GetUsersBySubjectDropdownQueryVariables> {
    document = GetUsersBySubjectDropdownDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query GetUser($id: Int!, $currentSubject: Int, $currentProject: Int) {
  GetUser(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
    firstName
    lastName
    email
    phoneNumber
    enabled
    address {
      street
      zip
      city
      country {
        id
        name
      }
    }
    superadmin
    blocked
    emailLogin
    googleLogin
    facebookLogin
    signatureHash
    signatureDescription
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPermissionsDocument = gql`
    query GetPermissions($currentSubject: Int, $currentProject: Int) {
  GetPermissions(currentSubject: $currentSubject, currentProject: $currentProject) {
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPermissionsGQL extends Apollo.Query<GetPermissionsQuery, GetPermissionsQueryVariables> {
    document = GetPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendInvitationDocument = gql`
    query SendInvitation($emails: [String], $currentSubject: Int, $currentProject: Int) {
  SendInvitation(
    currentSubject: $currentSubject
    currentProject: $currentProject
    emails: $emails
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendInvitationGQL extends Apollo.Query<SendInvitationQuery, SendInvitationQueryVariables> {
    document = SendInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserInput, $currentSubject: Int, $currentProject: Int) {
  CreateUser(
    user: $user
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserInput, $currentSubject: Int, $currentProject: Int) {
  UpdateUser(
    user: $user
    currentSubject: $currentSubject
    currentProject: $currentProject
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!, $currentSubject: Int, $currentProject: Int) {
  DeleteUser(
    id: $id
    currentSubject: $currentSubject
    currentProject: $currentProject
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V>
    extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}
    
  interface QueryOptionsAlone<V>
    extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}
    
  interface MutationOptionsAlone<T, V>
    extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}
    
  interface SubscriptionOptionsAlone<V>
    extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class ConPointService {
    constructor(
      private getAreasOfListGql: GetAreasOfListGQL,
      private getAreaOfByNameGql: GetAreaOfByNameGQL,
      private getAreaOfGql: GetAreaOfGQL,
      private createAreaOfGql: CreateAreaOfGQL,
      private updateAreaOfGql: UpdateAreaOfGQL,
      private deleteAreaOfGql: DeleteAreaOfGQL,
      private getContactsListGql: GetContactsListGQL,
      private getContactGql: GetContactGQL,
      private createContactGql: CreateContactGQL,
      private updateContactGql: UpdateContactGQL,
      private deleteContactGql: DeleteContactGQL,
      private getContractCategoryListGql: GetContractCategoryListGQL,
      private getContractCategoryGql: GetContractCategoryGQL,
      private createContractCategoryGql: CreateContractCategoryGQL,
      private sendEntityGql: SendEntityGQL,
      private getCountryListGql: GetCountryListGQL,
      private getGenericTextsListGql: GetGenericTextsListGQL,
      private getGenericTextByNameGql: GetGenericTextByNameGQL,
      private getGenericTextGql: GetGenericTextGQL,
      private genericTextsExportGql: GenericTextsExportGQL,
      private createGenericTextGql: CreateGenericTextGQL,
      private updateGenericTextGql: UpdateGenericTextGQL,
      private deleteGenericTextGql: DeleteGenericTextGQL,
      private getProjectsListGql: GetProjectsListGQL,
      private getProjectsDropdownGql: GetProjectsDropdownGQL,
      private getProjectsAppListGql: GetProjectsAppListGQL,
      private getProjectGql: GetProjectGQL,
      private getProjectUsersGql: GetProjectUsersGQL,
      private getProjectKeyNameGql: GetProjectKeyNameGQL,
      private createProjectGql: CreateProjectGQL,
      private updateProjectGql: UpdateProjectGQL,
      private deleteProjectGql: DeleteProjectGQL,
      private getReportsListGql: GetReportsListGQL,
      private getReportGql: GetReportGQL,
      private getReportGeneralSettingsGql: GetReportGeneralSettingsGQL,
      private getReportLvSettingsGql: GetReportLvSettingsGQL,
      private createReportGql: CreateReportGQL,
      private updateReportGql: UpdateReportGQL,
      private updateReportGeneralSettingsGql: UpdateReportGeneralSettingsGQL,
      private deleteReportGql: DeleteReportGQL,
      private getStateStatisticsGql: GetStateStatisticsGQL,
      private getSubjectsListGql: GetSubjectsListGQL,
      private getSubjectsAppListGql: GetSubjectsAppListGQL,
      private subjectsProjectListGql: SubjectsProjectListGQL,
      private getSubjectGql: GetSubjectGQL,
      private getCurrentSubjectDetailsGql: GetCurrentSubjectDetailsGQL,
      private getSubjectAresInfoGql: GetSubjectAresInfoGQL,
      private userUnAssignGql: UserUnAssignGQL,
      private createSubjectGql: CreateSubjectGQL,
      private updateSubjectGql: UpdateSubjectGQL,
      private deleteSubjectGql: DeleteSubjectGQL,
      private getTagDefectsListGql: GetTagDefectsListGQL,
      private getTagDefectGql: GetTagDefectGQL,
      private getTagDefectBrowseGql: GetTagDefectBrowseGQL,
      private createTagDefectGql: CreateTagDefectGQL,
      private updateTagDefectGql: UpdateTagDefectGQL,
      private deleteTagDefectGql: DeleteTagDefectGQL,
      private getTagGoodPracticeListGql: GetTagGoodPracticeListGQL,
      private getTagGoodPracticeGql: GetTagGoodPracticeGQL,
      private getTagGoodPracticeBrowseGql: GetTagGoodPracticeBrowseGQL,
      private createTagGoodPracticeGql: CreateTagGoodPracticeGQL,
      private updateTagGoodPracticeGql: UpdateTagGoodPracticeGQL,
      private deleteTagGoodPracticeGql: DeleteTagGoodPracticeGQL,
      private getTagTasksListGql: GetTagTasksListGQL,
      private getTagTaskGql: GetTagTaskGQL,
      private getTagTaskBrowseGql: GetTagTaskBrowseGQL,
      private createTagTaskGql: CreateTagTaskGQL,
      private updateTagTaskGql: UpdateTagTaskGQL,
      private deleteTagTaskGql: DeleteTagTaskGQL,
      private getUsersListGql: GetUsersListGQL,
      private getUsersDropdownGql: GetUsersDropdownGQL,
      private getUsersBySubjectDropdownGql: GetUsersBySubjectDropdownGQL,
      private getUserGql: GetUserGQL,
      private getPermissionsGql: GetPermissionsGQL,
      private sendInvitationGql: SendInvitationGQL,
      private createUserGql: CreateUserGQL,
      private updateUserGql: UpdateUserGQL,
      private deleteUserGql: DeleteUserGQL
    ) {}
      
    getAreasOfList(variables?: GetAreasOfListQueryVariables, options?: QueryOptionsAlone<GetAreasOfListQueryVariables>) {
      return this.getAreasOfListGql.fetch(variables, options)
    }
    
    getAreasOfListWatch(variables?: GetAreasOfListQueryVariables, options?: WatchQueryOptionsAlone<GetAreasOfListQueryVariables>) {
      return this.getAreasOfListGql.watch(variables, options)
    }
    
    getAreaOfByName(variables: GetAreaOfByNameQueryVariables, options?: QueryOptionsAlone<GetAreaOfByNameQueryVariables>) {
      return this.getAreaOfByNameGql.fetch(variables, options)
    }
    
    getAreaOfByNameWatch(variables: GetAreaOfByNameQueryVariables, options?: WatchQueryOptionsAlone<GetAreaOfByNameQueryVariables>) {
      return this.getAreaOfByNameGql.watch(variables, options)
    }
    
    getAreaOf(variables: GetAreaOfQueryVariables, options?: QueryOptionsAlone<GetAreaOfQueryVariables>) {
      return this.getAreaOfGql.fetch(variables, options)
    }
    
    getAreaOfWatch(variables: GetAreaOfQueryVariables, options?: WatchQueryOptionsAlone<GetAreaOfQueryVariables>) {
      return this.getAreaOfGql.watch(variables, options)
    }
    
    createAreaOf(variables?: CreateAreaOfMutationVariables, options?: MutationOptionsAlone<CreateAreaOfMutation, CreateAreaOfMutationVariables>) {
      return this.createAreaOfGql.mutate(variables, options)
    }
    
    updateAreaOf(variables?: UpdateAreaOfMutationVariables, options?: MutationOptionsAlone<UpdateAreaOfMutation, UpdateAreaOfMutationVariables>) {
      return this.updateAreaOfGql.mutate(variables, options)
    }
    
    deleteAreaOf(variables: DeleteAreaOfMutationVariables, options?: MutationOptionsAlone<DeleteAreaOfMutation, DeleteAreaOfMutationVariables>) {
      return this.deleteAreaOfGql.mutate(variables, options)
    }
    
    getContactsList(variables?: GetContactsListQueryVariables, options?: QueryOptionsAlone<GetContactsListQueryVariables>) {
      return this.getContactsListGql.fetch(variables, options)
    }
    
    getContactsListWatch(variables?: GetContactsListQueryVariables, options?: WatchQueryOptionsAlone<GetContactsListQueryVariables>) {
      return this.getContactsListGql.watch(variables, options)
    }
    
    getContact(variables: GetContactQueryVariables, options?: QueryOptionsAlone<GetContactQueryVariables>) {
      return this.getContactGql.fetch(variables, options)
    }
    
    getContactWatch(variables: GetContactQueryVariables, options?: WatchQueryOptionsAlone<GetContactQueryVariables>) {
      return this.getContactGql.watch(variables, options)
    }
    
    createContact(variables?: CreateContactMutationVariables, options?: MutationOptionsAlone<CreateContactMutation, CreateContactMutationVariables>) {
      return this.createContactGql.mutate(variables, options)
    }
    
    updateContact(variables?: UpdateContactMutationVariables, options?: MutationOptionsAlone<UpdateContactMutation, UpdateContactMutationVariables>) {
      return this.updateContactGql.mutate(variables, options)
    }
    
    deleteContact(variables: DeleteContactMutationVariables, options?: MutationOptionsAlone<DeleteContactMutation, DeleteContactMutationVariables>) {
      return this.deleteContactGql.mutate(variables, options)
    }
    
    getContractCategoryList(variables?: GetContractCategoryListQueryVariables, options?: QueryOptionsAlone<GetContractCategoryListQueryVariables>) {
      return this.getContractCategoryListGql.fetch(variables, options)
    }
    
    getContractCategoryListWatch(variables?: GetContractCategoryListQueryVariables, options?: WatchQueryOptionsAlone<GetContractCategoryListQueryVariables>) {
      return this.getContractCategoryListGql.watch(variables, options)
    }
    
    getContractCategory(variables: GetContractCategoryQueryVariables, options?: QueryOptionsAlone<GetContractCategoryQueryVariables>) {
      return this.getContractCategoryGql.fetch(variables, options)
    }
    
    getContractCategoryWatch(variables: GetContractCategoryQueryVariables, options?: WatchQueryOptionsAlone<GetContractCategoryQueryVariables>) {
      return this.getContractCategoryGql.watch(variables, options)
    }
    
    createContractCategory(variables?: CreateContractCategoryMutationVariables, options?: MutationOptionsAlone<CreateContractCategoryMutation, CreateContractCategoryMutationVariables>) {
      return this.createContractCategoryGql.mutate(variables, options)
    }
    
    sendEntity(variables: SendEntityQueryVariables, options?: QueryOptionsAlone<SendEntityQueryVariables>) {
      return this.sendEntityGql.fetch(variables, options)
    }
    
    sendEntityWatch(variables: SendEntityQueryVariables, options?: WatchQueryOptionsAlone<SendEntityQueryVariables>) {
      return this.sendEntityGql.watch(variables, options)
    }
    
    getCountryList(variables?: GetCountryListQueryVariables, options?: QueryOptionsAlone<GetCountryListQueryVariables>) {
      return this.getCountryListGql.fetch(variables, options)
    }
    
    getCountryListWatch(variables?: GetCountryListQueryVariables, options?: WatchQueryOptionsAlone<GetCountryListQueryVariables>) {
      return this.getCountryListGql.watch(variables, options)
    }
    
    getGenericTextsList(variables?: GetGenericTextsListQueryVariables, options?: QueryOptionsAlone<GetGenericTextsListQueryVariables>) {
      return this.getGenericTextsListGql.fetch(variables, options)
    }
    
    getGenericTextsListWatch(variables?: GetGenericTextsListQueryVariables, options?: WatchQueryOptionsAlone<GetGenericTextsListQueryVariables>) {
      return this.getGenericTextsListGql.watch(variables, options)
    }
    
    getGenericTextByName(variables: GetGenericTextByNameQueryVariables, options?: QueryOptionsAlone<GetGenericTextByNameQueryVariables>) {
      return this.getGenericTextByNameGql.fetch(variables, options)
    }
    
    getGenericTextByNameWatch(variables: GetGenericTextByNameQueryVariables, options?: WatchQueryOptionsAlone<GetGenericTextByNameQueryVariables>) {
      return this.getGenericTextByNameGql.watch(variables, options)
    }
    
    getGenericText(variables: GetGenericTextQueryVariables, options?: QueryOptionsAlone<GetGenericTextQueryVariables>) {
      return this.getGenericTextGql.fetch(variables, options)
    }
    
    getGenericTextWatch(variables: GetGenericTextQueryVariables, options?: WatchQueryOptionsAlone<GetGenericTextQueryVariables>) {
      return this.getGenericTextGql.watch(variables, options)
    }
    
    genericTextsExport(variables?: GenericTextsExportQueryVariables, options?: QueryOptionsAlone<GenericTextsExportQueryVariables>) {
      return this.genericTextsExportGql.fetch(variables, options)
    }
    
    genericTextsExportWatch(variables?: GenericTextsExportQueryVariables, options?: WatchQueryOptionsAlone<GenericTextsExportQueryVariables>) {
      return this.genericTextsExportGql.watch(variables, options)
    }
    
    createGenericText(variables?: CreateGenericTextMutationVariables, options?: MutationOptionsAlone<CreateGenericTextMutation, CreateGenericTextMutationVariables>) {
      return this.createGenericTextGql.mutate(variables, options)
    }
    
    updateGenericText(variables?: UpdateGenericTextMutationVariables, options?: MutationOptionsAlone<UpdateGenericTextMutation, UpdateGenericTextMutationVariables>) {
      return this.updateGenericTextGql.mutate(variables, options)
    }
    
    deleteGenericText(variables: DeleteGenericTextMutationVariables, options?: MutationOptionsAlone<DeleteGenericTextMutation, DeleteGenericTextMutationVariables>) {
      return this.deleteGenericTextGql.mutate(variables, options)
    }
    
    getProjectsList(variables?: GetProjectsListQueryVariables, options?: QueryOptionsAlone<GetProjectsListQueryVariables>) {
      return this.getProjectsListGql.fetch(variables, options)
    }
    
    getProjectsListWatch(variables?: GetProjectsListQueryVariables, options?: WatchQueryOptionsAlone<GetProjectsListQueryVariables>) {
      return this.getProjectsListGql.watch(variables, options)
    }
    
    getProjectsDropdown(variables?: GetProjectsDropdownQueryVariables, options?: QueryOptionsAlone<GetProjectsDropdownQueryVariables>) {
      return this.getProjectsDropdownGql.fetch(variables, options)
    }
    
    getProjectsDropdownWatch(variables?: GetProjectsDropdownQueryVariables, options?: WatchQueryOptionsAlone<GetProjectsDropdownQueryVariables>) {
      return this.getProjectsDropdownGql.watch(variables, options)
    }
    
    getProjectsAppList(variables?: GetProjectsAppListQueryVariables, options?: QueryOptionsAlone<GetProjectsAppListQueryVariables>) {
      return this.getProjectsAppListGql.fetch(variables, options)
    }
    
    getProjectsAppListWatch(variables?: GetProjectsAppListQueryVariables, options?: WatchQueryOptionsAlone<GetProjectsAppListQueryVariables>) {
      return this.getProjectsAppListGql.watch(variables, options)
    }
    
    getProject(variables: GetProjectQueryVariables, options?: QueryOptionsAlone<GetProjectQueryVariables>) {
      return this.getProjectGql.fetch(variables, options)
    }
    
    getProjectWatch(variables: GetProjectQueryVariables, options?: WatchQueryOptionsAlone<GetProjectQueryVariables>) {
      return this.getProjectGql.watch(variables, options)
    }
    
    getProjectUsers(variables: GetProjectUsersQueryVariables, options?: QueryOptionsAlone<GetProjectUsersQueryVariables>) {
      return this.getProjectUsersGql.fetch(variables, options)
    }
    
    getProjectUsersWatch(variables: GetProjectUsersQueryVariables, options?: WatchQueryOptionsAlone<GetProjectUsersQueryVariables>) {
      return this.getProjectUsersGql.watch(variables, options)
    }
    
    getProjectKeyName(variables: GetProjectKeyNameQueryVariables, options?: QueryOptionsAlone<GetProjectKeyNameQueryVariables>) {
      return this.getProjectKeyNameGql.fetch(variables, options)
    }
    
    getProjectKeyNameWatch(variables: GetProjectKeyNameQueryVariables, options?: WatchQueryOptionsAlone<GetProjectKeyNameQueryVariables>) {
      return this.getProjectKeyNameGql.watch(variables, options)
    }
    
    createProject(variables?: CreateProjectMutationVariables, options?: MutationOptionsAlone<CreateProjectMutation, CreateProjectMutationVariables>) {
      return this.createProjectGql.mutate(variables, options)
    }
    
    updateProject(variables?: UpdateProjectMutationVariables, options?: MutationOptionsAlone<UpdateProjectMutation, UpdateProjectMutationVariables>) {
      return this.updateProjectGql.mutate(variables, options)
    }
    
    deleteProject(variables: DeleteProjectMutationVariables, options?: MutationOptionsAlone<DeleteProjectMutation, DeleteProjectMutationVariables>) {
      return this.deleteProjectGql.mutate(variables, options)
    }
    
    getReportsList(variables?: GetReportsListQueryVariables, options?: QueryOptionsAlone<GetReportsListQueryVariables>) {
      return this.getReportsListGql.fetch(variables, options)
    }
    
    getReportsListWatch(variables?: GetReportsListQueryVariables, options?: WatchQueryOptionsAlone<GetReportsListQueryVariables>) {
      return this.getReportsListGql.watch(variables, options)
    }
    
    getReport(variables: GetReportQueryVariables, options?: QueryOptionsAlone<GetReportQueryVariables>) {
      return this.getReportGql.fetch(variables, options)
    }
    
    getReportWatch(variables: GetReportQueryVariables, options?: WatchQueryOptionsAlone<GetReportQueryVariables>) {
      return this.getReportGql.watch(variables, options)
    }
    
    getReportGeneralSettings(variables?: GetReportGeneralSettingsQueryVariables, options?: QueryOptionsAlone<GetReportGeneralSettingsQueryVariables>) {
      return this.getReportGeneralSettingsGql.fetch(variables, options)
    }
    
    getReportGeneralSettingsWatch(variables?: GetReportGeneralSettingsQueryVariables, options?: WatchQueryOptionsAlone<GetReportGeneralSettingsQueryVariables>) {
      return this.getReportGeneralSettingsGql.watch(variables, options)
    }
    
    getReportLvSettings(variables?: GetReportLvSettingsQueryVariables, options?: QueryOptionsAlone<GetReportLvSettingsQueryVariables>) {
      return this.getReportLvSettingsGql.fetch(variables, options)
    }
    
    getReportLvSettingsWatch(variables?: GetReportLvSettingsQueryVariables, options?: WatchQueryOptionsAlone<GetReportLvSettingsQueryVariables>) {
      return this.getReportLvSettingsGql.watch(variables, options)
    }
    
    createReport(variables?: CreateReportMutationVariables, options?: MutationOptionsAlone<CreateReportMutation, CreateReportMutationVariables>) {
      return this.createReportGql.mutate(variables, options)
    }
    
    updateReport(variables?: UpdateReportMutationVariables, options?: MutationOptionsAlone<UpdateReportMutation, UpdateReportMutationVariables>) {
      return this.updateReportGql.mutate(variables, options)
    }
    
    updateReportGeneralSettings(variables: UpdateReportGeneralSettingsMutationVariables, options?: MutationOptionsAlone<UpdateReportGeneralSettingsMutation, UpdateReportGeneralSettingsMutationVariables>) {
      return this.updateReportGeneralSettingsGql.mutate(variables, options)
    }
    
    deleteReport(variables: DeleteReportMutationVariables, options?: MutationOptionsAlone<DeleteReportMutation, DeleteReportMutationVariables>) {
      return this.deleteReportGql.mutate(variables, options)
    }
    
    getStateStatistics(variables?: GetStateStatisticsQueryVariables, options?: QueryOptionsAlone<GetStateStatisticsQueryVariables>) {
      return this.getStateStatisticsGql.fetch(variables, options)
    }
    
    getStateStatisticsWatch(variables?: GetStateStatisticsQueryVariables, options?: WatchQueryOptionsAlone<GetStateStatisticsQueryVariables>) {
      return this.getStateStatisticsGql.watch(variables, options)
    }
    
    getSubjectsList(variables?: GetSubjectsListQueryVariables, options?: QueryOptionsAlone<GetSubjectsListQueryVariables>) {
      return this.getSubjectsListGql.fetch(variables, options)
    }
    
    getSubjectsListWatch(variables?: GetSubjectsListQueryVariables, options?: WatchQueryOptionsAlone<GetSubjectsListQueryVariables>) {
      return this.getSubjectsListGql.watch(variables, options)
    }
    
    getSubjectsAppList(variables?: GetSubjectsAppListQueryVariables, options?: QueryOptionsAlone<GetSubjectsAppListQueryVariables>) {
      return this.getSubjectsAppListGql.fetch(variables, options)
    }
    
    getSubjectsAppListWatch(variables?: GetSubjectsAppListQueryVariables, options?: WatchQueryOptionsAlone<GetSubjectsAppListQueryVariables>) {
      return this.getSubjectsAppListGql.watch(variables, options)
    }
    
    subjectsProjectList(variables: SubjectsProjectListQueryVariables, options?: QueryOptionsAlone<SubjectsProjectListQueryVariables>) {
      return this.subjectsProjectListGql.fetch(variables, options)
    }
    
    subjectsProjectListWatch(variables: SubjectsProjectListQueryVariables, options?: WatchQueryOptionsAlone<SubjectsProjectListQueryVariables>) {
      return this.subjectsProjectListGql.watch(variables, options)
    }
    
    getSubject(variables: GetSubjectQueryVariables, options?: QueryOptionsAlone<GetSubjectQueryVariables>) {
      return this.getSubjectGql.fetch(variables, options)
    }
    
    getSubjectWatch(variables: GetSubjectQueryVariables, options?: WatchQueryOptionsAlone<GetSubjectQueryVariables>) {
      return this.getSubjectGql.watch(variables, options)
    }
    
    getCurrentSubjectDetails(variables?: GetCurrentSubjectDetailsQueryVariables, options?: QueryOptionsAlone<GetCurrentSubjectDetailsQueryVariables>) {
      return this.getCurrentSubjectDetailsGql.fetch(variables, options)
    }
    
    getCurrentSubjectDetailsWatch(variables?: GetCurrentSubjectDetailsQueryVariables, options?: WatchQueryOptionsAlone<GetCurrentSubjectDetailsQueryVariables>) {
      return this.getCurrentSubjectDetailsGql.watch(variables, options)
    }
    
    getSubjectAresInfo(variables: GetSubjectAresInfoQueryVariables, options?: QueryOptionsAlone<GetSubjectAresInfoQueryVariables>) {
      return this.getSubjectAresInfoGql.fetch(variables, options)
    }
    
    getSubjectAresInfoWatch(variables: GetSubjectAresInfoQueryVariables, options?: WatchQueryOptionsAlone<GetSubjectAresInfoQueryVariables>) {
      return this.getSubjectAresInfoGql.watch(variables, options)
    }
    
    userUnAssign(variables: UserUnAssignMutationVariables, options?: MutationOptionsAlone<UserUnAssignMutation, UserUnAssignMutationVariables>) {
      return this.userUnAssignGql.mutate(variables, options)
    }
    
    createSubject(variables?: CreateSubjectMutationVariables, options?: MutationOptionsAlone<CreateSubjectMutation, CreateSubjectMutationVariables>) {
      return this.createSubjectGql.mutate(variables, options)
    }
    
    updateSubject(variables?: UpdateSubjectMutationVariables, options?: MutationOptionsAlone<UpdateSubjectMutation, UpdateSubjectMutationVariables>) {
      return this.updateSubjectGql.mutate(variables, options)
    }
    
    deleteSubject(variables: DeleteSubjectMutationVariables, options?: MutationOptionsAlone<DeleteSubjectMutation, DeleteSubjectMutationVariables>) {
      return this.deleteSubjectGql.mutate(variables, options)
    }
    
    getTagDefectsList(variables?: GetTagDefectsListQueryVariables, options?: QueryOptionsAlone<GetTagDefectsListQueryVariables>) {
      return this.getTagDefectsListGql.fetch(variables, options)
    }
    
    getTagDefectsListWatch(variables?: GetTagDefectsListQueryVariables, options?: WatchQueryOptionsAlone<GetTagDefectsListQueryVariables>) {
      return this.getTagDefectsListGql.watch(variables, options)
    }
    
    getTagDefect(variables: GetTagDefectQueryVariables, options?: QueryOptionsAlone<GetTagDefectQueryVariables>) {
      return this.getTagDefectGql.fetch(variables, options)
    }
    
    getTagDefectWatch(variables: GetTagDefectQueryVariables, options?: WatchQueryOptionsAlone<GetTagDefectQueryVariables>) {
      return this.getTagDefectGql.watch(variables, options)
    }
    
    getTagDefectBrowse(variables: GetTagDefectBrowseQueryVariables, options?: QueryOptionsAlone<GetTagDefectBrowseQueryVariables>) {
      return this.getTagDefectBrowseGql.fetch(variables, options)
    }
    
    getTagDefectBrowseWatch(variables: GetTagDefectBrowseQueryVariables, options?: WatchQueryOptionsAlone<GetTagDefectBrowseQueryVariables>) {
      return this.getTagDefectBrowseGql.watch(variables, options)
    }
    
    createTagDefect(variables?: CreateTagDefectMutationVariables, options?: MutationOptionsAlone<CreateTagDefectMutation, CreateTagDefectMutationVariables>) {
      return this.createTagDefectGql.mutate(variables, options)
    }
    
    updateTagDefect(variables?: UpdateTagDefectMutationVariables, options?: MutationOptionsAlone<UpdateTagDefectMutation, UpdateTagDefectMutationVariables>) {
      return this.updateTagDefectGql.mutate(variables, options)
    }
    
    deleteTagDefect(variables: DeleteTagDefectMutationVariables, options?: MutationOptionsAlone<DeleteTagDefectMutation, DeleteTagDefectMutationVariables>) {
      return this.deleteTagDefectGql.mutate(variables, options)
    }
    
    getTagGoodPracticeList(variables?: GetTagGoodPracticeListQueryVariables, options?: QueryOptionsAlone<GetTagGoodPracticeListQueryVariables>) {
      return this.getTagGoodPracticeListGql.fetch(variables, options)
    }
    
    getTagGoodPracticeListWatch(variables?: GetTagGoodPracticeListQueryVariables, options?: WatchQueryOptionsAlone<GetTagGoodPracticeListQueryVariables>) {
      return this.getTagGoodPracticeListGql.watch(variables, options)
    }
    
    getTagGoodPractice(variables: GetTagGoodPracticeQueryVariables, options?: QueryOptionsAlone<GetTagGoodPracticeQueryVariables>) {
      return this.getTagGoodPracticeGql.fetch(variables, options)
    }
    
    getTagGoodPracticeWatch(variables: GetTagGoodPracticeQueryVariables, options?: WatchQueryOptionsAlone<GetTagGoodPracticeQueryVariables>) {
      return this.getTagGoodPracticeGql.watch(variables, options)
    }
    
    getTagGoodPracticeBrowse(variables: GetTagGoodPracticeBrowseQueryVariables, options?: QueryOptionsAlone<GetTagGoodPracticeBrowseQueryVariables>) {
      return this.getTagGoodPracticeBrowseGql.fetch(variables, options)
    }
    
    getTagGoodPracticeBrowseWatch(variables: GetTagGoodPracticeBrowseQueryVariables, options?: WatchQueryOptionsAlone<GetTagGoodPracticeBrowseQueryVariables>) {
      return this.getTagGoodPracticeBrowseGql.watch(variables, options)
    }
    
    createTagGoodPractice(variables?: CreateTagGoodPracticeMutationVariables, options?: MutationOptionsAlone<CreateTagGoodPracticeMutation, CreateTagGoodPracticeMutationVariables>) {
      return this.createTagGoodPracticeGql.mutate(variables, options)
    }
    
    updateTagGoodPractice(variables?: UpdateTagGoodPracticeMutationVariables, options?: MutationOptionsAlone<UpdateTagGoodPracticeMutation, UpdateTagGoodPracticeMutationVariables>) {
      return this.updateTagGoodPracticeGql.mutate(variables, options)
    }
    
    deleteTagGoodPractice(variables: DeleteTagGoodPracticeMutationVariables, options?: MutationOptionsAlone<DeleteTagGoodPracticeMutation, DeleteTagGoodPracticeMutationVariables>) {
      return this.deleteTagGoodPracticeGql.mutate(variables, options)
    }
    
    getTagTasksList(variables?: GetTagTasksListQueryVariables, options?: QueryOptionsAlone<GetTagTasksListQueryVariables>) {
      return this.getTagTasksListGql.fetch(variables, options)
    }
    
    getTagTasksListWatch(variables?: GetTagTasksListQueryVariables, options?: WatchQueryOptionsAlone<GetTagTasksListQueryVariables>) {
      return this.getTagTasksListGql.watch(variables, options)
    }
    
    getTagTask(variables: GetTagTaskQueryVariables, options?: QueryOptionsAlone<GetTagTaskQueryVariables>) {
      return this.getTagTaskGql.fetch(variables, options)
    }
    
    getTagTaskWatch(variables: GetTagTaskQueryVariables, options?: WatchQueryOptionsAlone<GetTagTaskQueryVariables>) {
      return this.getTagTaskGql.watch(variables, options)
    }
    
    getTagTaskBrowse(variables: GetTagTaskBrowseQueryVariables, options?: QueryOptionsAlone<GetTagTaskBrowseQueryVariables>) {
      return this.getTagTaskBrowseGql.fetch(variables, options)
    }
    
    getTagTaskBrowseWatch(variables: GetTagTaskBrowseQueryVariables, options?: WatchQueryOptionsAlone<GetTagTaskBrowseQueryVariables>) {
      return this.getTagTaskBrowseGql.watch(variables, options)
    }
    
    createTagTask(variables?: CreateTagTaskMutationVariables, options?: MutationOptionsAlone<CreateTagTaskMutation, CreateTagTaskMutationVariables>) {
      return this.createTagTaskGql.mutate(variables, options)
    }
    
    updateTagTask(variables?: UpdateTagTaskMutationVariables, options?: MutationOptionsAlone<UpdateTagTaskMutation, UpdateTagTaskMutationVariables>) {
      return this.updateTagTaskGql.mutate(variables, options)
    }
    
    deleteTagTask(variables: DeleteTagTaskMutationVariables, options?: MutationOptionsAlone<DeleteTagTaskMutation, DeleteTagTaskMutationVariables>) {
      return this.deleteTagTaskGql.mutate(variables, options)
    }
    
    getUsersList(variables?: GetUsersListQueryVariables, options?: QueryOptionsAlone<GetUsersListQueryVariables>) {
      return this.getUsersListGql.fetch(variables, options)
    }
    
    getUsersListWatch(variables?: GetUsersListQueryVariables, options?: WatchQueryOptionsAlone<GetUsersListQueryVariables>) {
      return this.getUsersListGql.watch(variables, options)
    }
    
    getUsersDropdown(variables?: GetUsersDropdownQueryVariables, options?: QueryOptionsAlone<GetUsersDropdownQueryVariables>) {
      return this.getUsersDropdownGql.fetch(variables, options)
    }
    
    getUsersDropdownWatch(variables?: GetUsersDropdownQueryVariables, options?: WatchQueryOptionsAlone<GetUsersDropdownQueryVariables>) {
      return this.getUsersDropdownGql.watch(variables, options)
    }
    
    getUsersBySubjectDropdown(variables?: GetUsersBySubjectDropdownQueryVariables, options?: QueryOptionsAlone<GetUsersBySubjectDropdownQueryVariables>) {
      return this.getUsersBySubjectDropdownGql.fetch(variables, options)
    }
    
    getUsersBySubjectDropdownWatch(variables?: GetUsersBySubjectDropdownQueryVariables, options?: WatchQueryOptionsAlone<GetUsersBySubjectDropdownQueryVariables>) {
      return this.getUsersBySubjectDropdownGql.watch(variables, options)
    }
    
    getUser(variables: GetUserQueryVariables, options?: QueryOptionsAlone<GetUserQueryVariables>) {
      return this.getUserGql.fetch(variables, options)
    }
    
    getUserWatch(variables: GetUserQueryVariables, options?: WatchQueryOptionsAlone<GetUserQueryVariables>) {
      return this.getUserGql.watch(variables, options)
    }
    
    getPermissions(variables?: GetPermissionsQueryVariables, options?: QueryOptionsAlone<GetPermissionsQueryVariables>) {
      return this.getPermissionsGql.fetch(variables, options)
    }
    
    getPermissionsWatch(variables?: GetPermissionsQueryVariables, options?: WatchQueryOptionsAlone<GetPermissionsQueryVariables>) {
      return this.getPermissionsGql.watch(variables, options)
    }
    
    sendInvitation(variables?: SendInvitationQueryVariables, options?: QueryOptionsAlone<SendInvitationQueryVariables>) {
      return this.sendInvitationGql.fetch(variables, options)
    }
    
    sendInvitationWatch(variables?: SendInvitationQueryVariables, options?: WatchQueryOptionsAlone<SendInvitationQueryVariables>) {
      return this.sendInvitationGql.watch(variables, options)
    }
    
    createUser(variables?: CreateUserMutationVariables, options?: MutationOptionsAlone<CreateUserMutation, CreateUserMutationVariables>) {
      return this.createUserGql.mutate(variables, options)
    }
    
    updateUser(variables?: UpdateUserMutationVariables, options?: MutationOptionsAlone<UpdateUserMutation, UpdateUserMutationVariables>) {
      return this.updateUserGql.mutate(variables, options)
    }
    
    deleteUser(variables: DeleteUserMutationVariables, options?: MutationOptionsAlone<DeleteUserMutation, DeleteUserMutationVariables>) {
      return this.deleteUserGql.mutate(variables, options)
    }
  }