import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Store} from '@ngrx/store';
import {SubSink} from 'subsink';
import {AddMessage} from '../../loading/loading.action';
import {FlashMessage} from '../../shared/model/flash-message.model';
import {ProxyService} from '../../shared/services/proxy.service';
import {MessageService} from 'primeng/api';
import {AppState} from '../../store/app.reducer';
import {UserFormDialogHide} from './store/user-form.actions';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.css'],
    providers: [MessageService],
})
export class UserFormComponent implements OnInit, OnDestroy {

    private subs = new SubSink();

    @ViewChild('f') dialogForm: NgForm;

    public display: boolean;
    public type: number;
    public id: number;
    public typeStr: string;
    public typeName: string;
    public emails: string[];
    public identifier: string;

    constructor(
        private store: Store<AppState>,
        private proxyService: ProxyService
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.store.select('userForm').subscribe((data) => {
            this.display = data.userFormDialogShow;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onHide($event: any) {
        this.store.dispatch(new UserFormDialogHide());
    }

    onSubmit(f: NgForm) {
        this.proxyService.sendInvitation(this.emails).then((result) => {
            this.dialogForm.resetForm();
            this.store.dispatch(new AddMessage([FlashMessage.successMessage('emails.invitation.sent')], 'global'));
            this.store.dispatch(new UserFormDialogHide());
        });
    }

    onCancel() {
        this.dialogForm.resetForm();
        this.store.dispatch(new UserFormDialogHide());
    }

    validateEmail(event: any) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(event.value)) {
            return true;
        }

        const index = this.emails.indexOf(event.value);
        if (index !== -1) {
            this.emails.splice(index, 1);
        }

        this.store.dispatch(new AddMessage([FlashMessage.warningMessage('emails.not-an-email-address')], 'global'));
        return false;
    }
}
