import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

export function isObjectValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const isObject = typeof control.value === 'object' && control.value !== null;
        return !isObject ? {message: {value: 'Invalid value'}} : null;
    };
}

@Directive({
    selector: '[appIsObjectValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: IsObjectDirective, multi: true}]
})
export class IsObjectDirective implements Validator {
    validate(control: AbstractControl): {[key: string]: any} | null {
        return isObjectValidator()(control);
    }
}
