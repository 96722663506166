import {Directive} from '@angular/core';
import {Calendar} from 'primeng/calendar';
import {AutoComplete} from 'primeng/autocomplete';
import {Dropdown} from 'primeng/dropdown';
import {MultiSelect} from 'primeng/multiselect';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[calTransition]'
})
export class CustomCalendarTransitionDirective {
    constructor(el: Calendar) {
        el.showTransitionOptions = '100ms';
        el.hideTransitionOptions = '50ms';
        el.dateFormat = 'dd.mm.yy';
        el.showButtonBar = true;
        el.firstDayOfWeek = 1;
        el.showIcon = true;
        el.autoZIndex = false;
        el.appendTo = 'body';
    }
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[calZIndexWithoutBody]'
})
export class CustomCalendarZIndexDirective {
    constructor(el: Calendar) {
        el.autoZIndex = false;
        el.appendTo = '';
    }
}


@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[autoCompleteTransition]'
})
export class CustomAutocompleteTransitionDirective {
    constructor(el: AutoComplete) {
        el.showTransitionOptions = '0ms';
        el.hideTransitionOptions = '50ms';
        el.type = 'search';
    }
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[dropdownTransition]'
})
export class CustomDropdownTransitionDirective {
    constructor(el: Dropdown) {
        el.showTransitionOptions = '0ms';
        el.hideTransitionOptions = '50ms';
    }
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[multiselectTransition]'
})
export class MutiselectTransitionDirective {
    constructor(el: MultiSelect) {
        el.showTransitionOptions = '0ms';
        el.hideTransitionOptions = '50ms';
    }
}
