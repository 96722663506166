import { Actions, Effect, ofType } from '@ngrx/effects';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ReportCreate,
    ReportDelete,
    ReportGeneralSettingsSet,
    ReportsError,
    ReportSet,
    ReportsFetch, ReportsFetchLazyMeta,
    ReportsSet,
    ReportUpdate, ReportUpdateGeneralSettings, ReportUpdateGeneralSettingsSuccess
} from './reports.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {
    ReportCreateSuccess,
    ReportDeleteSuccess,
    ReportFetch,
    ReportsActionTypes,
    ReportUpdateSuccess
} from './reports.actions';
import * as fromReports from '../../reports/store/reports.reducer';
import {Router} from '@angular/router';
import {AppState} from '../../../store/app.reducer';
import {UsersFetchLazyMeta} from '../../users/store/users.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new ReportsError());
};

@Injectable()
export class ReportsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect()
    fetchReports = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportsFetch),
        switchMap((reportsActions: ReportsFetch) => {
            this.store.dispatch(new ReportsFetchLazyMeta({lazyLoad: reportsActions.payload}));
            return this.conPointService.getReportsList({lazyLoad: reportsActions.payload}).pipe(
                map((result) => {
                    const reports = result.data.GetReportsList.result?.map(report => {
                        return {...report};
                    });

                    return {
                        reports,
                        total: result.data.GetReportsList.metadata.total,
                        count: result.data.GetReportsList.metadata.count
                    };
                }),
                map(({reports, total, count}) => {
                    return new ReportsSet({reports: reports ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    reportFetch = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportFetch),
        switchMap((reportsActions: ReportFetch) => {
            return this.conPointService.getReport({id: reportsActions.payload.id}).pipe(
                map((result) => {
                    return new ReportSet({report: result.data.GetReport});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    reportCreate = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportCreate),
        withLatestFrom(this.store.select('reports')),
        switchMap<[ReportCreate, fromReports.State], Observable<any>>(([reportActions, reportsState]) => {
            return this.conPointService.createReport({report: reportActions.payload.report}).pipe(
                map(() => {
                    return new ReportCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    reportUpdate = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportUpdate),
        withLatestFrom(this.store.select('reports')),
        switchMap<[ReportUpdate, fromReports.State], Observable<any>>(([reportActions, reportsState]) => {

            const report = {...reportActions.payload.report};
            delete report['createdAt'];
            delete report['updatedAt'];

            return this.conPointService.updateReport({report}).pipe(
                map(() => {
                    console.log(reportsState.currentLazyLoad);
                    this.store.dispatch(new ReportsFetch(reportsState.currentLazyLoad));
                    return new ReportUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    reportGeneralSettingsUpdate = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportUpdateGeneralSettings),
        withLatestFrom(this.store.select('reports')),
        switchMap<[ReportUpdateGeneralSettings, fromReports.State], Observable<any>>(([reportActions, reportsState]) => {
            return this.conPointService.updateReportGeneralSettings({settings: reportActions.payload.settings}).pipe(
                map((settings) => {
                    this.store.dispatch(new ReportGeneralSettingsSet({settings: settings.data.UpdateReportGeneralSettings}));
                    return new ReportUpdateGeneralSettingsSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    reportGeneralSettingsFetch = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportGeneralSettingsFetch),
        switchMap((reportsActions: ReportFetch) => {
            return this.conPointService.getReportGeneralSettings().pipe(
                map((result) => {
                    return new ReportGeneralSettingsSet({settings: result.data.GetReportGeneralSettings});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    reportDelete = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportDelete),
        withLatestFrom(this.store.select('reports')),
        switchMap<[ReportDelete, fromReports.State], Observable<any>>(([reportActions, reportsState]) => {
            return this.conPointService.deleteReport({id: reportActions.payload.id}).pipe(
                map(() => {
                    console.log(reportsState.currentLazyLoad);
                    this.store.dispatch(new ReportsFetch(reportsState.currentLazyLoad));
                    return new ReportDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect({dispatch: false})
    reportNavigate = this.actions$.pipe(
        ofType(ReportsActionTypes.ReportUpdateSuccess, ReportsActionTypes.ReportCreateSuccess),
        withLatestFrom(this.store.select('reports')),
        tap(() => {
            this.router.navigate(['/reports']).then();
        })
    );
}
