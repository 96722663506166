import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

// Application Components
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightPanelComponent} from './app.rightpanel.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

// Application services
import {BreadcrumbService} from './breadcrumb.service';
import {MenuService} from './app.menu.service';
import {AppCodeModule} from './app.code.component';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './components/auth/store/auth.effects';
import {UsersEffects} from './components/users/store/users.effects';
import {SubjectsEffects} from './components/subjects/store/subjects.effects';
import {ProjectsEffects} from './components/projects/store/projects.effects';
import {ContactsEffects} from './components/contacts/store/contacts.effects';
import {TagDefectsEffects} from './components/tags/tag-defects/store/tag-defects.effects';
import {TagTasksEffects} from './components/tags/tag-tasks/store/tag-tasks.effects';
import {TagGoodPracticeEffects} from './components/tags/tag-good-practice/store/tag-good-practice.effects';
import {ReportsEffects} from './components/reports/store/reports.effects';
import {GenericTextsEffects} from './components/generic-texts/store/generic-texts.effects';
import {AreasOfEffects} from './components/area-of/store/areas-of.effects';
import {CurrentStateEffects} from './store/current-state/current-state.effects';
import {NgxPermissionsModule} from 'ngx-permissions';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import * as fromApp from './store/app.reducer';
import * as fromLoadingReducer from './loading/loading.reducer';
import {StoreModule} from '@ngrx/store';
import {UsersComponent} from './components/users/users.component';
import {UserFormComponent} from './forms/user-form/user-form.component';
import {PasswordMatchDirective} from './forms/directives/password-match.directive';
import {IsObjectDirective} from './forms/directives/is-object.directive';
import {
    CustomAutocompleteTransitionDirective,
    CustomCalendarTransitionDirective, CustomCalendarZIndexDirective, CustomDropdownTransitionDirective, MutiselectTransitionDirective
} from './forms/directives/custom-transition.directive';
import {NgxSpinnerTemplateDirective} from './forms/directives/ngx-spinner-template.directive';
import {ModalLoadingSpinnerComponent} from './shared/modal-loading-spinner/modal-loading-spinner.component';
import {SubjectsComponent} from './components/subjects/subjects.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {SubjectEditComponent} from './components/subjects/subject-edit/subject-edit.component';
import {SubjectsListComponent} from './components/subjects/subjects-list/subjects-list.component';
import {BaseComponent} from './shared/base/base.component';
import {AddressPartComponent} from './forms/parts/address-part.component';
import {SignaturePartComponent} from './forms/parts/signature-part.component';
import {UserPartComponent} from './forms/parts/user-part.component';
import {ContactPartComponent} from './forms/parts/contact-part.component';
import {ProjectEditComponent} from './components/projects/project-edit/project-edit.component';
import {ProjectsListComponent} from './components/projects/projects-list/projects-list.component';
import {AuthComponent} from './components/auth/auth.component';
import {EmptyLayoutComponent} from './empty-layout/empty-layout.component';
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import {ContactFormComponent} from './forms/contact-form/contact-form.component';
import {ContactsComponent} from './components/contacts/contacts.component';
import {TagDefectsComponent} from './components/tags/tag-defects/tag-defects.component';
import {TagTasksComponent} from './components/tags/tag-tasks/tag-tasks.component';
import {TagGoodPracticeComponent} from './components/tags/tag-good-practice/tag-good-practice.component';
import {OshSubjectComponent} from './components/settings/osh-subject/osh-subject.component';
import {TagDefectsListComponent} from './components/tags/tag-defects/tag-defects-list/tag-defects-list.component';
import {TagDefectEditComponent} from './components/tags/tag-defects/tag-defect-edit/tag-defect-edit.component';
import {TagTasksListComponent} from './components/tags/tag-tasks/tag-tasks-list/tag-tasks-list.component';
import {TagTaskEditComponent} from './components/tags/tag-tasks/tag-task-edit/tag-task-edit.component';
import {TagGoodPracticeEditComponent} from './components/tags/tag-good-practice/tag-good-practice-edit/tag-good-practice-edit.component';
import {DefectCreateComponent} from './forms/tags/defect-create/defect-create.component';
import {TaskCreateComponent} from './forms/tags/task-create/task-create.component';
import {GoodPracticeCreateComponent} from './forms/tags/good-practice-create/good-practice-create.component';
import {TagGoodPracticeListComponent} from './components/tags/tag-good-practice/tag-good-practice-list/tag-good-practice-list.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AccountCreateComponent} from './components/users/account-create/account-create.component';
import {CreateUserPartComponent} from './forms/parts/create-user-part.component';
import {StandaloneTableFilterComponent} from './shared/components/standalone-table-filter/standalone-table-filter.component';
import {GenericTextFormComponent} from './forms/generic-text-form/generic-text-form.component';
import {SendFormComponent} from './forms/send-tag-form/send-form.component';
import {LiteListComponent} from './components/lite-list/lite-list.component';
import {ProjectDetailComponent} from './components/projects/project-detail/project-detail.component';
import {SubjectDetailComponent} from './components/subjects/subject-detail/subject-detail.component';
import {UsersListComponent} from './components/users/users-list/users-list.component';
import {UserEditComponent} from './components/users/user-edit/user-edit.component';
import {ContactsListComponent} from './components/contacts/contacts-list/contacts-list.component';
import {ContactEditComponent} from './components/contacts/contact-edit/contact-edit.component';
import {UserDetailComponent} from './components/users/user-detail/user-detail.component';
import {ContactDetailComponent} from './components/contacts/contact-detail/contact-detail.component';
import {ReportsComponent} from './components/reports/reports.component';
import {ReportsListComponent} from './components/reports/reports-list/reports-list.component';
import {ReportEditComponent} from './components/reports/report-edit/report-edit.component';
import {ReportCreateComponent} from './components/reports/report-create/report-create.component';
import {GenericTextsComponent} from './components/generic-texts/generic-texts.component';
import {GenericTextsListComponent} from './components/generic-texts/generic-texts-list/generic-texts-list.component';
import {GenericTextEditComponent} from './components/generic-texts/generic-text-edit/generic-text-edit.component';
import {AreaOfComponent} from './components/area-of/area-of.component';
import {AreaOfListComponent} from './components/area-of/area-of-list/area-of-list.component';
import {AreaOfEditComponent} from './components/area-of/area-of-edit/area-of-edit.component';
import {GALLERY_CONFIG, GalleryModule} from '@ngx-gallery/core';
import {ENVIRONMENT_SPECIFIC_PROVIDERS} from '../environments/interceptors';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgxSpinnerComponent, NgxSpinnerModule} from 'ngx-spinner';
import {GraphQLModule} from './graphql.module';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {ClipboardModule} from 'ngx-clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ChooseSubjectProjectComponent } from './forms/choose-subject-project/choose-subject-project.component';
import {environment} from '../environments/environment';
import {AppInvalidInvitationComponent} from './pages/app.invalidinvitation.component';
import { TagBaseComponent } from './components/tags/tag-base/tag-base.component';
import { TagDialogBaseComponentComponent } from './components/tags/tag-dialog-base-component/tag-dialog-base-component.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {OshSubjectEffects} from './components/settings/osh-subject/store/osh-subject.effects';
import { SubjectCreateComponent } from './components/subjects/subject-create/subject-create.component';
import { AccountsComponent } from './components/management/accounts/accounts.component';
import {AccountsEffects} from './components/management/accounts/store/accounts.effects';

@NgModule({
    imports: [
        StoreModule.forRoot(fromApp.appReducer),
        StoreModule.forFeature('@fx/ngrx/loading', fromLoadingReducer.reducer),
        EffectsModule.forRoot([
            AuthEffects,
            UsersEffects,
            SubjectsEffects,
            ProjectsEffects,
            ContactsEffects,
            TagDefectsEffects,
            TagTasksEffects,
            TagGoodPracticeEffects,
            ReportsEffects,
            GenericTextsEffects,
            AreasOfEffects,
            CurrentStateEffects,
            OshSubjectEffects,
            AccountsEffects,
        ]),
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'cs'
        }),
        GraphQLModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        NgxSpinnerModule,
        LightboxModule,
        GalleryModule,
        ClipboardModule,
        DragDropModule,
        StoreDevtoolsModule.instrument({logOnly: environment.production}),
        ProgressSpinnerModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppInvalidInvitationComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppTimelineDemoComponent,
        UsersComponent,
        UserFormComponent,
        PasswordMatchDirective,
        IsObjectDirective,
        CustomCalendarTransitionDirective,
        CustomCalendarZIndexDirective,
        CustomAutocompleteTransitionDirective,
        CustomDropdownTransitionDirective,
        MutiselectTransitionDirective,
        NgxSpinnerTemplateDirective,
        ModalLoadingSpinnerComponent,
        SubjectsComponent,
        ProjectsComponent,
        SubjectEditComponent,
        SubjectsListComponent,
        BaseComponent,
        AddressPartComponent,
        SignaturePartComponent,
        UserPartComponent,
        ContactPartComponent,
        ProjectEditComponent,
        ProjectsListComponent,
        AuthComponent,
        EmptyLayoutComponent,
        HomeLayoutComponent,
        ContactFormComponent,
        ContactsComponent,
        TagDefectsComponent,
        TagTasksComponent,
        TagGoodPracticeComponent,
        OshSubjectComponent,
        TagDefectsListComponent,
        TagDefectEditComponent,
        TagTasksListComponent,
        TagTaskEditComponent,
        TagGoodPracticeListComponent,
        TagGoodPracticeEditComponent,
        DefectCreateComponent,
        TaskCreateComponent,
        GoodPracticeCreateComponent,
        DashboardComponent,
        AccountCreateComponent,
        SubjectCreateComponent,
        CreateUserPartComponent,
        StandaloneTableFilterComponent,
        GenericTextFormComponent,
        SendFormComponent,
        LiteListComponent,
        ProjectDetailComponent,
        SubjectDetailComponent,
        UsersListComponent,
        UserEditComponent,
        ContactsListComponent,
        ContactEditComponent,
        UserDetailComponent,
        ContactDetailComponent,
        ReportsComponent,
        ReportsListComponent,
        ReportEditComponent,
        ReportCreateComponent,
        GenericTextsComponent,
        GenericTextsListComponent,
        GenericTextEditComponent,
        AreaOfComponent,
        AreaOfListComponent,
        AreaOfEditComponent,
        ChooseSubjectProjectComponent,
        TagBaseComponent,
        TagDialogBaseComponentComponent,
        SubjectCreateComponent,
        AccountsComponent,
    ],
    providers: [
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: GALLERY_CONFIG,
            useValue: {
                dots: true,
                imageSize: 'cover'
            }
        },
        ...ENVIRONMENT_SPECIFIC_PROVIDERS,
        BreadcrumbService, MenuService, MessageService, ConfirmationService, NgxSpinnerComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
