import {createAction, props} from '@ngrx/store';
import {Subject} from '../../../../../generated/graphql';

export const accountsFetch = createAction('[@Accounts] accountsFetch', () => {
    return {fxLoading: {action: 'add', ids: [{name: accountsFetch.type, spinner: 'sp_content'}], type: 'global'}};
});
export const accountsFetchSuccess = createAction('[@Accounts] accountsFetchSuccess', (payload: {subjects: Subject[], totalRows: number}) => {
    return {
        payload,
        fxLoading: {action: 'remove', ids: [{name: accountsFetch.type, spinner: 'sp_content'}], type: 'global'}
    };
});
export const accountsError = createAction('[@Accounts] accountsError', () => {
    return {fxLoading: {action: 'remove', ids: [{name: accountsFetch.type, spinner: 'sp_content'}], type: 'global'}};
});
