import {Directive} from '@angular/core';
import {NgxSpinnerComponent} from 'ngx-spinner';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[ngxSpinnerCustomTemplate]'
})
export class NgxSpinnerTemplateDirective {
    constructor(el: NgxSpinnerComponent) {
        el.template = `<div class="la-ball-clip-rotate-multiple la-2x">
                            <div class='outer-circle'></div>
                            <div class='inner-circle'></div>
                        </div>`;
    }
}
