import {Component, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {Store} from '@ngrx/store';
import {AddMessage} from '../../loading/loading.action';
import {FlashMessage} from '../../shared/model/flash-message.model';
import {GlobalService} from '../../shared/services/global.service';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AppState} from '../../store/app.reducer';

@Component({
    selector: 'app-signature-part',
    template: `
        <div class="card card-w-title" ngModelGroup="signatureData">
			<div class="p-d-flex form-group">
				<div class="p-col-12 ">
					<p-fileUpload
                            #signatureFileUpload
							customUpload="true"
                            mode="basic"
                            name="images[]"
                            accept="image/png"
                            maxFileSize="10000000000"
							chooseLabel="{{'general.browse'|translate}}"
							(uploadHandler)="onHandleUpload($event, signatureFileUpload)"
							[auto]="true"
                    ></p-fileUpload>
				</div>
			</div>
			<div class="p-d-flex form-group">
				<div class="p-col-12 ">
					<div class="p-d-flex">
						<div class="p-col-3" *ngIf="signatureHash">
                            <img [src]="signatureBaseUrl + '/' + signatureHash" width="150px">
						</div>
						<div class="p-col-3" *ngIf="signaturePreview">
							<img [src]="signaturePreview" alt="Signature preview" width="150px">
                        </div>
                        <p-button
                                type="button"
                                styleClass="p-button-danger"
                                label="X"
                                (onClick)="onRemoveSignature()"
                                *ngIf="signatureHash || signaturePreview"
                        ></p-button>
					</div>
				</div>
			</div>
            <div class="p-d-flex form-group">
                <div class="p-col-12 ">
                    <div class="p-fluid">
                        <div class="p-field">
					        <label for="signatureDescription">{{'users.signature-description' | translate}}</label>
                            <textarea
                                    id="signatureDescription"
                                    name="signatureDescription"
                                    #signatureDescription
                                    ngModel
                                    rows="3"
                                    cols="30"
                                    pInputTextarea
                                    style="resize: none"
                                    (keyup.enter)="onSignatureDescriptionNewLine($event)"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    `,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SignaturePartComponent implements OnInit {

    @ViewChildren('signatureFileUpload') signatureFileUpload;
    @ViewChildren('signatureDescription') signatureDescription;
    @Output() signatureImage = new EventEmitter();
    @Input() signatureHash: string;
    @Input() signaturePreview: SafeResourceUrl = null;
    public signatureBaseUrl: string;

    constructor(
        private store: Store<AppState>,
        private gs: GlobalService
    ) {
    }

    ngOnInit() {
        this.signatureBaseUrl = this.gs.getEnvironment().mediaUrl + 'media/thumb/';
    }

    onHandleUpload(event: any, form) {
        const signatureFile = event.files[0];
        if (signatureFile.size > 500000) {
            this.store.dispatch(new AddMessage([FlashMessage.warningMessage('users.signature-file-too-big')], 'global'));
        } else {
            const myReader = new FileReader();
            myReader.onloadend = (e) => {
                this.signatureImage.emit(myReader.result);
            };
            myReader.readAsDataURL(signatureFile);
        }

        form.clear();
    }

    onRemoveSignature() {
        this.signatureHash = null;
        this.signaturePreview = null;
        this.signatureImage.emit('');
    }

    onSignatureDescriptionNewLine(event: any) {
        const lineNumber = event.target.value.substr(0, event.target.selectionStart).split('\n').length;
        if (lineNumber > 3) {
            this.signatureDescription.first.nativeElement.value = event.target.value.substr(0, event.target.value.length - 1);
        }
    }
}
