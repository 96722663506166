import {ToolbarButton} from './toolbar-button.model';
import {MenuItem} from 'primeng/api';
import {ActionTypes, FixedToolbarActions} from './fixed-toolbar.action';

export interface State {
    breadcrumbItems: MenuItem[];
    toolbarButtons: Map<string, ToolbarButton>;
}

const initialState = {
    breadcrumbItems: [],
    toolbarButtons: new Map()
};

export function fixedToolbarReducer(state = initialState, action: FixedToolbarActions): State {
    switch (action.type) {
        case ActionTypes.AddBreadcrumb:
            state = {
                ...state,
                breadcrumbItems: [...state.breadcrumbItems, ...action.payload]
            };
            break;
        case ActionTypes.SetBreadcrumb:
            state = {
                ...state,
                breadcrumbItems: action.payload
            };
            break;
        case ActionTypes.ClearBreadcrumb:
            state = {
                ...state,
                breadcrumbItems: []
            };
            break;
        case ActionTypes.SetToolbarButtons:
            {
                const buttons = new Map();

                action.payload.map((button) => {
                    buttons.set(button.id, button);
                });

                state = {
                    ...state,
                    toolbarButtons: buttons
                };
            }
            break;
        case ActionTypes.UpdateToolbarButtons:
            {
                const buttons = state.toolbarButtons;
                action.payload.map((button) => {
                    if (buttons.has(button.id)) {
                        const stateButton = {...buttons.get(button.id)};
                        for (const key of Object.keys(button)) {
                            stateButton[key] = button[key];
                        }
                        buttons.set(button.id, stateButton);
                    } else {
                        buttons.set(button.id, button);
                    }
                });

                state = {
                    ...state,
                    toolbarButtons: buttons
                };
            }
            break;
        case ActionTypes.ClearToolbarButtons:
            state = {
                ...state,
                toolbarButtons: new Map()
            };
            break;
    }
    return state;
}
