import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {TagsService} from '../../../components/tags/tags.service';
import {
    TagDefectInput,
} from '../../../../generated/graphql';
import {UploadManager} from '../../../shared/classes/upload-manager';
import {GeneralTypes, TagTypes} from '../../../shared/enums/entity-enums';
import {currentStateGetCurrentProject} from '../../../store/current-state/current-state.selector';
import {take} from 'rxjs/operators';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {AppState} from '../../../store/app.reducer';
import {TagDefectCreate} from '../../../components/tags/tag-defects/store/tag-defects.actions';
import {AppActions} from '../../../store/app-actions.service';
import {GeneralSaveFormAction} from '../../../store/general/general.actions';
import {generalGetDefectCreateForm} from '../../../store/general/general.selector';
import {GlobalService} from '../../../shared/services/global.service';
import {TagDialogBaseComponentComponent} from '../../../components/tags/tag-dialog-base-component/tag-dialog-base-component.component';
import {DialogHide} from './store/defect-create-form.actions';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {Gallery} from '@ngx-gallery/core';

@Component({
  selector: 'app-defect-create',
  templateUrl: './defect-create.component.html',
  styleUrls: ['./defect-create.component.css']
})
export class DefectCreateComponent extends TagDialogBaseComponentComponent implements OnInit, OnDestroy {

    tagType: number = TagTypes.TAG_DEFECT;

    @ViewChild('defectImageUpload') defectImageUpload;

    generalTypeDefect: number = GeneralTypes.DEFECT;
    generalTypePrecaution: number = GeneralTypes.PRECAUTION;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected tagsService: TagsService,
        private enumsHelper: EnumsHelper,
        private appActions: AppActions,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
        protected gallery: Gallery,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {

        this.uploadManager = new UploadManager(this.store, this.tagsService, 'defect');

        this.subs.sink = this.uploadManager.uploadingState.subscribe((state) => {
            this.uploadingState = state;
        });

        this.subs.sink = this.store.select('tagDefectCreate').pipe().subscribe((data) => {
            if (this.display !== data.dialogShow) {
                this.display = data.dialogShow;

                if (this.display) {
                    this.severities = this.enumsHelper.severities;

                    this.store.select(generalGetDefectCreateForm).pipe(take(1)).subscribe((formData) => {
                        if (formData?.data == null) {
                            this.store.pipe(take(1), select(currentStateGetCurrentProject)).subscribe(project => {
                                this.selectedProject = project;
                                this.tagForm.form.patchValue({
                                    project: this.selectedProject
                                });
                            });

                            this.selectedSeverity = {id: 1};
                        } else {
                            const fData = JSON.parse(formData.data);
                            this.tagForm.form.patchValue({
                                ...fData,
                                occurredAt: fData.occurredAt ? new Date(fData.occurredAt) : null,
                                deadline: fData.deadline ? new Date(fData.deadline) : null,
                            });
                        }

                        this.subs.sink = this.tagForm.valueChanges.subscribe(() => {
                            this.appActions.dispatch(new GeneralSaveFormAction({
                                id: 'defectCreateForm',
                                data: JSON.stringify(this.tagForm.value)
                            })).toPromise().then();
                        });
                    });

                }
            }
        });
    }

    ngOnDestroy() {
        this.clearUploadImages();
        this.subs.unsubscribe();
    }

    clearUploadImages() {
        if (this.defectImageUpload) {
            if (this.imagesStored) {
                this.uploadManager.clearUploadedFilesList();
            }
            this.defectImageUpload.clear();
        }
    }

    onHide(event) {
        this.clearUploadImages();
        this.store.dispatch(new DialogHide());
    }

    onCancel() {
        this.store.dispatch(new DialogHide());
    }

    onSubmit(form: NgForm) {
        const defect: TagDefectInput = {
            severity: form.value.severity.id,
            location: form.value.location,
            project: form.value.project.id,
            assignedToSubjects: form.value.assignedToSubjects.map(s => s.id) ?? [],
            assignedToSubjectNote: form.value?.assignedToSubjectNote ?? null,
            assignedToUser: form.value.assignedToUser?.id ?? null,
            areaOf: form.value.areaOf?.id ?? null,
            deadline: form.value.deadline ?? null,
            deadlineTerm: form.value.deadlineTerm ?? null,
            occurredAt: form.value.occurredAt ?? null,
            description: form.value.description ?? null,
            precautionSuggestion: form.value.precautionSuggestion ?? null,
            resolvedDescription: null,
            images: this.uploadManager.getFilesNameArray(),
            resolvedImages: [],
            note: form.value.note
        };

        this.imagesStored = true;
        this.store.dispatch(new TagDefectCreate({defect}));
        this.appActions.dispatch(new GeneralSaveFormAction({
            id: 'defectCreateForm',
            data: null
        })).toPromise().then();
    }
}
