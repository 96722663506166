import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {SubSink} from 'subsink';
import {Injectable, OnDestroy} from '@angular/core';
import {CustomPermissionsService} from '../services/custom-permissions.service';
import {select, Store} from '@ngrx/store';
import {currentStateGetCurrentProject} from '../../store/current-state/current-state.selector';
import {AppState} from '../../store/app.reducer';

@Injectable({
    providedIn: 'root'
})
export class EnumsHelper implements OnDestroy {
    private subs = new SubSink();
    private severities$ = new BehaviorSubject([]);
    private states$ = new BehaviorSubject([]);

    private severitiesMulti$ = new BehaviorSubject([]);
    private statesMulti$ = new BehaviorSubject([]);

    constructor(
        private translate: TranslateService,
        private ps: CustomPermissionsService,
        private store: Store<AppState>
    ) {
        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.subs.sink = this.store.pipe(select(currentStateGetCurrentProject)).subscribe((project) => {
                if (project) {
                    const role = this.ps.getProjectRole(project);

                    this.subs.sink = forkJoin({
                        low: this.translate.get('general_tag.severity.low'),
                        normal: this.translate.get('general_tag.severity.normal'),
                        high: this.translate.get('general_tag.severity.high'),
                        asap: this.translate.get('general_tag.severity.asap'),
                    }).subscribe(({low, normal, high, asap}) => {

                        const lowObj = {id: 1, name: low};
                        const normalObj = {id: 2, name: normal};
                        const highObj = {id: 3, name: high};
                        const asapObj = {id: 4, name: asap};

                        this.severities$.next([
                            lowObj,
                            normalObj,
                            highObj,
                            asapObj,
                        ]);

                        this.severitiesMulti$.next([
                            {label: lowObj.name, value: lowObj},
                            {label: normalObj.name, value: normalObj},
                            {label: highObj.name, value: highObj},
                            {label: asapObj.name, value: asapObj},
                        ]);
                    });

                    this.subs.sink = forkJoin({
                        pending: this.translate.get('general_tag.state.pending'),
                        inProgress: this.translate.get('general_tag.state.in_progress'),
                        waitingForApproval: this.translate.get('general_tag.state.waiting_for_approval'),
                        approved: this.translate.get('general_tag.state.approved'),
                        closed: this.translate.get('general_tag.state.closed'),
                    }).subscribe(({pending, inProgress, waitingForApproval, approved, closed}) => {

                        const pendingObj = {id: 1, name: pending, inactive: false};
                        const inProgressObj = {id: 2, name: inProgress, inactive: false};
                        const waitingForApprovalObj = {id: 3, name: waitingForApproval, inactive: false};
                        const approvedObj = {id: 4, name: approved, inactive: false};
                        const closedObj = {id: 5, name: closed, inactive: false};

                        this.states$.next([
                            pendingObj,
                            inProgressObj,
                            waitingForApprovalObj,
                            approvedObj,
                            closedObj,
                        ]);

                        const statesMulti = [];
                        if (role < 35) {
                            statesMulti.push(
                                {label: pendingObj.name, value: pendingObj}
                            );
                        }

                        statesMulti.push(
                            {label: inProgressObj.name, value: inProgressObj},
                            {label: waitingForApprovalObj.name, value: waitingForApprovalObj},
                            {label: approvedObj.name, value: approvedObj},
                            {label: closedObj.name, value: closedObj},
                        );

                        this.statesMulti$.next(statesMulti);
                    });
                }
            });
        });
    }

    get severities() {
        return this.severities$;
    }

    get states() {
        return this.states$;
    }

    get severitiesMulti() {
        return this.severitiesMulti$;
    }

    get statesMulti() {
        return this.statesMulti$;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
