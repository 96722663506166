import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {ConPointService} from '../../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {
    OshSubjectActionTypes,
    OshSubjectError,
    OshSubjectFetch,
    OshSubjectSet,
    OshUserUnAssign,
    OshUserUnAssignSuccess,
} from './osh-subject.actions';
import {Router} from '@angular/router';
import {AppState} from '../../../../store/app.reducer';

const handleError = (errorRes: ApolloError) => {
    return of(new OshSubjectError());
};

@Injectable()
export class OshSubjectEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private router: Router,
                private conPointService: ConPointService) {}

    @Effect()
    fetchOshSubject = this.actions$.pipe(
        ofType(OshSubjectActionTypes.OshSubjectFetch),
        switchMap((oshSubjectActions: OshSubjectFetch) => {
            return this.conPointService.getCurrentSubjectDetails().pipe(
                map((result) => {
                    return new OshSubjectSet({oshSubject: result.data.GetCurrentSubjectDetails});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );

    @Effect()
    userUnAssign = this.actions$.pipe(
        ofType(OshSubjectActionTypes.OshUserUnAssign),
        switchMap((action: OshUserUnAssign) => {
            return this.conPointService.userUnAssign({userId: action.payload.userId, projectId: action.payload.projectId}).pipe(
                map(() => {
                    this.store.dispatch(new OshSubjectFetch());
                    return new OshUserUnAssignSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        }),
    );
}
