import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



/** A stored file. */
export type File = {
  __typename?: 'File';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Where it’s stored in the filesystem. */
  path: Scalars['String'];
  /** Filename, including extension. */
  filename: Scalars['String'];
  /** MIME type. */
  mimetype: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Removes uploaded file. */
  deleteUpload?: Maybe<Scalars['Boolean']>;
  /** Stores a single file. */
  singleUpload: File;
  /** Stores multiple files. */
  multipleUpload: Array<File>;
};


export type MutationDeleteUploadArgs = {
  file?: Maybe<Scalars['String']>;
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  files: Array<Scalars['Upload']>;
};

export type Query = {
  __typename?: 'Query';
  /** All stored files. */
  uploads: Array<File>;
};


export type MultipleUploadMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type MultipleUploadMutation = (
  { __typename?: 'Mutation' }
  & { multipleUpload: Array<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'filename' | 'path'>
  )> }
);

export type DeleteUploadMutationVariables = Exact<{
  file: Scalars['String'];
}>;


export type DeleteUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUpload'>
);

export const MultipleUploadDocument = gql`
    mutation multipleUpload($files: [Upload!]!) {
  multipleUpload(files: $files) {
    id
    filename
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MultipleUploadGQL extends Apollo.Mutation<MultipleUploadMutation, MultipleUploadMutationVariables> {
    document = MultipleUploadDocument;
    client = 'images';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUploadDocument = gql`
    mutation deleteUpload($file: String!) {
  deleteUpload(file: $file)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUploadGQL extends Apollo.Mutation<DeleteUploadMutation, DeleteUploadMutationVariables> {
    document = DeleteUploadDocument;
    client = 'images';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V>
    extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}
    
  interface QueryOptionsAlone<V>
    extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}
    
  interface MutationOptionsAlone<T, V>
    extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}
    
  interface SubscriptionOptionsAlone<V>
    extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class ConPointImagesService {
    constructor(
      private multipleUploadGql: MultipleUploadGQL,
      private deleteUploadGql: DeleteUploadGQL
    ) {}
      
    multipleUpload(variables: MultipleUploadMutationVariables, options?: MutationOptionsAlone<MultipleUploadMutation, MultipleUploadMutationVariables>) {
      return this.multipleUploadGql.mutate(variables, options)
    }
    
    deleteUpload(variables: DeleteUploadMutationVariables, options?: MutationOptionsAlone<DeleteUploadMutation, DeleteUploadMutationVariables>) {
      return this.deleteUploadGql.mutate(variables, options)
    }
  }