import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Report, ReportInput, ReportSettings, ReportSettingsInput} from '../../../../generated/graphql';
import {SubjectsActionTypes} from '../../subjects/store/subjects.actions';

export enum ReportsActionTypes {
    ReportsFetchLazyMeta = '[@Reports] FetchLazyMeta',
    ReportsSet = '[@Reports] Set',
    ReportSet = '[@Report] Set',
    ReportsFetch = '[@Reports] Fetch',
    ReportFetch = '[@Report] Fetch',
    ReportCreate = '[@Report] Create',
    ReportCreateSuccess = '[@Report] CreateSuccess',
    ReportUpdate = '[@Report] Update',
    ReportUpdateSuccess = '[@Report] UpdateSuccess',
    ReportDelete = '[@Report] Delete',
    ReportDeleteSuccess = '[@Report] DeleteSuccess',
    ReportUpdateGeneralSettings = '[@Report] UpdateGeneralSettings',
    ReportUpdateGeneralSettingsSuccess = '[@Report] UpdateGeneralSettingsSuccess',
    ReportGeneralSettingsFetch = '[@Report] GeneralSettingsFetch',
    ReportGeneralSettingsSet = '[@Report] GeneralSettingsSet',

    ReportsError = '[@Reports] Error',
}

export class ReportsSet implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportsFetch, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {reports: Report[], totalRows: number }) {}
}

export class ReportsFetch implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportsFetch, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class ReportSet implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportFetch, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {report: Report}) {}
}

export class ReportFetch implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportFetch, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {id: number}) {}
}

export class ReportGeneralSettingsSet implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportGeneralSettingsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportGeneralSettingsFetch, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {settings: ReportSettings}) {}
}

export class ReportGeneralSettingsFetch implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportGeneralSettingsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportGeneralSettingsFetch, spinner: 'sp_content'}],
        type: 'global'
    };
}

export class ReportUpdateGeneralSettings implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportUpdateGeneralSettings;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportUpdateGeneralSettings, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {settings: ReportSettingsInput}) {}
}

export class ReportUpdateGeneralSettingsSuccess implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportUpdateGeneralSettingsSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportUpdateGeneralSettings, spinner: 'sp_content'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('reports.general-settings-saved')],
        type: 'global'
    };
}

export class ReportCreate implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportCreate, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {report: ReportInput}) {}
}

export class ReportCreateSuccess implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportCreate, spinner: 'sp_content'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('reports.created')],
        type: 'global'
    };
}

export class ReportUpdate implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportUpdate, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {report: ReportInput}) {}
}

export class ReportUpdateSuccess implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportUpdate, spinner: 'sp_content'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('reports.updated')],
        type: 'global'
    };
}

export class ReportDelete implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: ReportsActionTypes.ReportDelete, spinner: 'sp_content'}],
        type: 'global'
    };
    constructor(public payload: {id: number}) {}
}

export class ReportDeleteSuccess implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ReportsActionTypes.ReportDelete, spinner: 'sp_content'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('reports.deleted')],
        type: 'global'
    };
}

export class ReportsFetchLazyMeta implements Action {
    readonly type = ReportsActionTypes.ReportsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export class ReportsError implements Action, LoadingAction {
    readonly type = ReportsActionTypes.ReportsError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: ReportsActionTypes.ReportsFetch, spinner: 'sp_content'},
            {name: ReportsActionTypes.ReportFetch, spinner: 'sp_content'},
            {name: ReportsActionTypes.ReportUpdate, spinner: 'sp_content'},
            {name: ReportsActionTypes.ReportCreate, spinner: 'sp_content'},
            {name: ReportsActionTypes.ReportDelete, spinner: 'sp_content'},
        ],
        type: 'all'
    };

    constructor() {}
}

export type ReportsActions =
    ReportsFetch |
    ReportsSet |
    ReportFetch |
    ReportSet |
    ReportGeneralSettingsFetch |
    ReportGeneralSettingsSet |
    ReportCreate |
    ReportCreateSuccess |
    ReportUpdate |
    ReportUpdateSuccess |
    ReportDelete |
    ReportDeleteSuccess |
    ReportsFetchLazyMeta |
    ReportsError;
