import {GeneralActions, GeneralActionTypes} from './general.actions';

export interface FormData {
    id: string;
    data: string;
}

export interface State {
    forms: FormData[];
}

const initialState = {
    forms: [],
};

export function generalReducer(state: State = initialState, action: GeneralActions) {
    switch (action.type) {
        case GeneralActionTypes.GeneralSaveForm:
            let forms = [...state.forms];
            forms = forms.filter(x => x.id !== action.payload.id);
            const data = {
                id: action.payload.id,
                data: action.payload.data,
            } as FormData;

            forms.push(data);

            state = {
                ...state,
                forms: [
                    ...forms,
                ]
            };

            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
