import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ProjectsActionTypes, ProjectsFetch} from './store/projects.actions';
import {Project} from '../../../generated/graphql';
import {AppState} from '../../store/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class ProjectsResolverService implements Resolve<Project[]> {
    constructor(private store: Store<AppState>,
                private actions$: Actions) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project[]> | Promise<Project[]> | Project[] {

        return this.store.select('projects').pipe(
            take(1),
            map(projectsState => {
                return projectsState.projects;
            }),
            switchMap(projects => {
                if (projects.length === 0) {
                    this.store.dispatch(new ProjectsFetch(null));
                    return this.actions$.pipe(
                        ofType(ProjectsActionTypes.ProjectsSet),
                        take(1)
                    );
                } else {
                    return of(projects);
                }
            })
        );
    }
}
