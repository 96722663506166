import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {LazyLoadMeta} from '../../shared/model/lazy-load-meta.model';
import {UserFormComponent} from '../../forms/user-form/user-form.component';
import {BaseComponent} from '../../shared/base/base.component';
import {User} from '../../../generated/graphql';
import {Store} from '@ngrx/store';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../shared/services/global.service';
import {getUsers} from './store/users.selector';
import {Table} from 'primeng/table';
import {AppState} from '../../store/app.reducer';
import {SetBreadcrumb, SetToolbarButtons} from '../../fixed-toolbar/fixed-toolbar.action';
import {UserFormDialogShow} from '../../forms/user-form/store/user-form.actions';
import {UserDelete, UserFetch, UserSet, UsersFetch} from './store/users.actions';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(UserFormComponent) userForm: UserFormComponent;
    @ViewChild('dt') dataTable: Table;

    cols: any[];
    users: User[];
    selectedUser: User;
    loading: boolean;
    totalRecords: number;
    userFormDisplay = false;
    filtersDef: any;
    private tableFilter: any;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected confirmationService: ConfirmationService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        forkJoin([
            this.translate.get('users.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/users'}
            ]));
        });

        this.store.dispatch(new SetToolbarButtons([
            {
                id: 'createUserButton',
                name: 'users.create',
                action: 'onCreateUser',
                class: 'p-button-success',
                icon: 'pi pi-plus',
                disabled: false,
            },
        ]));

        this.cols = [
            { field: 'firstName', header: 'users.first_name', display: 'table-cell' },
            { field: 'lastName', header: 'users.last_name', display: 'table-cell' },
            { field: 'email', header: 'users.email', display: 'table-cell' },
            { field: 'phoneNumber', header: 'users.phone_number', display: 'table-cell' },
            { field: 'blocked', header: 'general.blocked', display: 'table-cell' },
            { field: 'createdAt', header: 'general_tag.created_at', display: 'table-cell' },
            { field: 'lastLoggedIn', header: 'general.last_logged_in', display: 'table-cell' },
        ];

        this.subs.sink = this.store.select(getUsers).pipe(
            map(usersState => {
                return {loadedUser: usersState.loadedUser};
            })).subscribe(
            ({loadedUser: editedUser}) => {
                if (editedUser) {
                    // this.userForm.editUser(editedUser);
                    this.store.dispatch(new UserFormDialogShow());
                    this.store.dispatch(new UserSet({user: null}));
                }
            });

        this.subs.sink = this.store.select(getUsers).pipe(
            map(usersState => {
                return {
                    users: usersState.users,
                    totalRows: usersState.totalRows,
                    currentLazyLoad: usersState.currentLazyLoad
                };
                })).subscribe(
                ({users: users, totalRows: totalRows, currentLazyLoad: currentLazyLoad}) => {
                    this.users = users;
                    this.loading = false;
                    this.totalRecords = totalRows;
                });

        this.loading = true;
    }

    fetchUsers(event: object): void {
        this.store.dispatch(new UsersFetch(event));
    }

    loadLazy(event: LazyLoadEvent) {
        if (this.dataTable) {
            this.dataTable.saveState();
        }

        const searchData = LazyLoadMeta.fromData(event);
        searchData.filters = this.tableFilter;
        this.fetchUsers(searchData);
    }

    ngAfterViewInit() {
        this.dataTable.restoreState();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toggleUserForm() {
        // this.userForm.dialogToggle();
        this.store.dispatch(new UserFormDialogShow());
    }

    onEdit(userId: number) {
        this.store.dispatch(new UserFetch({userId}));
    }

    onCreateUser() {
        this.toggleUserForm();
    }

    onDelete(id: number) {
        forkJoin([this.translate.get('users.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                message: result[0],
                accept: () => {
                    this.store.dispatch(new UserDelete({id}));
                }
            });
        });
    }

    onFilterChanged($event: any) {
        this.tableFilter = $event;
        this.loadLazy(this.dataTable.createLazyLoadMetadata());
    }
}
