import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../shared/services/global.service';
import {SubSink} from 'subsink';
import {AppState} from '../../store/app.reducer';
import {AuthForgetPassword, AuthLogin, AuthPasswordReset} from './store/auth.actions';
import {ActivatedRoute, Params, UrlSegment} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {FormsHelperService} from '../../forms/forms-helper.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {

    private subs = new SubSink();

    dark: boolean;

    checked: boolean;
    public environmentName: string;

    public subPage = new BehaviorSubject<string>(null);
    private passwordResetHash = null;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private store: Store<AppState>,
                private gs: GlobalService,
                private route: ActivatedRoute,
                public formsHelper: FormsHelperService
    ) {
    }

    ngOnInit(): void {
        this.environmentName = this.gs.getEnvironment().environmentName;

        this.subs.sink = this.route.url.subscribe((urlSegment: UrlSegment[]) => {
            this.subPage.next(urlSegment[0].path);
        });

        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.passwordResetHash = params.hash;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onSubmit(authForm: NgForm) {
        if (!authForm.valid) {
            return;
        }
        const email = authForm.value.email;
        const password = authForm.value.password;

        this.store.dispatch(new AuthLogin({
            email,
            password
        }));
    }

    onSubmitForgetPassword(forgetPasswordForm: NgForm) {
        if (!forgetPasswordForm.valid) {
            return;
        }
        const email = forgetPasswordForm.value.fpemail;
        this.store.dispatch(new AuthForgetPassword({email}));
    }

    onSubmitResetPassword(resetPasswordForm: NgForm) {
        const password = resetPasswordForm.value.prPassword;
        this.store.dispatch(new AuthPasswordReset({hash: this.passwordResetHash, password}));
    }
}
