import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../shared/services/global.service';
import {AppState} from '../../store/app.reducer';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends BaseComponent implements OnInit {

  constructor(
      protected store: Store<AppState>,
      protected gs: GlobalService,
  ) {
    super(store, gs);
  }
  ngOnInit(): void {
  }

}
