import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.reducer';
import {ActivatedRoute, Params} from '@angular/router';
import {SubSink} from 'subsink';

@Component({
    selector: 'app-invalid-invitation',
    templateUrl: './app.invalidinvitation.component.html',
})
export class AppInvalidInvitationComponent implements OnInit{
    private subs = new SubSink();

    public type: string = null;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.type = params.type;
        });
    }
}
