import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { MenuItem } from 'primeng/api';
import {ToolbarButton} from './fixed-toolbar/toolbar-button.model';
import {SubSink} from 'subsink';

@Component({
    selector: 'app-breadcrumb',
    styleUrls: ['app.breadcrumb.component.css'],
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    private subs = new SubSink();

    items: MenuItem[];
    buttons: ToolbarButton[];

    @Output() toolbarActionButton = new EventEmitter();

    constructor(public breadcrumbService: BreadcrumbService) {
        this.subs.sink = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });

        this.subs.sink = breadcrumbService.buttonsHandler.subscribe(response => {
            this.buttons = response;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    onToolbarButtonClick(action: string) {
        this.toolbarActionButton.emit(action);
    }
}
