import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';

@Injectable({
    providedIn: 'root'
})
export class AuthCheckGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private store: Store<AppState>
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select('auth').pipe(
            take(1),
            map(authState => {
                const isAuth = !!authState.user;
                if (isAuth) {
                    return this.router.createUrlTree(['/']);
                }
                return !isAuth;
            })
        );
    }
}
