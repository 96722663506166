import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    public tracy = '';

    private dimensions$ = new BehaviorSubject<any>(null);

    constructor() {
    }

    public getEnvironment() {
        return environment;
    }

    get dimensions() {
        return this.dimensions$.asObservable();
    }

    public setDimensions(values: any) {
        this.dimensions$.next(values);
    }
}
