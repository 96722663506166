import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import { MessageAction } from '../../../loading/loading.model';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {AddressInput, User, UserInput} from '../../../../generated/graphql';

export enum UsersActionTypes {
    UsersFetchLazyMeta = '[@Users] FetchLazyMeta',
    UsersSet = '[@Users] Set',
    UserSet = '[@User] Set',
    UsersFetch = '[@Users] Fetch',
    UserFetch = '[@User] Fetch',
    UserCreate = '[@User] Create',
    UserCreateSuccess = '[@User] SaveSuccess',
    UserFinishAccount = '[@User] FinishAccount',
    UserFinishAccountSuccess = '[@User] FinishAccountSuccess',
    UserUpdate = '[@User] Update',
    UserUpdateSuccess = '[@User] UpdateSuccess',
    UserDelete = '[@User] Delete',
    UserDeleteSuccess = '[@User] DeleteSuccess',
    UsersError = '[@Users] Error',
    AddressCreate = '[@Address] Create',
}

export class UsersSet implements Action, LoadingAction {
    readonly type = UsersActionTypes.UsersSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: UsersActionTypes.UsersFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {users: User[], totalRows: number }) {}
}

export class UsersFetch implements Action, LoadingAction {
    readonly type = UsersActionTypes.UsersFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: UsersActionTypes.UsersFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class UserSet implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: UsersActionTypes.UserFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {user: User }) {}
}

export class UserFetch implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: UsersActionTypes.UserFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {userId: number}) {}
}

export class UserCreate implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: UsersActionTypes.UserCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {user: UserInput}) {}
}

export class UserCreateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = UsersActionTypes.UserCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: UsersActionTypes.UserCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('users.created')],
        type: 'global'
    };
}

export class UserFinishAccount implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserFinishAccount;
    fxLoading = {
        action: 'add',
        ids: [UsersActionTypes.UserFinishAccount],
        type: 'global'
    };
    constructor(public payload: {user: UserInput, accountCreationCode: string}) {}
}

export class UserFinishAccountSuccess implements Action, LoadingAction, MessageAction {
    readonly type = UsersActionTypes.UserFinishAccountSuccess;
    fxLoading = {
        action: 'remove',
        ids: [UsersActionTypes.UserFinishAccount],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('users.account-finished')],
        type: 'global'
    };
}

export class UserUpdate implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: UsersActionTypes.UserUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {user: UserInput}) {}
}

export class UserUpdateSuccess implements Action, LoadingAction, MessageAction {
    readonly type = UsersActionTypes.UserUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: UsersActionTypes.UserUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('users.updated')],
        type: 'global'
    };
}

export class UserDelete implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: UsersActionTypes.UserDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {id: number}) {}
}

export class UserDeleteSuccess implements Action, LoadingAction {
    readonly type = UsersActionTypes.UserDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: UsersActionTypes.UserDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('users.deleted')],
        type: 'global'
    };
}

export class AddressCreate implements Action {
    readonly type = UsersActionTypes.AddressCreate;
    constructor(public payload: {address: AddressInput}) {}
}

export class UsersError implements Action, LoadingAction {
    readonly type = UsersActionTypes.UsersError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: UsersActionTypes.UsersFetch, spinner: 'sp_lll'},
            {name: UsersActionTypes.UserFetch, spinner: 'sp_llcl'},
            {name: UsersActionTypes.UserCreate, spinner: 'sp_lll'},
            {name: UsersActionTypes.UserUpdate, spinner: 'sp_llb'},
            {name: UsersActionTypes.UserDelete, spinner: 'sp_lll'},
            UsersActionTypes.UserFinishAccount,
        ],
        type: 'all'
    };

    constructor() {}
}

export class UsersFetchLazyMeta implements Action {
    readonly type = UsersActionTypes.UsersFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export type UsersActions =
    UsersFetch |
    UserCreate |
    UserCreateSuccess |
    UserUpdate |
    UserUpdateSuccess |
    UserDelete |
    UserDeleteSuccess |
    UserFinishAccount |
    UserFinishAccountSuccess |
    AddressCreate |
    UsersSet |
    UserSet |
    UserFetch |
    UsersFetchLazyMeta |
    UsersError;
