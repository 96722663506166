import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {
    MediaFileInput,
    TagDefect,
    TagDefectInput,
} from '../../../../../generated/graphql';
import {TagsService} from '../../tags.service';
import {GeneralTypes, TagTypes} from '../../../../shared/enums/entity-enums';
import {Gallery, ImageItem} from '@ngx-gallery/core';
import {MediaFileStateEnum} from '../../../../shared/enums/media-file-state-enum';
import {GlobalService} from '../../../../shared/services/global.service';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../../shared/services/custom-permissions.service';
import {MediaService} from '../../../../shared/services/media.service';
import {EnumsHelper} from '../../../../shared/helpers/enums-helper.service';
import {AppState} from '../../../../store/app.reducer';
import {SetBreadcrumb, SetToolbarButtons} from '../../../../fixed-toolbar/fixed-toolbar.action';
import {TagDefectBrowseFetch, TagDefectFetch, TagDefectSet, TagDefectUpdate} from '../store/tag-defects.actions';
import {currentStateGetCurrentProject} from '../../../../store/current-state/current-state.selector';
import {TagBaseComponent} from '../../tag-base/tag-base.component';

@Component({
    selector: 'app-tag-defect-edit',
    templateUrl: './tag-defect-edit.component.html',
    styleUrls: ['./tag-defect-edit.component.css']
})
export class TagDefectEditComponent extends TagBaseComponent implements OnInit, OnDestroy, AfterViewInit {

    tagType = TagTypes.TAG_DEFECT;

    generalTypeDefect: number = GeneralTypes.DEFECT;
    generalTypePrecaution: number = GeneralTypes.PRECAUTION;

    loadedTagDefect: TagDefect;

    tagAction = 'update';

    assignedToSubjectNoteEmpty = false;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected tagsService: TagsService,
        protected gallery: Gallery,
        private media: MediaService,
        private translate: TranslateService,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = this.route.params.subscribe((params: Params) => {
            const browseFullId = params.fullId;
            const browseProjectKeyName = params.projectKeyName;
            const browseTagSequenceNumber = +params.tagSequenceNumber;
            const browseTagAction = params.action;
            let browseEditMode = false;

            this.tagAction = browseTagAction;

            const editId = +params.id;
            const editMode = params.id != null;

            if (!editMode && browseFullId) {
                browseEditMode = true;
            }

            forkJoin([
                this.translate.get('defects.title_m'),
                this.translate.get('defects.edit'),
                this.translate.get('defects.create'),
            ]).subscribe(result => {
                const breadCrumbItems = [];
                breadCrumbItems.push({label: result[0], routerLink: ['/defects']});

                if (editMode) {
                    breadCrumbItems.push({label: result[1], routerLink: ['/defects/edit', editId]});
                    this.fetchDefect(editId);
                } else if (browseEditMode && browseTagAction === 'update') {
                    breadCrumbItems.push({label: browseProjectKeyName});
                    breadCrumbItems.push({label: browseFullId, routerLink: ['/browse/', browseFullId]});
                    this.fetchDefectBrowse(browseProjectKeyName, browseTagSequenceNumber);
                } else if (browseEditMode && browseTagAction === 'resolve') {
                    breadCrumbItems.push({label: browseProjectKeyName});
                    breadCrumbItems.push({label: browseFullId, routerLink: ['/resolve/', browseFullId]});
                    this.fetchDefectBrowse(browseProjectKeyName, browseTagSequenceNumber);
                } else {
                    breadCrumbItems.push({label: result[2], routerLink: '/defects/create'});
                }

                this.store.dispatch(new SetBreadcrumb(breadCrumbItems));
            });

            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.subs.sink = this.store.select(currentStateGetCurrentProject).subscribe((project) => {

                    super.subscribeTagStates(project, this.loadedTagDefect);

                    forkJoin({
                            getPerm: this.ps.hasProjectPermission(project, 'getDefects'),
                            resolvePerm: this.ps.hasProjectPermission(project, 'resolveDefects'),
                            updatePerm: this.ps.hasProjectPermission(project, 'updateDefects'),
                            createPerm: this.ps.hasProjectPermission(project, 'createDefects')
                        },
                    ).subscribe(results => {
                        this.buttons = [...this.buttons];

                        if (results.getPerm) {
                            this.permission = 'get';
                        }

                        let resolveUpdateButton = null;
                        if (results.resolvePerm) {
                            this.permission = 'resolve';
                            this.tagAction = 'resolve';

                            resolveUpdateButton = {
                                id: 'resolveDefectButton',
                                name: 'defects.resolve',
                                action: 'onSaveDefect',
                                class: 'p-button-success',
                                icon: 'pi pi-save p-mr-1',
                                disabled: false,
                            };
                        }

                        if (results.updatePerm) {
                            this.permission = 'update';
                            this.tagAction = 'update';

                            resolveUpdateButton = {
                                id: 'saveDefectButton',
                                name: 'defects.save',
                                action: 'onSaveDefect',
                                class: 'p-button-success',
                                icon: 'pi pi-save p-mr-1',
                                disabled: false,
                            };
                        }

                        if (resolveUpdateButton) {
                            this.buttons.push(resolveUpdateButton);
                        }

                        if (results.createPerm) {
                            this.buttons.push(
                                {
                                    id: 'createDefectButton',
                                    name: 'defects.create',
                                    action: 'onCreateDefect',
                                    class: 'p-button-primary',
                                    icon: 'pi pi-plus',
                                    disabled: false,
                                }
                            );
                        }

                        this.store.dispatch(new SetToolbarButtons(this.buttons));
                    });
                });
            });

            this.galleryImages1 = [];
            this.galleryImages2 = [];

            this.uploadManager1.clearUploadedFiles(null);
            this.uploadManager2.clearUploadedFiles(null);

            this.image1Upload?.clear();
            this.image2Upload?.clear();
        });


    }

    ngAfterViewInit() {
        // Load items into gallery
        const gallery1Ref = this.gallery.ref(this.images1Id, {
            thumbPosition: 'top',
            imageSize: 'contain'
        });
        // Load items into gallery
        const gallery2Ref = this.gallery.ref(this.images2Id, {
            thumbPosition: 'top',
            imageSize: 'contain'
        });

        this.subs.sink = this.store.select('tagDefects').pipe(
            map(subjectState => {
                return {
                    loadedTagDefect: subjectState.loadedTagDefect,
                };
            })).subscribe(
            ({
                 loadedTagDefect: loadedTagDefect,
             }) => {
                if (loadedTagDefect) {
                    this.tagForm.form.patchValue({
                        ...loadedTagDefect,
                        defectId: loadedTagDefect.id,
                        assignedToUser: loadedTagDefect.assignedToUser,
                        deadline: loadedTagDefect.deadline ? new Date(loadedTagDefect.deadline.date) : null,
                        occurredAt: loadedTagDefect.occurredAt ? new Date(loadedTagDefect.occurredAt.date) : null,
                        resolvedAt: loadedTagDefect.resolvedAt ? new Date(loadedTagDefect.resolvedAt.date) : null,
                        precautionSuggestionGeneralTypes: null,
                        defectGeneralTypes: null
                    });

                    this.store.dispatch(new TagDefectSet({defect: null}));

                    this.loadedTagDefect = loadedTagDefect;
                    this.selectedProject = this.loadedTagDefect.project;

                    this.loadedImages1 = [...loadedTagDefect.images];
                    this.loadedImages2 = [...loadedTagDefect.resolvedImages];

                    this.assignedToSubjectNoteEmpty = (
                        this.loadedTagDefect.assignedToSubjectNote?.trim().length === 0 ||
                        this.loadedTagDefect.assignedToSubjectNote === null
                    );

                    this.loadedImages1.forEach((fileCode) => {
                        if (!this.galleryImages1.find(item => item.data.code === fileCode)) {
                            this.galleryImages1.push(new ImageItem({
                                src: this.media.getMediaLink(fileCode),
                                thumb: this.media.getMediaThumbLink(fileCode),
                                code: fileCode
                            }));
                        }
                    });

                    this.loadedImages2.forEach((fileCode) => {
                        if (!this.galleryImages2.find(item => item.data.code === fileCode)) {
                            this.galleryImages2.push(new ImageItem({
                                src: this.media.getMediaLink(fileCode),
                                thumb: this.media.getMediaThumbLink(fileCode),
                                code: fileCode
                            }));
                        }
                    });

                    gallery1Ref.load(this.galleryImages1);
                    gallery2Ref.load(this.galleryImages2);

                    this.uploadManager1.clearUploadedFilesList();
                    this.uploadManager2.clearUploadedFilesList();
                    this.image1Upload?.clear();
                    this.image2Upload?.clear();

                    this.isLoading = false;
                }
            });
    }

    fetchDefect(defectId: number) {
        this.store.dispatch(new TagDefectFetch({defectId}));
    }

    fetchDefectBrowse(projectKeyName: string, tagSequenceNumber: number) {
        this.store.dispatch(new TagDefectBrowseFetch({projectKeyName, tagSequenceNumber}));
    }

    onSaveDefect() {
        this.tagForm.ngSubmit.emit();
    }

    onSubmit(form: NgForm) {

        if (this.tagAction === 'update') {

            console.log('Class: TagDefectEditComponent, Function: onSubmit, Line 282: '
            , form.value);


            const defect: TagDefectInput = {
                id: (form.value.defectId !== null) ? +form.value.defectId : null,
                state: form.value.state.id,
                severity: form.value.severity.id,
                project: this.loadedTagDefect.project.id,
                assignedToSubjects: form.value.assignedToSubjects?.map(s => s.id) ?? [],
                assignedToSubjectNote: form.value?.assignedToSubjectNote ?? null,
                assignedToUser: form.value.assignedToUser?.id ?? null,
                responsiblePerson: form.value.responsiblePerson ?? null,
                areaOf: form.value.areaOf?.id ?? null,
                occurredAt: form.value.occurredAt,
                resolvedAt: form.value.resolvedAt,
                deadline: form.value.deadline,
                deadlineTerm: form.value.deadlineTerm,
                description: form.value.description,
                precautionSuggestion: form.value.precautionSuggestion ?? null,
                resolvedDescription: form.value.resolvedDescription,
                images: [...(this.galleryImages1.map((file) => {
                    const mediaFile: MediaFileInput = {
                        fileName: file.data.code,
                        fileState: MediaFileStateEnum.STATE_EXISTING
                    };
                    return mediaFile;
                }) ?? []), ...this.uploadManager1.getFilesNameArray()],
                resolvedImages: [...(this.galleryImages2.map((file) => {
                    const mediaFile: MediaFileInput = {
                        fileName: file.data.code,
                        fileState: MediaFileStateEnum.STATE_EXISTING
                    };
                    return mediaFile;
                }) ?? []), ...this.uploadManager2.getFilesNameArray()],
                note: form.value.note,
                location: form.value.location
            };
            this.store.dispatch(new TagDefectUpdate({defect, action: this.tagAction}));
        } else if (this.tagAction === 'resolve') {
            const defect: TagDefectInput = {
                id: +form.value.defectId,
                state: form.value.state.id,
                severity: 0,
                project: 0,
                assignedToSubjects: [],
                assignedToUser: null,
                responsiblePerson: null,
                areaOf: 0,
                precautionSuggestion: null,
                occurredAt: null,
                resolvedAt: form.value.resolvedAt,
                deadline: null,
                deadlineTerm: null,
                description: null,
                note: null,
                location: null,
                images: [],
                resolvedDescription: form.value.resolvedDescription,
                resolvedImages: [...(this.galleryImages2.map((file) => {
                    const mediaFile: MediaFileInput = {
                        fileName: file.data.code,
                        fileState: MediaFileStateEnum.STATE_EXISTING
                    };
                    return mediaFile;
                }) ?? []), ...this.uploadManager2.getFilesNameArray()]
            };

            this.store.dispatch(new TagDefectUpdate({defect, action: this.tagAction}));
        }
    }
}
