import {Action} from '@ngrx/store';
import {GenericText} from '../../../../generated/graphql';

export const GENERIC_TEXT_FORM_DIALOG_SHOW = '[@GenericText] DialogShow';
export const GENERIC_TEXT_FORM_DIALOG_HIDE = '[@GenericText] DialogHide';
// Will be moved to different ngrx store
export const GENERIC_TEXT_FORM_ADDED = '[@GenericText] GenericTextAdded';

export class DialogShow implements Action {
    readonly type = GENERIC_TEXT_FORM_DIALOG_SHOW;
    constructor(public payload: {action: string}) {}
}

export class DialogHide implements Action {
    readonly type = GENERIC_TEXT_FORM_DIALOG_HIDE;
}

export class GeneralTypeAdded implements Action {
    readonly type = GENERIC_TEXT_FORM_ADDED;
    constructor(public payload: {data: GenericText}) {
    }
}

export type GenericTextFormActions =
    GeneralTypeAdded |
    DialogShow |
    DialogHide;
