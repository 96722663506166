import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    TagGoodPracticeFetch,
    TagGoodPracticesFetch,
    TagGoodPracticeCreateSuccess,
    TagGoodPracticeUpdateSuccess,
    TagGoodPracticeDeleteSuccess,
    TagGoodPracticeSet,
    TagGoodPracticeBrowseFetch,
    TagGoodPracticesActionTypes,
    TagGoodPracticesFetchLazyMeta, TagGoodPracticesSet, TagGoodPracticesError, TagGoodPracticeUpdate, TagGoodPracticeDelete,
} from './tag-good-practice.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {AppState} from '../../../../store/app.reducer';
import {DialogHide} from '../../../../forms/tags/good-practice-create/store/good-practice-create-form.actions';
import {State} from './tag-good-practice.reducer';

const handleError = (errorRes: ApolloError) => {
    return of(new TagGoodPracticesError());
};

@Injectable()
export class TagGoodPracticeEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private conPointService: ConPointService) {}

    @Effect()
    goodPracticesFetch = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticesFetch),
        switchMap((goodPracticesActions: TagGoodPracticesFetch) => {
            this.store.dispatch(new TagGoodPracticesFetchLazyMeta({lazyLoad: goodPracticesActions.payload}));
            return this.conPointService.getTagGoodPracticeList({lazyLoad: goodPracticesActions.payload}).pipe(
                map((result) => {
                    const goodPractices = result.data.GetTagGoodPracticeList.result?.map(goodPractice => {
                        return {...goodPractice};
                    });

                    return {
                        goodPractices,
                        total: result.data.GetTagGoodPracticeList.metadata.total,
                        count: result.data.GetTagGoodPracticeList.metadata.count
                    };
                }),
                map(({goodPractices, total, count}) => {
                    return new TagGoodPracticesSet({goodPractices: goodPractices ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    goodPracticeFetch = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticeFetch),
        switchMap((goodPracticesActions: TagGoodPracticeFetch) => {
            return this.conPointService.getTagGoodPractice({id: goodPracticesActions.payload.goodPracticeId}).pipe(
                map((result) => {
                    return new TagGoodPracticeSet({goodPractice: result.data.GetTagGoodPractice});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    goodPracticeBrowseFetch = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticeBrowseFetch),
        switchMap((goodPracticesActions: TagGoodPracticeBrowseFetch) => {
            return this.conPointService.getTagGoodPracticeBrowse({
                projectKeyName: goodPracticesActions.payload.projectKeyName,
                tagSequenceNumber: goodPracticesActions.payload.tagSequenceNumber
            }).pipe(
                map((result) => {
                    return new TagGoodPracticeSet({goodPractice: result.data.GetTagGoodPracticeBrowse});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    goodPracticeCreate = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticeCreate),
        withLatestFrom(this.store.select('tagGoodPractices')),
        switchMap<[TagGoodPracticeUpdate, State], Observable<any>>(([goodPracticesActions, goodPracticesState]) => {
            return this.conPointService.createTagGoodPractice(
                {goodPractice: goodPracticesActions.payload.goodPractice}).pipe(
                map(() => {
                    this.store.dispatch(new TagGoodPracticesFetch(goodPracticesState.currentLazyLoad));
                    this.store.dispatch(new DialogHide());
                    return new TagGoodPracticeCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    goodPracticeUpdate = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticeUpdate),
        withLatestFrom(this.store.select('tagGoodPractices')),
        switchMap<[TagGoodPracticeUpdate, State], Observable<any>>(([goodPracticesActions, goodPracticesState]) => {
            return this.conPointService.updateTagGoodPractice({goodPractice: goodPracticesActions.payload.goodPractice}).pipe(
                map((data) => {
                    this.store.dispatch(new TagGoodPracticesFetch(goodPracticesState.currentLazyLoad));
                    this.store.dispatch(new TagGoodPracticeSet({goodPractice: data.data.UpdateTagGoodPractice}));
                    return new TagGoodPracticeUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    goodPracticeDelete = this.actions$.pipe(
        ofType(TagGoodPracticesActionTypes.TagGoodPracticeDelete),
        withLatestFrom(this.store.select('tagGoodPractices')),
        switchMap<[TagGoodPracticeDelete, State],
            Observable<any>>(([goodPracticesActions, goodPracticesState]) => {
            return this.conPointService.deleteTagGoodPractice({id: goodPracticesActions.payload.goodPracticeId}).pipe(
                map(() => {
                    this.store.dispatch(new TagGoodPracticesFetch(goodPracticesState.currentLazyLoad));
                    return new TagGoodPracticeDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
