import {createFeatureSelector, createSelector, createSelectorFactory, MemoizedProjection} from '@ngrx/store';
import { State } from './subjects.reducer';
import {AnyFn} from '@ngrx/store/src/selector';

export const getSubjectsFeature = createFeatureSelector<State>('subjects');

export const subjectsGetActiveOshSubject = createSelector(
    getSubjectsFeature,
    (state) => state.loadedSubject
);

export function isEqual(a: any, b: any): boolean {
    return a.totalRows === b.totalRows &&
        a.subjects.every(fromA => b.subjects.includes(fromA));
}

export function customMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export const getSubjects = createSelectorFactory(customMemoize)(
    getSubjectsFeature,
    (state) => {
        return {
            subjects: state.subjects,
            totalRows: state.totalRows,
            currentLazyLoad: state.currentLazyLoad
        };
    }
);
