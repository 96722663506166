import { Action } from '@ngrx/store';
import { LazyLoadMeta } from '../../../shared/model/lazy-load-meta.model';
import { LoadingAction } from '../../../loading';
import {FlashMessage} from '../../../shared/model/flash-message.model';
import {Project, ProjectInput} from '../../../../generated/graphql';
import {SubjectsActionTypes} from '../../subjects/store/subjects.actions';

export enum ProjectsActionTypes {
    ProjectsFetchLazyMeta = '[@Projects] FetchLazyMeta',
    ProjectsSet = '[@Projects] Set',
    ProjectSet = '[@Project] Set',
    ProjectsFetch = '[@Projects] Fetch',
    ProjectFetch = '[@Project] Fetch',
    ProjectCreate = '[@Project] Create',
    ProjectCreateSuccess = '[@Project] CreateSuccess',
    ProjectUpdate = '[@Project] Update',
    ProjectUpdateSuccess = '[@Project] UpdateSuccess',
    ProjectDelete = '[@Project] Delete',
    ProjectDeleteSuccess = '[@Project] DeleteSuccess',
    ProjectsAppFetch = '[@Projects] AppFetch',
    ProjectsAppSet = '[@Projects] AppSet',

    ProjectsError = '[@Projects] Error',
}

export class ProjectsSet implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectsSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ProjectsActionTypes.ProjectsFetch, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {projects: Project[], totalRows: number }) {}
}

export class ProjectsFetch implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectsFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ProjectsActionTypes.ProjectsFetch, spinner: 'sp_lll', append: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: LazyLoadMeta) {}
}

export class ProjectSet implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectSet;
    fxLoading = {
        action: 'remove',
        ids: [{name: ProjectsActionTypes.ProjectFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {project: Project}) {}
}

export class ProjectFetch implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectFetch;
    fxLoading = {
        action: 'add',
        ids: [{name: ProjectsActionTypes.ProjectFetch, spinner: 'sp_llcl'}],
        type: 'global'
    };
    constructor(public payload: {projectId: number}) {}
}

export class ProjectCreate implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectCreate;
    fxLoading = {
        action: 'add',
        ids: [{name: ProjectsActionTypes.ProjectCreate, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {project: ProjectInput}) {}
}

export class ProjectCreateSuccess implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectCreateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ProjectsActionTypes.ProjectCreate, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('projects.created')],
        type: 'global'
    };
}

export class ProjectUpdate implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectUpdate;
    fxLoading = {
        action: 'add',
        ids: [{name: ProjectsActionTypes.ProjectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };
    constructor(public payload: {project: ProjectInput}) {}
}

export class ProjectUpdateSuccess implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectUpdateSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ProjectsActionTypes.ProjectUpdate, spinner: 'sp_llb'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('projects.updated')],
        type: 'global'
    };
}

export class ProjectDelete implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectDelete;
    fxLoading = {
        action: 'add',
        ids: [{name: ProjectsActionTypes.ProjectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };
    constructor(public payload: {projectId: number}) {}
}

export class ProjectDeleteSuccess implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectDeleteSuccess;
    fxLoading = {
        action: 'remove',
        ids: [{name: ProjectsActionTypes.ProjectDelete, spinner: 'sp_lll'}],
        type: 'global'
    };

    fxMessage = {
        action: 'add',
        messages: [FlashMessage.successMessage('projects.deleted')],
        type: 'global'
    };
}

export class ProjectsFetchLazyMeta implements Action {
    readonly type = ProjectsActionTypes.ProjectsFetchLazyMeta;

    constructor(public payload: {lazyLoad: LazyLoadMeta}) {
    }
}

export class ProjectsAppFetch implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectsAppFetch;
    fxLoading = {
        action: 'add',
        ids: [SubjectsActionTypes.SubjectsAppFetch],
        type: 'global'
    };
}

export class ProjectsAppSet implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectsAppSet;
    fxLoading = {
        action: 'remove',
        ids: [SubjectsActionTypes.SubjectsAppFetch],
        type: 'global'
    };
    constructor(public payload: {projects: Project[]}) {}
}


export class ProjectsError implements Action, LoadingAction {
    readonly type = ProjectsActionTypes.ProjectsError;
    fxLoading = {
        action: 'remove',
        ids: [
            {name: ProjectsActionTypes.ProjectsFetch, spinner: 'sp_lll'},
            {name: ProjectsActionTypes.ProjectFetch, spinner: 'sp_llcl'},
            {name: ProjectsActionTypes.ProjectUpdate, spinner: 'sp_llb'},
            {name: ProjectsActionTypes.ProjectCreate, spinner: 'sp_lll'},
            {name: ProjectsActionTypes.ProjectDelete, spinner: 'sp_lll'},
        ],
        type: 'all'
    };

    constructor() {}
}

export type ProjectsActions =
    ProjectsFetch |
    ProjectsSet |
    ProjectFetch |
    ProjectSet |
    ProjectsAppFetch |
    ProjectsAppSet |
    ProjectCreate |
    ProjectCreateSuccess |
    ProjectUpdate |
    ProjectUpdateSuccess |
    ProjectDelete |
    ProjectDeleteSuccess |
    ProjectsFetchLazyMeta |
    ProjectsError;
