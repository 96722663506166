import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {GlobalService} from '../services/global.service';

@Injectable()
export class ResponseInterceptorService implements HttpInterceptor {
    first = true;

    constructor(
       private gs: GlobalService
    ) {}

    // TODO vyřešit 500ky
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const modifiedReq = request.clone({responseType: 'text'});

        if (request.url.includes(this.gs.getEnvironment().graphQLOrigin)) {
            return next.handle(modifiedReq)
                .pipe(
                    map(event => {
                        if (event instanceof HttpResponse) {
                            event = event.clone({body: JSON.parse(event.body)});
                        }
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorContainerWrapper = document.getElementById('error-log-container-wrapper');
                        errorContainerWrapper.classList.remove('hidden');

                        const errorContainer = document.getElementById('error-log-container');
                        // @ts-ignore
                        const doc = errorContainer.contentWindow.document;
                        doc.open();
                        doc.write(error.error);
                        doc.close();

                        return throwError(error);
                    }),
                    tap((r) => {
                        if (r.type === HttpEventType.Response) {
                            if (r?.body?.debugBar) {
                                let statusCode = 200;
                                if (r?.body?.errorCode) {
                                    statusCode = r?.body?.errorCode;
                                }
                                this.showDebugger(r?.body?.debugBar, statusCode);
                            }
                        }
                    })
                );
        } else {
            return next.handle(request);
        }
    }
    modifyErrorResponse(e: HttpErrorResponse) {
        const modifiedResponse = new HttpResponse(
            {
                body: {...e.error, errorCode: e.status},
                headers: e.headers,
                status: 200,
                statusText: 'OK',
                url: e.url
            });
        return of(modifiedResponse);
    }
    showDebugger(s: any, statusCode: number) {
        if (!this.first) {
            try {
                // @ts-ignore
                Tracy.Debug.loadAjax(s.content);
            } catch (exception) {
                console.log('Class: ResponseInterceptorService, Function: showDebugger, Line 75: '
                , exception);
            }
            return;
        }
        setTimeout(() => {
            this.first = false;
            const data = s.split('<!-- Tracy Debug Bar -->');
            if (data.length > 1) {
                document.getElementById('tracy-debug')?.remove();
                const scripts = data[1].split('</script>');
                const script1 = document.createRange().createContextualFragment(
                    scripts[0].replace('<script src="/', '<script data-tracy src="' + this.gs.getEnvironment().webOrigin)
                );
                this.gs.tracy = script1.querySelector('[data-id]').getAttribute('data-id');

                document.body.appendChild(script1);
                setTimeout(() => {
                    const script2 = document.createRange().createContextualFragment(scripts[1]);
                    document.body.appendChild(script2);
                }, 300);
                return;
            }
        }, 300);
    }
}
