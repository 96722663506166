import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {Contact} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {map, switchMap} from 'rxjs/operators';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {getContacts} from '../store/contacts.selector';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb, SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {ContactDelete, ContactsFetch} from '../store/contacts.actions';

@Component({
    selector: 'app-contacts-list',
    templateUrl: './contacts-list.component.html',
    styleUrls: ['./contacts-list.component.css']
})
export class ContactsListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('appLiteList') appLiteList;

    public contacts: Contact[];
    public loading: boolean;
    public totalRecords: number;
    public sortColumns: any;
    public showAllButtonValue = false;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected confirmationService: ConfirmationService,
        private ps: CustomPermissionsService,
        private translate: TranslateService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = forkJoin([
            this.translate.get('general.created_at'),
            this.translate.get('contacts.name'),
            this.translate.get('contacts.title_m'),
        ]).subscribe(result => {
            this.sortColumns = [
                {label: result[0], value: {dbField: 'c.created_at', name: 'general.created_at'}},
                {label: result[1], value: {dbField: 'c.firstName', name: 'contacts.first_name'}},
            ];

            this.store.dispatch(new SetBreadcrumb([
                {label: result[2], routerLink: '/contacts'}
            ]));
        });

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject)).subscribe((currentSubject) => {
            this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
                this.ps.hasPermission('createContacts').then((permission) => {
                    this.buttons = [...this.buttons];
                    if (permission) {
                        this.store.dispatch(new SetToolbarButtons([
                            {
                                id: 'createContactButton',
                                name: 'contacts.create',
                                action: 'onCreateContact',
                                class: 'p-button-primary',
                                icon: 'pi pi-plus',
                                disabled: false,
                            }])
                        );
                    }
                });
            });

            this.subs.sink = this.store.select(getContacts).pipe(
                map(state => {
                    return {
                        contacts: state.contacts,
                        totalRows: state.totalRows,
                    };
                }),
                switchMap(({
                               contacts: contacts,
                               totalRows: totalRows,
                           }) => {
                    if (contacts.length > 0) {

                        const promises = [];

                        contacts.forEach(record => {
                            promises.push(this.ps.hasSubjectPermission(currentSubject, 'updateContacts'));
                            promises.push(this.ps.hasSubjectPermission(currentSubject, 'deleteContacts'));
                        });

                        return Promise.all(
                            promises
                        ).then(results => {
                            contacts = contacts.map((user, index) => {
                                const idx1 = (index * 2);
                                const idx2 = (index * 2) + 1;

                                const updatePerm = results[idx1];
                                const deletePerm = results[idx2];

                                const contextMenu = [];

                                if (deletePerm) {
                                    contextMenu.push({
                                        label: this.translate.instant('general.delete'),
                                        icon: 'pi pi-trash',
                                        command: (event: Event) => {
                                            this.onDelete(user.id, user.firstName + ' ' + user.lastName + ' (' + user.email + ')');
                                        }
                                    });
                                }

                                const navLink = ['/', 'contacts'];
                                if (updatePerm) {
                                    navLink.push('edit');
                                }
                                navLink.push(user.id);

                                return {
                                    ...user,
                                    contextMenu,
                                    navLink
                                };
                            });

                            return {
                                contacts,
                                totalRows,
                            };
                        });
                    } else {
                        return new Promise((resolve) => {
                            resolve({
                                contacts: [],
                                totalRows: 0,
                            });
                        });
                    }

                })).subscribe(
                ({
                     contacts: contacts,
                     totalRows: totalRows,
                 }) => {
                    this.contacts = contacts;
                    this.totalRecords = totalRows;
                });
        });
    }

    onCreateContact() {
        this.router.navigate(['create'], {relativeTo: this.route});
    }

    fetchContacts(event: object): void {
        this.store.dispatch(new ContactsFetch(event));
    }

    loadLazy(event: LazyLoadEvent) {
        const searchData = LazyLoadMeta.fromData(event);
        this.fetchContacts(searchData);
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onEdit(id: number) {
        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    onDelete(id: number, identifier: string) {
        forkJoin([this.translate.get('contacts.delete_confirm_message')]).subscribe(result => {
            this.confirmationService.confirm({
                header: result[0],
                message:  identifier,

                accept: () => {
                    this.store.dispatch(new ContactDelete({contactId: id}));
                }
            });
        });
    }

    onSaveContact() {
        if (this.router.url.includes('create')) {
            this.showAllButtonValue = true;
        }
        this.onToolbarButtonClick('onSaveContact');
    }

    onResetClick() {
    }
}
