import { Component } from '@angular/core';
import {FormsHelperService} from '../forms-helper.service';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'app-contact-part',
    template: `
        <div class="card card-w-title" ngModelGroup="contactData">
            <input
                id="personId"
                type="number"
                ngModel
                name="personId"
                #personId="ngModel"
                hidden
            >

            <div class="p-d-flex form-group">
                <div class="p-col-12 ">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="firstname">{{'users.first_name' | translate}}<span style="color: red;">*</span></label>
                            <input
                                    id="firstname"
                                    type="text"
                                    pInputText
                                    ngModel
                                    name="firstName"
                                    required
                                    autofocus
                                    #firstName="ngModel"
                                    [ngClass]="!firstName.valid && (firstName.dirty || firstName.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="lastname">{{'users.last_name' | translate}} <span style="color: red;">*</span></label>
                            <input
                                id="lastname"
                                type="text"
                                pInputText
                                ngModel
                                name="lastName"
                                required
                                #lastName="ngModel"
                                [ngClass]="!lastName.valid && (lastName.dirty || lastName.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="email">{{'users.email' | translate}} <span style="color: red;">*</span></label>
                            <input
                                id="email"
                                type="text"
                                pInputText
                                ngModel
                                name="email"
                                required
                                email
                                #email="ngModel"
                                [ngClass]="!email.valid && (email.dirty || email.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="phoneNumber">{{'users.phone_number' | translate}}</label>
                            <input
                                id="phoneNumber"
                                type="text"
                                pInputText
                                ngModel
                                name="phoneNumber"
                                pattern="^\\+?(?:[0-9]?){6,14}[0-9]$"
                                #phoneNumber="ngModel"
                                [ngClass]="!phoneNumber.valid && (phoneNumber.dirty || phoneNumber.touched) ? 'ng-dirty ng-invalid' : ''"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
					        <label for="companyName">{{'users.company-name' | translate}}</label>
                            <input
                                    id="companyName"
                                    type="text"
                                    pInputText
                                    ngModel
                                    name="companyName"
                            >
                        </div>
                    </div>
                    <div class="p-fluid">
                        <div class="p-field">
                        <label for="function">{{'users.function' | translate}}</label>
                            <input
                                    id="function"
                                    type="text"
                                    pInputText
                                    ngModel
                                    name="function"
                            >
                        </div>
                    </div>
				</div>
			</div>
        </div>
    `,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class ContactPartComponent {

  constructor(
      public formsHelper: FormsHelperService
  ) { }

}
