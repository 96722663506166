import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    TagDefectFetch,
    TagDefectsFetch,
    TagDefectCreateSuccess,
    TagDefectUpdateSuccess,
    TagDefectDeleteSuccess,
    TagDefectSet,
    TagDefectBrowseFetch,
    TagDefectsError,
    TagDefectsFetchLazyMeta,
    TagDefectsActionTypes,
    TagDefectsSet, TagDefectUpdate, TagDefectDelete,
} from './tag-defects.actions';
import {Observable, of} from 'rxjs';
import {ConPointService} from '../../../../../generated/graphql';
import {ApolloError} from '@apollo/client/core';
import {AppState} from '../../../../store/app.reducer';
import {State} from './tag-defects.reducer';
import {DialogHide} from '../../../../forms/tags/defect-create/store/defect-create-form.actions';

const handleError = (errorRes: ApolloError) => {
    return of(new TagDefectsError());
};

@Injectable()
export class TagDefectsEffects {
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>,
                private conPointService: ConPointService) {}

    @Effect()
    defectsFetch = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectsFetch),
        switchMap((defectsActions: TagDefectsFetch) => {
            this.store.dispatch(new TagDefectsFetchLazyMeta({lazyLoad: defectsActions.payload}));
            return this.conPointService.getTagDefectsList({lazyLoad: defectsActions.payload}).pipe(
                map((result) => {
                    const defects = result.data.GetTagDefectsList.result?.map(defect => {
                        return {...defect};
                    });

                    return {
                        defects,
                        total: result.data.GetTagDefectsList.metadata.total,
                        count: result.data.GetTagDefectsList.metadata.count
                    };
                }),
                map(({defects, total, count}) => {
                    return new TagDefectsSet({defects: defects ?? [], totalRows: total });
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    defectFetch = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectFetch),
        switchMap((defectsActions: TagDefectFetch) => {
            return this.conPointService.getTagDefect({id: defectsActions.payload.defectId}).pipe(
                map((result) => {
                    return new TagDefectSet({defect: result.data.GetTagDefect});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    defectBrowseFetch = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectBrowseFetch),
        switchMap((defectsActions: TagDefectBrowseFetch) => {
            return this.conPointService.getTagDefectBrowse({
                projectKeyName: defectsActions.payload.projectKeyName,
                tagSequenceNumber: defectsActions.payload.tagSequenceNumber
            }).pipe(
                map((result) => {
                    return new TagDefectSet({defect: result.data.GetTagDefectBrowse});
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    defectCreate = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectCreate),
        withLatestFrom(this.store.select('tagDefects')),
        switchMap<[TagDefectUpdate, State], Observable<any>>(([defectsActions, defectsState]) => {
            return this.conPointService.createTagDefect(
                {defect: defectsActions.payload.defect}).pipe(
                map(() => {
                    this.store.dispatch(new TagDefectsFetch(defectsState.currentLazyLoad));
                    this.store.dispatch(new DialogHide());
                    return new TagDefectCreateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    defectUpdate = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectUpdate),
        withLatestFrom(this.store.select('tagDefects')),
        switchMap<[TagDefectUpdate, State], Observable<any>>(([defectsActions, defectsState]) => {
            return this.conPointService.updateTagDefect({defect: defectsActions.payload.defect, action: defectsActions.payload.action}).pipe(
                map((data) => {
                    this.store.dispatch(new TagDefectsFetch(defectsState.currentLazyLoad));
                    this.store.dispatch(new TagDefectSet({defect: data.data.UpdateTagDefect}));
                    return new TagDefectUpdateSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    defectDelete = this.actions$.pipe(
        ofType(TagDefectsActionTypes.TagDefectDelete),
        withLatestFrom(this.store.select('tagDefects')),
        switchMap<[TagDefectDelete, State], Observable<any>>(([defectsActions, defectsState]) => {
            return this.conPointService.deleteTagDefect({id: defectsActions.payload.defectId}).pipe(
                map(() => {
                    this.store.dispatch(new TagDefectsFetch(defectsState.currentLazyLoad));
                    return new TagDefectDeleteSuccess();
                }),
                catchError(errorRes => {
                    return handleError(errorRes);
                })
            );
        })
    );
}
