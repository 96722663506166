import {createFeatureSelector, createSelectorFactory, MemoizedProjection} from '@ngrx/store';
import { State } from './tag-defects.reducer';
import {AnyFn} from '@ngrx/store/src/selector';

export const getDefectsFeature = createFeatureSelector<State>('tagDefects');

export function isEqual(a: any, b: any): boolean {
    return a.totalRows === b.totalRows &&
        a.defects.every(fromA => b.defects.includes(fromA));
}

export function customMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export const getDefects = createSelectorFactory(customMemoize)(
    getDefectsFeature,
    (state) => {
        return {
            currentLazyLoad: state.currentLazyLoad,
            defects: state.defects,
            totalRows: state.totalRows
        };
    }
);
