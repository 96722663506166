export class User {
  constructor(
    public email: string,
    public id: number,
    private pToken: string,
    private pTokenExpirationDate: Date
  ) {}

  get token() {
    if (!this.pTokenExpirationDate || new Date() > this.pTokenExpirationDate) {
      return null;
    }
    return this.pToken;
  }
}
