import {Component, OnInit, ViewChild} from '@angular/core';
import {DefectCreateComponent} from '../../../forms/tags/defect-create/defect-create.component';
import {BaseComponent} from '../../../shared/base/base.component';
import {forkJoin} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../shared/services/global.service';
import {AppState} from '../../../store/app.reducer';
import {SetBreadcrumb} from '../../../fixed-toolbar/fixed-toolbar.action';
import {DialogShow} from '../../../forms/tags/task-create/store/task-create-form.actions';

@Component({
  selector: 'app-tag-tasks',
  templateUrl: './tag-tasks.component.html',
  styleUrls: ['./tag-tasks.component.css']
})
export class TagTasksComponent extends BaseComponent implements OnInit {

    @ViewChild(DefectCreateComponent) defectCreateComponent: DefectCreateComponent;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        forkJoin([
            this.translate.get('tasks.title_m'),
        ]).subscribe(result => {
            this.store.dispatch(new SetBreadcrumb([
                {label: result[0], routerLink: '/tasks'},
            ]));
        });
    }

    onCreateTask() {
        this.store.dispatch(new DialogShow());
    }
}
