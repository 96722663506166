import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
    TagTaskInput,
} from '../../../../generated/graphql';
import {select, Store} from '@ngrx/store';
import {TagsService} from '../../../components/tags/tags.service';
import {UploadManager} from '../../../shared/classes/upload-manager';
import {currentStateGetCurrentProject} from '../../../store/current-state/current-state.selector';
import {take} from 'rxjs/operators';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {TagTypes} from '../../../shared/enums/entity-enums';
import {AppState} from '../../../store/app.reducer';
import {TagTaskCreate} from '../../../components/tags/tag-tasks/store/tag-tasks.actions';
import {GeneralSaveFormAction} from '../../../store/general/general.actions';
import {AppActions} from '../../../store/app-actions.service';
import {generalGetTaskCreateForm} from '../../../store/general/general.selector';
import {GlobalService} from '../../../shared/services/global.service';
import {TagDialogBaseComponentComponent} from '../../../components/tags/tag-dialog-base-component/tag-dialog-base-component.component';
import {DialogHide} from './store/task-create-form.actions';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {Gallery} from '@ngx-gallery/core';

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.css']
})
export class TaskCreateComponent extends TagDialogBaseComponentComponent implements OnInit, OnDestroy {

    tagType: number = TagTypes.TAG_TASK;

    @ViewChild('taskImageUpload') taskImageUpload;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected tagsService: TagsService,
        private enumsHelper: EnumsHelper,
        private appActions: AppActions,
        protected ps: CustomPermissionsService,
        protected enums: EnumsHelper,
        protected gallery: Gallery,
    ) {
        super(store, gs, tagsService, ps, enums, gallery);
    }

    ngOnInit(): void {
        this.uploadManager = new UploadManager(this.store, this.tagsService, 'task');

        this.subs.sink = this.uploadManager.uploadingState.subscribe((state) => {
            this.uploadingState = state;
        });

        this.subs.sink = this.store.select('tagTaskCreate').pipe().subscribe((data) => {
            if (this.display !== data.dialogShow) {
                this.display = data.dialogShow;

                if (this.display) {
                    this.severities = this.enumsHelper.severities;
                    this.store.select(generalGetTaskCreateForm).pipe(take(1)).subscribe((formData) => {
                        if (formData?.data == null) {
                            this.store.pipe(take(1), select(currentStateGetCurrentProject)).subscribe(project => {
                                this.selectedProject = project;
                                this.tagForm.form.patchValue({
                                    project: this.selectedProject
                                });
                            });

                            this.selectedSeverity = {id: 1};
                        } else {
                            const fData = JSON.parse(formData.data);
                            this.tagForm.form.patchValue({
                                ...fData,
                                occurredAt: fData.occurredAt ? new Date(fData.occurredAt) : null,
                                deadline: fData.deadline ? new Date(fData.deadline) : null,
                            });
                        }

                        this.subs.sink = this.tagForm.valueChanges.subscribe(() => {
                            this.appActions.dispatch(new GeneralSaveFormAction({
                                id: 'taskCreateForm',
                                data: JSON.stringify(this.tagForm.value)
                            })).toPromise().then();
                        });
                    });
                }
            }
        });
    }

    ngOnDestroy() {
        this.clearUploadImages();
        this.subs.unsubscribe();
    }

    clearUploadImages() {
        if (this.taskImageUpload) {
            if (this.imagesStored) {
                this.uploadManager.clearUploadedFilesList();
            }
            this.taskImageUpload.clear();
        }
    }

    onHide(event) {
        this.clearUploadImages();
        this.store.dispatch(new DialogHide());
    }

    onCancel() {
        this.store.dispatch(new DialogHide());
    }

    onSubmit(form: NgForm) {
        const task: TagTaskInput = {
            severity: form.value.severity.id,
            location: form.value.location,
            project: form.value.project.id,
            assignedToSubjects: form.value.assignedToSubjects.map(s => s.id) ?? [],
            assignedToSubjectNote: form.value?.assignedToSubjectNote ?? null,
            assignedToUser: form.value.assignedToUser?.id ?? null,
            areaOf: form.value.areaOf?.id ?? null,
            deadline: form.value.deadline,
            deadlineTerm: form.value.deadlineTerm,
            description: form.value.description,
            images: this.uploadManager.getFilesNameArray(),
            note: form.value.note
        };

        this.imagesStored = true;
        this.store.dispatch(new TagTaskCreate({task}));
    }
}
