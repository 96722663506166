import { Injectable } from '@angular/core';
import {AbstractControl, NgModel} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormsHelperService {

  constructor() { }

    isRequiredField(field: NgModel) {
        const validator = field.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    checkValidity(field: NgModel) {
        if (field) {
            return (
                (field.touched || field.dirty) &&
                ((field.value && !field.value.length && this.isRequiredField(field)) || field.invalid)
            );
        }
        return true;
    }
}
