import * as ReportsActions from './reports.actions';
import {ReportsActionTypes} from './reports.actions';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {Report, ReportSettings} from '../../../../generated/graphql';

export interface State {
    reports: Report[];
    currentLazyLoad: LazyLoadMeta;
    loadedReport: Report;
    settings: ReportSettings;
    totalRows: number;
    appConf: {reports: Report[]};
}

const initialState = {
    reports: [],
    currentLazyLoad: null,
    loadedReport: null,
    settings: null,
    totalRows: 0,
    appConf: {reports: []},
};

export function reportsReducer(state: State = initialState, action: ReportsActions.ReportsActions) {
    switch (action.type) {
        case ReportsActionTypes.ReportsSet:
            state =  {
                ...state,
                reports: [...action.payload.reports],
                totalRows: action.payload.totalRows
            };
            break;
        case ReportsActionTypes.ReportSet:
            state =  {
                ...state,
                loadedReport: action.payload.report
            };
            break;
        case ReportsActionTypes.ReportsFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case ReportsActionTypes.ReportsError:
            state =  {
                ...state,
            };
            break;
        case ReportsActionTypes.ReportGeneralSettingsSet:
            state = {
                ...state,
                settings: action.payload.settings
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
