import {
    AfterViewChecked,
    Component,
    ComponentRef,
    ElementRef, HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { MenuService } from './app.menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {AppComponent} from './app.component';
import {SubSink} from 'subsink';
import {select, Store} from '@ngrx/store';
import {BreadcrumbService} from './breadcrumb.service';
import {GlobalService} from './shared/services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {getGlobalErrorMessages} from './loading/loading.selector';
import {RemoveMessage} from './loading/loading.action';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent implements OnInit, OnDestroy, AfterViewChecked {

    private subs = new SubSink();

    componentRef: ComponentRef<any>;

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    menuClick: boolean;

    staticMenuActive = true;

    menuMobileActive: boolean;

    megaMenuClick: boolean;

    megaMenuActive: boolean;

    megaMenuMobileClick: boolean;

    megaMenuMobileActive: boolean;

    topbarItemClick: boolean;

    topbarMobileMenuClick: boolean;

    topbarMobileMenuActive: boolean;

    sidebarActive: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    menuHoverActive: boolean;

    configActive: boolean;

    configClick: boolean;

    envDesktop = true;

    @ViewChild('layoutContent') layoutContent: ElementRef;

    constructor(public renderer: Renderer2,
                private menuService: MenuService,
                private primengConfig: PrimeNGConfig,
                public app: AppComponent,
                private store: Store<any>,
                private messageService: MessageService,
                private breadcrumbService: BreadcrumbService,
                public gs: GlobalService,
                private translator: TranslateService
    ) {}

    ngOnInit() {

        this.envDesktop = this.isDesktop();

        this.subs.sink = this.store.select('breadcrumb').pipe(
            map(breadcrumbState => {
                return {breadcrumb: breadcrumbState.breadcrumbItems, toolbarButtons: breadcrumbState.toolbarButtons};
            })).subscribe(
            async ({breadcrumb: breadcrumb, toolbarButtons: toolbarButtons}) => {

                if (breadcrumb.length > 0) {
                    this.breadcrumbService.setItems(breadcrumb);
                }

                const buttons = [];
                toolbarButtons.forEach((button, index) => {
                    buttons.push(button);
                });

                if (buttons.length > 0) {
                    this.breadcrumbService.setButtons(buttons);
                } else {
                    this.breadcrumbService.setButtons([]);
                }
            });

        this.sidebarActive = true;

        this.subs.sink = this.store.pipe(select(getGlobalErrorMessages)).subscribe(
            (data) => {
                if (data.length > 0) {
                    data.forEach((message => {
                        this.messageService.add({
                            key: 'tst',
                            severity: message.severity,
                            summary: this.translator.instant(message.summary),
                            detail: this.translator.instant(message.data),
                        });
                    }));
                    this.store.dispatch(new RemoveMessage(data, 'global'));
                }
            }
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    ngAfterViewChecked() {
        const dimensions = {
            layoutContent: {
                height: this.layoutContent.nativeElement.height,
                width: this.layoutContent.nativeElement.width
            },
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        };

        this.gs.setDimensions(dimensions);
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.megaMenuClick) {
            this.megaMenuActive = false;
        }

        if (!this.megaMenuMobileClick) {
            this.megaMenuMobileActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }

            this.menuHoverActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
        this.megaMenuClick = false;
        this.megaMenuMobileClick = false;
        this.rightPanelClick = false;
    }

    onMegaMenuButtonClick(event) {
        this.megaMenuClick = true;
        this.megaMenuActive = !this.megaMenuActive;
        event.preventDefault();
    }

    onMegaMenuClick(event) {
        this.megaMenuClick = true;
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        event.preventDefault();
    }

    onRightPanelClose(event) {
        this.rightPanelActive = false;
        this.rightPanelClick = false;

        event.preventDefault();
    }

    onRightPanelClick(event) {
        this.rightPanelClick = true;

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMobileMenuClick = true;
        this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

        event.preventDefault();
    }

    onMegaMenuMobileButtonClick(event) {
        this.megaMenuMobileClick = true;
        this.megaMenuMobileActive = !this.megaMenuMobileActive;

        event.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    isDesktop() {
        if (window.innerWidth > 991) {
            this.envDesktop = true;
            return true;
        } else {
            this.envDesktop = false;
            return false;
        }
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isHorizontal() {
        return this.app.horizontalMenu === true;
    }

    onToolbarButtonClick(action: string) {
        const generalMethod = 'onToolbarButtonClick';

        if (this.componentRef[action] !== undefined) {
            this.componentRef[action]();
        } else if (this.componentRef[generalMethod]) {
            this.componentRef[generalMethod](action);
        } else {
            throw new Error('Button click handle not exists');
        }
    }

    onActivate(componentRef: ComponentRef<any>) {
        this.componentRef = componentRef;
    }

    onCloseLogContainer(logContainer: HTMLDivElement) {
        logContainer.classList.add('hidden');
    }


    async onDownloadApp() {
        const response = await fetch(
            this.gs.getEnvironment().baseUrl +
            'assets/apk/current-version-' +
            this.gs.getEnvironment().envShortName +
            '.txt?timestamp=' + Date.now());

        const version = await response.text();
        if (this.gs.getEnvironment().production) {
            window.location.href = this.gs.getEnvironment().baseUrl +
                'assets/apk/conpoint_' + version + '.apk';
        } else {
            window.location.href = this.gs.getEnvironment().baseUrl +
                'assets/apk/conpoint-' +
                this.gs.getEnvironment().envShortName + '_' + version + '.apk';
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isDesktop();
    }
}
