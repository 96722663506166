import {Message} from 'primeng/api';

export class FlashMessage implements Message {
    severity?: string;
    summary?: string;
    detail?: string;
    id?: any;
    key?: string;
    life?: number;
    sticky?: boolean;
    closable?: boolean;
    data?: any;

    public constructor() {
    }

    static successMessage(msg: string) {
        const object = new this();
        object.severity = 'success';
        object.summary = 'Success';
        object.data = msg;
        object.id = msg.toUpperCase();
        object.closable = true;
        return object;
    }

    static errorMessage(msg: string) {
        const object = new this();
        object.severity = 'error';
        object.summary = 'Error';
        object.data = msg;
        object.id = msg.toUpperCase();
        object.closable = true;
        return object;
    }

    static warningMessage(msg: string) {
        const object = new this();
        object.severity = 'warn';
        object.summary = 'Warning';
        object.data = msg;
        object.id = msg.toUpperCase();
        object.closable = true;
        return object;
    }

    static infoMessage(msg: string) {
        const object = new this();
        object.severity = 'info';
        object.summary = 'Info';
        object.data = msg;
        object.id = msg.toUpperCase();
        object.closable = true;
        return object;
    }

    static generalMessage(severity: string, msg: string) {
        const object = new this();
        object.severity = severity;
        object.summary = severity.charAt(0).toUpperCase() + severity.slice(1);
        object.data = msg;
        object.id = msg.toUpperCase();
        object.closable = true;
        return object;
    }
}
