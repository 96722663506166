import * as TagGoodPracticesActions from './tag-good-practice.actions';
import {LazyLoadMeta} from '../../../../shared/model/lazy-load-meta.model';
import {TagGoodPractice} from '../../../../../generated/graphql';
import {TagGoodPracticeActions} from './tag-good-practice.actions';

export interface State {
    goodPractices: TagGoodPractice[];
    loadedTagGoodPractice: TagGoodPractice;
    goodPracticeFormDialogShow: boolean;
    totalRows: number;
    currentLazyLoad: LazyLoadMeta;
}

const initialState = {
    goodPractices: [],
    currentLazyLoad: null,
    loadedTagGoodPractice: null,
    goodPracticeFormDialogShow: false,
    totalRows: 0,
};

export function tagGoodPracticeReducer(state: State = initialState, action: TagGoodPracticeActions) {
    switch (action.type) {
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticeCreateSuccess:
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticeUpdateSuccess:
            state = {
                ...state
            };
            break;
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticesSet:
            state = {
                ...state,
                goodPractices: [...action.payload.goodPractices],
                totalRows: action.payload.totalRows
            };
            break;
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticeSet:
            state = {
                ...state,
                loadedTagGoodPractice: action.payload.goodPractice
            };
            break;
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticesFetchLazyMeta:
            state = {
                ...state,
                currentLazyLoad: action.payload.lazyLoad
            };
            break;
        case TagGoodPracticesActions.TagGoodPracticesActionTypes.TagGoodPracticesError:
            state = {
                ...state
            };
            break;
        default:
            return state;
            break;
    }
    return state;
}
