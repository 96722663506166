import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './general.reducer';

export const getGeneralFeature = createFeatureSelector<State>('general');

export const generalGetDefectCreateForm = createSelector(
    getGeneralFeature,
    (state) => {
        return state.forms.find(x => x.id === 'defectCreateForm');
    }
);

export const generalGetTaskCreateForm = createSelector(
    getGeneralFeature,
    (state) => {
        return state.forms.find(x => x.id === 'taskCreateForm');
    }
);

export const generalGetGoodPracticeCreateForm = createSelector(
    getGeneralFeature,
    (state) => {
        return state.forms.find(x => x.id === 'goodPracticeCreateForm');
    }
);
