import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {NgForm} from '@angular/forms';
import {AreaOf, AreaOfInput} from '../../../../generated/graphql';
import {Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TagsService} from '../../tags/tags.service';
import {Gallery} from '@ngx-gallery/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {PresetFilterService} from '../../../shared/services/preset-filter.service';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {AreaOfCreate, AreaOfFetch, AreaOfSet, AreaOfUpdate} from '../store/areas-of.actions';

@Component({
    selector: 'app-area-of-edit',
    templateUrl: './area-of-edit.component.html',
    styleUrls: ['./area-of-edit.component.css']
})
export class AreaOfEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('f') areaOfForm: NgForm;
    public type = 1;
    private loadedAreaOf: AreaOf;
    private editId: number;
    public editMode: boolean;

    constructor(
        protected store: Store<AppState>,
        protected gs: GlobalService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected tagsService: TagsService,
        private gallery: Gallery,
        private translate: TranslateService,
        private ps: CustomPermissionsService,
        private presetFilterService: PresetFilterService,
    ) {
        super(store, gs);
    }

    ngOnInit(): void {
        this.subs.sink = this.route.params.subscribe((params: Params) => {
            this.editId = +params.id;
            this.editMode = params.id != null;

            forkJoin([
                this.translate.get('area_of.title_m'),
                this.translate.get('area_of.edit'),
                this.translate.get('area_of.create'),
            ]).subscribe(result => {
                if (this.editMode) {
                    this.fetchAreaOf(this.editId);
                }
            });
        });
    }

    ngAfterViewInit() {
        this.buttons = [
            {
                id: 'saveAreaOfButton',
                name: 'area_of.save',
                action: 'onSaveAreaOf',
                class: 'p-button-success',
                icon: 'pi pi-save p-mr-1',
                disabled: false,
            }
        ];

        this.subs.sink = this.ps.getNgxPermissions$().subscribe(() => {
            this.ps.hasPermission('createGoodPractice').then((permission) => {
                this.buttons = [...this.buttons];
                if (permission) {
                    this.buttons.push(
                        {
                            id: 'createAreaOfButton',
                            name: 'area_of.create',
                            action: 'onCreateAreaOf',
                            class: 'p-button-primary',
                            icon: 'pi pi-plus',
                            disabled: false,
                        }
                    );
                }
                this.store.dispatch(new SetToolbarButtons(this.buttons));
            });
        });

        this.subs.sink = this.store.select('areasOf').pipe(
            map(areasOfState => {
                return {
                    loadedAreaOf: areasOfState.loadedAreaOf,
                };
            })).subscribe(
            ({
                 loadedAreaOf: loadedAreaOf,
             }) => {
                if (loadedAreaOf) {
                    this.areaOfForm.form.patchValue({
                            ...loadedAreaOf,
                            areaOfId: loadedAreaOf.id,
                        }
                    );
                    this.store.dispatch(new AreaOfSet({areaOf: null}));
                    this.loadedAreaOf = loadedAreaOf;
                    this.type = this.loadedAreaOf.type;
                }
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    fetchAreaOf(id: number) {
        this.store.dispatch(new AreaOfFetch({id}));
    }

    onSaveAreaOf() {
        this.areaOfForm.ngSubmit.emit();
    }

    onSubmit(form: NgForm) {
        const areaOf: AreaOfInput = {
            id: (this.editMode) ? +this.editId : null,
            name: form.value.name,
            description: form.value.description,
            type: this.type
        };

        if (this.editMode) {
            this.store.dispatch(new AreaOfUpdate({areaOf}));
        } else {
            this.store.dispatch(new AreaOfCreate({areaOf}));
            setTimeout(() => {
                this.presetFilterService.setAreaOfPresetFilter([{field: 'type', value: this.type}]);
            }, 150);
        }
    }
}
