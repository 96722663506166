import {createFeatureSelector, createSelectorFactory, MemoizedProjection} from '@ngrx/store';
import { State } from './areas-of.reducer';
import {AnyFn} from '@ngrx/store/src/selector';

export const getAreasOfFeature = createFeatureSelector<State>('areasOf');

export function isEqual(a: any, b: any): boolean {
    return a.totalRows === b.totalRows &&
        a.areasOf.every(fromA => b.areasOf.includes(fromA));
}

export function customMemoize(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() { lastResult = null; }

    return {
        clearResult(): void {
        }, setResult(result: any): void {
        }, memoized, reset};
}

export const getAreasOf = createSelectorFactory(customMemoize)(
    getAreasOfFeature,
    (state) => {
        return {
            areasOf: state.areasOf,
            totalRows: state.totalRows,
            currentLazyLoad: state.currentLazyLoad
        };
    }
);
