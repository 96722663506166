import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {select, Store} from '@ngrx/store';
import {GlobalService} from '../../../shared/services/global.service';
import {ProxyService} from '../../../shared/services/proxy.service';
import {EnumsHelper} from '../../../shared/helpers/enums-helper.service';
import {SubjectType} from '../../../shared/model/subject-type.model';
import {currentStateGetCurrentSubject} from '../../../store/current-state/current-state.selector';
import {getDefects} from '../../tags/tag-defects/store/tag-defects.selector';
import {map, take} from 'rxjs/operators';
import {CustomPermissionsService} from '../../../shared/services/custom-permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {
    ReportInput, ReportLvSettings, ReportTagInput, TagDefect, TagGoodPractice, TagTask
} from '../../../../generated/graphql';
import {LazyLoadMeta} from '../../../shared/model/lazy-load-meta.model';
import {NgForm} from '@angular/forms';
import {getTasks} from '../../tags/tag-tasks/store/tag-tasks.selector';
import {getGoodPractices} from '../../tags/tag-good-practice/store/tag-good-practice.selector';
import {Table} from 'primeng/table';
import {AppState} from '../../../store/app.reducer';
import {SetToolbarButtons} from '../../../fixed-toolbar/fixed-toolbar.action';
import {TagDefectsFetch} from '../../tags/tag-defects/store/tag-defects.actions';
import {TagTasksFetch} from '../../tags/tag-tasks/store/tag-tasks.actions';
import {TagGoodPracticesFetch} from '../../tags/tag-good-practice/store/tag-good-practice.actions';
import {ReportCreate} from '../store/reports.actions';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-report-create',
    templateUrl: './report-create.component.html',
    styleUrls: ['./report-create.component.css']
})
export class ReportCreateComponent extends BaseComponent implements OnInit {

    @ViewChild('f') form: NgForm;

    @ViewChild('dt') dataTable: Table;

    public defectsFiltersDef: any;
    public defectsCols: any;
    public defects: TagDefect[];
    public selectedDefects: TagDefect[];
    public defectsTotalRecords: number;

    public tasksFiltersDef: any;
    public tasksCols: any;
    public tasks: TagTask[];
    public selectedTasks: TagTask[];
    public tasksTotalRecords: number;

    public goodPracticeFiltersDef: any;
    public goodPracticeCols: any;
    public goodPractices: TagGoodPractice[];
    public selectedGoodPractices: TagGoodPractice[];
    public goodPracticeTotalRecords: number;

    public lastValueSettings: ReportLvSettings;

    public lastValuesLoading = true;
    public defectsLoading = true;
    public tasksLoading = true;
    public goodPracticeLoading = true;

    constructor(
        protected store: Store<AppState>,
        public gs: GlobalService,
        protected proxyService: ProxyService,
        protected enumsHelper: EnumsHelper,
        protected ps: CustomPermissionsService,
        protected translate: TranslateService
    ) {
        super(store, gs);
    }

    ngOnInit(): void {

        this.buttons.push(
            {
                id: 'generateReportButton',
                name: 'reports.generate.button',
                action: 'onGenerateReport',
                class: 'p-button-primary',
                icon: 'pi pi-plus',
                disabled: false,
            }
        );
        this.store.dispatch(new SetToolbarButtons(this.buttons));

        this.subs.sink = this.store.pipe(select(currentStateGetCurrentSubject), take(1)).subscribe((currentSubject) => {
            this.subs.sink = this.store.select(getDefects).pipe(
                map(defectState => {
                    return {
                        defects: defectState.defects,
                        totalRows: defectState.totalRows,
                    };
                })
            ).subscribe(data => {
                this.defects = data.defects;
                this.selectedDefects = data.defects;
                this.defectsTotalRecords = data.totalRows;

                this.defectsLoading = false;
            });

            this.subs.sink = this.store.select(getTasks).pipe(
                map(tasksState => {
                    return {
                        tasks: tasksState.tasks,
                        totalRows: tasksState.totalRows,
                    };
                })
            ).subscribe(data => {
                this.tasks = data.tasks;
                this.selectedTasks = data.tasks;
                this.defectsTotalRecords = data.totalRows;

                this.tasksLoading = false;
            });

            this.subs.sink = this.store.select(getGoodPractices).pipe(
                map(goodPracticeState => {
                    return {
                        goodPractices: goodPracticeState.goodPractices,
                        totalRows: goodPracticeState.totalRows,
                    };
                })
            ).subscribe(data => {
                this.goodPractices = data.goodPractices;
                this.selectedGoodPractices = data.goodPractices;
                this.goodPracticeTotalRecords = data.totalRows;

                this.goodPracticeLoading = false;
            });

            this.proxyService.getReportLVSettings().then((data: ReportLvSettings) => {
                this.lastValueSettings = data;
                this.lastValuesLoading = false;
            });

            this.fetchDefects(null);
            this.fetchTasks(null);
            this.fetchGoodPractice(null);
        });

        this.defectsCols = [
            { field: 'thumbnail', header: 'general.thumbnail', display: 'table-cell' },
            { field: 'name', header: 'general_tag.identifier', display: 'table-cell' },
            { field: 'state', header: 'general_tag.state.title', display: 'table-cell' },
            { field: 'severity', header: 'general_tag.severity.title', display: 'table-cell' },
            { field: 'assignedToSubject', header: 'general_tag.assigned_to_subject', display: 'table-cell' },
            { field: 'occurredAt', header: 'general_tag.occurred_at', display: 'table-cell' },
            { field: 'deadline', header: 'defects.deadline', display: 'table-cell' },
        ];

        this.tasksCols = [
            { field: 'thumbnail', header: 'general.thumbnail', display: 'table-cell' },
            { field: 'name', header: 'general_tag.identifier', display: 'table-cell' },
            { field: 'state', header: 'general_tag.state.title', display: 'table-cell' },
            { field: 'severity', header: 'general_tag.severity.title', display: 'table-cell' },
            { field: 'assignedToSubject', header: 'general_tag.assigned_to_subject', display: 'table-cell' },
            { field: 'occurredAt', header: 'general_tag.occurred_at', display: 'table-cell' },
            { field: 'deadline', header: 'tasks.deadline', display: 'table-cell' },
        ];

        this.goodPracticeCols = [
            { field: 'thumbnail', header: 'general.thumbnail', display: 'table-cell' },
            { field: 'name', header: 'general_tag.identifier', display: 'table-cell' },
            { field: 'state', header: 'general_tag.state.title', display: 'table-cell' },
            { field: 'assignedToSubject', header: 'general_tag.assigned_to_subject', display: 'table-cell' },
            { field: 'occurredAt', header: 'general_tag.occurred_at', display: 'table-cell' },
        ];

        this.defectsFiltersDef = {
            rows: [
                [
                    {
                        field: 'occurredAt',
                        dbField: 'td.occurred_at',
                        matchMode: 'dateRange',
                        col: 'p-col-8',
                        label: 'general_tag.occurred_at'
                    }
                ],
                [
                    {
                        field: 'severity',
                        dbField: 'td.severity_id',
                        matchMode: 'in',
                        values: this.enumsHelper.severitiesMulti,
                        col: 'p-col-2',
                        label: 'general_tag.severity.title',
                        placeholder: 'general_tag.state.choose'
                    },
                    {
                        field: 'state',
                        dbField: 'td.state_id',
                        matchMode: 'in',
                        values: this.enumsHelper.statesMulti,
                        col: 'p-col-2',
                        label: 'general_tag.state.title',
                        placeholder: 'general_tag.state.choose'
                    },
                    {
                        field: 'subject',
                        dbField: 's2.subject_id',
                        matchMode: 'in',
                        values: this.proxyService.getSubjectsListOptions(SubjectType.SUBJECT),
                        col: 'p-col-4',
                        label: 'subjects.title_s',
                        placeholder: 'subjects.choose_record'
                    }
                ],
            ]
        };

        this.tasksFiltersDef = {
            rows: [
                // [
                //     {
                //         field: 'occurredAt',
                //         dbField: 'tt.occurred_at',
                //         matchMode: 'dateRange',
                //         col: 'p-col-8',
                //         label: 'general_tag.occurred_at'
                //     }
                // ],
                [
                    {
                        field: 'severity',
                        dbField: 'tt.severity_id',
                        matchMode: 'in',
                        values: this.enumsHelper.severitiesMulti,
                        col: 'p-col-2',
                        label: 'general_tag.severity.title',
                        placeholder: 'general_tag.state.choose'
                    },
                    {
                        field: 'state',
                        dbField: 'tt.state_id',
                        matchMode: 'in',
                        values: this.enumsHelper.statesMulti,
                        col: 'p-col-2',
                        label: 'general_tag.state.title',
                        placeholder: 'general_tag.state.choose'
                    },
                    {
                        field: 'subject',
                        dbField: 's2.subject_id',
                        matchMode: 'in',
                        values: this.proxyService.getSubjectsListOptions(SubjectType.SUBJECT),
                        col: 'p-col-4',
                        label: 'subjects.title_s',
                        placeholder: 'subjects.choose_record'
                    }
                ],
            ]
        };

        this.goodPracticeFiltersDef = {
            rows: [
                [
                    {
                        field: 'occurredAt',
                        dbField: 'tg.occurred_at',
                        matchMode: 'dateRange',
                        col: 'p-col-8',
                        label: 'general_tag.occurred_at'
                    }
                ],
                [
                    {
                        field: 'state',
                        dbField: 'tg.state_id',
                        matchMode: 'in',
                        values: this.enumsHelper.statesMulti,
                        col: 'p-col-2',
                        label: 'general_tag.state.title',
                        placeholder: 'general_tag.state.choose'
                    },
                    {
                        field: 'subject',
                        dbField: 's2.subject_id',
                        matchMode: 'in',
                        values: this.proxyService.getSubjectsListOptions(SubjectType.SUBJECT),
                        col: 'p-col-4',
                        label: 'subjects.title_s',
                        placeholder: 'subjects.choose_record'
                    }
                ],
            ]
        };
    }

    fetchDefects(lazyLoad: LazyLoadMeta) {
        if (!lazyLoad) {
            lazyLoad = this.createLazyLoad(null, 'td.created_at');
        }
        this.store.dispatch(new TagDefectsFetch(lazyLoad));
    }

    fetchTasks(lazyLoad: LazyLoadMeta) {
        if (!lazyLoad) {
            lazyLoad = this.createLazyLoad(null, 'tt.created_at');
        }
        this.store.dispatch(new TagTasksFetch(lazyLoad));
    }

    fetchGoodPractice(lazyLoad: LazyLoadMeta) {
        if (!lazyLoad) {
            lazyLoad = this.createLazyLoad(null, 'tg.created_at');
        }
        this.store.dispatch(new TagGoodPracticesFetch(lazyLoad));
    }

    createLazyLoad(lazyLoad: LazyLoadMeta, sortField: string) {
        if (!lazyLoad) {
            lazyLoad = {} as LazyLoadMeta;
        }

        lazyLoad.first = 0;
        lazyLoad.rows = 10000;
        lazyLoad.sortField = sortField;
        lazyLoad.sortOrder = -1;

        return lazyLoad;
    }

    onDefectsFilterChanged(event: any) {
        let lazyLoad = this.dataTable.createLazyLoadMetadata();
        lazyLoad.filters = event;

        lazyLoad = this.createLazyLoad(lazyLoad, 'td.created_at');
        this.fetchDefects(lazyLoad);
    }

    onTasksFilterChanged(event: any) {
        let lazyLoad = this.dataTable.createLazyLoadMetadata();
        lazyLoad.filters = event;

        lazyLoad = this.createLazyLoad(lazyLoad, 'tt.created_at');
        this.fetchTasks(lazyLoad);
    }

    onGoodPracticeFilterChanged(event: any) {
        let lazyLoad = this.dataTable.createLazyLoadMetadata();
        lazyLoad.filters = event;

        lazyLoad = this.createLazyLoad(lazyLoad, 'tg.created_at');
        this.fetchGoodPractice(lazyLoad);
    }

    onGenerateReport() {
        this.form.ngSubmit.emit();
    }

    onSubmit(f: NgForm) {

        const defectsEnabled = f.value.defectsReportData.enabledFlag;
        const tasksEnabled = f.value.tasksReportData.enabledFlag;
        const goodPracticeEnabled = f.value.goodPracticeReportData.enabledFlag;

        const report: ReportInput = {
            id: null,
        };

        const defects = {enabled: defectsEnabled} as ReportTagInput;
        const tasks = {enabled: tasksEnabled} as ReportTagInput;
        const goodPractice = {enabled: goodPracticeEnabled} as ReportTagInput;

        if (defectsEnabled) {
            defects.ids = this.selectedDefects.map(record => record.id);
            defects.message = f.value.defectsReportData.message;
        }

        if (tasksEnabled) {
            tasks.ids = this.selectedTasks.map(record => record.id);
            tasks.message = f.value.tasksReportData.message;
        }

        if (goodPracticeEnabled) {
            goodPractice.ids = this.selectedGoodPractices.map(record => record.id);
            goodPractice.message = f.value.goodPracticeReportData.message;
        }

        report.defects = defects;
        report.tasks = tasks;
        report.goodPractice = goodPractice;

        report.header = {
            investorEnabled: true,
            oshEnabled: true,
            customName: f.value.settingsReportData.customName,
        };

        this.store.dispatch(new ReportCreate({report}));
    }
}
